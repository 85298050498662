import { createSlice } from '@reduxjs/toolkit';
import { COMMON_SLICE_KEY } from '../../Constants/Common';
import {
  getChassisType,
  getDiv,
  getPool,
  getSize,
  getSsl,
  getType,
} from '../../../services/Common/Common/get';

export const commonInitialState = {
  loading: false,
  error: null,
  ssl: [],
  pool: [],
  size: [],
  type: [],
  div: [],
  allDiv: localStorage?.getItem('allDiv')?.split(',') || null,
  chassisType: [],
};

const commonSlice = createSlice({
  name: COMMON_SLICE_KEY,
  initialState: commonInitialState,
  reducers: {
    /**
     * All division
     */
    setAllDiv: (state, action) => {
      const newAllDiv = action?.payload;
      return { ...state, allDiv: newAllDiv };
    },
  },
  extraReducers: builder => {
    /**
     * SSL
     */
    builder.addCase(getSsl.fulfilled, (state, action) => {
      state.loading = false;
      state.ssl = action.payload;
    });
    builder.addCase(getSsl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSsl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * POOL
     */
    builder.addCase(getPool.fulfilled, (state, action) => {
      state.loading = false;
      state.pool = action.payload;
    });
    builder.addCase(getPool.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPool.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Size
     */
    builder.addCase(getSize.fulfilled, (state, action) => {
      state.loading = false;
      state.size = action.payload;
    });
    builder.addCase(getSize.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Type
     */
    builder.addCase(getType.fulfilled, (state, action) => {
      state.loading = false;
      state.type = action.payload;
    });
    builder.addCase(getType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * division
     */
    builder.addCase(getDiv.fulfilled, (state, action) => {
      state.loading = false;
      state.div = action.payload;
    });
    builder.addCase(getDiv.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDiv.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Chassis Type
     */
    builder.addCase(getChassisType.fulfilled, (state, action) => {
      state.loading = false;
      state.chassisType = action.payload;
    });
    builder.addCase(getChassisType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getChassisType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { setAllDiv } = commonSlice?.actions;
export default commonSlice.reducer;
