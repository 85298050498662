import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchCompleteBookingList from '../../../../services/Dispatch/Export/Booking/patch/patchCompleteBookingList';
import dispatchKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePatchCompleteBookingList = ({
  setIsMessageModalOpen,
  setMessageModalMessage,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchCompleteBookingList,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({
        queryKey: dispatchKeys?.bookingCount(),
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setIsMessageModalOpen(true);
      setMessageModalMessage(error.message);
    },
  });
  return mutator;
};

export default usePatchCompleteBookingList;
