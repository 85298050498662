import BASIC_COL_DEF from '../../../../constants/Dispatch/Common/basicColDef';
import BUTTON_COL_DEF from '../../../../constants/Dispatch/Common/buttonColDef';
import { BUTTON_COLS } from '../../../../constants/Dispatch/Common/buttonColList';
import { DISPATCH_PAGE_EXPORT_BOOKING } from '../../../../constants/Dispatch/Common/category';
import { DISPATCH_CATEGORY_COLUMNS } from '../../../../constants/Dispatch/Common/columnList';
import {
  DISPATCH_COLUMN_GRID_FIELD,
  DISPATCH_GROUP_HEADER,
} from '../../../../constants/Dispatch/Common/dataFieldKey';
import EDITABLE_COL_DEF from '../../../../constants/Dispatch/Common/editableColDef';
import { STATUS_EDITABLE_COLS } from '../../../../constants/Dispatch/Common/editableColList';
import FIELD_HEADER from '../../../../constants/Dispatch/Common/fieldsHeader';
import FIELD_WIDTH from '../../../../constants/Dispatch/Common/fieldsWidth';
import { DISPATCH_CATEGORY_THEME } from '../../../../constants/Dispatch/Common/themeValues';
import convertStatus from './convertStatus';
import getCellStyle from './getCellStyle';
import getSortingDefs from './getSortingDefs';

const setResultColDef = ({
  status,
  rowData,
  editParam,
  isEditable,
  subFilter,
  ...props
}) => {
  const category = convertStatus({ status });
  const columnDef = DISPATCH_CATEGORY_COLUMNS?.[category]?.[status];

  const selectedItems = rowData?.dsp_column || [];

  const editableCols = isEditable ? STATUS_EDITABLE_COLS?.[status] : [];
  const buttonCols = BUTTON_COLS?.[status];

  const colors = DISPATCH_CATEGORY_THEME?.[category];
  const divColorSet = rowData?.bg_color || {};

  const fieldKey =
    (status === DISPATCH_PAGE_EXPORT_BOOKING && {
      ...FIELD_HEADER,
      dilv_location: 'DELV',
      rtn_location: 'RETURN',
    }) ||
    (category === 'brokerage' && {
      ...FIELD_HEADER,
      trailer_no: 'EQUIPMENT',
    }) ||
    FIELD_HEADER;
  const groupKey =
    (category === 'van' &&
      DISPATCH_GROUP_HEADER({ po: 'PICK UP', pu: 'L/E PICK UP' })) ||
    (category === 'brokerage' &&
      DISPATCH_GROUP_HEADER({ po: 'PICK UP', pu: 'L/E PICK UP' })) ||
    DISPATCH_GROUP_HEADER();

  const sortingDefs = getSortingDefs({ status, subFilter });

  const isGroupedColumn = field => {
    return field instanceof Object;
  };

  const setOneCol = field => {
    const editSettings = {
      ...(EDITABLE_COL_DEF?.[field] &&
        (EDITABLE_COL_DEF[field] instanceof Function
          ? EDITABLE_COL_DEF[field](editParam?.[field])
          : EDITABLE_COL_DEF[field])),
    };
    const basicSettings = {
      ...(BASIC_COL_DEF?.[field] &&
        (BASIC_COL_DEF[field] instanceof Function
          ? BASIC_COL_DEF[field](editParam?.[field])
          : BASIC_COL_DEF[field])),
    };
    const buttonSettings = {
      ...(BUTTON_COL_DEF?.[field] &&
        BUTTON_COL_DEF[field]({
          onClickHandler: editParam?.[field],
          ...props,
        })),
    };

    const sortSettings = sortingDefs?.[field] || {};

    return {
      field: DISPATCH_COLUMN_GRID_FIELD?.[field] || field,
      headerName: fieldKey[field],
      cellStyle: params => getCellStyle({ ...params, divColorSet }),
      ...(FIELD_WIDTH?.[field] && { width: FIELD_WIDTH[field] }),
      ...(editableCols?.includes(field)
        ? { ...editSettings }
        : { ...basicSettings, editable: false }),
      ...(buttonCols?.includes(field) && { ...buttonSettings }),
      ...{ ...sortSettings },
    };
  };

  const result = selectedItems
    ?.filter(
      field => isEditable || (field !== 'export_btn' && field !== 'complete'),
    )
    ?.map(field => {
      if (!isGroupedColumn(field)) {
        return { ...setOneCol(field) };
      }

      const [headerField, childrenList] = Object.entries(field)[0];

      const groupHeader = groupKey?.[headerField];

      let childCounter = 0;
      const groupDef = columnDef?.find(item => item?.header === groupHeader);

      const newcolumn = {
        headerName: groupDef?.header || headerField,
        isColumnGroup: true,
        openByDefault: true,
        ...colors?.[groupDef?.theme],
        children: childrenList.map(child => {
          const newChild = {
            ...setOneCol(child),
            ...(childCounter > 0 && { columnGroupShow: 'open' }),
          };
          childCounter += 1;
          return newChild;
        }),
      };
      return newcolumn;
    });

  return result;
};

export default setResultColDef;
