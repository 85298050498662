const getDailyReportRowStyle = params => {
  if (params.data.color_type === 'Blue') {
    return { background: '#E7EEFF' };
  }
  if (params.data.color_type === 'Red') {
    return { background: '#FFE9E9' };
  }
  if (params.data.color_type === 'Yellow') {
    return { background: '#FFFDE9' };
  }
  if (params.data.color_type === 'Green') {
    return { background: '#F2FFFA' };
  }
  return {};
};

export default getDailyReportRowStyle;
