import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchColumnOrder from '../../../services/Dispatch/Common/patch/patchColumnOrder';
import dispatchKeys from '../keys';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';
import useControlToast from '../../Common/Toast/useControlToast';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchColumnOrder = ({
  setIsMessageModalOpen,
  setMessageModalMessage,
  setCustomizeCols,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchColumnOrder,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setCustomizeCols(false);
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setCustomizeCols(false);
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchColumnOrder;
