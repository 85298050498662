const DAILY_REPORT_BASIC_PULL_OUT_GROUP = [
  'po_location',
  'po_schedule',
  'po_driver',
];
const DAILY_REPORT_BASIC_DELIVERY_GROUP = [
  'dilv_location',
  'dilv_city',
  'dilv_state',
  'dilv_schedule',
];

const DAILY_REPORT_BASIC_RETURN_GROUP = [
  'rtn_location',
  'rtn_schedule',
  'rtn_driver',
];

const DAILY_REPORT_GROUP = {
  pull_out: {
    po_group: DAILY_REPORT_BASIC_PULL_OUT_GROUP,
    dilv_group: DAILY_REPORT_BASIC_DELIVERY_GROUP,
  },
  delivery: {
    po_group: DAILY_REPORT_BASIC_PULL_OUT_GROUP,
    dilv_group: [...DAILY_REPORT_BASIC_DELIVERY_GROUP, 'dilv_driver'],
  },
  return: { rtn_group: DAILY_REPORT_BASIC_RETURN_GROUP },
  return_yard: { rtn_group: DAILY_REPORT_BASIC_RETURN_GROUP },
};

export default DAILY_REPORT_GROUP;
