import React, { useEffect } from 'react';
import { PLUS_ICON } from '../../../../../../constants/Order/Common/path';

const ScheduleTableColumn = ({ ...props }) => {
  /** Get Data */
  const { location_set: locationSet } = props?.detailInfoData;

  /** Modal Opener */
  const { setAddModalOpener } = props;

  /** WO type */
  const woType =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  const generatePOAddButton = () => {
    const isAddNeeded =
      locationSet?.length > 0 &&
      (locationSet?.[0]?.type !== 'P/O' ||
        (locationSet?.[0]?.type === 'P/O' && locationSet?.[0]?.ordering === 1));

    if (isAddNeeded)
      return (
        <div
          onClick={() => {
            setAddModalOpener?.(true);
          }}
          className="cursor-pointer"
        >
          <img src={PLUS_ICON} alt="plus-icon" className="w-full h-full" />
        </div>
      );

    return null;
  };

  return (
    <div className="flex ml-[144px] h-[28px] items-center space-x-[2.5px] my-[2px]">
      <div className="w-[130px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        LOCATION ID
      </div>
      <div className="w-[99px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        CITY
      </div>
      <div className="w-[74px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        STATE
      </div>
      <div className="w-[74px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        TIME ZONE
      </div>
      <div className="w-[168px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        SCHEDULE DATE
      </div>
      <div className="w-[119px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        TIME
      </div>
      <div className="w-[168px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        ACTUAL DATE
      </div>
      <div className="w-[119px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        TIME
      </div>
      <div className="w-[168px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        ACTUAL DATE
      </div>
      <div className="w-[119px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        TIME
      </div>
      <div className="w-[99px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        MEMO
      </div>
      <div className="w-[103px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        DRIVER ID
      </div>
      <div className="w-[115px] text-center pt-[8px] pb-[9px] text-[#222] text-[11px] font-[500]">
        STATUS
      </div>
      <div className="w-[36px] flex justify-center items-center">
        {woType === 'export' && generatePOAddButton()}
      </div>
    </div>
  );
};

export default ScheduleTableColumn;
