import dayjs from 'dayjs';

const dateComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  const date1Number = dayjs(valueA).isValid()
    ? Number(dayjs(valueA).format('YYYYMMDD'))
    : null;
  const date2Number = dayjs(valueB).isValid()
    ? Number(dayjs(valueB).format('YYYYMMDD'))
    : null;

  if (date1Number === null && date2Number === null) return 0;
  if (date1Number === null) return -1;
  if (date2Number === null) return 1;
  return date1Number - date2Number;
};

export default dateComparator;
