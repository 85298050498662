import React, { useContext, useEffect, useState } from 'react';
import StyledCommonToastWrapper from '../../../styles/Common/CommonToast/StyledCommonToastWrapper';
import StyledCommonToastColorBox from '../../../styles/Common/CommonToast/StyledCommonToastColorBox';
import StyledCommonToastContentWrapper from '../../../styles/Common/CommonToast/StyledCommonToastContentWrapper';
import StyledCommonToastIconWrapper from '../../../styles/Common/CommonToast/StyledCommonToastIconWrapper';
import StyledCommonToastMessageWrapper from '../../../styles/Common/CommonToast/StyledCommonToastMessageWrapper';
import StyledCommonToastTitle from '../../../styles/Common/CommonToast/StyledCommonToastTitle';
import StyledCommonToastMainText from '../../../styles/Common/CommonToast/StyledCommonToastMainText';
import { PATH_TOAST_ERROR_ICON } from '../../../constants/Common/path';
import { TOAST_TYPE } from '../../../constants/Common/Toast/toastTypes';
import {
  generateToastHeader,
  generateToastMessage,
} from '../../../utils/Common/Toast/Generator/toastContentGenerator';
import { TOAST_ICON } from '../../../constants/Common/Toast/toastIcon';
import { ToastContext } from '../../../contexts/Toast/ToastContext';

const CommonToastItem = ({ toastKey, onCancelHandler, ...props }) => {
  const FADE_OUT_TIME = 500;
  const { removeToast } = useContext(ToastContext);
  const { type, time, header, message, suppressAutoFadeOut } = props;

  const [toastingTime, setToastingTime] = useState(2000);
  const [toastType, setToastType] = useState(TOAST_TYPE?.SUCCESS);
  const [isStartFadeOut, setIsStartFadeOut] = useState(false);

  useEffect(() => {
    if (time !== undefined && time !== null && !isNaN(time) && time > 0) {
      setToastingTime(time);
    }
  }, [time]);

  useEffect(() => {
    if (type && Object?.values?.(TOAST_TYPE)?.includes?.(type)) {
      setToastType(type);
    }
  }, [type]);

  const closeToast = () => {
    onCancelHandler?.();
    removeToast(toastKey);
  };

  useEffect(() => {
    if (!suppressAutoFadeOut) {
      const toastTimer = setTimeout(() => {
        closeToast();
      }, toastingTime);

      const opacityTimer = setTimeout(() => {
        setIsStartFadeOut(true);
      }, toastingTime - FADE_OUT_TIME);

      return () => {
        if (!suppressAutoFadeOut) {
          clearTimeout(toastTimer);
          clearTimeout(opacityTimer);
        }
      };
    }
    return () => {};
  }, [suppressAutoFadeOut, toastingTime]);

  return (
    <StyledCommonToastWrapper
      $toastingTime={toastingTime}
      $toastType={toastType}
      $suppressAutoFadeOut={suppressAutoFadeOut}
      $isStartFadeOut={isStartFadeOut}
      $FADE_OUT_TIME={FADE_OUT_TIME}
      {...props}
    >
      {toastType !== TOAST_TYPE?.CUSTOM && (
        <StyledCommonToastColorBox $toastType={toastType} {...props} />
      )}
      <StyledCommonToastContentWrapper>
        {toastType !== TOAST_TYPE?.CUSTOM && (
          <StyledCommonToastIconWrapper>
            <img
              src={TOAST_ICON?.[toastType]?.iconPath}
              width={TOAST_ICON?.[toastType]?.width}
              height={TOAST_ICON?.[toastType]?.height}
              alt="Toast Icon"
            />
          </StyledCommonToastIconWrapper>
        )}
        <StyledCommonToastMessageWrapper>
          <StyledCommonToastTitle>
            {generateToastHeader({ type: toastType, header })}
          </StyledCommonToastTitle>
          <StyledCommonToastMainText>
            {generateToastMessage({ type: toastType, message })}
          </StyledCommonToastMainText>
        </StyledCommonToastMessageWrapper>
        <div
          className="flex justify-center items-center cursor-pointer mb-[36px]"
          onClick={() => {
            closeToast();
          }}
        >
          <img
            src={PATH_TOAST_ERROR_ICON}
            width={12}
            height={12}
            alt="Toast Close Icon"
          />
        </div>
      </StyledCommonToastContentWrapper>
    </StyledCommonToastWrapper>
  );
};

export default CommonToastItem;
