import { useQuery } from '@tanstack/react-query';
import tmsReportScheduleKeys from './keys';
import getSchedule from '../../../services/TmsReport/Schedules/Get/getSchedule';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetSchedule = ({ ...scheduleParams }) => {
  const params = getURLSearchParams(scheduleParams);
  const result = useQuery({
    queryKey: tmsReportScheduleKeys.list({ ...scheduleParams }),
    queryFn: () => getSchedule({ params }),
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useGetSchedule;
