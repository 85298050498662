/**
 * @description
 * Function that cancel the editing status of the row.
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 */
const onRowCancelEditClickHandler = ({ gridParam, ...props }) => {
  /** Stop editing \wo commit changed data */
  gridParam?.api?.stopEditing(true);
};

export default onRowCancelEditClickHandler;
