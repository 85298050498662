import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ORDER_GET_BILLTO_KEY,
  ORDER_GET_BKNO_KEY,
  ORDER_GET_PODILV_KEY,
  ORDER_GET_POOL_KEY,
  ORDER_GET_SIZE_KEY,
  ORDER_GET_SSL_KEY,
  ORDER_GET_TYPE_KEY,
} from '../../../redux/Constants/Order';
import { commonCompanyGetAPI } from '../../Common/Company/get';
import {
  COMPANY_CATEGORY_CNEE,
  COMPANY_CATEGORY_CUSTOMER,
  COMPANY_CATEGORY_NGL,
} from '../../../constants/Order/Common/standard';
import { commonDefaultGetAPI } from '../../Common/Default/get';

/**
 * Function that return initial billTo value
 * Get data from back-end using redux-toolkit async function
 */
export const getBillTo = createAsyncThunk(ORDER_GET_BILLTO_KEY, async () => {
  try {
    const companyCategory = COMPANY_CATEGORY_CUSTOMER;
    const response = await commonCompanyGetAPI.getCommonCompanyIdName({
      companyCategory,
    });
    return response.data;
  } catch (err) {
    // console.log('Failed to get initial billTo data');
    return null;
  }
});

export const getPoDilvLoc = createAsyncThunk(ORDER_GET_PODILV_KEY, async () => {
  try {
    const companyCategory = COMPANY_CATEGORY_CNEE + COMPANY_CATEGORY_NGL;
    const response = await commonCompanyGetAPI.getCommonCompanyIdName({
      companyCategory,
    });
    return response.data;
  } catch (err) {
    // console.log('Failed to get initial billTo data');
    return null;
  }
});

export const getBkNo = createAsyncThunk(ORDER_GET_BKNO_KEY, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonBkNo();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getSsl = createAsyncThunk(ORDER_GET_SSL_KEY, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonSsl();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getPool = createAsyncThunk(ORDER_GET_POOL_KEY, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonPool();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getSize = createAsyncThunk(ORDER_GET_SIZE_KEY, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonSize();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getType = createAsyncThunk(ORDER_GET_TYPE_KEY, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonType();
    return response?.data;
  } catch (err) {
    return null;
  }
});
