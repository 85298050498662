const handleTabToNextCell = ({ gridParam }) => {
  /** Get parameter */
  const { columnApi, nextCellPosition, previousCellPosition } = gridParam;

  if (nextCellPosition?.rowPinned || previousCellPosition?.rowPinned) {
    if (nextCellPosition?.rowPinned) {
      return {
        rowIndex: previousCellPosition?.rowIndex,
        column: nextCellPosition?.column,
        rowPinned: nextCellPosition?.rowPinned,
      };
    }
    return {
      rowIndex: previousCellPosition?.rowIndex,
      column: previousCellPosition?.column,
      rowPinned: previousCellPosition?.rowPinned,
    };
  }

  /** If next cell is editable, move to next cell */
  if (nextCellPosition?.column?.colDef?.editable) {
    return {
      rowIndex: previousCellPosition?.rowIndex,
      column: nextCellPosition?.column,
      rowPinned: nextCellPosition?.rowPinned,
    };
  }
  /** Get next column index */
  const nextColumnIdx = columnApi
    ?.getColumns()
    ?.findIndex(col => col.getColId() === nextCellPosition?.column?.colId);

  /** Get all grid columns even if column virtualisation is enabled */
  const allGridColumns = columnApi?.getAllGridColumns();

  /** Loop to find editable column */
  for (let i = nextColumnIdx; i < allGridColumns.length; i++) {
    if (allGridColumns?.[i]?.colDef?.editable) {
      return {
        rowIndex: previousCellPosition?.rowIndex,
        column: allGridColumns?.[i],
        rowPinned: previousCellPosition?.rowPinned,
      };
    }
  }

  /** Return First editable column when cannot find editable column in loop */
  return {
    rowIndex: previousCellPosition?.rowIndex,
    column: allGridColumns?.find(col => col?.colDef?.editable),
    rowPinned: previousCellPosition?.rowPinned,
  };
};

export default handleTabToNextCell;
