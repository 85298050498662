import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, #root {
        height: 100%;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif !important;
    }

    body {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.16px;
        background-color: #f2f4f8;
        overflow-y: scroll !important;
        height: 100%;
        width: 100%;
    }

    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        margin: 20px;
    }
      
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        background-clip: padding-box;
        border: 3px solid transparent;
        height: 10%;
    }
      
    /* Chrome, Safari, Edge, Opera */
    /* Delete input number arrow */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /** AG-Grid related style */
    .ag-icon {
        font-family: agGridAlpine !important;
    }

    .ag-theme-alpine {
        --ag-grid-size: 2px;
    }
`;

export default GlobalStyle;
