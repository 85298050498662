import React from 'react';
import dayjs from 'dayjs';
import CommonSelect from '../../../../../Inputs/CommonSelect/CommonSelect';
import CommonDate from '../../../../../Inputs/CommonDate/CommonDate';
import { STATISTICS_SEARCH_SELECT_OPTIONS } from '../../../../../../constants/TmsReport/Statistics/statisticsSearchOption';

const AdvancedSearchModalContent = ({ cycle, currentDate }) => {
  return (
    <div className="bg-[#F9F9F9] w-full h-fit px-[30px] pt-[15px] pb-[5px] flex gap-x-[20px]">
      <div>
        <CommonSelect
          label="Period"
          inputName="period"
          width="w-[153px]"
          defaultValue={cycle || ''}
          option={STATISTICS_SEARCH_SELECT_OPTIONS}
          required
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
        />
      </div>
      <div>
        <CommonDate
          label="Date"
          inputName="date"
          width="w-[116px]"
          defaultValue={currentDate || ''}
          required
          onChangeHandler={({ e, onChange }) => {
            const value = dayjs(e)?.isValid()
              ? dayjs(e)?.format('YYYY-MM-DD')
              : null;
            onChange?.(value);
          }}
        />
      </div>
    </div>
  );
};
export default AdvancedSearchModalContent;
