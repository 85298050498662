const dispatchKeys = {
  all: ['DISPATCH'],
  count: () => [...dispatchKeys.all, 'COUNT'],
  countDiv: ({ division }) => [...dispatchKeys.count(), division],
  countDivCategory: ({ division, category }) => [
    ...dispatchKeys.countDiv({ division }),
    category,
  ],
  countDivCategoryStatus: ({ division, category, status }) => [
    ...dispatchKeys.countDivCategory({ division, category }),
    status,
  ],
  countDivCategoryStatusQuery: ({
    division,
    category,
    paramQueries,
    yardParamQueries,
    status,
  }) => [
    ...dispatchKeys.countDivCategoryStatus({ division, category, status }),
    paramQueries,
    yardParamQueries,
  ],
  list: () => [...dispatchKeys.all, 'LIST'],
  listDiv: ({ division }) => [...dispatchKeys.list(), division],
  listDivStatusQuery: ({
    division,
    status,
    category,
    paramQueries,
    yardParamQueries,
  }) => [
    ...dispatchKeys.listDiv({ division }),
    status,
    category,
    paramQueries,
    yardParamQueries,
  ],
  driver: () => [...dispatchKeys.all, 'DRIVER'],
  driverList: ({ woNo, driver }) => [...dispatchKeys.driver(), woNo, driver],
  driverAP: ({ woNo, scheduleSeq, driver }) => [
    ...dispatchKeys.driverList({ woNo, driver }),
    scheduleSeq,
  ],

  bookingAll: ['BOOKING'],
  bookingCount: () => [...dispatchKeys.bookingAll, 'COUNT'],
  bookingCountDiv: ({ division }) => [...dispatchKeys.bookingCount(), division],
  bookingCountDivQuery: ({ division, paramQueries }) => [
    ...dispatchKeys.bookingCountDiv({ division }),
    paramQueries,
  ],
  bookingList: () => [...dispatchKeys.bookingAll, 'LIST'],
  bookingListDiv: ({ division }) => [...dispatchKeys.bookingList(), division],
  bookingDetail: ({ bookingSeq }) => [...dispatchKeys.bookingAll, bookingSeq],
  bookingWO: ({ bookingSeq }) => [
    ...dispatchKeys.bookingDetail({ bookingSeq }),
    'WO',
  ],

  companyAll: ['COMPANY'],
  companyBillto: () => [...dispatchKeys.companyAll, 'BILL_TO'],
  companyLocation: () => [...dispatchKeys.companyAll, 'LOCATION'],
  companyNGLYard: () => [...dispatchKeys.companyAll, 'NGL_YARD'],
};

export default dispatchKeys;
