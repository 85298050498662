import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleRowSave = ({
  submitData,
  id,
  type,
  woNo,
  documentList,
  documentMutator,
  buttonRef,
}) => {
  if (buttonRef?.current)
    updateButtonDisabled({ ref: buttonRef, disabled: true });

  const docData = {};

  const newList = [...documentList];
  newList[id - 1] = submitData[id];

  docData.doc = JSON.stringify(newList);
  docData[type] = submitData?.file?.[id]?.[type];

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });
};

export default handleRowSave;
