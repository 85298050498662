import { GRID_MOD_COL_ID } from '../../../../constants/Common/DataGrid/setting';

/**
 * @description
 * Function of judge disable row click handler.
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @returns
 * True if the row is clickable, false otherwise.
 */
const judgeDisableRowClickHandler = ({ gridParam, ...props }) => {
  /** Get grid api from grid parameter */
  const getGridAPI = gridParam?.api;
  /** Get focused column when row clicked */
  const getFocusedColumn = getGridAPI?.getFocusedCell()?.column;

  /** Are there any items in the grid that are being modified? */
  const isEditing = getGridAPI?.getEditingCells().length > 0;
  /** Does any of the items you are modifying match the current Column? */
  const isEditingCell = getGridAPI?.getEditingCells().some(cell => {
    return cell.rowIndex === gridParam?.rowIndex;
  });

  /** If the column is a modification column, it is not clickable. */
  if (getFocusedColumn?.colId === GRID_MOD_COL_ID) return false;
  /** If the target row pinned, it is not clickable */
  if (gridParam?.node?.rowPinned) return false;
  /** If the column is being modified, it is not clickable. */
  if (isEditing || isEditingCell) return false;
  /** Otherwise, clickable */
  return true;
};

export default judgeDisableRowClickHandler;
