import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getReturnYard from '../../../services/TmsReport/Schedules/Get/getReturnYard';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetReturnYard = ({
  setErrorMessage,
  setIsErrorModalOpen,
  ...scheduleParams
}) => {
  const params = getURLSearchParams(scheduleParams);
  const result = useQuery({
    queryKey: dailyReportKeys.returnYardList({
      ...scheduleParams,
      yard: scheduleParams?.['y-com'],
    }),
    queryFn: () => getReturnYard({ params }),
    refetchOnWindowFocus: false,
    enabled: !!scheduleParams?.['y-com'],
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetReturnYard;
