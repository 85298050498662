const DATE_OPTIONS = [
  { label: 'Create Date', value: 'created' },
  { label: 'P/O S Date', value: 'pullout-sche' },
  { label: 'Dilv S Date', value: 'delivery-sche' },
  { label: 'Rtn S Date', value: 'return-sche' },
  { label: 'RFD', value: 'rfd' },
];

const IMP_EXP_OPTIONS = [
  { label: 'IMPORT', value: 'I' },
  { label: 'EXPORT', value: 'O' },
];

const IN_OUT_OPTIONS = [
  { label: 'IN', value: 'I' },
  { label: 'OUT', value: 'OA' },
];

const LOAD_EMPTY_OPTIONS = [
  { label: 'LOAD', value: 'L' },
  { label: 'EMPTY', value: 'E' },
];

const DECLINE_CODE_OPTIONS = [
  { label: 'Capacity Type', value: 'CPT' },
  { label: 'Capacity Unavailable', value: 'CPU' },
  { label: 'Equipment Type', value: 'EQT' },
  { label: 'Equipment Unavailable', value: 'EQU' },
  { label: 'Length of Haul', value: 'LNH' },
  { label: 'Permits', value: 'PRM' },
  { label: 'Rate', value: 'RAT' },
  { label: 'Weight', value: 'WGT' },
];

const CATEGORY_322 = {
  HYUNDAI: 'HYMM',
  EVERGREEN: 'EVERGREEN',
};

const CATEGORY_204 = {
  EXPEDITORS: 'EXPD',
  MSC: 'MSC',
};

const TYPE_322_OPTIONS = [
  { label: 'GROSS', value: 'GROSS' },
  { label: 'NET', value: 'NET' },
];

const UNIT_322_OPTIONS = [
  { label: 'KGS', value: 'KGS' },
  { label: 'LBS', value: 'LBS' },
];

export {
  CATEGORY_204,
  CATEGORY_322,
  DATE_OPTIONS,
  DECLINE_CODE_OPTIONS,
  IMP_EXP_OPTIONS,
  IN_OUT_OPTIONS,
  LOAD_EMPTY_OPTIONS,
  TYPE_322_OPTIONS,
  UNIT_322_OPTIONS,
};
