import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const SearchDate = ({ date, setDate }) => {
  const [isInputting, setIsInputting] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="MM/DD/YY"
        value={dayjs(date)}
        onChange={newData => {
          if (newData && dayjs(newData).isValid() && !isInputting) {
            setDate(dayjs(newData).format('YYYY-MM-DD'));
          }
        }}
        slotProps={{
          popper: {
            sx: {
              '&.MuiPickersPopper-root': {
                zIndex: '10000 !important',
              },
            },
          },
          textField: {
            onKeyDown: event => {
              if (
                event.key === 'Enter' &&
                dayjs(event.target.value).isValid()
              ) {
                setIsInputting(false);
                setDate(dayjs(event.target.value).format('YYYY-MM-DD'));
              } else {
                setIsInputting(true);
              }
            },
            onBlur: event => {
              if (dayjs(event.target.value).isValid()) {
                setIsInputting(false);
                setDate(dayjs(event.target.value).format('YYYY-MM-DD'));
              }
            },
            sx: {
              bgcolor: 'white',
              svg: { color: '#264B9F !important' },
              '&.MuiFormControl-root': {
                width: '100%',
              },
              '.MuiInputBase-root': {
                color: `#000000 !important`,
                height: '30px',
                borderRadius: '5px',
                fontSize: '12px',
                fontWeight: '500',
                '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
                  borderColor: '#8E9396 !important',
                },
                '&.Mui-disabled input': {
                  WebkitTextFillColor: `#222 !important`,
                },
                '&.Mui-focused fieldset': {
                  border: '1.2px solid #264B9F !important',
                  boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                },
                '&.Mui-error fieldset': {
                  border: '1.2px solid #C24D4D !important',
                },
              },
              '.MuiSvgIcon-root': {
                fontSize: '16px',
              },
              fieldset: {
                borderColor: '#D9D9D9 !important',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default SearchDate;
