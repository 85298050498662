const DISPATCH_IMPORT_FILTER_DATE = [
  {
    value: 'created-',
    label: 'WO CREATE DATE',
  },
  {
    value: 'pullout-sche-',
    label: 'P/O S DATE',
  },
  {
    value: 'pullout-actual-out-',
    label: 'P/O A OUT DATE',
  },
  {
    value: 'delivery-sche-',
    label: 'DILV S DATE',
  },
  {
    value: 'delivery-actual-out-',
    label: 'DILV A OUT DATE',
  },
  {
    value: 'return-sche-',
    label: 'RTN S DATE',
  },
  {
    value: 'return-actual-out-',
    label: 'RTN A OUT DATE',
  },
];

const DISPATCH_IMPORT_FILTER_CATEGORY = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'container-no',
    label: 'CONT #',
  },
  {
    value: 'wo-no',
    label: 'WO #',
  },
  {
    value: 'ref-no',
    label: 'REF #',
  },
  {
    value: 'purchase-no',
    label: 'PO #',
  },
  {
    value: 'mbl-no',
    label: 'MBL #',
  },
  {
    value: 'billto',
    label: 'BILL TO',
  },
  {
    value: 'pullout-loc',
    label: 'P/O LOC',
  },
  {
    value: 'delivery-loc',
    label: 'DILV LOC',
  },
  {
    value: 'pickup-loc',
    label: 'P/U LOC',
  },
  {
    value: 'return-loc',
    label: 'RTN LOC',
  },
  {
    value: 'drv-id',
    label: 'DRV ID',
  },
];

export { DISPATCH_IMPORT_FILTER_CATEGORY, DISPATCH_IMPORT_FILTER_DATE };
