import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dailyReportKeys from '../keys';
import getDailySchedule from '../../../services/TmsReport/Schedules/Get/getDailySchedule';
import getURLSearchParams from '../../../utils/TmsReport/Schedule/Generator/getURLSearchParams';

const useGetSchedule = ({
  setErrorMessage,
  setIsErrorModalOpen,
  ...scheduleParams
}) => {
  const params = getURLSearchParams(scheduleParams);
  const result = useQuery({
    queryKey: dailyReportKeys.list({ ...scheduleParams }),
    queryFn: () => getDailySchedule({ params }),
    refetchOnWindowFocus: false,
    enabled:
      !!scheduleParams?.date &&
      !!scheduleParams?.sche &&
      !!scheduleParams?.cat &&
      !!scheduleParams?.div,
  });

  useEffect(() => {
    if (result?.isError) {
      setIsErrorModalOpen(true);
      setErrorMessage(result?.error?.message);
    }
  }, [result?.isError]);

  return result;
};

export default useGetSchedule;
