import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonMessageModal from '../../../components/CommonMessageModal/CommonMessageModal';
import CommonToast from '../../../components/CommonToast';
import CommonCustomColumnModal from '../../../components/CommonCustomColumnModal';
import AdvancedSearchButtonContent from '../../../components/Main/AdvancedSearchButtonContent';
import AdvancedSearchContent from '../../../components/Main/AdvancedSearchContent';
import HeaderButtons from '../../../components/Main/HeaderButtons';
import DEFAULT_DEFS from '../../../constants/Main/Common/defaultDefs';
import { createColumnTypes } from '../../../constants/Main/Common/mainDataGridOptions';
import useGetMainList from '../../../hooks/Main/Get/useGetMainList';
import usePatchMainColumn from '../../../hooks/Main/Patch/usePatchMainColumn';
import checkDateDiff from '../../../utils/Common/Handler/checkDateDiff';
import clickRow from '../../../utils/Main/Common/clickRow';
import generateInitAdvancedForm from '../../../utils/Main/Common/Generator/generateInitAdvancedForm';
import getAvailableItems from '../../../utils/Main/Common/getAvailableItems';
import getContextMenuItems from '../../../utils/Main/Common/getContextMenuItems';
import getSelectedItems from '../../../utils/Main/Common/getSelectedItems';
import setResultColDef from '../../../utils/Main/Common/setResultColDef';
import setResultData from '../../../utils/Main/Common/setResultData';

const MainWrapper = ({ category, ...props }) => {
  const selectedDiv = useSelector(state => state.nowDiv);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [customizeCols, setCustomizeCols] = useState(false);
  const [allQueries, setAllQueries] = useState({
    queryString: generateInitAdvancedForm(),
    category,
    selectedDiv,
  });

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    isLoading,
    data: list,
    isRefetching,
  } = useGetMainList({
    ...allQueries,
  });

  const { mutate: mainColumnMutator } = usePatchMainColumn({
    setIsOpenAlertModal,
    setErrorMessage,
    setCustomizeCols,
  });

  const advancedSearchController = useForm({
    shouldUnregister: true,
    defaultValues: generateInitAdvancedForm(),
  });

  useEffect(() => {
    advancedSearchController.reset(generateInitAdvancedForm());
    setAllQueries({
      queryString: generateInitAdvancedForm(),
      category,
      selectedDiv,
    });
  }, [category, selectedDiv]);

  const openAdvancedSearch = () => {
    advancedSearchController.reset({ ...allQueries?.queryString });
    setAdvancedSearchOpen(true);
  };

  const gridRef = useRef(null);

  const columnDefs = useMemo(
    () =>
      setResultColDef({
        setting: list?.main_column,
        category,
      }),
    [list?.main_column],
  );

  return (
    <>
      <div className="w-full h-full pr-[40px] flex flex-col">
        <HeaderButtons
          openAdvancedSearch={openAdvancedSearch}
          setCustomizeCols={setCustomizeCols}
          gridRef={gridRef}
          category={category}
        />
        {list?.results && (
          <CommonDataGrid
            gridRef={gridRef}
            data={setResultData(list.results)}
            columnDefs={columnDefs}
            getContextMenuItems={e => {
              return getContextMenuItems({ e, category });
            }}
            columnTypes={createColumnTypes}
            onRowClicked={({ gridParam }) => {
              clickRow({ gridParam, category });
            }}
          />
        )}
        {customizeCols && (
          <CommonCustomColumnModal
            openModal={customizeCols}
            setOpenModal={setCustomizeCols}
            status="Available Items"
            selectedItems={getSelectedItems({
              items: list?.main_column,
              category,
            })}
            columnItems={getAvailableItems({
              items: DEFAULT_DEFS[category],
              category,
            })}
            onApplyClick={({ buttonRef, appliedColumnList }) => {
              mainColumnMutator({
                category,
                userID: list?.user_id,
                patchData: { main_column: appliedColumnList },
                buttonRef,
              });
            }}
          />
        )}
        <FormProvider {...advancedSearchController}>
          <form
            onSubmit={advancedSearchController.handleSubmit(data => {
              if (
                !checkDateDiff({
                  fromDate: data?.['from-date'],
                  toDate: data?.['to-date'],
                })
              ) {
                setIsSuccess(false);
                setErrorMessage(
                  'The date range cannot exceed one year. Please try again.',
                );
                setIsOpenAlertModal(true);
              } else {
                setAllQueries(prev => ({ ...prev, queryString: data }));
                setAdvancedSearchOpen(false);
                advancedSearchController.reset({ ...data });
              }
            })}
          >
            {advancedSearchOpen && (
              <CommonMessageModal
                open={advancedSearchOpen}
                setOpen={setAdvancedSearchOpen}
                header="Advanced Search"
                content={<AdvancedSearchContent category={category} />}
                buttonContent={
                  <AdvancedSearchButtonContent
                    setAdvancedSearchOpen={setAdvancedSearchOpen}
                  />
                }
              />
            )}
          </form>
        </FormProvider>
      </div>
      {isOpenAlertModal && (
        <CommonErrorModal
          open={isOpenAlertModal}
          setIsOpen={setIsOpenAlertModal}
          message={errorMessage}
        />
      )}
      {(isLoading || isRefetching) && (
        <CommonLoading open={isLoading || isRefetching} />
      )}
    </>
  );
};

export default MainWrapper;
