import styled from 'styled-components';

const StyledCommonInputRequiredLabel = styled.span`
  font-size: 11px !important;
  color: #ff2424 !important;
  font-weight: 500 !important;
  margin-right: 0.25rem;
`;

export default StyledCommonInputRequiredLabel;
