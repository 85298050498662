import styled from 'styled-components';

const StyledNormalCellValueRenderer = styled.div`
  width: ${props => props?.width || '100%'};
  height: ${props => props?.height || '100%'};
  display: flex;
  align-items: center;
  justify-content: ${props => {
    if (props?.textAlign === 'center') {
      return 'center';
    }
    if (props?.textAlign === 'right') {
      return 'flex-end';
    }
    return 'none';
  }};
`;

export default StyledNormalCellValueRenderer;
