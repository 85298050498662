export const MAIN_FILTER_STATUS = [
  {
    value: 'ALL',
    label: 'ALL',
  },
  {
    value: 'COMPLETE',
    label: 'COMPLETE',
  },
  {
    value: 'IN TRANSIT',
    label: 'IN TRANSIT',
  },
];

export const MAIN_IMPORT_FILTER_CATEGORY = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'cont_no',
    label: 'CONT #',
  },
  {
    value: 'ch_no',
    label: 'CHASSIS #',
  },
  {
    value: 'wo_no',
    label: 'WO #',
  },
  {
    value: 'ref_no',
    label: 'REF #',
  },
  {
    value: 'purchase_no',
    label: 'PO #',
  },
  {
    value: 'mbl_no',
    label: 'MBL #',
  },
  {
    value: 'billto',
    label: 'BILL TO',
  },
  {
    value: 'po_location',
    label: 'P/O LOC',
  },
  {
    value: 'dilv_location',
    label: 'DILV LOC',
  },
  {
    value: 'pu_location',
    label: 'P/U LOC',
  },
  {
    value: 'rtn_location',
    label: 'RTN LOC',
  },
  {
    value: 'driver',
    label: 'DRV ID',
  },
];

export const MAIN_EXPORT_FILTER_CATEGORY = [...MAIN_IMPORT_FILTER_CATEGORY].map(
  item => {
    if (item.value === 'mbl_no') {
      return {
        value: 'bk_no',
        label: 'BOOKING #',
      };
    }
    return item;
  },
);

export const MAIN_VAN_FILTER_CATEGORY = [...MAIN_IMPORT_FILTER_CATEGORY]
  .map(item => {
    if (item.value === 'cont_no') {
      return {
        value: 'trailer_no',
        label: 'TRAILER #',
      };
    }
    return item;
  })
  .filter(item => item.value !== 'ch_no');

export const MAIN_BROKERAGE_FILTER_CATEGORY = [...MAIN_VAN_FILTER_CATEGORY];

export const MAIN_BARE_CHASSIS_FILTER_CATEGORY = [
  ...MAIN_IMPORT_FILTER_CATEGORY,
].filter(item => item.value !== 'cont_no');

export const MAIN_BARE_CHASSIS_FILTER_DATE = [
  {
    value: 'created_date',
    label: 'CREATE DATE',
  },
  {
    value: 'po_sche_date',
    label: 'P/O S DATE',
  },
  {
    value: 'po_actual_out_date',
    label: 'P/O A DATE',
  },
  {
    value: 'dilv_sche_date',
    label: 'DILV S DATE',
  },
  {
    value: 'dilv_actual_out_date',
    label: 'DILV A DATE',
  },
  {
    value: 'pu_sche_date',
    label: 'P/U S DATE',
  },
  {
    value: 'pu_actual_out_date',
    label: 'P/U A DATE',
  },
  {
    value: 'rtn_sche_date',
    label: 'RTN S DATE',
  },
  {
    value: 'rtn_actual_out_date',
    label: 'RTN A DATE',
  },
];

export const MAIN_IMPORT_FILTER_DATE = [
  ...MAIN_BARE_CHASSIS_FILTER_DATE,
  {
    value: 'empty_load_cfm',
    label: 'MTY/LOAD CFM',
  },
  {
    value: 'eta',
    label: 'ETA',
  },
  {
    value: 'lfd',
    label: 'LFD',
  },
  {
    value: 'rfd',
    label: 'RFD',
  },
];

export const MAIN_EXPORT_FILTER_DATE = [
  ...MAIN_BARE_CHASSIS_FILTER_DATE,
  {
    value: 'empty_load_cfm',
    label: 'MTY/LOAD CFM',
  },
  {
    value: 'erd',
    label: 'ERD',
  },
  {
    value: 'cutoff',
    label: 'CUT/OFF',
  },
  {
    value: 'rfd',
    label: 'RFD',
  },
  {
    value: 'st',
    label: 'S/T',
  },
];

export const MAIN_VAN_FILTER_DATE = [...MAIN_IMPORT_FILTER_DATE];

export const MAIN_BROKERAGE_FILTER_DATE = [...MAIN_IMPORT_FILTER_DATE];
