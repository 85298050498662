const createARAgingColDefs = () => {
  return [
    {
      headerName: 'ID',
      field: 'company',
      editable: false,
    },
    {
      headerName: 'NAME',
      field: 'name',
      editable: false,
    },
    {
      headerName: 'CURRENT',
      field: 'current',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
    {
      headerName: 'OVER 30',
      field: 'over30',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
    {
      headerName: 'OVER 60',
      field: 'over60',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
    {
      headerName: 'OVER 90',
      field: 'over90',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
    {
      headerName: 'OVER 120',
      field: 'over120',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
    {
      headerName: 'TOTAL',
      field: 'total_amt',
      editable: false,
      valueFormatter: params => {
        return params?.value?.toFixed(2);
      },
    },
  ];
};

export default createARAgingColDefs;
