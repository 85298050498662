import dayjs from 'dayjs';
import FloatingFilterInput from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import cellRendererGenerator from '../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_TEXT,
} from '../../../constants/Common/DataGrid/cellType';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';

const createTmsReportDemurrageColumnDefs = ({
  postSendEmailMutation,
  setMessage,
  setIsSuccess,
  setIsMessageOpen,
}) => [
  {
    headerName: 'CAT',
    field: 'category',
    width: 60,
    editable: false,
  },
  {
    headerName: 'WO #',
    field: 'wo_no',
    width: 100,
    editable: false,
  },
  {
    headerName: 'BILL TO',
    field: 'billto',
    width: 76,
    editable: false,
  },
  {
    headerName: 'CONT #',
    field: 'cont_no',
    width: 92,
    editable: false,
  },
  {
    headerName: 'SSL',
    field: 'ssl',
    width: 50,
    editable: false,
  },
  {
    headerName: 'LFD',
    field: 'lfd',
    width: 62,
    editable: false,
    comparator: dateComparator,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
      }),
    valueGetter: params => {
      const dateValue = params?.data?.lfd;
      return dateValue ? dayjs(dateValue)?.format('MM/DD/YY') : null;
    },
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
  },
  {
    headerName: 'P/O SCHED',
    field: 'po_sche',
    width: 98,
    comparator: dateComparator,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
      }),
    valueGetter: params => {
      const dateValue = params?.data?.po_sche;
      return dateValue ? dayjs(dateValue)?.format('MM/DD/YY HH:mm') : null;
    },
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'datetime',
      maxLength: 14,
      placeholder: 'MM/DD/YY HH:mm',
    },
  },
  {
    headerName: 'DSC',
    field: 'ava_dsc',
    width: 40,
    editable: false,
  },
  {
    headerName: 'OBL',
    field: 'ava_obl',
    width: 40,
    editable: false,
  },
  {
    headerName: 'CUS',
    field: 'ava_cus',
    width: 40,
    editable: false,
  },
  {
    headerName: 'NGL',
    field: 'ngl_ratio',
    width: 50,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
        value: gridParam?.data?.ngl_ratio,
        customStyle: {
          textAlign: 'right',
        },
      }),
  },
  {
    headerName: 'CUS',
    field: 'customer_ratio',
    width: 50,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
        value: gridParam?.data?.customer_ratio,
        customStyle: {
          textAlign: 'right',
        },
      }),
  },
  {
    headerName: 'REASON',
    field: 'reason',
    width: 180,
    editable: false,
  },
  {
    headerName: 'EMAIL',
    field: 'email',
    width: 75,
    editable: false,
    floatingFilter: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_BUTTON,
        customStyle: {
          size: 'small',
        },
        content: 'Email',
        category: 'email',
        onClickHandler: e => {
          if (!e?.data?.sendto) {
            setMessage('Please enter SEND TO');
            setIsSuccess(false);
            setIsMessageOpen(true);
            return;
          }
          if (!e?.data?.cont_no) {
            setMessage('Please enter CONT #');
            setIsSuccess(false);
            setIsMessageOpen(true);
            return;
          }
          postSendEmailMutation({ woNo: e.data.wo_no });
        },
      }),
  },
];

export default createTmsReportDemurrageColumnDefs;
