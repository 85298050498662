import handleCheckboxChange from '../../../TmsReport/Common/handleCheckboxChange';

const getCheckboxOptions = ({
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  setYardQuery,
  setStatusQuery,
  allQueries,
  yardList,
  divList,
}) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const scheduleTypeOptionList = [
    {
      label: 'LIVE',
      value: 'LIVE',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setScheduleTypeQuery }),
    },
    {
      label: 'DROP',
      value: 'DROP',
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
        }),
    },
  ];

  const divisionOptionList = [
    ...divList?.map(div => {
      return {
        label: div,
        value: div,
        defaultValue: div === defaultDiv,
        onChange: ({ ...props }) =>
          handleCheckboxChange({
            ...props,
            setQuery: setDivisionQuery,
          }),
      };
    }),
  ];

  const categoryOptionList = [
    {
      label: 'IMPORT',
      value: 'M',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
    {
      label: 'EXPORT',
      value: 'X',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
  ];

  const yardOptionList = [
    ...yardList?.map(yard => ({
      label: yard,
      value: yard,
      defaultValue: allQueries?.['y-com']?.[yard] || false,
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setYardQuery }),
    })),
  ];

  const statusOptionList = [
    {
      label: 'IN YARD',
      value: 'W',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setStatusQuery }),
    },
    {
      label: 'TO YARD',
      value: 'Y',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setStatusQuery }),
    },
    {
      label: 'IN RTN',
      value: 'B',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setStatusQuery }),
    },
    {
      label: 'TO RTN',
      value: 'G',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setStatusQuery }),
    },
  ];

  return {
    scheduleTypeOptionList,
    divisionOptionList,
    categoryOptionList,
    yardOptionList,
    statusOptionList,
  };
};

export default getCheckboxOptions;
