import { useLayoutEffect, useState } from 'react';

/**
 * @description
 * Custom hook to get row data for data grid
 * @param {*} data
 * Data to be shown in the grid
 * @returns {object}
 * rowData: Data to be shown in the grid
 */
const useGetRowData = ({ data, ...props }) => {
  /** Data to be shown in the grid */
  const [rowData, setRowData] = useState(data || []);

  /** Set row data if data is changed */
  useLayoutEffect(() => {
    setRowData(data);
  }, [data]);

  return { rowData };
};

export default useGetRowData;
