import { createSlice } from '@reduxjs/toolkit';
import { MAIN_SLICE_KEY } from '../../Constants/Main';

export const mainInitialState = {
  listFilter: {},
};

const mainSlice = createSlice({
  name: MAIN_SLICE_KEY,
  initialState: mainInitialState,
  reducers: {
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.location]: action.payload.dateValue,
          },
        },
      }),
    },
  },
});

export const { updateListDateFilter } = mainSlice.actions;

export default mainSlice.reducer;
