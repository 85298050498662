import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_DATE,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  handleFSCOnChangePair,
  handleFSCOnModChangePair,
} from '../Handler/handleFSCOnChangePair';

const createMasterARRateColDefs = (nglCneeList, customerList) => {
  return [
    {
      headerName: 'BILL TO',
      field: 'billto',
      width: 170,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: customerList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: customerList,
        }),
      valueGetter: params => {
        return !params.data.billto?.includes('/')
          ? customerList.find(
              company => company.value.split('/')[0] === params.data.billto,
            )?.label
          : params.data.billto;
      },
    },
    {
      headerName: 'FROM LOC',
      field: 'po_location',
      width: 170,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      valueGetter: params => {
        return !params.data.po_location?.includes('/')
          ? nglCneeList.find(
              company =>
                company.value.split('/')[0] === params.data.po_location,
            )?.label
          : params.data.po_location;
      },
    },
    {
      headerName: 'DELV LOC',
      field: 'dilv_location',
      width: 170,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: nglCneeList,
        }),
      valueGetter: params => {
        return !params.data.dilv_location?.includes('/')
          ? nglCneeList.find(
              company =>
                company.value.split('/')[0] === params.data.dilv_location,
            )?.label
          : params.data.dilv_location;
      },
    },
    {
      headerName: 'TRUCKING CHARGE',
      field: 'trucking_charge',
      width: 145,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
        }),
    },
    {
      headerName: 'FSC (%)',
      field: 'fsc_percent',
      width: 75,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'end',
            content: '%',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          onChangeHandler: handleFSCOnChangePair,
          pair: 'fsc_amt',
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'end',
            content: '%',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          onChangeHandler: handleFSCOnModChangePair,
          pair: 'fsc_amt',
        }),
      valueGetter: params => {
        if (!params.data?.fsc_percent) return '';
        return String(params.data.fsc_percent);
      },
    },
    {
      headerName: 'FSC ($)',
      field: 'fsc_amt',
      width: 85,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          onChangeHandler: handleFSCOnChangePair,
          pair: 'fsc_percent',
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          onChangeHandler: handleFSCOnModChangePair,
          pair: 'fsc_percent',
        }),
    },
    {
      headerName: 'TOTAL ($)',
      field: 'total',
      width: 85,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
          regex: REGEX_NUMBER_MINUS_DECIMAL,
        }),
    },
    {
      headerName: 'EFFECTIVE DATE',
      field: 'effective_date',
      width: 127,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.effective_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
  ];
};

export default createMasterARRateColDefs;
