const FIELD_WIDTH = {
  category: 42,
  wo_no: 100,
  billto: 76,

  cont_no: 92,
  ch_no: 86,
  trailer_no: 87,
  equipment_no: 90,

  type: 57,
  ch_type: 77,
  trailer_type: 80,
  size: 42,
  ssl: 50,
  pool: 66,
  wgt: 60,
  bond_entry: 75,
  vessel: 60,

  bk_seq: 150,
  bk_no: 150,
  st: 62,
  erd: 62,
  cutoff: 97,

  ref_no: 110,
  purchase_no: 95,
  mbl_no: 125,

  cont_size_1: 42,
  cont_type_1: 57,
  total_1: 57,
  balance_1: 75,
  cont_size_2: 42,
  cont_type_2: 57,
  total_2: 57,
  balance_2: 75,
  cont_size_3: 42,
  cont_type_3: 57,
  total_3: 57,
  balance_3: 75,

  orig: 76,
  curr: 76,
  dest: 76,

  eta: 62,
  lfd: 62,
  rfd: 62,
  lfd_date: 62,
  rfd_date: 62,
  rfd_day: 42,
  lfd_day: 42,

  ava_dsc: 52,
  ava_obl: 52,
  ava_cus: 52,

  po_location: 76,
  dilv_location: 76,
  pu_location: 76,
  rtn_location: 76,

  po_schedule: 98,
  dilv_schedule: 98,
  pu_schedule: 98,
  rtn_schedule: 98,

  po_actual_out: 98,
  dilv_actual_out: 98,
  pu_actual_out: 98,
  rtn_actual_out: 98,

  po_actual_in: 98,
  dilv_actual_in: 98,
  pu_actual_in: 98,
  rtn_actual_in: 98,

  po_driver: 73,
  dilv_driver: 73,
  pu_driver: 73,
  rtn_driver: 73,

  dilv_city: 98,
  dilv_state: 35,

  sche_type: 42,
  dilv_empty_cfm: 90,
  dilv_load_cfm: 92,
  dilv_empty_load_cfm: 78,
  load_empty: 47,
  empty_load_status: 47,

  yard_actual_out: 98,
  yard_actual_in: 98,
  yard_out_location: 76,
  yard_in_location: 76,
  yard_driver: 72,

  over_height: 52,
  over_weight: 52,
  hazmat: 52,
  hot: 52,

  export_btn: 80,
  complete: 90,

  doc: 60,
  status: 70,
};

export default FIELD_WIDTH;
