import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CommonButton from '../../../CommonButton/CommonButton';
import generateAPData from '../../../../utils/Dispatch/Common/Generator/generateAPData';
import dispatchKeys from '../../../../hooks/Dispatch/keys';
import usePostAssignDriverAPInput from '../../../../hooks/Dispatch/Common/usePostAssignDriverAPInput';
import { INIT_AP_TABLE_CONTENT } from '../../../../constants/Dispatch/Common/initAPTableContent';

const APModalButtonContent = ({
  woNo,
  setWONo,
  apInputData,
  selectedDriver,
  apTableContent,
  setAPTableContent,
  setAPModalOpener,
  setSelectedScheduleSeq,
  setSelectedDriverData,
  selectedScheduleSeq,
  setMessageModalOpener,
  setMessageModalMessage,
  setLoadingOpener,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { mutate: apPostMutator } = usePostAssignDriverAPInput({
    setAPTableContent,
    setAPModalOpener,
    setModalOpener: setMessageModalOpener,
    setModalMessage: setMessageModalMessage,
    setLoadingOpener,
  });

  const { product_codes: productCodes } = apInputData;

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-[20px]">
        <CommonButton
          category="cancel"
          onClick={() => {
            queryClient?.removeQueries({ queryKey: dispatchKeys?.driver() });
            setWONo('');
            setSelectedScheduleSeq(null);
            setSelectedDriverData(null);
            setAPModalOpener(false);
            setAPTableContent(INIT_AP_TABLE_CONTENT);
          }}
        >
          Cancel
        </CommonButton>
        <CommonButton
          category="save"
          preventDupClick
          onClick={({ buttonRef }) => {
            const tempAPdata = generateAPData({
              apData: apTableContent,
              productCodes,
            });
            apPostMutator({
              woNo,
              seq: selectedScheduleSeq,
              driver: selectedDriver,
              apData: tempAPdata,
              buttonRef,
            });
          }}
        >
          Save
        </CommonButton>
      </div>
    </div>
  );
};

export default APModalButtonContent;
