/**
 * @description
 * Function that handle unauthorized status code
 * @returns {string}
 * Error message
 */
export const errorUnauthorizedHandler = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('csrfToken');
  localStorage.removeItem('permission');
  localStorage.removeItem('allDiv');
  localStorage.removeItem('defaultDiv');
  localStorage.removeItem('nowDiv');
  localStorage.removeItem('firstPage');
  window.location.replace('/sign-in');
  return 'Unauthorized. Please try again.';
};

/**
 * @description
 * Function that handle forbidden status code
 * @returns {string}
 * Error message
 */
export const errorForbiddenHandler = () => {
  window.location.replace('/error/403');
  return 'Permission Denied. Please try again.';
};

/**
 * @description
 * Function that handle internal server error status code
 * @returns {string}
 * Error message
 */
export const errorInternalServerErrorHandler = () => {
  return 'Oops! Something went wrong. Please contact the IT team for assistance.';
};

/**
 * @description
 * Function that handle not found error status code
 * @returns {string}
 * Error message
 */
export const handleNotFoundError = () => {
  return `Not Found. Please try again.`;
};
