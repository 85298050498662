import styled, { css } from 'styled-components';
import BUTTON_ICON_PAIR from '../../../constants/Common/buttonIconPair';

const RED_ITEM_CATEGORY = [
  'pdf',
  'incomplete',
  'no',
  'cancel',
  'clear',
  'attached',
  'skip',
  'redDelete',
];

const StyledCommonButton = styled.button`
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  gap: 10px;

  // Set Button Icon
  ${props => {
    const { category } = props;

    const ACTIVE_ICON_PATH = BUTTON_ICON_PAIR.find(
      element => element?.category === category,
    )?.iconPath?.selected;

    return css`
      &:not(:disabled):active > img {
        content: url(${ACTIVE_ICON_PATH});
      }
    `;
  }}

  ${props =>
    // Default Size and theme
    props?.size === 'small'
      ? css`
          height: 20px;
          padding: 3px 5px;
          border-radius: 3px;
          gap: 7px;
        `
      : css`
          height: 24px;
          padding: 5px 10px;
          border-radius: 5px;
        `}

  ${props => {
    // On Click :active
    if (props?.category === 'excel') {
      return css`
        border: 1px solid #3a825d;
        background-color: #fff;
        color: #3a825d;

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: rgba(58, 130, 93, 0.2);
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: rgba(58, 130, 93, 1);
          color: white;
        }
      `;
    }
    if (RED_ITEM_CATEGORY?.includes(props?.category)) {
      return css`
        border: 1px solid #c24d4d;
        background-color: #fff;
        color: #c24d4d;

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: rgba(194, 77, 77, 0.2);
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: rgba(194, 77, 77, 1);
          color: white;
        }
      `;
    }
    if (props?.category === 'tracking') {
      return css`
        border: 1px solid #666;
        background-color: #fff;
        color: #666;

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: rgba(102, 102, 102, 0.2);
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: rgba(102, 102, 102, 1);
          color: white;
        }
      `;
    }
    if (props?.category === 'switchToDROP') {
      return css`
        border: 1px solid #ff9214;
        background-color: #fff;
        color: #ff9214;

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: rgba(255, 146, 20, 0.2);
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: rgba(255, 146, 20, 1);
          color: white;
        }
      `;
    }
    if (props?.category === 'switchToLIVE') {
      return css`
        border: 1px solid #0ec71c;
        background-color: #fff;
        color: #0ec71c;

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: rgba(14, 199, 28, 0.2);
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: rgba(14, 199, 28, 1);
          color: white;
        }
      `;
    }

    if (props?.borderColor) {
      return css`
        border: 1px solid ${props?.borderColor};
        background-color: #fff;
        color: ${props?.borderColor};

        &:not(:disabled):hover {
          transition: 0.3s;
          background-color: ${props?.borderColor}33;
        }

        &:not(:disabled):active {
          transition-property: background-color;
          transition-duration: 0.3s;
          background-color: ${props?.borderColor};
          color: white;
        }
      `;
    }

    return css`
      border: 1px solid #556da1;
      background-color: #fff;
      color: #556da1;

      &:not(:disabled):hover {
        transition: 0.3s;
        background-color: rgba(85, 109, 161, 0.2);
      }

      &:not(:disabled):active {
        transition-property: background-color;
        transition-duration: 0.3s;
        background-color: rgba(85, 109, 161, 1);
        color: white;
      }
    `;
  }}

  /** Custom Style */
  ${props =>
    props?.color &&
    css`
      color: ${props?.color};

      &:not(:disabled):hover {
        transition: 0.3s;
        background-color: ${props?.color}33;
      }

      &:not(:disabled):active {
        transition-property: background-color;
        transition-duration: 0.3s;
        background-color: ${props?.color};
        color: white;
      }
    `}

  ${props =>
    props?.fontWeight &&
    css`
      font-weight: ${props?.fontWeight};
    `}

  ${props =>
    props?.borderColor &&
    css`
      border: 1px solid ${props?.borderColor};
      background-color: #fff;
      color: ${props?.borderColor};

      &:not(:disabled):hover {
        transition: 0.3s;
        background-color: ${props?.borderColor}33;
      }

      &:not(:disabled):active {
        transition-property: background-color;
        transition-duration: 0.3s;
        background-color: ${props?.borderColor};
        color: white;
      }
    `}

  ${props =>
    props?.borderWidth &&
    css`
      border-width: ${props?.borderWidth};
    `}

  ${props =>
    props?.border &&
    css`
      border: ${props?.border};
    `}

    ${props =>
    props?.borderRadius &&
    css`
      border-radius: ${props?.borderRadius};
    `}

  ${props =>
    props?.backgroundColor &&
    css`
      background-color: ${props?.backgroundColor};
    `}

  ${props =>
    props?.width &&
    css`
      width: ${props?.width};
    `}

  ${props =>
    props?.height &&
    css`
      height: ${props?.height};
    `}

  ${props =>
    props?.padding &&
    css`
      padding: ${props?.padding};
    `}

    ${props =>
    props?.transform &&
    css`
      transform: ${props?.transform};
    `}

    ${props =>
    props?.transitionDuration &&
    css`
      transition-duration: ${props?.transitionDuration};
    `}

  // Disabled
  ${props =>
    props?.disabled &&
    css`
      background-color: #a6a6a6;
      border: 1px solid #a6a6a6;
      color: white;
      cursor: not-allowed;
    `}
`;

export default StyledCommonButton;
