const PO_NAME_SET = {
  title: 'PULL OUT',
  sche_time: 'po_sche_time',
  loc: 'po_loc',
  driver_id: 'po_driver_id',
  driver_name: 'po_driver_name',
  city: 'po_loc_city',
  state: 'po_loc_state',
  zip: 'po_loc_zip',
};

export default PO_NAME_SET;
