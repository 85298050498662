import { useQuery } from '@tanstack/react-query';
import getBookingWO from '../../../../services/Dispatch/Export/Booking/get/getBookingWO';
import dispatchKeys from '../../keys';

const useGetBookingWO = ({ bookingSeq }) => {
  const response = useQuery({
    queryFn: () => getBookingWO({ bookingSeq }),
    queryKey: dispatchKeys?.bookingWO({ bookingSeq }),
    refetchOnWindowFocus: true,
    enabled: !!bookingSeq,
  });

  return {
    ...response,
    bookingWO: response?.data?.data || [],
  };
};

export default useGetBookingWO;
