import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import getURLSearchParamsObject from '../../../../utils/Dispatch/Common/Generator/getURLSearchParamsObject';
import dispatchDefaultAPI from '../../api';

const getButtonCount = async ({
  paramDiv,
  paramType,
  paramQueries,
  yardParamQueries,
  status,
}) => {
  const params = getURLSearchParamsObject({
    div: paramDiv,
    type: paramType,
    status,
    ...paramQueries,
    ...yardParamQueries,
  });
  try {
    const response = await dispatchDefaultAPI.get(`count`, {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getButtonCount;
