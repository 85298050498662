import { useEffect, useState } from 'react';
import checkIsDefaultYard from '../../../utils/Common/Handler/checkIsDefaultYard';

const useControlReturnYardQueries = ({ yardList, divList }) => {
  const defaultDiv = localStorage.getItem('defaultDiv');

  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP: true,
    LIVE: true,
  });

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(divList?.map(div => [div, div === defaultDiv])),
  });

  const [categoryQuery, setCategoryQuery] = useState({
    M: true,
    X: true,
  });

  const [yardQuery, setYardQuery] = useState({});
  useEffect(() => {
    if (!_.isEmpty(yardList)) {
      setYardQuery({
        ...Object.fromEntries(
          yardList
            ?.map(yard => [yard, checkIsDefaultYard({ yard, defaultDiv })])
            ?.filter(item => typeof item[0] === 'string'),
        ),
      });
    }
  }, [yardList]);

  const [statusQuery, setStatusQuery] = useState({
    W: true,
    B: true,
    G: true,
    Y: true,
  });

  const [allQueries, setAllQueries] = useState({});

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: { ...scheduleTypeQuery },
      div: { ...divisionQuery },
      cat: { ...categoryQuery },
      color: { ...statusQuery },
    }));
    if (!_.isEmpty(yardList)) {
      setAllQueries(prev => ({
        ...prev,
        'y-com': { ...yardQuery },
      }));
    }
  }, [scheduleTypeQuery, divisionQuery, categoryQuery, yardQuery, statusQuery]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setYardQuery,
    setStatusQuery,
    allQueries,
  };
};

export default useControlReturnYardQueries;
