import orderAPI from '../api';

const getSendTo = async ({ billto }) => {
  try {
    const response = await orderAPI.get(`sendto/${billto}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getSendTo;
