import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import MainLayOut from '../../components/Layout/MainLayOut';

const DailyReport = () => {
  const location = useLocation();

  return (
    <MainLayOut suppressSidebar>
      <Outlet />
    </MainLayOut>
  );
};

export default DailyReport;
