import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import StyledCommonButton from '../../styles/Common/CommonButton/StyledCommonButton';
import StyledCommonButtonIcon from '../../styles/Common/CommonButton/StyledCommonButtonIcon';
import updateButtonDisabled from '../../utils/Common/Handler/updateButtonDisabled';

const CommonButton = forwardRef(
  ({ children, customStyle, onClick, ...props }, ref) => {
    const buttonRef = ref || useRef(null);

    const [isDisabled, setDisabled] = useState(props?.disabled || false);

    /* The disabled state should be updated when the property is changed. */
    useEffect(() => {
      if (props?.disabled !== null && props?.disabled !== undefined)
        setDisabled(props?.disabled);
    }, [props?.disabled]);

    /** The disabled state should be synced when the ref's disabled is changed.
     *  The priority should be the passed property. */
    useLayoutEffect(() => {
      if (_.isBoolean(buttonRef?.current?.disabled))
        setDisabled(props?.disabled || buttonRef?.current?.disabled);
    }, [buttonRef?.current?.disabled]);

    return (
      <StyledCommonButton
        ref={buttonRef}
        type={props?.type === 'submit' ? 'submit' : 'button'}
        onClick={e => {
          props?.preventDupClick &&
            updateButtonDisabled({ ref: buttonRef, disabled: true });
          onClick?.({ e, buttonRef });
        }}
        {...props}
        {...customStyle}
        disabled={isDisabled}
      >
        {(props?.icon || props?.category) && (
          <div className="flex justify-center items-center">
            {/* Render custom icon */}
            {props?.icon && props?.icon}
            {/* Render specified icon */}
            {!props?.icon && props?.category && (
              <StyledCommonButtonIcon
                {...props}
                {...customStyle}
                disabled={isDisabled}
              />
            )}
          </div>
        )}
        {children && (
          <div className="flex justify-center items-center">{children}</div>
        )}
      </StyledCommonButton>
    );
  },
);

export default CommonButton;
