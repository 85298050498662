import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import DriverModalButtonContent from './DriverModalButtonContent';
import DriverModalContent from './DriverModalContent';
import CommonMessageModal from '../../CommonMessageModal/CommonMessageModal';
import dispatchKeys from '../../../hooks/Dispatch/keys';

const DriverModal = ({ ...props }) => {
  /** Query client */
  const queryClient = new QueryClient();

  /** Modal opener setter */
  const { driverAPModalOpener, setAPModalOpener, setDriverAPModalOpener } =
    props;

  /** Data setter */
  const {
    setWONo,
    setSelectedScheduleSeq,
    setSelectedDriverData,
    getAPRefetcher,
  } = props;

  /** API Status */
  const { getAPIsError } = props;

  /** Data */
  const { driverList, selectedDriverData } = props;

  /** Render */
  return (
    driverList && (
      <CommonMessageModal
        open={driverAPModalOpener}
        setOpen={setDriverAPModalOpener}
        onCloseClick={() => {
          queryClient?.invalidateQueries({ queryKey: dispatchKeys?.driver() });
          setWONo('');
          setSelectedScheduleSeq(null);
          setSelectedDriverData(null);
        }}
        width="600px"
        height="533px"
        header="Driver List"
        content={
          <DriverModalContent
            driverList={driverList}
            setSelectedDriverData={setSelectedDriverData}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setOpen={setDriverAPModalOpener}
            setAPModalOpen={setAPModalOpener}
            getAPDataRefetch={getAPRefetcher}
          />
        }
        buttonContent={
          <DriverModalButtonContent
            setWONo={setWONo}
            setOpen={setDriverAPModalOpener}
            setAPModalOpen={setAPModalOpener}
            getAPDataRefetch={getAPRefetcher}
            selectedDriverData={selectedDriverData}
            setSelectedScheduleSeq={setSelectedScheduleSeq}
            setSelectedDriverData={setSelectedDriverData}
            getAPIsError={getAPIsError}
          />
        }
      />
    )
  );
};

export default DriverModal;
