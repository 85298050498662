import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchDeleteBooking from '../../../../services/Dispatch/Export/Booking/patch/patchDeleteBooking';
import dispatchKeys from '../../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_DELETE } from '../../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const usePatchDeleteBooking = ({
  setIsMessageModalOpen,
  setMessageModalMessage,
  updateBookingController,
  setIsUpdateBookingModalOpen,
  setTabIndexOpen,
  setBookingSeq,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchDeleteBooking,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, parameter) => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.bookingAll });
      addToast({ message: SUCCESS_DELETE });
      setIsUpdateBookingModalOpen(false);
      setTabIndexOpen(0);
      setBookingSeq(null);
      updateBookingController.reset();
    },
    onError: error => {
      setIsMessageModalOpen(true);
      setMessageModalMessage(error.message);
    },
  });
  return mutator;
};

export default usePatchDeleteBooking;
