import styled, { css } from 'styled-components';

const StyledNormalCellRenderer = styled.div`
  width: ${props => props?.width || '100%'};
  height: ${props => props?.height || '100%'};
  display: flex;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  background-color: ${props => props?.backgroundColor || 'transparent'};
  color: ${props => props?.color || 'black'};
  font-weight: ${props => props?.fontWeight || '500'};

  ${props =>
    props?.colDef?.editable &&
    css`
      :hover {
        border-top: 1px solid #264b9f;
        border-bottom: 1px solid #264b9f;
        color: #264b9f;
        font-weight: 500;
        transition-duration: 300ms;
        cursor: text;
      }
    `}
`;

export default StyledNormalCellRenderer;
