import {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP,
  GROUP_DEFS,
  PICK_UP_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
} from '../Common/columnGroups';

const IMPORT_DEFAULT_FIELDS = {
  category: {
    headerName: 'CATEGORY',
  },
  created_date: {
    headerName: 'DATE',
    type: 'nglDateType',
  },
  wo_no: {
    headerName: 'WO #',
  },
  billto: {
    headerName: 'BILL TO',
  },
  cont_no: {
    headerName: 'CONT #',
  },
  ssl: {
    headerName: 'SSL',
  },
  vessel: {
    headerName: 'VSL',
  },
  size: {
    headerName: 'SZ',
  },
  type: {
    headerName: 'TYPE',
  },
  ch_no: {
    headerName: 'CHASSIS #',
  },
  pool: {
    headerName: 'POOL',
  },
  ch_type: {
    headerName: 'CH TYPE',
  },
  bond_entry: {
    headerName: 'BOND #',
  },
  mbl_no: {
    headerName: 'MBL #',
  },
  ref_no: {
    headerName: 'REF #',
  },
  purchase_no: {
    headerName: 'PO #',
  },
  wgt: {
    headerName: 'WGT',
  },
  qty: {
    headerName: 'QTY',
  },
  over_height: {
    headerName: 'O/H',
  },
  over_weight: {
    headerName: 'O/W',
  },
  hazmat: {
    headerName: 'HAZ',
  },
  hot: {
    headerName: 'HOT',
  },
  eta: {
    headerName: 'ETA',
    type: 'nglDateType',
  },
  lfd: {
    headerName: 'LFD',
    type: 'nglDateType',
  },
  rfd: {
    headerName: 'RFD',
    type: 'nglDateType',
  },
  ava_date: {
    headerName: 'AVA DATE',
    type: 'nglDateType',
  },
  st: {
    headerName: 'S/T',
    type: 'nglDateType',
  },
  po_g: {
    headerName: 'PULL OUT',
    children: PULL_OUT_GROUP,
  },
  dilv_g: {
    headerName: 'DELIVERY',
    children: DELIVERY_GROUP,
  },
  pu_g: {
    headerName: 'PICK UP',
    children: PICK_UP_GROUP,
  },
  rtn_g: {
    headerName: 'RETURN',
    children: RETURN_GROUP,
  },
  empty_load_cfm: {
    headerName: 'MTY CFM',
    type: 'nglDateType',
  },
  ar_g: {
    headerName: 'AR',
    children: AR_GROUP,
  },
  ap_g: {
    headerName: 'AP',
    children: AP_GROUP,
  },
  profit: {
    headerName: 'PRFT',
  },
  inv: {
    headerName: 'INV',
  },
  doc_g: {
    headerName: 'DOCUMENT',
    children: DOCUMENT_GROUP,
  },
  remarks: {
    headerName: 'REMARKS',
  },
};

export { IMPORT_DEFAULT_FIELDS };
