import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import ediDefaultAPI from '../api';

const accept204 = async ({ id, data }) => {
  try {
    const response = await ediDefaultAPI.patch(`204/${id}/accept`, {
      billto: data?.billto,
      size: data?.size,
      type: data?.type,
      ssl: data?.ssl,
      pool: data?.pool,
      cont_no: data?.cont_no,
      ch_no: data?.ch_no,
      mbl_no: data?.mbl_no,
      hbl_no: data?.hbl_no,
      ref_no: data?.ref_no,
      purchase_no: data?.purchase_no,
      dilv_no: data?.dilv_no,
      vessel: data?.vessel,
      seal: data?.seal,
      wgt: data?.wgt,
      qty: data?.qty,
      tare: data?.tare,
      unit: data?.unit,
      eta: data?.eta,
      lfd: data?.lfd,
      freedays: data?.freedays,
      freedays_type: data?.freedays_type,
      commodity: data?.commodity,
      remarks: data?.remarks,
      po_location: data?.po_location,
      dilv_location: data?.dilv_location,
      rtn_location: data?.rtn_location,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default accept204;
