import { useQuery } from '@tanstack/react-query';
import { getARAgingList } from '../../../../services/Acct/ARAging/Get/getARAgingList';
import acctKeys from '../../keys';

const useGetARAgingList = () => {
  const { data: arAgingList, isLoading: isLoadingARAgingList } = useQuery({
    queryKey: acctKeys.arAgingList(),
    queryFn: () => getARAgingList(),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  return { arAgingList: arAgingList?.data, isLoadingARAgingList };
};

export default useGetARAgingList;
