import React from 'react';
import { useSelector } from 'react-redux';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/TmsReport/Schedules/HeaderButtons';
import WeeklyTable from '../../../components/TmsReport/Schedules/WeeklyTable';
import StyledWeeklyTable from '../../../styles/TmsReport/Schedules/StyledWeeklyTable';
import PO_NAME_SET from '../../../constants/TmsReport/PullOut/pullOutNameSet';
import useGetSchedule from '../../../hooks/TmsReport/Schedules/useGetSchedule';
import useControlQueries from '../../../hooks/TmsReport/Schedules/useControlQueries';

const PullOut = () => {
  const divList = useSelector(state => state?.common?.allDiv);

  const {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    allQueries,
  } = useControlQueries({ divList });

  const querySetters = {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
  };

  const {
    isLoading: isWeeklyLoading,
    isFetching: isWeeklyFetching,
    data: weeklyData,
  } = useGetSchedule({
    type: 'po',
    ...allQueries,
  });

  return (
    <>
      <HeaderButtons
        allQueries={allQueries}
        divList={divList}
        {...querySetters}
      />
      <StyledWeeklyTable>
        <WeeklyTable
          weeklyData={weeklyData}
          nameSet={PO_NAME_SET}
          allQueries={allQueries}
        />
      </StyledWeeklyTable>
      <CommonLoading open={isWeeklyLoading || isWeeklyFetching} />
    </>
  );
};

export default PullOut;
