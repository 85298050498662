import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constants/Common/DataGrid/cellType';
import {
  STATUS_STANDARD_CODE,
  STATUS_STANDARD_CODE_OBJECT,
} from '../../../../constants/TmsReport/EarlyWarning/standardCode';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  generateCategoryOption,
  generateLevelOption,
} from './floatingFilterOptionGenerator';
import generateIssueDescription from './generateIssueDescription';

const isBoolean = value => {
  return Object.prototype.toString.call(value) === '[object Boolean]';
};

const createEWColumnDefs = () => {
  return [
    {
      headerName: 'CREATE DATE',
      field: 'created_date',
      width: 98,
      editable: false,
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'LV',
      field: 'level',
      width: 33,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateLevelOption(),
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'CAT',
      field: 'category',
      width: 42,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateCategoryOption(),
      },
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'EQUIPMENT',
      field: 'cont_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'ISSUE DESCRIPTION',
      field: 'warning_issue',
      width: 330,
      editable: false,
      valueGetter: params => {
        return generateIssueDescription({
          days: params?.data?.days_hours,
          frequency: params?.data?.frequency,
          warningFrom: params?.data?.warn_from,
          waringTo: params?.data?.warn_to,
          warningIssue: params?.data?.warning_issue,
          level: params?.data?.level,
        });
      },
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 80,
      editable: true,
      floatingFilter: false,
      valueGetter: params => {
        return STATUS_STANDARD_CODE_OBJECT?.[params?.data?.status]?.label;
      },
      valueSetter: params => {
        const oldValue = isBoolean(params?.oldValue)
          ? params?.oldValue
          : params?.oldValue === 'CONFIRM';

        const newValue = isBoolean(params?.newValue)
          ? params?.newValue
          : params?.oldValue === 'CONFIRM';

        return oldValue !== newValue;
      },
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.status === STATUS_STANDARD_CODE?.CONFIRM,
          onChangeHandler: ({ ...props }) => {
            props?.setDataValue(!!props?.e?.target?.checked);
            props?.onValueChange?.(!!props?.e?.target?.checked);
          },
        });
      },
    },
    {
      headerName: 'UPDATED DATE',
      field: 'updated_date',
      width: 110,
      editable: false,
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'datetime',
        maxLength: 14,
        placeholder: 'MM/DD/YY HH:mm',
      },
    },
    {
      headerName: 'UPDATED BY',
      field: 'updated_by',
      width: 92,
      editable: false,
    },
  ];
};

export default createEWColumnDefs;
