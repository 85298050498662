import React, { useState } from 'react';
import {
  STATISTICS_ROW_TITLE_VALUE,
  STATISTICS_TABLE_NORMAL_AND_COST_TYPE_LIST,
} from '../../../../../constants/TmsReport/Statistics/statisticsValues';
import StyledStatContentsContainer from '../../../../../styles/TmsReport/Statistics/StyledStatContentsContainer';

const StatRowContents = ({
  division,
  cycle,
  rowTitle,
  rowData,
  cycleUnits,
  tableTitle,
  ...props
}) => {
  const [isCellHovered, setIsCellHovered] = useState(false);
  const isAmountCell =
    !!STATISTICS_TABLE_NORMAL_AND_COST_TYPE_LIST?.includes(tableTitle);

  const generateCellValue = unit => {
    const numberValue =
      cycle === 'weekly' ? rowData?.[unit]?.count : rowData?.[unit];
    const cellValue = isAmountCell ? numberValue?.toFixed(2) : numberValue;

    return cellValue;
  };

  return (
    <StyledStatContentsContainer isCellHovered={isCellHovered} {...props}>
      {rowTitle && (
        <div className="row-title left-category-sticky">
          {STATISTICS_ROW_TITLE_VALUE[rowTitle]}
        </div>
      )}
      {Object.keys(cycleUnits)?.map((unit, index) => (
        <div
          key={String(cycle + division + index)}
          onMouseOver={() => setIsCellHovered(true)}
          onMouseLeave={() => setIsCellHovered(false)}
          className={`cell ${isAmountCell && 'justify-between px-[20px]'}`}
        >
          {isAmountCell && <div>$</div>}
          {generateCellValue(unit)}
        </div>
      ))}
    </StyledStatContentsContainer>
  );
};

export default StatRowContents;
