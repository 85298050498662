import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import orderKeys from './keys';
import getSendTo from '../../services/Order/Get/getSendTo';

/**
 * @description
 * Get sendTo value according to billTo value
 * @returns {object} sendTo value and query
 */
const useGetSendto = () => {
  // RHF property
  const { setValue } = useFormContext();
  // Scope billto value
  const billTo = useWatch({ name: 'billto' });
  // Bill to's email
  const [sendTo, setSendTo] = useState('');

  // Query
  const query = useQuery({
    queryKey: orderKeys.sendTo({ billTo }),
    queryFn: () => getSendTo({ billto: billTo }),
    refetchOnWindowFocus: false,
    enabled: !!billTo,
  });

  // Set sendTo value according to billTo value
  useEffect(() => {
    if (query?.isSuccess) {
      if (query?.data?.data) {
        const querySendToValue = query?.data?.data?.email;
        setSendTo(
          querySendToValue && querySendToValue?.length > 0
            ? querySendToValue?.join(',')
            : '',
        );
        setValue(
          'sendto',
          querySendToValue && querySendToValue?.length > 0
            ? querySendToValue?.join(',')
            : '',
        );
      } else {
        setSendTo('');
      }
    }
  }, [query?.isFetching]);

  return { sendTo, query };
};

export default useGetSendto;
