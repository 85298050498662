import { ERROR_SPECIFIC_STATUS_CODE } from '../../../constants/Common/errorStatus';
import errorStatusHandler from './errorStatusHandler';
import handleCustomQueryError from './handleCustomQueryError';
import handleSystemQueryError from './handleSystemQueryError';

/**
 * @description
 * Handle query error from API
 * @param {object} error
 * Error object from query
 * @returns {string}
 * Error message
 */
const handleQueryError = ({ error }) => {
  /** Check error response status code */
  if (ERROR_SPECIFIC_STATUS_CODE.includes(error?.response?.status))
    return errorStatusHandler({ error });

  /** Check error response data have code and fields */
  if (error?.response?.data?.code) {
    /** Custom Error */
    return handleCustomQueryError({ error });
  }

  /** System Error */
  return handleSystemQueryError({ error });
};

export default handleQueryError;
