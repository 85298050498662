import React from 'react';
import dayjs from 'dayjs';
import { useWatch } from 'react-hook-form';
import CommonDate from '../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../Inputs/CommonTextInput/CommonTextInput';
import {
  DISPATCH_TYPE_EXPORT,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_IMPORT,
  DISPATCH_TYPE_VAN,
  DISPATCH_TYPE_BROKERAGE,
} from '../../../constants/Dispatch/Common/category';
import {
  DISPATCH_BOOKING_FILTER_DATE,
  DISPATCH_BOOKING_FILTER_STATUS,
  DISPATCH_EXPORT_FILTER_CATEGORY,
  DISPATCH_EXPORT_FILTER_DATE,
} from '../../../constants/Dispatch/Export/Filter/advancedSearchOptions';
import {
  DISPATCH_IMPORT_FILTER_CATEGORY,
  DISPATCH_IMPORT_FILTER_DATE,
} from '../../../constants/Dispatch/Import/Filter/advancedSearchOptions';
import {
  DISPATCH_VAN_FILTER_CATEGORY,
  DISPATCH_VAN_FILTER_DATE,
} from '../../../constants/Dispatch/Van/Filter/advancedSearchOptions';

const AdvancedSearchContent = ({ ...props }) => {
  const dateOption = ({ woType }) => {
    if (woType === DISPATCH_TYPE_IMPORT) {
      return DISPATCH_IMPORT_FILTER_DATE;
    }
    if (woType === DISPATCH_TYPE_EXPORT) {
      return DISPATCH_EXPORT_FILTER_DATE;
    }
    if (woType === DISPATCH_TYPE_BOOKING) {
      return DISPATCH_BOOKING_FILTER_DATE;
    }
    if (woType === DISPATCH_TYPE_VAN || woType === DISPATCH_TYPE_BROKERAGE) {
      return DISPATCH_VAN_FILTER_DATE;
    }
    return [];
  };

  const categoryOption = ({ woType }) => {
    if (woType === DISPATCH_TYPE_IMPORT) {
      return DISPATCH_IMPORT_FILTER_CATEGORY;
    }
    if (woType === DISPATCH_TYPE_EXPORT) {
      return DISPATCH_EXPORT_FILTER_CATEGORY;
    }
    if (woType === DISPATCH_TYPE_VAN || woType === DISPATCH_TYPE_BROKERAGE) {
      return DISPATCH_VAN_FILTER_CATEGORY;
    }
    return [];
  };

  return (
    <div className="flex flex-col w-full h-fit bg-[#F9F9F9] px-[30px] py-[15px] justify-center space-y-[20px]">
      {[DISPATCH_TYPE_BOOKING].includes(props.woType) && (
        <div className="flex flex-row space-x-[20px]">
          <div className="w-[116px]">
            <CommonSelect
              label=""
              inputName="status"
              option={DISPATCH_BOOKING_FILTER_STATUS}
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
            />
          </div>
        </div>
      )}
      <div className="flex flex-row justify-center space-x-[20px]">
        <div className="w-[153px]">
          <CommonSelect
            label="DATE"
            inputName="date"
            option={dateOption({ woType: props.woType })}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonDate
            label="FROM"
            inputName="dateFrom"
            required
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);
            }}
          />
        </div>
        <div className="w-[116px]">
          <CommonDate
            label="TO"
            inputName="dateTo"
            required
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);
            }}
          />
        </div>
      </div>
      {[
        DISPATCH_TYPE_IMPORT,
        DISPATCH_TYPE_EXPORT,
        DISPATCH_TYPE_VAN,
        DISPATCH_TYPE_BROKERAGE,
      ].includes(props.woType) && (
        <div className="flex flex-row space-x-[20px]">
          <div className="w-[153px]">
            <CommonSelect
              label="CATEGORY"
              inputName="category"
              option={categoryOption({ woType: props.woType })}
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value);
              }}
            />
          </div>
          <div className="w-[252px]">
            <CommonTextInput
              label=""
              inputName="categoryText"
              placeholder="Type here..."
              onChangeHandler={({ e, onChange }) => {
                onChange?.(e?.target?.value.replace(' ', ''));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedSearchContent;
