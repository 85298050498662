import React from 'react';

/**
 * @description
 * After click event, button should be disabled to prevent duplicated clicking.
 * After mutation is settled, button should be enabled.
 * @param {React.RefObject<HTMLElement>} ref
 * @param {boolean} disabled
 *
 */
const updateButtonDisabled = ({ ref, disabled }) => {
  if (ref?.current) ref.current.disabled = disabled;
  return ref;
};

export default updateButtonDisabled;
