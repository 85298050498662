import { createSlice } from '@reduxjs/toolkit';
import { TMS_DEMURRAGE_SLICE_KEY } from '../../../Constants/TmsReport/Demurrage';

export const demurrageInitialState = {
  listFilter: {},
};

const demurrageSlice = createSlice({
  name: TMS_DEMURRAGE_SLICE_KEY,
  initialState: demurrageInitialState,
  reducers: {
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.location]: action.payload.dateValue,
          },
        },
      }),
    },
  },
});

export const { updateListDateFilter } = demurrageSlice.actions;

export default demurrageSlice.reducer;
