import { createSlice } from '@reduxjs/toolkit';

const cneeSlice = createSlice({
  name: 'cnee',
  initialState: null,
  reducers: {
    saveCnee: (state, action) => action.payload,
  },
});

export const { saveCnee } = cneeSlice.actions;
export default cneeSlice.reducer;
