export const ERROR_REQUIRED = 'field(s) is required.';
export const ERROR_INVALID = 'field(s) is invalid.';
export const ERROR_UNIQUE = 'field(s) should be given a unique value.';
export const ERROR_PAIR_UNIQUE = 'field(s) pairs must have unique values.';
export const ERROR_NOT_FOUND = `Sorry, we couldn't find what you were looking for.`;
export const ERROR_PERMISSION_DENIED = 'Permission denied.';
export const ERROR_QB_ALREADY_EXIST = 'is already exist in QuickBook.';
export const ERROR_DEPENDENT = 'Please check dependent fields follow.';
export const ERROR_MUTEX = `field(s) can't be used together. Choice one of them.`;
export const ERROR_ASSIGNED = `field(s) already assigned. Please check again.`;
export const ERROR_DUPLICATED = `field(s) is duplicated.`;
export const ERROR_SAME_LOCATION = `Follow location should be different.`;
export const ERROR_DEACTIVATION_FAIL = `make(s) deactivation failed.`;
export const ERROR_INACTIVE_USER = `Your account has been deactivated. Please contact to System Admin.`;
export const ERROR_BILLTO_CREDIT_HOLD = `This Bill To is under Credit Hold.`;
export const ERROR_INVALID_9_2_DECIMAL = `Please enter a decimal number with up to 9 digits and 2 decimal places.`;
export const ERROR_NOT_AUTHOR = "is not your account. You can't edit this.";

export const ERROR_UNKNOWN =
  'Unknown Error occurred :<. Please contact to admin.';
