import setBCDetailTab from '../../Common/Handler/setBCDetailTitle';

const clickRow = ({ gridParam, category }) => {
  const bcDetailTab = window.open(
    `/bc-detail/detail-info/${category}/${gridParam.node.data.wo_no}`,
    'bc-detail-tab',
  );

  setBCDetailTab({
    bcDetailTab,
    woNo: gridParam.node.data.wo_no,
  });
};

export default clickRow;
