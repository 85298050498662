import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_DATE } from '../../../../constants/Common/DataGrid/cellType';
import {
  getDateFontColor,
  getDateFontWeight,
} from '../../../TmsReport/Common/dateStyleGenerator';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const getDateDef = field => {
  return {
    comparator: dateComparator,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      placeholder: 'MM/DD/YY',
      inputType: 'date',
      maxLength: 8,
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
        customStyle: {
          color: getDateFontColor({ field, data: gridParam?.data }),
          fontWeight: getDateFontWeight({ field, data: gridParam?.data }),
        },
      }),
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
    },
  };
};

export default getDateDef;
