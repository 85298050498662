import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getDailySchedule = async ({ params }) => {
  try {
    const response = await tmsReportAPI.get('/schedule/daily', {
      params,
    });
    return response?.data;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDailySchedule;
