import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_USER_SLICE_KEY } from '../../../Constants/Admin/User';

const adminUserInitialState = {
  adminUserList: [],
  authNameList: [],
  userDateFilter: {
    last_login_date: {
      from: null,
      to: null,
    },
  },
  userTimeFilter: {
    last_login_time: {
      from: null,
      to: null,
    },
  },
};

const adminUserSlice = createSlice({
  name: ADMIN_USER_SLICE_KEY,
  initialState: adminUserInitialState,
  reducers: {
    originAdminUserList: {
      reducer: (state, action) => ({
        ...state,
        adminUserList: action.payload.originList,
      }),
    },
    originAuthNameList: {
      reducer: (state, action) => ({
        ...state,
        authNameList: action.payload.authNameList,
      }),
    },
    updateUserDateFilter: {
      reducer: (state, action) => ({
        ...state,
        userDateFilter: {
          [action.payload.filterName]: {
            ...state.userDateFilter?.[action.payload.filterName],
            [action.payload.filterItem]: action.payload.date,
          },
        },
      }),
    },
    updateUserTimeFilter: {
      reducer: (state, action) => ({
        ...state,
        userTimeFilter: {
          [action.payload.filterName]: {
            ...state.userTimeFilter?.[action.payload.filterName],
            [action.payload.filterItem]: action.payload.time,
          },
        },
      }),
    },
  },
});

export const {
  originAdminUserList,
  originAuthNameList,
  updateUserDateFilter,
  updateUserTimeFilter,
} = adminUserSlice.actions;
export default adminUserSlice.reducer;
