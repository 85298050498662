import { useEffect, useState } from 'react';

/**
 * @description
 * Get group background color according to user define property
 * @param {*} columnDefs
 * User defined column definition
 * @returns
 * Return array of background color
 */
const useGetGroupColBgColor = ({ columnDefs }) => {
  /** Background color state */
  const [bgColor, setBgColor] = useState([]);

  /** Extract background color from user defined column definition object */
  useEffect(() => {
    const bgArray = [];
    columnDefs?.forEach(res => {
      if (res?.isColumnGroup) {
        if (res?.backgroundColor) {
          bgArray.push(res?.backgroundColor);
        } else {
          bgArray.push('#ffffff');
        }
      } else {
        bgArray.push(null);
      }
    });
    setBgColor(bgArray);
  }, [columnDefs]);

  return { bgColor };
};

export default useGetGroupColBgColor;
