import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useGetCompanyIDList from '../../../../../hooks/Common/useGetCompanyIDList';
import viewDualScheduleButton from '../../../../../utils/BCDetail/Common/Handler/viewDualScheduleButton';
import convertScheduleType from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/convertScheduleType';
import handleDriverAssignDisable from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleDriverAssignDisable';
import handleScheduleAcctInputPrevent from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleAcctInputPrevent';
import handleScheduleDriverChainChange from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleDriverChainChange';
import handleScheduleInputPrevent from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleInputPrevent';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../SecondInputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../../SecondInputs/CommonDate/CommonDate';
import CommonStatusBox from '../../../../SecondInputs/CommonStatusBox/CommonStatusBox';
import CommonTextInput from '../../../../SecondInputs/CommonTextInput/CommonTextInput';
import CommonTime from '../../../../SecondInputs/CommonTime/CommonTime';
import Tooltip from '../../BasicInfo/Tooltip';
import ScheduleDriverDeleteButton from '../ScheduleTable/ScheduleDriverDeleteButton';
import ScheduleRowButtonGroup from './ScheduleRowButtonGroup';
import handleDetailInfoUpdate from '../../../../../utils/BCDetail/DetailInfo/Common/Handler/handleDetailInfoUpdate';
import { UPDATE_TYPE } from '../../../../../constants/BCDetail/Common/type';
import fillActualInOutSchedule from '../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/fillActualInOutSchedule';

const ScheduleRow = ({
  pageCategory,
  scheType,
  jobSetIdx,
  locationSetIdx,
  rowIdx,
  driver,
  data,
  jobData,
  jobSet,
  locationSet,
  prevData,
  nextData,
  locationInfo,
  dataKey,
  locationOption,
  driverListModalOpener,
  setSelectedScheduleSeq,
  scheduleSeq,
  setSelectedRowData,
  setAddModalOpener,
  scheduleType,
  setDeleteModalOpener,
  setMobileModalOpener,
  setDeleteDriverModalOpener,
  relatedWO,
  deleteDriverMutator,
  getAPRefetcher,
  isEditable,
  completed,
  isCompanyIDListLoading,
  ...props
}) => {
  const woNo =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 1];
  const {
    basicInfoFormController,
    scheduleFormController,
    updateDetailInfo,
    detailInfoData,
    setMessageModalOpener,
    setMessageModalMessage,
    setMessageModalType,
    frDataKey,
    ldDataKey,
    toDataKey,
  } = props;

  const updateProps = {
    woNo,
    detailInfoData,
    updateDetailInfo,
    basicInfoFormController,
    scheduleFormController,
    setErrorModalOpen: setMessageModalOpener,
    setMessage: setMessageModalMessage,
    setMessageModalType,
  };

  const {
    actual_in_date: actualInDate,
    actual_in_time: actualInTime,
    actual_out_date: actualOutDate,
    actual_out_time: actualOutTime,
    company,
    ordering,
    sche_date: scheDate,
    sche_time: scheTime,
    seq,
    status,
    type,
  } = data;

  const { setValue, getValues } = useFormContext();

  const [selectedCompany, setSelectedCompany] = useState(null);

  const isAssignDisabled = handleDriverAssignDisable({
    scheduleData: data,
    scheduleType,
    locationInfo,
    jobData,
    frDataKey,
    ldDataKey,
    toDataKey,
  });

  const { companyIDList, isFetching } = useGetCompanyIDList({
    company: selectedCompany,
  });
  useEffect(() => {
    if (!isFetching && companyIDList && companyIDList.length > 0) {
      handleScheduleDriverChainChange({
        scheType,
        type,
        setValue,
        dataKey,
        locationInfo,
        ordering,
        locationSet,
        jobSet,
        prevData,
        nextData,
        companyIDList,
        setSelectedCompany,
        locationSetIdx,
      });
    }
  }, [isFetching, companyIDList]);

  const scheduleFillingTools = {
    dataKey,
    locationInfo,
    setValue,
    getValues,
  };

  return (
    <div>
      <div className="flex items-center gap-x-[2.5px]">
        <div className="flex justify-center items-center w-[43px] pr-[1px]">
          {viewDualScheduleButton({
            ordering,
            data,
            driver,
            jobData,
          }) && (
            <CommonButton
              icon={null}
              customStyle={{
                color: '#F3873A',
                borderColor: '#F3873A',
              }}
              onClick={() => {
                props?.setDualMoveModalOpener?.(prev => true);
                props?.setDualMoveRowData(prev => data);
              }}
            >
              Dual
            </CommonButton>
          )}
        </div>
        {/* TYPE */}
        <div className="flex items-center space-x-[2px]">
          <div className="relative w-[100px] h-[30px] flex items-center shadow box-shadow-[0_0.5px_1px_rgba(0,0,0,0.25)]">
            <div className="w-[7px] h-[30px] bg-[#264B9f] absolute" />
            <div className="ml-[19px] w-[29px] text-[11px] font-[700] text-[#264B9f] pt-[8px] pb-[9px]">
              {ordering !== 0 ? 'STOP' : convertScheduleType({ data })}
            </div>
          </div>
        </div>
        {/* Location ID */}
        <div className="w-[130px] relative group/company">
          <CommonAutoComplete
            textCenter={Boolean(true)}
            inputName={`company_${dataKey}_${locationInfo}`}
            defaultValue={company?.company}
            disabled={handleScheduleInputPrevent({
              scheduleData: data,
              component: 'company',
              scheType,
              ordering,
              relatedWO,
              isEditable,
              completed,
            })}
            option={locationOption}
            onChangeHandler={({
              e,
              selectProps,
              onChange,
              ...handlerProps
            }) => {
              onChange(selectProps?.value);
              setSelectedCompany(selectProps?.value);
            }}
            isLoading={isCompanyIDListLoading}
          />
          <div className="absolute z-[1000] top-7 group-hover/company:block hidden">
            <Tooltip message={data?.log?.company_updated_by} />
          </div>
        </div>
        {/* City */}
        <div className="w-[99px]">
          <CommonTextInput
            textCenter={Boolean(true)}
            inputName={`city_${dataKey}_${locationInfo}`}
            defaultValue={company?.city}
            disabled={Boolean(true)}
          />
        </div>
        {/* STATE */}
        <div className="w-[74px]">
          <CommonTextInput
            textCenter={Boolean(true)}
            inputName={`state_${dataKey}_${locationInfo}`}
            defaultValue={company?.state}
            disabled={Boolean(true)}
          />
        </div>
        {/* Time Zone */}
        <div className="w-[74px]">
          <CommonTextInput
            textCenter={Boolean(true)}
            inputName={`timezone_${dataKey}_${locationInfo}`}
            defaultValue={company?.timezone}
            disabled={Boolean(true)}
          />
        </div>
        {/* SCHE DATE */}
        <div className="w-[168px] relative group/scheDate">
          <CommonDate
            textCenter={Boolean(true)}
            inputName={`sche_date_${dataKey}_${locationInfo}`}
            defaultValue={
              scheDate && dayjs(scheDate)?.isValid() ? dayjs(scheDate) : null
            }
            disabled={handleScheduleInputPrevent({
              scheduleData: data,
              isEditable,
              completed,
            })}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
          />
          <div className="absolute z-[1000] top-7 group-hover/scheDate:block hidden">
            <Tooltip message={data?.log?.sche_date_updated_by} />
          </div>
        </div>
        {/* TIME */}
        <div className="w-[119px] relative group/scheTime">
          <CommonTime
            textCenter={Boolean(true)}
            inputName={`sche_time_${dataKey}_${locationInfo}`}
            defaultValue={
              scheDate &&
              scheTime &&
              dayjs(`${scheDate} ${scheTime}`)?.isValid()
                ? dayjs(`${scheDate} ${scheTime}`)
                : null
            }
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
            disabled={handleScheduleInputPrevent({
              scheduleData: data,
              isEditable,
              completed,
            })}
          />
          <div className="absolute z-[1000] top-7 group-hover/scheTime:block hidden">
            <Tooltip message={data?.log?.sche_time_updated_by} />
          </div>
        </div>
        {/* ACT DATE */}
        <div className="w-[168px] relative group/actualInDate">
          <CommonDate
            textCenter={Boolean(true)}
            inputName={`actual_in_date_${dataKey}_${locationInfo}`}
            defaultValue={
              actualInDate && dayjs(actualInDate)?.isValid()
                ? dayjs(actualInDate)
                : null
            }
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
            onFocusHandler={({ e, onChange, ...handlerProps }) => {
              fillActualInOutSchedule({
                ...scheduleFillingTools,
                scheduleType: 'actual_in',
              });
            }}
            disabled={handleScheduleAcctInputPrevent({
              scheduleData: { ...data, driver },
              type: 'acctIn',
              isEditable,
              completed,
            })}
          />
          <div className="absolute z-[1000] top-7 group-hover/actualInDate:block hidden">
            <Tooltip message={data?.log?.actual_in_date_updated_by} />
          </div>
        </div>
        {/* TIME */}
        <div className="w-[119px] relative group/actualInTime">
          <CommonTime
            textCenter={Boolean(true)}
            inputName={`actual_in_time_${dataKey}_${locationInfo}`}
            defaultValue={
              actualInDate &&
              actualInTime &&
              dayjs(`${actualInDate} ${actualInTime}`)?.isValid()
                ? dayjs(`${actualInDate} ${actualInTime}`)
                : null
            }
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
            onFocusHandler={({ e, onChange, ...handlerProps }) => {
              fillActualInOutSchedule({
                ...scheduleFillingTools,
                scheduleType: 'actual_in',
              });
            }}
            disabled={handleScheduleAcctInputPrevent({
              scheduleData: { ...data, driver },
              type: 'acctIn',
              isEditable,
              completed,
            })}
          />
          <div className="absolute z-[1000] top-7 group-hover/actualInTime:block hidden">
            <Tooltip message={data?.log?.actual_in_time_updated_by} />
          </div>
        </div>
        {/* ACT DATE */}
        <div className="w-[168px] relative group/actualOutDate">
          <CommonDate
            textCenter={Boolean(true)}
            inputName={`actual_out_date_${dataKey}_${locationInfo}`}
            defaultValue={
              actualOutDate && dayjs(actualOutDate)?.isValid()
                ? dayjs(actualOutDate)
                : null
            }
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
            onFocusHandler={({ e, onChange, ...handlerProps }) => {
              fillActualInOutSchedule({
                ...scheduleFillingTools,
                scheduleType: 'actual_out',
              });
            }}
            disabled={handleScheduleAcctInputPrevent({
              scheduleData: { ...data, driver },
              type: 'acctOut',
              isEditable,
              completed,
            })}
          />
          <div className="absolute z-[1000] top-7 group-hover/actualOutDate:block hidden">
            <Tooltip message={data?.log?.actual_out_date_updated_by} />
          </div>
        </div>
        {/* TIME */}
        <div className="w-[119px] relative group/actualOutTime">
          <CommonTime
            textCenter={Boolean(true)}
            inputName={`actual_out_time_${dataKey}_${locationInfo}`}
            defaultValue={
              actualOutDate &&
              actualOutTime &&
              dayjs(`${actualOutDate} ${actualOutTime}`)?.isValid()
                ? dayjs(`${actualOutDate} ${actualOutTime}`)
                : null
            }
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (dayjs(e).isValid()) onChange?.(e);
              else onChange?.(null);
            }}
            onFocusHandler={({ e, onChange, ...handlerProps }) => {
              fillActualInOutSchedule({
                ...scheduleFillingTools,
                scheduleType: 'actual_out',
              });
            }}
            disabled={handleScheduleAcctInputPrevent({
              scheduleData: { ...data, driver },
              type: 'acctOut',
              isEditable,
              completed,
            })}
          />
          <div className="absolute z-[1000] top-7 group-hover/actualOutTime:block hidden">
            <Tooltip message={data?.log?.actual_out_time_updated_by} />
          </div>
        </div>
        {/** Mobile Memo Button */}
        <div className="w-[99px]" />
        <div className="w-[103px] relative group/driver">
          <CommonTextInput
            textCenter={false}
            inputName={`driver_${dataKey}_${locationInfo}`}
            defaultValue={driver || ''}
            onClickHandler={() => {
              handleDetailInfoUpdate({
                ...updateProps,
                basicInfoData: basicInfoFormController?.getValues(),
                scheduleData: scheduleFormController?.getValues(),
                driverListModalOpener,
                setSelectedScheduleSeq,
                scheduleSeq,
                updateType: UPDATE_TYPE.ASSIGN_DRIVER,
              });
            }}
            /** If schedule date or schedule time is not entered, input would be disabled */
            disabled={
              isAssignDisabled ||
              !isEditable ||
              !!jobData?.dual_job ||
              !!completed
            }
            adornment={
              isEditable &&
              !isAssignDisabled &&
              driver &&
              !jobData?.dual_job &&
              !completed && {
                position: 'end',
                content: (
                  <ScheduleDriverDeleteButton
                    setSelectedRowData={setSelectedRowData}
                    jobData={jobData}
                    setDeleteDriverModalOpener={setDeleteDriverModalOpener}
                    deleteDriverMutator={deleteDriverMutator}
                    updateProps={updateProps}
                    basicInfoData={basicInfoFormController?.getValues()}
                    scheduleData={scheduleFormController?.getValues()}
                    setSelectedScheduleSeq={setSelectedScheduleSeq}
                    scheduleSeq={scheduleSeq}
                    basicInfoFormController={basicInfoFormController}
                    scheduleFormController={scheduleFormController}
                  />
                ),
              }
            }
          />
          <div className="absolute z-[1000] top-7 group-hover/driver:block hidden">
            <Tooltip message={jobData?.driver_updated_by} />
          </div>
        </div>
        <div className="w-[115px]">
          <CommonStatusBox width="w-[115px]" status={status} />
        </div>
        <div className="w-[44px] pl-[8px]">
          {isEditable && !completed && (
            <ScheduleRowButtonGroup
              pageCategory={pageCategory}
              scheType={scheType}
              jobSetIdx={jobSetIdx}
              jobSet={jobSet}
              locationSetIdx={locationSetIdx}
              locationSet={locationSet}
              rowIdx={rowIdx}
              scheduleData={data}
              setSelectedRowData={setSelectedRowData}
              setAddModalOpener={setAddModalOpener}
              scheduleType={scheduleType}
              setDeleteModalOpener={setDeleteModalOpener}
              setSelectedScheduleSeq={setSelectedScheduleSeq}
              locationInfo={locationInfo}
              updateProps={updateProps}
              basicInfoFormController={basicInfoFormController}
              scheduleFormController={scheduleFormController}
              basicInfoData={basicInfoFormController?.getValues()}
              scheduleFormData={scheduleFormController?.getValues()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ScheduleRow;
