import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto',sans-serif",
    fontSize: 16,
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.75',
    letterSpacing: '-0.16px',
    subtitle1: {
      fontSize: 14,
      color: '#222',
      fontWeight: '600',
      lineHeight: '1.14',
    },
    subTitleNav: {
      fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: '-0.10px',
      lineHeight: 1,
    },
    subFieldTitle: {
      fontSize: 14,
      marginBottom: '5px',
      color: '#222',
      fontWeight: '600',
      lineHeight: '1.14',
    },
  },
  MuiButton: {
    disableElevation: true,
    disableRipple: true,
  },
  MuiTab: {
    disableElevation: true,
    disableRipple: true,
  },
});

export default theme;
