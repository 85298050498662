import styled from 'styled-components';

const StyledEditCellRenderer = styled.div`
  width: ${props => props?.width || '100%'};
  height: ${props => props?.height || '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledEditCellRenderer;
