import { CELL_TYPE_TEXT } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';

const getEditableEquipmentDef = () => {
  return {
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
      }),
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
      }),
    valueSetter: params => {
      if (params?.data?.equipment !== params?.newValue) {
        const cat = params?.data?.category;
        if (['IMP', 'EXP'].includes(cat)) {
          params.data.cont_no = params.newValue;
        } else if (['VAN', 'BROKERAGE'].includes(cat)) {
          params.data.trailer_no = params.newValue;
        }
        return true;
      }
      return false;
    },
  };
};

export default getEditableEquipmentDef;
