import dayjs from 'dayjs';
import FloatingFilterInput from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_DATETIME,
  CELL_TYPE_TEXT,
} from '../../../constants/Common/DataGrid/cellType';
import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../constants/Common/path';
import cellRendererGenerator from '../../Common/Datagrid/Generator/cellRendererGenerator';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';

const createMainColumnDef = () => {
  return [
    {
      headerName: 'DATE',
      field: 'created_date',
      width: 62,
      editable: false,
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.created_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'BILL TO',
      field: 'billto',
      width: 72,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'CONT #',
      field: 'cont_no',
      width: 92,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'MBL #',
      field: 'mbl_no',
      width: 125,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'PULL OUT',
      isColumnGroup: true,
      backgroundColor: '#CCD3E3',
      children: [
        {
          headerName: 'LOC',
          field: 'po_location',
          width: 72,
          editable: false,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SCHEDULE',
          field: 'po_sche_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.po_sche_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
      ],
    },
    {
      headerName: 'DELIVERY',
      isColumnGroup: true,
      backgroundColor: '#B5BFD8',
      children: [
        {
          headerName: 'LOC',
          field: 'dilv_location',
          width: 72,
          editable: false,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SCHEDULE',
          field: 'dilv_sche_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.dilv_sche_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
      ],
    },
    {
      headerName: 'RETURN',
      isColumnGroup: true,
      backgroundColor: '#919FC1',
      children: [
        {
          headerName: 'LOC',
          field: 'rtn_location',
          width: 72,
          editable: false,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_TEXT,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SCHEDULE',
          field: 'rtn_sche_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.rtn_sche_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
      ],
    },
    {
      headerName: 'RFD',
      field: 'rfd',
      width: 62,
      editable: false,
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.rfd;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: '204',
      isColumnGroup: true,
      backgroundColor: '#405687',
      color: '#FFFFFF',
      icon: {
        fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
        unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
      },
      children: [
        {
          headerName: 'FILE',
          field: '204_file',
          width: 65,
          editable: false,
          floatingFilter: false,
          cellRendererSelector: gridParam => {
            return gridParam?.data?.['204_file'] !== null &&
              gridParam?.data?.['204_file'] !== ''
              ? cellRendererGenerator({
                  gridParam,
                  cellType: CELL_TYPE_BUTTON,
                  customStyle: {
                    size: 'small',
                  },
                  content: 'File',
                  category: 'invoice',
                  onClickHandler: e => {
                    window.open(gridParam?.data?.['204_file'], '_blank');
                  },
                })
              : null;
          },
        },
        {
          columnGroupShow: 'open',
          headerName: 'FLAG',
          field: '204_flag',
          width: 55,
          editable: false,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATE,
            }),
          valueGetter: params => {
            const value = params?.data?.['204_flag'];
            if (!value) return null;
            if (value === 'N') return 'NEW';
            if (value === 'C') return 'CANCEL';
            if (value === 'U') return 'UPDATE';
            return 'Non-Type';
          },
        },
      ],
    },
    {
      headerName: '214',
      isColumnGroup: true,
      backgroundColor: '#223C77',
      color: '#FFFFFF',
      icon: {
        fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
        unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
      },
      children: [
        {
          headerName: 'PSD',
          field: 'psd_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.psd_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
        {
          columnGroupShow: 'open',
          headerName: 'DSD',
          field: 'dsd_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.dsd_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
        {
          columnGroupShow: 'open',
          headerName: 'PAD',
          field: 'pad_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.pad_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },

        {
          columnGroupShow: 'open',
          headerName: 'COB',
          field: 'cob_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.cob_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },

        {
          columnGroupShow: 'open',
          headerName: 'OFD',
          field: 'ofd_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.ofd_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },

        {
          columnGroupShow: 'open',
          headerName: 'ADL',
          field: 'adl_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.adl_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },

        {
          columnGroupShow: 'open',
          headerName: 'POD',
          field: 'pod_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.pod_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },

        {
          columnGroupShow: 'open',
          headerName: 'DAT',
          field: 'dat_date',
          width: 98,
          editable: false,
          comparator: dateComparator,
          cellRendererSelector: gridParam =>
            cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_DATETIME,
            }),
          valueGetter: params => {
            const dateValue = params.data?.dat_date;
            return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
          },
          floatingFilterComponent: FloatingFilterInput,
          floatingFilterComponentParams: {
            inputType: 'datetime',
            maxLength: 14,
            placeholder: 'MM/DD/YY HH:mm',
          },
        },
      ],
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      width: 162,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
  ];
};

export default createMainColumnDef;
