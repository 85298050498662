import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonCheckboxList from '../../../Inputs/CommonCheckboxList';
import getCheckboxOptions from '../../../../utils/TmsReport/Common/getCheckboxOptions';
import SearchDate from '../SearchDate';
import handleAllCheckboxChange from '../../../../utils/TmsReport/Common/handleAllCheckboxChange';

const HeaderButtons = ({
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  setDateQuery,
  allQueries,
  divList,
  isReturn,
}) => {
  const prevArrow = '../../../../assets/images/common_icon/prevArrow.svg';
  const nextArrow = '../../../../assets/images/common_icon/nextArrow.svg';

  const [isScheduleTypeOpen, setIsScheduleTypeOpen] = useState(false);
  const [isDivisionOpen, setIsDivisionOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const controlOpen = ({ target, setTarget }) => {
    if (target) {
      setTarget(false);
    } else {
      setIsScheduleTypeOpen(false);
      setIsDivisionOpen(false);
      setIsCategoryOpen(false);
      setTarget(true);
    }
  };

  const scheduleTypeController = useForm({});
  const divisionController = useForm({});
  const categoryController = useForm({});

  const { scheduleTypeOptionList, divisionOptionList, categoryOptionList } =
    getCheckboxOptions({
      scheduleTypeController,
      setScheduleTypeQuery,
      setDivisionQuery,
      setCategoryQuery,
      allQueries,
      divList,
    });

  return (
    <div className="flex flex-col pr-[40px]">
      <div className="flex flex-row justify-end items-center my-[20px]">
        <div className="text-[14px] font-[500] text-[#666666]">Date</div>
        <div className="w-[117px] mr-[13px] ml-[9px]">
          <SearchDate date={allQueries?.date} setDate={setDateQuery} />
        </div>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        <div className="pb-[3px]">
          <FormProvider {...scheduleTypeController}>
            <CommonCheckboxList
              isOpen={isScheduleTypeOpen}
              options={scheduleTypeOptionList}
              onChangeAll={({ ...props }) => {
                handleAllCheckboxChange({
                  ...props,
                  setQuery: setScheduleTypeQuery,
                });
              }}
              suppressIndeterminate
              button={
                <CommonButton
                  category="scheduleType"
                  onClick={() => {
                    controlOpen({
                      target: isScheduleTypeOpen,
                      setTarget: setIsScheduleTypeOpen,
                    });
                  }}
                  border="0"
                  backgroundColor="transparent"
                  color="#666666"
                >
                  Schedule Type
                </CommonButton>
              }
            />
          </FormProvider>
        </div>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        <div className="pb-[3px]">
          <FormProvider {...divisionController}>
            <CommonCheckboxList
              isOpen={isDivisionOpen}
              options={divisionOptionList}
              onChangeAll={({ ...props }) => {
                handleAllCheckboxChange({
                  ...props,
                  setQuery: setDivisionQuery,
                });
              }}
              suppressIndeterminate
              button={
                <CommonButton
                  category="customizeColumn"
                  onClick={() => {
                    controlOpen({
                      target: isDivisionOpen,
                      setTarget: setIsDivisionOpen,
                    });
                  }}
                  border="0"
                  backgroundColor="transparent"
                  color="#666666"
                >
                  Division
                </CommonButton>
              }
            />
          </FormProvider>
        </div>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        <div className="pb-[3px]">
          <FormProvider {...categoryController}>
            <CommonCheckboxList
              isOpen={isCategoryOpen}
              options={categoryOptionList}
              onChangeAll={({ ...props }) => {
                handleAllCheckboxChange({
                  ...props,
                  setQuery: setCategoryQuery,
                });
              }}
              suppressIndeterminate
              button={
                <CommonButton
                  category="category"
                  onClick={() => {
                    controlOpen({
                      target: isCategoryOpen,
                      setTarget: setIsCategoryOpen,
                    });
                  }}
                  border="0"
                  backgroundColor="transparent"
                  color="#666666"
                >
                  Category
                </CommonButton>
              }
            />
          </FormProvider>
        </div>
        {isReturn && (
          <CommonButton
            category="blueYard"
            onClick={() => {
              window.open(`/daily-report/return-yard`, '_blank');
            }}
            color="#556DA1"
          >
            RETURN YARD
          </CommonButton>
        )}
      </div>
      <div className="flex flex-row justify-between h-[30px] relative pb-[5px]">
        <div
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => {
            setDateQuery(
              dayjs(allQueries?.date).subtract(7, 'day').format('YYYY-MM-DD'),
            );
          }}
        >
          <img src={prevArrow} alt="prevArrow" />
          <div className="pt-[2px]">Prev Week</div>
        </div>
        <div
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => {
            setDateQuery(
              dayjs(allQueries?.date).add(7, 'day').format('YYYY-MM-DD'),
            );
          }}
        >
          <div className="pt-[2px]">Next Week</div>
          <img src={nextArrow} alt="nextArrow" />
        </div>
      </div>
    </div>
  );
};

export default HeaderButtons;
