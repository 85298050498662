const getURLSearchParams = params => {
  const list = Object.fromEntries(
    Object.keys(params)?.map(key => {
      if (['cat', 'div', 'sche', 'y-com', 'color']?.includes(key)) {
        return [
          key,
          [...Object.keys(params?.[key]).filter(value => params?.[key][value])],
        ];
      }
      return [key, params[key]];
    }),
  );
  const urlSearchParams = new URLSearchParams();
  Object.entries(list).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        urlSearchParams.append(key, item);
      });
    } else {
      urlSearchParams.append(key, value);
    }
  });
  return urlSearchParams;
};

export default getURLSearchParams;
