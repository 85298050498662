import { createSlice } from '@reduxjs/toolkit';
import { TMS_EARLY_WARNING_SLICE_KEY } from '../../../Constants/TmsReport/EarlyWarning';

const initialState = {
  flag: false,
};

const earlyWarningSlice = createSlice({
  name: TMS_EARLY_WARNING_SLICE_KEY,
  initialState,
  reducers: {
    setFlag: (state, actions) => {
      const newFlag = !state?.flag;
      return { ...state, flag: newFlag };
    },
  },
});

export const { setFlag } = earlyWarningSlice?.actions;

export default earlyWarningSlice?.reducer;
