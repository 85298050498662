import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

/**
 * Sign in base url
 */
const SIGN_IN_BASE_URL = `${process.env.REACT_APP_BASE_URL}/sign/`;

/**
 * Axios object with base url
 */
const signInAPI = axios.create({
  withCredentials: true,
  baseURL: SIGN_IN_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(signInAPI);

export default signInAPI;
