import React, { useRef } from 'react';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/Master/Common/HeaderButtons';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import useGetAcctCode from '../../../hooks/Master/AccountCode/useGetAcctCode';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import createAcctCodeColumnDefs from '../../../utils/Master/AccountCode/Generator/createAcctCodeColumnDefs';

const AccountCode = () => {
  const defaultColumnDefs = defaultColumnDefsGenerator();
  const gridRef = useRef(null);

  const { isLoading, isError, data: acctCodeList } = useGetAcctCode();

  return (
    <>
      {(isLoading || isError) && <CommonLoading open={isLoading || isError} />}
      <div className="flex flex-col h-full">
        <HeaderButtons gridRef={gridRef} fileName="ACCOUNT CODE" />
        <StyledMasterCommonDataGridContainer>
          <CommonDataGrid
            gridRef={gridRef}
            sideBar={false}
            data={acctCodeList?.data}
            columnDefs={createAcctCodeColumnDefs()}
            defaultColDef={defaultColumnDefs}
            suppressDragLeaveHidesColumns
          />
        </StyledMasterCommonDataGridContainer>
      </div>
    </>
  );
};

export default AccountCode;
