import styled from 'styled-components';

const StyledCommonErrorPageContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 895px;
  height: 570px;

  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
`;

export default StyledCommonErrorPageContentsWrapper;
