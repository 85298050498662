import React from 'react';
import CommonMessageModal from '../CommonMessageModal/CommonMessageModal';
import CommonButton from '../CommonButton/CommonButton';
import StyledCommonErrorModalWrapper from '../../styles/Common/CommonErrorModal/StyledCommonErrorModalWrapper';
import StyledCommonErrorModalButton from '../../styles/Common/CommonErrorModal/StyledCommonErrorModalButton';

const CommonErrorModal = ({
  open,
  setIsOpen,
  message,
  onOkClickHandler,
  onCloseClickHandler,
  isSuccess,
  ...props
}) => {
  return (
    open && (
      <CommonMessageModal
        open={open}
        setOpen={setIsOpen}
        header={isSuccess ? 'Success' : 'Error'}
        onCloseClick={onCloseClickHandler}
        content={
          <StyledCommonErrorModalWrapper>
            {message}
          </StyledCommonErrorModalWrapper>
        }
        buttonContent={
          <StyledCommonErrorModalButton>
            <CommonButton
              onClick={() => {
                setIsOpen(!open);
                onOkClickHandler?.();
              }}
              category="gotIt"
            >
              Got it
            </CommonButton>
          </StyledCommonErrorModalButton>
        }
      />
    )
  );
};

export default CommonErrorModal;
