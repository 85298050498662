import moment from 'moment-timezone';

const getFormattedDate = () => {
  const date = moment.tz('America/Los_Angeles');
  const day = date.format('DD');
  const month = date.format('MM');
  const year = date.format('YYYY');

  return `${month}${day}${year}`;
};

const exportToExcel = ({ id, gridRef }) => {
  let fileName = 'AR_AGING_REPORT';
  if (id) fileName += `_${id}_${getFormattedDate()}`;
  else fileName += `_${getFormattedDate()}`;

  gridRef.current.api.exportDataAsExcel({
    fileName,
    processCellCallback: params => {
      return params.value;
    },
  });
};

export default exportToExcel;
