import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import exportToExcel from '../../../../utils/Common/Datagrid/Handler/exportToExcel';
import handleQuickBook from '../../../../utils/Acct/Common/Handler/handleQuickBook';
import {
  ACCT_EXCEL_FILE_NAMES,
  ACCT_PAGE,
} from '../../../../constants/Acct/Common/staticNames';
import handleDriverPayClick from '../../../../utils/Acct/QBAPAR/Handler/handleDriverPayClick';
import getPermissionList from '../../../../utils/Acct/Common/Generator/getPermissionList';

const AccountQBHeaderBar = ({ ...props }) => {
  const {
    page,
    gridRef,
    setOpenModal,
    handlerPostQuickBooks,
    driverPayPatchMutator,
    errorModalOptions,
  } = props;

  const { isSendable, isUpdatable, isLockedUpdatable, isDriverPayable } =
    getPermissionList();

  return (
    <div className="flex items-center gap-x-[18px] justify-end mb-[19px] mt-[21px]">
      <div>
        <CommonButton
          category="greySearch"
          border="0"
          backgroundColor="transparent"
          color="#666666"
          fontWeight="500"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Search
        </CommonButton>
      </div>
      <div>
        <CommonButton
          type="button"
          onClick={() =>
            exportToExcel({ gridRef, fileName: ACCT_EXCEL_FILE_NAMES?.[page] })
          }
          category="excel"
          fontWeight="500"
        >
          EXCEL
        </CommonButton>
      </div>
      {page === ACCT_PAGE?.qbAPAR && isDriverPayable && (
        <div>
          <CommonButton
            preventDupClick
            type="button"
            fontWeight="500"
            borderColor="#264B9F"
            category="lockPay"
            onClick={({ buttonRef }) =>
              handleDriverPayClick({
                buttonRef,
                gridRef,
                driverPayPatchMutator,
                errorModalOptions,
              })
            }
          >
            Driver Pay
          </CommonButton>
        </div>
      )}
      {(isSendable || isUpdatable || isLockedUpdatable) && (
        <div>
          <CommonButton
            preventDupClick
            onClick={({ buttonRef }) => {
              handleQuickBook({
                buttonRef,
                page,
                gridRef,
                handlerPostQuickBooks,
                errorModalOptions,
              });
            }}
            type="button"
            category="quickBooks"
            borderColor="#3A825D"
          >
            QuickBooks
          </CommonButton>
        </div>
      )}
    </div>
  );
};

export default AccountQBHeaderBar;
