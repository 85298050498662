import { createSlice } from '@reduxjs/toolkit';
import { TMS_PERDIEM_SLICE_KEY } from '../../../Constants/TmsReport/Perdiem';

export const perdiemInitialState = {
  listFilter: {},
};

const perdiemSlice = createSlice({
  name: TMS_PERDIEM_SLICE_KEY,
  initialState: perdiemInitialState,
  reducers: {
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.location]: action.payload.dateValue,
          },
        },
      }),
    },
  },
});

export const { updateListDateFilter } = perdiemSlice.actions;

export default perdiemSlice.reducer;
