import React from 'react';
import COL_DEFS from '../../../../constants/TmsReport/Schedules/weeklyColDefs';
import WeeklyTableHeader from '../WeeklyTableHeader';
import WeeklyTableItem from '../WeeklyTableItem';

const WeeklyTable = ({ weeklyData, nameSet, allQueries }) => {
  return (
    <div className="flex flex-row divide-x-[2px] divide-transparent">
      {COL_DEFS.map(item => {
        return (
          <div
            key={`${item}Day`}
            className="flex flex-col h-full"
            id={`${item}`}
          >
            <WeeklyTableHeader
              page={nameSet?.title?.replace(' ', '-')?.toLowerCase()}
              weeklyData={weeklyData}
              day={item}
              allQueries={allQueries}
            />
            <div
              className="flex flex-col h-full w-[251px] gap-[5px] bg-[white] 
              overflow-y-auto justify-start items-center py-[20px]"
            >
              {weeklyData?.[item]?.data?.map(i => {
                return (
                  <WeeklyTableItem
                    key={`item${i?.wo_no}`}
                    wo={i}
                    weeklyData={weeklyData}
                    nameSet={nameSet}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WeeklyTable;
