import COLUMN_ITEM_PAIR from '../../../constants/Common/columnPair';
import { ERROR_UNKNOWN } from '../../../constants/Common/errorMessage';
import ERROR_MESSAGE_PAIR from '../../../constants/Common/errorMessagePair';
import { specificCodeErrorMessageGenerator } from '../Generator/specificErrorMessageGenerator';
import {
  specificCodeExistHandler,
  specificFieldsExistHandler,
} from './specificFieldsCodeExistHandler';

/**
 * @description
 * Function that handle custom error.
 * @param {object} error: Error object from query
 * @returns {string}
 * Error message
 */
const handleCustomQueryError = ({ error }) => {
  /** Axios error property. Not in use now */
  const getResponseErrorCodeString = error?.code;
  const getResponseErrorCode = error?.response?.status;

  /** Custom error property */
  const getResponseErrorDataFields = error?.response?.data?.fields;
  const getResponseErrorDataCode = error?.response?.data?.code;
  const getResponseErrorDataDependency = error?.response?.data?.dependency;

  /** Return a message for a specific error or field */
  if (specificCodeExistHandler({ code: getResponseErrorDataCode })) {
    return specificCodeErrorMessageGenerator({
      code: getResponseErrorDataCode,
      fields: getResponseErrorDataFields,
      dependency: getResponseErrorDataDependency,
    });
  }
  if (specificFieldsExistHandler({ fields: getResponseErrorDataFields })) {
    return specificCodeErrorMessageGenerator({
      fields: getResponseErrorDataFields,
    });
  }

  /** Get each pair list according to give error code and fields */
  const getFieldPair = getResponseErrorDataFields?.map(
    field => COLUMN_ITEM_PAIR?.[field],
  );
  const getCodePair = ERROR_MESSAGE_PAIR?.[getResponseErrorDataCode];

  /** Handle unknown column and message */
  if (getFieldPair?.includes(undefined) || !getCodePair) return ERROR_UNKNOWN;

  const createErrorMessage = `${getFieldPair?.join(', ')} ${getCodePair}`;

  return createErrorMessage;
};

export default handleCustomQueryError;
