import { createSlice } from '@reduxjs/toolkit';

const cneeNglCompanySlice = createSlice({
  name: 'cneeNglCompany',
  initialState: null,
  reducers: {
    saveCneeNglCompany: (state, action) => action.payload,
  },
});

export const { saveCneeNglCompany } = cneeNglCompanySlice.actions;
export default cneeNglCompanySlice.reducer;
