import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
} from '../../../constants/Dispatch/Common/category';
import { columnTypes } from '../../../constants/Dispatch/Common/dataGridOptions';
import { INIT_FILTERS } from '../../../constants/Dispatch/Common/filterButtonSettings';
import { DISPATCH_BOOKING_COLUMNS } from '../../../constants/Dispatch/Export/Common/exportColList';
import useGetCompanyOption from '../../../hooks/Dispatch/Common/useGetCompanyOption';
import useGetDispatchList from '../../../hooks/Dispatch/Common/useGetDispatchList';
import useGetBookingDetail from '../../../hooks/Dispatch/Export/Booking/useGetBookingDetail';
import useGetBookingWO from '../../../hooks/Dispatch/Export/Booking/useGetBookingWO';
import StyledDispatchDataGrid from '../../../styles/Dispatch/Common/StyledDispatchDataGrid';
import {
  createFloatingFilterSelectOption,
  createGridSelectOption,
} from '../../../utils/Common/createFloatingFilterSelectOption';
import getSubFilterCondition from '../../../utils/Dispatch/Common/Generator/getSubFilterCondition';
import setResultColDef from '../../../utils/Dispatch/Common/Generator/setResultColDef';
import CommonDataGrid from '../../CommonDataGrid';
import AddBookingModal from '../BookingModal/AddBookingModal';
import UpdateBookingModal from '../BookingModal/UpdateBookingModal';

const DispatchBooking = ({
  division,
  woStatus,
  category,
  subFilter,
  queryObject,
  gridRef,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsLoadingOpen,
  openers,
}) => {
  /** Droopdown Data */
  const getSSL = useSelector(state => state.common.ssl);
  const getSize = useSelector(state => state.common.size);
  const getType = useSelector(state => state.common.type);
  const { billTo, loc, query: queryGetCompanyOption } = useGetCompanyOption();

  const [defaultOption, setDefaultOption] = useState({
    ssl: createFloatingFilterSelectOption(getSSL?.map(item => item.ssl)),
    size: createGridSelectOption(getSize?.map(item => item.size)),
    type: createGridSelectOption(getType?.map(item => item.type)),
    billTo,
    loc,
    div: division,
  });
  useEffect(() => {
    setDefaultOption({
      ssl: createFloatingFilterSelectOption(getSSL?.map(item => item.ssl)),
      size: createGridSelectOption(getSize?.map(item => item.size)),
      type: createGridSelectOption(getType?.map(item => item.type)),
      billTo,
      loc,
      div: division,
    });
  }, [getSSL, getSize, getType, billTo, loc, division]);

  const { dispatchList, getListLoading } = useGetDispatchList({
    division,
    status: DISPATCH_PAGE_EXPORT_BOOKING,
    category: DISPATCH_TYPE_BOOKING,
    queryObject,
    isBooking: true,
  });

  /* State for Editing */
  const [bookingSeq, setBookingSeq] = useState(null);
  const [editData, setEditData] = useState({});
  const [isUpdateBookingModalOpen, setIsUpdateBookingModalOpen] =
    useState(false);

  useEffect(() => {
    if (!isUpdateBookingModalOpen) {
      setBookingSeq(null);
      setEditData({});
    }
  }, [isUpdateBookingModalOpen]);

  const patchProps = {
    setIsMessageModalOpen,
    setMessageModalMessage,
    setIsLoadingOpen,
  };

  /* Hooks for Editing */
  const {
    bookingDetail,
    bookingHistory,
    isLoading: isLoadingBookingDetail,
  } = useGetBookingDetail({ bookingSeq });

  const { bookingWO, isLoading: isLoadingBookingWO } = useGetBookingWO({
    bookingSeq,
  });

  /* Functions for Grid */
  const isExternalFilterPresent = useCallback(() => {
    return !INIT_FILTERS.includes(subFilter);
  }, [subFilter]);

  const doesExternalFilterPass = useCallback(
    node => {
      return getSubFilterCondition({
        subFilter,
        status: DISPATCH_PAGE_EXPORT_BOOKING,
        node,
      });
    },
    [subFilter, category, woStatus],
  );

  const isRowSelectable = useCallback(params => {
    return !!params?.data?.ava_complete;
  }, []);

  const clickCell = ({ gridParam }) => {
    if (gridParam?.column?.colId !== 'doc') {
      setBookingSeq(gridParam?.data?.seq);
      setIsUpdateBookingModalOpen(true);
    }
  };

  useEffect(() => {
    setEditData(bookingDetail);
  }, [bookingSeq, isLoadingBookingDetail, isLoadingBookingWO]);

  const waitingList = [
    isLoadingBookingDetail,
    isLoadingBookingWO,
    getListLoading,
  ];
  useEffect(() => {
    setIsLoadingOpen(![...waitingList].every(value => value === false));
  }, [waitingList]);

  const [columnDefs, setColumnDefs] = useState(
    setResultColDef({
      status: DISPATCH_PAGE_EXPORT_BOOKING,
      rowData: { dsp_column: DISPATCH_BOOKING_COLUMNS },
    }),
  );

  return (
    <>
      <StyledDispatchDataGrid>
        {!!columnDefs.length && (
          <CommonDataGrid
            gridRef={gridRef}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            data={dispatchList}
            columnDefs={columnDefs}
            columnTypes={columnTypes}
            onCellClicked={clickCell}
            isRowSelectable={isRowSelectable}
          />
        )}
      </StyledDispatchDataGrid>
      {isUpdateBookingModalOpen && !_.isEmpty(editData) && (
        <UpdateBookingModal
          setBookingSeq={setBookingSeq}
          bookingSeq={bookingSeq}
          queryObject={queryObject}
          editData={editData}
          editWOData={bookingWO}
          isUpdateBookingModalOpen={isUpdateBookingModalOpen}
          setIsUpdateBookingModalOpen={setIsUpdateBookingModalOpen}
          isLoadingBillToLoc={
            queryGetCompanyOption[0]?.isLoading ||
            queryGetCompanyOption[1]?.isLoading
          }
          {...openers}
          {...defaultOption}
          {...patchProps}
        />
      )}
      <AddBookingModal
        isLoadingBillToLoc={
          queryGetCompanyOption[0]?.isLoading ||
          queryGetCompanyOption[1]?.isLoading
        }
        {...openers}
        {...defaultOption}
        {...patchProps}
      />
    </>
  );
};

export default DispatchBooking;
