import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';

const StyledCommonDatagrid = styled(AgGridReact)`
  // Vertical line color between cells
  --ag-cell-horizontal-border: 1px solid #d9d9d9 !important;

  // Column header separator
  *:not(.ag-header-group-cell) {
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 1px;
    --ag-header-column-separator-color: #d9d9d9;
  }
  *.ag-header-group-cell {
    --ag-header-column-separator-display: none;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 0px;
    --ag-header-column-separator-color: #d9d9d9;
  }

  // Column resize bar
  .ag-header-cell-resize::after {
    background-color: unset !important;
    top: 0 !important;
    height: 100%;
  }

  .ag-row-odd {
    background-color: #fff;
  }
  .ag-row {
    border-bottom: 2px solid #f2f4f8;
  }
  .ag-root-wrapper {
    border: none;
  }
  .ag-header {
    background-color: #fff;
    border-bottom: 2px solid #f2f4f8;
  }
  .ag-header-cell-text {
    font-size: 13px;
  }
  .ag-floating-filter {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-left: 1px !important;
    padding-right: 2px !important;
  }
  .ag-row-pinned > .ag-cell {
    padding-left: 0px !important;
    padding-right: 1px !important;
  }
  .ag-cell-value {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-cell-inline-editing {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ag-row {
    &:hover {
      background-color: #f2f4f8 !important;
    }
  }
  .ag-row-selected {
    outline: none !important;
  }
  .ag-icon {
    color: unset !important;
  }
  .ag-icon-none {
    background: transparent
      url('/assets/images/common_icon/sortDefaultIcon.svg') center/contain
      no-repeat !important;
    color: transparent !important;
  }
  .ag-icon-asc {
    background: transparent url('/assets/images/common_icon/sortAscIcon.svg')
      center/contain no-repeat !important;
    color: transparent !important;
  }
  .ag-icon-desc {
    background: transparent url('/assets/images/common_icon/sortDescIcon.svg')
      center/contain no-repeat !important;
    color: transparent !important;
  }
  input {
    box-shadow: none !important;
    border-radius: 5px !important;
    border: 1px solid #d9d9d9 !important;
    height: 24px !important;
    padding-left: 12px !important;
  }
  .ag-icon-none {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-icon-asc {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-icon-desc {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-checkbox-input-wrapper {
    font-family: agGridAlpine !important;
  }
  .ag-checkbox-input {
    font-family: agGridAlpine !important;
    margin-top: -10px !important;
  }
  .ag-header-row:not(:first-child)
    .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
  .ag-header-row:not(:first-child)
    .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: none;
  }
  .ag-row-hover::before {
    background-color: #f2f4f8 !important;
    background-image: none !important;
  }
  .ag-icon-filter {
    display: none !important;
  }
  .ag-icon-filter {
    overflow: visible;
  }
  ${props => {
    const filteredIcon = props?.icon?.filter(icon => icon);
    const filteredColor = props?.color?.filter(color => color);
    const filteredBg = props?.background?.filter(bg => bg);

    let headerExpandIcon = ``;
    const isColIDStartWithZero = !props?.foldable;
    const groupIdxStartNumber = isColIDStartWithZero ? 0 : 1;
    for (let groupIdx = groupIdxStartNumber; groupIdx < 100; groupIdx++) {
      headerExpandIcon += `div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group > .ag-header-group-cell-label > .ag-header-expand-icon-expanded {
        background: transparent center/contain no-repeat !important;
        background-image: url(${
          filteredIcon?.[groupIdx - groupIdxStartNumber]?.fold
        }) !important;
        color: transparent !important;
        margin-left: 12px !important;
      }

      div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group > .ag-header-group-cell-label >.ag-header-expand-icon-collapsed {
        background: transparent center/contain no-repeat !important;
        background-image: url(${
          filteredIcon?.[groupIdx - groupIdxStartNumber]?.unfold
        }) !important;
        color: transparent !important;
        margin-left: 12px !important;
      }

      div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group {
          background-color: ${
            filteredBg?.[groupIdx - groupIdxStartNumber] || 'none'
          } !important;
          color: ${
            filteredColor?.[groupIdx - groupIdxStartNumber] || 'none'
          } !important;
        }
      
      `;
    }
    return headerExpandIcon;
  }}

  .ag-header-group-cell-no-group {
    background-color: #f2f4f8 !important;
  }
  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none;
  }
  .ag-floating-top-container {
    height: 40px;
  }
  .ag-row-pinned {
    background-color: #f8f2f2;
  }
  .ag-header-row-column-group {
    height: 30px !important;
  }

  /** Make a resizable bar inside a group invisible */
  .ag-header-group-cell > .ag-header-cell-resize {
    display: none;
  }

  /** Make Fold button invisible */
  .ag-group-contracted {
    display: none !important;
  }
  .ag-group-expanded {
    display: none !important;
  }

  /** Set group cell width and height */
  .ag-cell-expandable {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ag-group-value {
    width: 100%;
    height: 100%;
  }

  /** Filter layout */
  .ag-input-field-input {
    padding-left: 2px !important;
  }
`;

export default StyledCommonDatagrid;
