import { useEffect, useState } from 'react';

const useGetGroupExpandIcon = ({ columnDefs }) => {
  /** Icon state */
  const [icon, setIcon] = useState([]);

  /** Extract icon from user defined column definition object */
  useEffect(() => {
    const iconArr = [];
    columnDefs?.forEach(res => {
      if (res?.isColumnGroup) {
        if (res?.icon) {
          iconArr.push(res?.icon);
        } else {
          iconArr.push({
            unfold: '/assets/images/common_icon/unFolding.svg',
            fold: '/assets/images/common_icon/folding.svg',
          });
        }
      } else {
        iconArr.push(null);
      }
    });
    setIcon(iconArr);
  }, [columnDefs]);

  return { icon };
};

export default useGetGroupExpandIcon;
