import updateButtonDisabled from '../../../Common/Handler/updateButtonDisabled';

const handleAllSave = ({ submitData, woNo, documentMutator, buttonRef }) => {
  if (buttonRef?.current)
    updateButtonDisabled({ ref: buttonRef, disabled: true });

  const docData = {};
  const newList = [];
  const fileData = submitData.file;

  Object.entries(submitData).forEach(([id, data]) => {
    if (id === '0') return;
    if (id === 'file') return;
    newList.push(data);
  });

  docData.doc = JSON.stringify(newList);

  Object.entries(fileData).forEach(([key, file]) => {
    const type = submitData[key].type;
    if (file[type]) docData[type] = file?.[type];
  });

  documentMutator({
    woNo,
    docData,
    buttonRef,
  });
};

export default handleAllSave;
