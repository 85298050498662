import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constants/Common/DataGrid/cellType';
import {
  MASTER_COMMON_ACTIVE_OPTION,
  MASTER_COMMON_CHECK_OPTION,
} from '../../../../constants/Master/Common/masterCommonOption';
import { DRIVER_POSITION_VALUE } from '../../../../constants/Master/Driver/masterDriverValue';
import { createFloatingFilterSelectOption } from '../../../Common/createFloatingFilterSelectOption';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import generateFormattedDate from '../../../Common/Generator/generateFormattedDate';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import { makePrettyPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';

const createDriverColumnDefs = ({ divList }) => [
  {
    headerName: 'DIV',
    field: 'div',
    width: 38,
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: createFloatingFilterSelectOption(divList),
    },
    editable: false,
  },
  {
    headerName: 'POSITION',
    field: 'position',
    valueGetter: params => DRIVER_POSITION_VALUE[params?.data?.category],
    width: 75,
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: createFloatingFilterSelectOption(
        Object.values(DRIVER_POSITION_VALUE),
      ),
    },
    editable: false,
  },
  {
    headerName: 'ID',
    field: 'driver',
    width: 76,
    editable: false,
  },
  {
    headerName: 'TABLET ID',
    field: 'tablet_id',
    width: 76,
    editable: false,
  },
  {
    headerName: 'NAME',
    field: 'name',
    width: 150,
    valueGetter: params => {
      if (DRIVER_POSITION_VALUE?.[params?.data?.category] === 'Company') {
        const name = [
          params?.data?.first_name,
          params?.data?.mid_name,
          params?.data?.last_name,
        ].join(' ');
        return name;
      }
      return params?.data?.business_name;
    },
    editable: false,
  },
  {
    headerName: 'CELL #',
    field: 'cell_no',
    valueGetter: gridParam =>
      makePrettyPhoneNumber({ phone: gridParam?.data?.cell_no }),
    width: 95,
    editable: false,
  },
  {
    headerName: 'EMAIL',
    field: 'email',
    width: 150,
    editable: false,
  },
  {
    headerName: 'CITY',
    field: 'city',
    width: 110,
    editable: false,
  },
  {
    headerName: 'ST',
    field: 'state',
    width: 35,
    editable: false,
  },
  {
    headerName: 'ZIP',
    field: 'zip',
    width: 45,
    editable: false,
  },
  {
    headerName: 'DL #',
    field: 'dl_no',
    width: 75,
    editable: false,
  },
  {
    headerName: 'DL EXP',
    field: 'dl_exp',
    width: 62,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.dl_exp }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'HAZ',
    field: 'hazmat',
    width: 45,
    cellRendererParams: {
      disabled: true,
    },
    cellEditor: 'agCheckboxCellEditor',
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: MASTER_COMMON_CHECK_OPTION,
    },
    cellStyle: {
      justifyContent: 'center',
    },
    resizable: false,
    editable: false,
  },
  {
    headerName: 'TWIC EXP',
    field: 'twic_exp',
    width: 75,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.twic_exp }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'MED EXP',
    field: 'medical_exp',
    width: 72,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.medical_exp }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'DRUG TEST',
    field: 'drug_test',
    width: 83,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.drug_test }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'MC #',
    field: 'mc_no',
    editable: false,
    width: 55,
  },
  {
    headerName: 'DOT #',
    field: 'dot_no',
    editable: false,
    width: 60,
  },
  {
    headerName: 'HIRE',
    field: 'hire_date',
    width: 62,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.hire_date }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'TERMINATE',
    field: 'terminate_date',
    width: 88,
    editable: false,
    valueGetter: params =>
      generateFormattedDate({ date: params?.data?.terminate_date }),
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    comparator: dateComparator,
  },
  {
    headerName: 'QB ID',
    field: 'qb_id',
    width: 50,
    editable: false,
  },
  {
    headerName: 'ACTIVE',
    field: 'active',
    valueGetter: params => params.data.active,
    width: 65,
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: MASTER_COMMON_ACTIVE_OPTION,
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_SWITCH,
        value: gridParam?.data?.active,
        disabled: true,
      }),
    editable: false,
  },
];

export default createDriverColumnDefs;
