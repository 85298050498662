import { useEffect, useState } from 'react';

/**
 * @description
 * Get group text color according to user define property
 * @param {*} columnDefs
 * User defined column definition
 * @returns
 * Return array of text color
 */
const useGetGroupTextColor = ({ columnDefs }) => {
  /**  color state */
  const [textColor, setTextColor] = useState([]);

  /** Extract text color from user defined column definition object */
  useEffect(() => {
    const colorArr = [];
    columnDefs?.forEach(res => {
      if (res?.isColumnGroup) {
        if (res?.color) {
          colorArr.push(res?.color);
        } else {
          colorArr?.push('#000000');
        }
      } else {
        colorArr.push(null);
      }
    });
    setTextColor(colorArr);
  }, [columnDefs]);

  return { textColor };
};

export default useGetGroupTextColor;
