import styled from 'styled-components';

const StyledCommonErrorPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;

  background-color: #c4c6cc;
  overflow: hidden;
`;

export default StyledCommonErrorPageWrapper;
