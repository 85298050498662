import styled from 'styled-components';

const StyledStatTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  padding-bottom: 40px;
  width: 100%;
  height: fit;

  .header-row {
    display: flex;
    align-items: center;
    column-gap: 2px;
    width: 100%;
    height: 35px;
    box-shadow: 0 0 0 1px #f2f4f8;
  }

  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 153px;
    width: 153px;
    height: 100%;
    font-size: 11px;
    font-weight: 500;
    color: #ffffff;
    background-color: #001e5f;
  }

  .header-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    min-width: 110px;
    width: 110px;
    height: 35px;
    font-size: 11px;
    font-weight: 500;
    color: #001e5f;
    background-color: #f2f4f8;
  }

  .division-group {
    display: flex;
    column-gap: 2px;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .top-sticky {
    position: sticky;
    top: 0;
    z-index: 50;
  }

  .left-div-sticky {
    position: sticky;
    left: 0px;
    z-index: 49;
    box-shadow: 0 0 0 1px #f2f4f8;
  }

  .left-category-sticky {
    position: sticky;
    left: 43px;
    z-index: 49;
    box-shadow: 0 0 0 1px #f2f4f8;
  }
`;

export default StyledStatTableContainer;
