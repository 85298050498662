import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_AUTH_SLICE_KEY } from '../../../Constants/Admin/Auth';

const adminAuthInitialState = {
  adminAuthList: [],
  newAdminAuth: {
    name: '',
    permissions: {
      acct_qb: null,
      acct_recon: null,
      acct_uninv: null,
      admin_auth: null,
      admin_conf: null,
      admin_div: null,
      admin_log: null,
      admin_user: null,
      cus_report: null,
      dmb_ap: null,
      dmb_ar: null,
      dmb_exp: null,
      dmb_imp: null,
      dmb_other: null,
      dmb_van: null,
      dmb_wo: null,
      edi: null,
      master_acct: null,
      master_ap_rate: null,
      master_ar_rate: null,
      master_ch_rate: null,
      master_cnee: null,
      master_comp: null,
      master_drv: null,
      master_serv_code: null,
      mobile_admin: null,
      order_exp: null,
      order_imp: null,
      order_other: null,
      order_van: null,
      saf_equip: null,
      saf_report: null,
      tms_dem_per: null,
      tms_po_dilv_rtn: null,
      tms_stat: null,
      trace: null,
    },
  },
};

const adminAuthSlice = createSlice({
  name: ADMIN_AUTH_SLICE_KEY,
  initialState: adminAuthInitialState,
  reducers: {
    originAdminAuthList: {
      reducer: (state, action) => ({
        ...state,
        adminAuthList: [...action.payload.adminAuthList],
      }),
    },
    createNewAuth: {
      reducer: (state, action) => ({
        ...state,
        adminAuthList: [
          ...state.adminAuthList,
          {
            id: action.payload.newAdminAuthId,
            name: action.payload.newAdminAuthName,
            permissions: { ...state.newAdminAuth.permissions },
          },
        ],
      }),
    },
    makeNewAuth: {
      reducer: (state, action) => ({
        ...state,
        newAdminAuth: {
          name: action.payload.name,
          permissions: {
            ...action.payload.permissions,
          },
        },
      }),
    },
    clearNewAuth: {
      reducer: (state, action) => ({
        ...state,
        newAdminAuth: {
          name: '',
          permissions: {
            acct_qb: null,
            acct_recon: null,
            acct_uninv: null,
            admin_auth: null,
            admin_conf: null,
            admin_div: null,
            admin_log: null,
            admin_user: null,
            cus_report: null,
            dmb_ap: null,
            dmb_ar: null,
            dmb_exp: null,
            dmb_imp: null,
            dmb_other: null,
            dmb_van: null,
            dmb_wo: null,
            edi: null,
            master_acct: null,
            master_ap_rate: null,
            master_ar_rate: null,
            master_ch_rate: null,
            master_cnee: null,
            master_comp: null,
            master_drv: null,
            master_serv_code: null,
            mobile_admin: null,
            order_exp: null,
            order_imp: null,
            order_other: null,
            order_van: null,
            saf_equip: null,
            saf_report: null,
            tms_dem_per: null,
            tms_po_dilv_rtn: null,
            tms_stat: null,
            trace: null,
          },
        },
      }),
    },
    partialUpdateAuth: {
      reducer: (state, action) => ({
        ...state,
        adminAuthList: state.adminAuthList.map(element =>
          element.id === action.payload.id
            ? {
                ...element,
                permissions: { ...action.payload.permissions },
              }
            : element,
        ),
      }),
    },
    partialUpdateAuthName: {
      reducer: (state, action) => ({
        ...state,
        adminAuthList: state.adminAuthList.map(element =>
          element.id === action.payload.id
            ? { ...element, name: action.payload.name }
            : element,
        ),
      }),
    },
  },
});

export const {
  originAdminAuthList,
  createNewAuth,
  makeNewAuth,
  clearNewAuth,
  partialUpdateAuth,
  partialUpdateAuthName,
} = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
