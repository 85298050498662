import { createSlice } from '@reduxjs/toolkit';
import { TMS_REPORT_SLICE_KEY } from '../../Constants/TmsReport';

export const tmsReportInitialState = {
  listFilter: {},
};

const tmsReportSlice = createSlice({
  name: TMS_REPORT_SLICE_KEY,
  initialState: tmsReportInitialState,
  reducers: {
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.location]: action.payload.dateValue,
          },
        },
      }),
    },
    resetListDateFilter: {
      reducer: state => ({
        ...state,
        listFilter: {},
      }),
    },
    updateListTimeFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.filterItem]: action.payload.timeValue,
          },
        },
      }),
    },
  },
});

export const {
  updateListDateFilter,
  resetListDateFilter,
  updateListTimeFilter,
} = tmsReportSlice.actions;

export default tmsReportSlice.reducer;
