import React, { useEffect } from 'react';
import { Route, Routes, useParams, Outlet } from 'react-router-dom';
import BCDetailHeader from '../../components/BCDetail/BCDetailHeader';

const BCDetail = () => {
  return (
    <div className="w-[1900px] h-full, bg-[#f9f9f9]">
      <BCDetailHeader />
      <Outlet />
    </div>
  );
};

export default BCDetail;
