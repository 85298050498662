const generateStatisticsData = (
  data,
  { setCurrentDate, setPrevDate, setNextDate },
) => {
  setCurrentDate(prev => {
    const newDate =
      data?.current_date && prev !== data?.current_date
        ? data?.current_date
        : prev;
    delete data?.current_date;
    return newDate;
  });
  setPrevDate(prev => {
    const newDate =
      data?.previous_date && prev !== data?.previous_date
        ? data?.previous_date
        : prev;
    delete data?.previous_date;
    return newDate;
  });
  setNextDate(prev => {
    const newDate =
      data?.next_date && prev !== data?.next_date ? data?.next_date : prev;
    delete data?.next_date;
    return newDate;
  });

  delete data?.page_num;

  return { tableData: data };
};
export default generateStatisticsData;
