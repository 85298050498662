import {
  ERROR_ASSIGNED,
  ERROR_DEACTIVATION_FAIL,
  ERROR_DEPENDENT,
  ERROR_DUPLICATED,
  ERROR_INACTIVE_USER,
  ERROR_INVALID,
  ERROR_MUTEX,
  ERROR_NOT_FOUND,
  ERROR_PAIR_UNIQUE,
  ERROR_PERMISSION_DENIED,
  ERROR_QB_ALREADY_EXIST,
  ERROR_REQUIRED,
  ERROR_SAME_LOCATION,
  ERROR_UNIQUE,
  ERROR_BILLTO_CREDIT_HOLD,
  ERROR_NOT_AUTHOR,
} from './errorMessage';

const ERROR_MESSAGE_PAIR = {
  required: ERROR_REQUIRED,
  invalid: ERROR_INVALID,
  unique: ERROR_UNIQUE,
  pair_unique: ERROR_PAIR_UNIQUE,
  not_found: ERROR_NOT_FOUND,
  permission_denied: ERROR_PERMISSION_DENIED,
  qb_already_exist: ERROR_QB_ALREADY_EXIST,
  dependent: ERROR_DEPENDENT,
  mutex: ERROR_MUTEX,
  assigned: ERROR_ASSIGNED,
  duplicated: ERROR_DUPLICATED,
  same_location: ERROR_SAME_LOCATION,
  deactivation_failed: ERROR_DEACTIVATION_FAIL,
  billto_credit_hold: ERROR_BILLTO_CREDIT_HOLD,
  inactive_user: ERROR_INACTIVE_USER,
  not_author: ERROR_NOT_AUTHOR,
};

export default ERROR_MESSAGE_PAIR;
