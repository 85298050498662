import {
  MAIN_CATEGORY_BARE_CHASSIS,
  MAIN_CATEGORY_BROKERAGE,
  MAIN_CATEGORY_EXPORT,
  MAIN_CATEGORY_IMPORT,
  MAIN_CATEGORY_VAN,
} from '../../../../constants/Main/Common/category';
import MAIN_FIELD_HEADER from '../../../../constants/Main/Common/fieldHeader';

const getHeader = ({ item, category }) => {
  if (category === MAIN_CATEGORY_EXPORT) {
    return (
      { ...MAIN_FIELD_HEADER, doc_pod: 'POL', empty_load_cfm: 'LOAD CFM' }?.[
        item
      ] || item
    );
  }

  if (category === MAIN_CATEGORY_BROKERAGE) {
    return { ...MAIN_FIELD_HEADER, empty_load_cfm: 'L/E CFM' }?.[item] || item;
  }

  return MAIN_FIELD_HEADER?.[item] || item;
};

export default getHeader;
