import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import getCustomerData from '../../services/CustomerReport/Get/getCustomerData';
import customerReportKeys from './keys';

const useGetCustomerData = ({
  setLoadingOpener,
  setErrorOpener,
  setMessage,
  setIsSuccess,
}) => {
  const { listName } = useParams();

  const [customerData, setCustomerData] = useState(null);

  const query = useQuery({
    queryKey: customerReportKeys?.customer({ listName }),
    queryFn: () => getCustomerData({ listName }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setCustomerData?.(query?.data?.data);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isLoading || query?.isFetching) setLoadingOpener(true);
    if (!query?.isLoading && !query?.isFetching) setLoadingOpener(false);
  }, [query?.isLoading, query?.isFetching]);

  useEffect(() => {
    if (query?.isError) {
      setMessage(query?.error?.message);
      setIsSuccess(false);
      setErrorOpener(true);
    }
  }, [query?.isError]);

  return { ...query, customerData };
};

export default useGetCustomerData;
