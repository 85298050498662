import styled from 'styled-components';

const StyledCommonToastMainText = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  margin-top: 3px;

  min-height: 1.1em;
  max-height: 2.2em;
  line-height: 1.1em;
  white-space: pre-wrap;

  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default StyledCommonToastMainText;
