const acctKeys = {
  all: ['ACCT'],
  companyList: ({ category }) => [...acctKeys.all, 'COMPANY_LIST', category],
  productList: ({ type }) => [...acctKeys.all, 'PRODUCT_LIST', type],
  uninvoicedList: ({ queryParam }) => [
    ...acctKeys.all,
    'FILTERED_ACCT_UNINVOICED_LIST',
    queryParam,
  ],
  qbAPAR: ({ queryParam }) => [...acctKeys.all, 'QB_AP_AR_LIST', queryParam],
  apARReconcile: ({ queryParam }) => [
    ...acctKeys.all,
    'AP_AR_RECONCILE',
    queryParam,
  ],
  chassisReconcileList: ({ queryString }) => [
    ...acctKeys.all,
    'ACCT_CHASSIS_RECONCILE_LIST',
    queryString,
  ],
  chassisWOFromList: ({ queryString }) => [
    ...acctKeys.all,
    'CHASSIS_WO_FROM_LIST',
    queryString,
  ],
  chassisWOToList: ({ queryString }) => [
    ...acctKeys.all,
    'CHASSIS_WO_TO_LIST',
    queryString,
  ],
  perdiemReconcileList: ({ queryString }) => [
    ...acctKeys.all,
    'ACCT_PERDIEM_RECONCILE_LIST',
    queryString,
  ],
  woList: ({ queryString }) => [
    ...acctKeys.all,
    'PERDIEM_WO_LIST',
    queryString,
  ],
  driverList: () => [...acctKeys.all, 'DRIVER_LIST'],
  arAgingList: () => [...acctKeys.all, 'AR_AGING_LIST'],
  selectedARAging: ({ id }) => [...acctKeys.all, 'SELECTED_AR_AGING', id],
};

export default acctKeys;
