import styled, { css } from 'styled-components';
import { TOAST_LOCATION } from '../../../constants/Common/Toast/toastLocations';

const StyledCommonToastBackGround = styled.div`
  position: fixed;
  z-index: 10000;

  ${props =>
    props?.location === TOAST_LOCATION?.CENTER_BOTTOM &&
    css`
      left: calc(50%);
      bottom: 50px;
      transform: translateX(-50%);
    `}

  ${props =>
    props?.location === TOAST_LOCATION?.RIGHT_TOP &&
    css`
      right: 50px;
      top: 150px;
    `}

    ${props =>
    (!props?.location || props?.location === TOAST_LOCATION?.RIGHT_BOTTOM) &&
    css`
      right: 50px;
      bottom: 50px;
    `}
`;

export default StyledCommonToastBackGround;
