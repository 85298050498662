import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import signInAPI from '../api';

const getUserInfo = async () => {
  try {
    const response = await signInAPI.get(`user-info`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getUserInfo;
