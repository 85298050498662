import commonDefaultApi from '../api';

const getCommonBkNo = async () => {
  try {
    const response = await commonDefaultApi.get('bk-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getCommonSsl = async () => {
  try {
    const response = await commonDefaultApi.get('ssl-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getCommonPool = async () => {
  try {
    const response = await commonDefaultApi.get('pool-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getCommonSize = async () => {
  try {
    const response = await commonDefaultApi.get('size-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getCommonType = async () => {
  try {
    const response = await commonDefaultApi.get('type-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getCommonDriver = async () => {
  try {
    const response = await commonDefaultApi.get('driver-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getAddressValidation = async address => {
  try {
    const response = await commonDefaultApi.get(`addr-validator/${address}`);
    return response;
  } catch (e) {
    return null;
  }
};

const getDivisionList = async () => {
  try {
    const response = await commonDefaultApi.get('div-list');
    return response;
  } catch (e) {
    return null;
  }
};

const getChassisTypeList = async () => {
  try {
    const response = await commonDefaultApi.get('ch-type-list');
    return response;
  } catch (e) {
    return null;
  }
};

export const commonDefaultGetAPI = {
  getAddressValidation,
  getCommonBkNo,
  getCommonSsl,
  getCommonPool,
  getCommonSize,
  getCommonType,
  getCommonDriver,
  getDivisionList,
  getChassisTypeList,
};
