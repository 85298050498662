const FIELD_HEADER = {
  category: 'CAT',
  wo_no: 'WO #',
  billto: 'BILL TO',

  cont_no: 'CONT #',
  ch_no: 'CH #',
  ch_type: 'CH TYPE',
  size: 'SZ',
  vessel: 'VSL',
  type: 'TYPE',
  ssl: 'SSL',
  pool: 'POOL',
  bond_entry: 'BOND #',
  wgt: 'WGT',

  bk_seq: 'BOOKING #',
  bk_no: 'BOOKING #',
  st: 'S/T',
  erd: 'ERD',
  cutoff: 'C/O',

  cont_size_1: 'SZ',
  cont_type_1: 'TYPE',
  total_1: 'TOTAL',
  balance_1: 'BALANCE',
  cont_size_2: 'SZ',
  cont_type_2: 'TYPE',
  total_2: 'TOTAL',
  balance_2: 'BALANCE',
  cont_size_3: 'SZ',
  cont_type_3: 'TYPE',
  total_3: 'TOTAL',
  balance_3: 'BALANCE',

  trailer_no: 'TRAILER #',
  equipment_no: 'EQUIPMENT',
  trailer_type: 'TYPE',

  empty_load_status: 'L/E',
  load_empty: 'L/E',

  ref_no: 'REF #',
  purchase_no: 'PO #',
  mbl_no: 'MBL #',

  orig: 'ORIG',
  curr: 'CURR',
  dest: 'DEST',

  eta: 'ETA',
  rfd: 'RFD',
  rfd_date: 'RFD',
  rfd_day: 'DAY',
  lfd: 'LFD',
  lfd_date: 'LFD',
  lfd_day: 'DAY',

  ava_dsc: 'DSC',
  ava_obl: 'OBL',
  ava_cus: 'CUS',

  po_location: 'LOC',
  dilv_location: 'LOC',
  pu_location: 'LOC',
  rtn_location: 'LOC',

  po_schedule: 'SCHEDULE',
  dilv_schedule: 'SCHEDULE',
  pu_schedule: 'SCHEDULE',
  rtn_schedule: 'SCHEDULE',

  po_driver: 'DRIVER',
  dilv_driver: 'DRIVER',
  pu_driver: 'DRIVER',
  rtn_driver: 'DRIVER',

  po_actual_out: 'ACTUAL OUT',
  dilv_actual_out: 'ACTUAL OUT',
  pu_actual_out: 'ACTUAL OUT',
  rtn_actual_out: 'ACTUAL OUT',

  po_actual_in: 'ACTUAL IN',
  dilv_actual_in: 'ACTUAL IN',
  pu_actual_in: 'ACTUAL IN',
  rtn_actual_in: 'ACTUAL IN',

  dilv_city: 'CITY',
  dilv_state: 'ST',

  sche_type: 'LIVE',
  dilv_empty_cfm: 'MTY CFM',
  dilv_load_cfm: 'LOAD CFM',
  dilv_empty_load_cfm: 'L/E CFM',

  yard: 'YARD',
  yard_actual_out: 'ACTUAL OUT',
  yard_actual_in: 'ACTUAL IN',
  yard_out_location: 'FROM',
  yard_in_location: 'TO',
  yard_driver: 'DRIVER',

  over_height: 'O/H',
  over_weight: 'O/W',
  hazmat: 'HAZ',
  hot: 'HOT',
  remarks: 'REMARKS',

  complete: 'COMPLETE',
  export_btn: 'EXPORT',

  doc: 'DOC',
  status: 'STATUS',

  equip: 'EQUIPMENT',
  info: 'INFORMATION',
  geofence: 'GEOFENCE',
  ava: 'AVAILABILITIES',
  cont_1: '1ST CONT',
  cont_2: '2ND CONT',
  cont_3: '3RD CONT',
  booking: 'BOOKING',

  po: 'PULL OUT',
  dilv: 'DELIVERY',
  pu: 'PICK UP',
  rtn: 'RETURN',
};

export default FIELD_HEADER;
