import { createSlice } from '@reduxjs/toolkit';
import { DRIVER_SLICE_KEY } from '../../Constants/Driver';
import { getDriver } from '../../../services/Common/Common/get';

export const driverInitialState = null;

const driverSlice = createSlice({
  name: DRIVER_SLICE_KEY,
  initialState: driverInitialState,
  extraReducers: builder => {
    builder.addCase(getDriver.fulfilled, (state, action) => {
      //   state.loading = false;
      const rtnValue = action.payload?.map(element => element?.id);
      return rtnValue;
    });
    builder.addCase(getDriver.pending, (state, action) => {
      //   state.loading = true;
    });
    builder.addCase(getDriver.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
    });
  },
});

export default driverSlice.reducer;
