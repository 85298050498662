import createCustomerReportColDefs from '../Generator/createCustomerReportColDefs';

/**
 * @description
 * Function that filter column definition and reapply it to the grid
 * @param {object} selectedList
 * Selected list from the saved list modal
 * @returns {array}
 * Filtered column definition
 */
const filterColumnDefinition = ({ selectedList }) => {
  return selectedList?.cus_column
    ?.map(column => {
      return createCustomerReportColDefs?.find(item => item?.field === column);
    })
    ?.filter(column => column);
};

export default filterColumnDefinition;
