import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import dispatchKeys from '../keys';
import getCompanyList from '../../../services/Dispatch/Common/get/getCompanyList';
import handleCheckboxChange from '../../../utils/TmsReport/Common/handleCheckboxChange';
import checkIsDefaultYard from '../../../utils/Common/Handler/checkIsDefaultYard';

const useGetYardList = ({ setYardQuery, setYardList, category }) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const dispatchQueries = sessionStorage.getItem('dispatchQueries');

  const NGL_YARD_URL_PARAMS = { category: 8 };
  const result = useQuery({
    queryKey: dispatchKeys.companyNGLYard(),
    queryFn: () => getCompanyList({ urlParams: NGL_YARD_URL_PARAMS }),
    refetchOnWindowFocus: false,
  });

  /* Set yard query and list */
  useEffect(() => {
    if (
      !result?.isError &&
      result?.isSuccess &&
      _?.isArray(result?.data?.data)
    ) {
      setYardQuery(
        JSON.parse(dispatchQueries)?.yardQueryObject ||
          Object.fromEntries(
            result.data?.data?.map(company => [
              company?.company,
              checkIsDefaultYard({ yard: company?.company, defaultDiv }),
            ]),
          ),
      );

      setYardList(
        result.data?.data?.map(company => ({
          label: company?.company,
          value: company?.company,
          defaultValue: JSON.parse(dispatchQueries)?.yardQueryObject
            ? JSON.parse(dispatchQueries)?.yardQueryObject?.[company?.company]
            : checkIsDefaultYard({ yard: company?.company, defaultDiv }),
          onChange: ({ ...props }) =>
            handleCheckboxChange({ ...props, setQuery: setYardQuery }),
        })),
      );
    }
  }, [result?.isError, result?.isSuccess]);

  /* Reset query and list when category is changed */
  useEffect(() => {
    if (
      !result?.isError &&
      result?.isSuccess &&
      _?.isArray(result?.data?.data)
    ) {
      setYardQuery(
        Object.fromEntries(
          result.data?.data?.map(company => [
            company?.company,
            checkIsDefaultYard({ yard: company?.company, defaultDiv }),
          ]),
        ),
      );

      setYardList(
        result.data?.data?.map(company => ({
          label: company?.company,
          value: company?.company,
          defaultValue: checkIsDefaultYard({
            yard: company?.company,
            defaultDiv,
          }),
          onChange: ({ ...props }) =>
            handleCheckboxChange({ ...props, setQuery: setYardQuery }),
        })),
      );
    }
  }, [category]);

  return result;
};

export default useGetYardList;
