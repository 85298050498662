const getURIQuery = query => {
  let divAll = true;

  const queryString = Object.entries(query)
    .filter(([item, selected]) => {
      if (!selected) divAll = false;
      return selected;
    })
    .map(([item, selected]) => `div=${item}`)
    .join('&');

  return `div-all=${divAll}&${queryString}`;
};

export default getURIQuery;
