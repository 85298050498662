import { createSlice } from '@reduxjs/toolkit';
import { BCDETAIL_MEMO_SLICE_KEY } from '../../Constants/BCDetail/key';

/**
 * Initial state of slice
 */
export const bcDetailMemoInitialState = {
  loading: false,
  error: null,
};

/**
 * Redux slice
 */
const bcDetailMemoSlice = createSlice({
  name: BCDETAIL_MEMO_SLICE_KEY,
  initialState: bcDetailMemoInitialState,
  reducers: {},
});

/**
 * Export
 */
// export const {} = bcDetailMemoSlice.actions;
export default bcDetailMemoSlice.reducer;
