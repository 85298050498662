import { InputAdornment } from '@mui/material';
import styled from 'styled-components';

const StyledCommonInputAdornment = styled(InputAdornment)`
  z-index: 100;

  &.MuiInputAdornment-root {
    .MuiTypography-root {
      background-color: ${props =>
        props?.$styles?.backgroundColor || 'transparent'};
      color: ${props => props?.$styles?.color || '#000'};
      font-size: ${props => props?.$styles?.fontSize || '12px'};
      font-weight: ${props => props?.$styles?.fontWeight || '500'};
    }
  }
`;

export default StyledCommonInputAdornment;
