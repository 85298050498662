import styled, { css } from 'styled-components';

const StyledCommonLabel = styled.div`
  color: #595656;
  height: 14px;
  min-height: 14px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  ${props =>
    props?.labelStyle === 'row'
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: ${props?.[`lbl-pd-right`] || '29px'};
        `
      : css`
          padding: 0 0 3px 0;
        `}
`;

export default StyledCommonLabel;
