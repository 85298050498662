/** Const value of cell type */
export const CELL_TYPE_TEXT = 'text';
export const CELL_TYPE_SELECT = 'select';
export const CELL_TYPE_AUTO_COMPLETE = 'autoComplete';
export const CELL_TYPE_DATE = 'date';
export const CELL_TYPE_DATETIME = 'datetime';
export const CELL_TYPE_TIME = 'time';
export const CELL_TYPE_BUTTON = 'button';
export const CELL_TYPE_SWITCH = 'switch';
export const CELL_TYPE_GROUP = 'group';
