import setBCDetailTab from '../../../Common/Handler/setBCDetailTitle';

const handleRowClick = ({ gridParam }) => {
  const getColID = gridParam?.api?.getFocusedCell().column.getColId();

  if (getColID !== 'status') {
    let categoryCode;
    const woNo = gridParam?.data?.wo_no;
    const category = woNo?.slice(1, 2);
    if (category === 'M') {
      categoryCode = 'import';
    } else if (category === 'X') {
      categoryCode = 'export';
    } else if (category === 'V') {
      categoryCode = 'van';
    } else if (category === 'B') {
      categoryCode = 'brokerage';
    } else if (category === 'C') {
      categoryCode = 'bare-chassis';
    }

    const bcDetailTab = window.open(
      `/bc-detail/detail-info/${categoryCode}/${woNo}`,
      'bc-detail-tab',
    );
    setBCDetailTab({
      bcDetailTab,
      woNo,
    });
  }
};

export default handleRowClick;
