import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';

const getDateTimeDef = field => {
  return {
    comparator: datetimeComparator,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      placeholder: 'MM/DD/YY HH:mm',
      maxLength: 14,
      inputType: 'datetime',
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
  };
};

export default getDateTimeDef;
