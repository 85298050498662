import findWOType from './Generator/findWOType';
import { WO_TYPE_VALUES } from '../../constants/Common/woTypeValues';
import setBCDetailTab from './Handler/setBCDetailTitle';

const openBCDetail = ({ woNo }) => {
  const cateogryURL = WO_TYPE_VALUES?.[findWOType(woNo)]?.url;

  const bcDetailTab = window.open(
    `/bc-detail/detail-info/${cateogryURL}/${woNo}`,
    'bc-detail-tab',
  );

  setBCDetailTab({
    bcDetailTab,
    woNo,
  });
};

export default openBCDetail;
