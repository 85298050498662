import axios from 'axios';
import interceptRequest from '../../utils/Common/Interceptor/interceptRequest';

/**
 * Common base url
 */
const COMMON_BASE_URL = `${process.env.REACT_APP_BASE_URL}/common/`;

/**
 * Axios object with base url
 */
const commonDefaultApi = axios.create({
  withCredentials: true,
  baseURL: COMMON_BASE_URL,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'XCSRF-TOKEN',
  headers: {
    common: {
      'X-CSRFToken': localStorage.getItem('csrfToken'),
    },
  },
});

/** Interceptors */
interceptRequest(commonDefaultApi);

export default commonDefaultApi;
