import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledNavItemTabWidth = styled(Box)`
  justify-content: center;
  height: 0px;
  display: block;
  visibility: hidden;
  font-size: 16px;
  font-weight: bold;
`;

export default StyledNavItemTabWidth;
