import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CommonButton from '../../../CommonButton/CommonButton';

const ButtonCellRenderer = forwardRef((props, ref) => {
  const { content, customStyle, category, icon, isCancelBeforeStartHandler } =
    props;

  const compRef = useRef(null);

  const [disabled, setDisabled] = useState(props?.disabled || false);

  /** Component data setter */
  const compDataSetter = {
    setDisabled,
  };

  useEffect(() => {
    setDisabled(props?.disabled);
  }, [props?.disabled]);

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      ...compDataSetter,
      ...props,
    };
  });

  return (
    <div className="flex justify-center items-center w-full">
      <CommonButton
        ref={compRef}
        customStyle={{ ...customStyle }}
        category={category}
        icon={
          icon ? (
            <img
              src={icon}
              alt="buttonCellRendererIcon"
              width={15}
              height={15}
            />
          ) : null
        }
        onClick={() =>
          props?.onClickHandler({
            content,
            customStyle,
            category,
            icon,
            ...props,
          })
        }
        disabled={disabled}
      >
        {content}
      </CommonButton>
    </div>
  );
});

export default ButtonCellRenderer;
