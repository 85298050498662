import React, { useRef } from 'react';
import { GRID_MOD_EDIT } from '../../../../../constants/Common/DataGrid/setting';
import createSelectedARAgingColDefs from '../../../../../utils/Acct/ARAging/Generator/createSelectedARAgingColDefs';
import exportToExcel from '../../../../../utils/Acct/ARAging/Handler/exportToExcel';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonDataGrid from '../../../../CommonDataGrid';

const Content = ({ id, data, mutation }) => {
  const gridRef = useRef(null);

  return (
    <>
      <div className="flex justify-end p-[30px]">
        <CommonButton
          type="button"
          onClick={() => exportToExcel({ id, gridRef })}
          category="excel"
        >
          EXCEL
        </CommonButton>
      </div>
      <div className="w-[1000px] h-[600px]">
        <CommonDataGrid
          modType={GRID_MOD_EDIT}
          gridRef={gridRef}
          columnDefs={createSelectedARAgingColDefs()}
          data={data}
          onSaveClicked={e =>
            mutation({
              invNo: e?.gridParam?.data?.inv_no,
              memo: e?.gridParam?.data?.memo,
            })
          }
        />
      </div>
    </>
  );
};

export default Content;
