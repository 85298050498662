const tmsReportScheduleKeys = {
  all: ['TMS_REPORT_SCHEDULE'],
  list: ({ type, date, cat, div, sche }) => [
    ...tmsReportScheduleKeys.all,
    type,
    date,
    cat,
    div,
    sche,
  ],
};

export default tmsReportScheduleKeys;
