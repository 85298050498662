import React, { useEffect, useState } from 'react';
import StyledDispatchDataGrid from '../../../../../styles/Dispatch/Common/StyledDispatchDataGrid';
import CommonDataGrid from '../../../../CommonDataGrid';
import { columnTypes } from '../../../../../constants/Dispatch/Common/dataGridOptions';
import setResultColDef from '../../../../../utils/Dispatch/Common/Generator/setResultColDef';
import getModeType from '../../../../../utils/Dispatch/Common/Generator/getModType';

const ImportReturn = ({
  gridRef,
  rowData,
  isEditable,
  status,
  isExternalFilterPresent,
  doesExternalFilterPass,
  clickCell,
  clickSave,
  handleRowEditingStart,
  handleRowEditingStop,
  editParam,
  subFilter,
  ...props
}) => {
  const [columnDefs, setColumnDefs] = useState(
    setResultColDef({
      isEditable,
      status,
      rowData,
      editParam,
      subFilter,
      ...props,
    }),
  );

  useEffect(() => {
    setColumnDefs(
      setResultColDef({
        isEditable,
        status,
        rowData,
        editParam,
        subFilter,
        ...props,
      }),
    );
  }, [rowData, editParam?.pool, editParam?.booking, status]);

  return (
    <StyledDispatchDataGrid>
      {!!columnDefs.length && (
        <CommonDataGrid
          gridRef={gridRef}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          data={rowData?.result}
          modType={getModeType({ columnDefs, isEditable })}
          columnDefs={columnDefs}
          columnTypes={columnTypes}
          onCellClicked={clickCell}
          onRowValueChanged={clickSave}
          undoRedoCellEditing
          undoRedoCellEditingLimit={1}
          onRowEditingStart={handleRowEditingStart}
          onRowEditingStop={handleRowEditingStop}
        />
      )}
    </StyledDispatchDataGrid>
  );
};

export default ImportReturn;
