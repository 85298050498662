import { useQuery } from '@tanstack/react-query';
import { getSelectedARAging } from '../../../../services/Acct/ARAging/Get/getSelectedARAging';
import acctKeys from '../../keys';

const useGetSelectedARAging = ({ id }) => {
  const { data: selectedARAging, isLoading: isLoadingSelectedARAging } =
    useQuery({
      queryKey: acctKeys.selectedARAging({ id }),
      queryFn: () => getSelectedARAging({ id }),
      staleTime: 0,
      refetchOnWindowFocus: false,
    });

  return { selectedARAging: selectedARAging?.data, isLoadingSelectedARAging };
};

export default useGetSelectedARAging;
