import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { generateDateValue } from '../../Common/Generator/dataGridValueGetter';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect/index';
import { generateListToOption } from '../../Common/Generator/floatingFilterSelectOptionGenerator';
import { CATEGORY, TYPE } from '../../../../constants/Acct/Common/acctElement';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import { CELL_CUSTOM_STYLE } from '../../../../styles/Acct/columnStyles';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createUnInvoicedColumnDefs = ({ divList }) => {
  return [
    {
      headerName: 'DIV',
      field: 'div',
      width: 38,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(divList?.map(div => div?.div)),
      },
    },
    {
      headerName: 'CREATE DATE',
      field: 'created_date',
      width: 100,
      editable: false,
      comparator: dateComparator,
      valueGetter: param => {
        return generateDateValue(param?.data?.created_date);
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'ISSUE DATE',
      field: 'inv_date',
      width: 96,
      editable: false,
      comparator: dateComparator,
      valueGetter: param => {
        return generateDateValue(param?.data?.inv_date);
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 50,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(TYPE),
      },
    },
    {
      headerName: 'ID',
      field: 'business_id',
      width: 72,
      editable: false,
    },
    {
      headerName: 'NAME',
      field: 'name',
      width: 160,
      editable: false,
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'EQUIPMENT',
      field: 'equip_no',
      width: 90,
      editable: false,
    },
    {
      headerName: 'CAT',
      field: 'category',
      width: 73,
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(CATEGORY),
      },
    },
    {
      headerName: 'ACCT CODE',
      field: 'product_code',
      width: 90,
      editable: false,
    },
    {
      headerName: 'ACCT CODE NAME',
      field: 'product_code_name',
      width: 160,
      editable: false,
    },
    {
      headerName: 'RATE',
      field: 'rate',
      width: 85,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
    {
      headerName: 'QTY',
      field: 'qty',
      width: 46,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'AMT',
      field: 'amt',
      width: 85,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
  ];
};

export default createUnInvoicedColumnDefs;
