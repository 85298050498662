import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import Content from '../../../components/Acct/ARAging/Modal/Content';
import Header from '../../../components/Acct/ARAging/Modal/Header';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonMessageModal from '../../../components/CommonMessageModal/CommonMessageModal';
import useGetARAgingList from '../../../hooks/Acct/ARAging/Get/useGetARAgingList';
import useGetSelectedARAging from '../../../hooks/Acct/ARAging/Get/useGetSelectedARAging';
import usePatchARAgingMemoMutation from '../../../hooks/Acct/ARAging/Patch/usePatchARAgingMemoMutation';
import createARAgingColDefs from '../../../utils/Acct/ARAging/Generator/createARAgingColDefs';
import exportToExcel from '../../../utils/Acct/ARAging/Handler/exportToExcel';

const ARAging = () => {
  const gridRef = useRef(null);
  const date = moment.tz('America/Los_Angeles').format('MM/DD/YYYY');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectAgingID, setSelectAgingID] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSuccessAlert, setIsSuccessAlert] = useState(false);

  const { arAgingList, isLoadingARAgingList } = useGetARAgingList();
  const { selectedARAging, isLoadingSelectedARAging } = useGetSelectedARAging({
    id: selectAgingID,
  });

  const {
    mutate: patchARAgingMemoMutation,
    isPending: isPendingPatchARAgingMemo,
  } = usePatchARAgingMemoMutation({
    setIsAlertOpen,
    setIsSuccessAlert,
    setAlertMessage,
    id: selectAgingID,
  });

  return (
    <div className="w-[1650px]">
      <CommonLoading
        open={
          isLoadingARAgingList ||
          isLoadingSelectedARAging ||
          isPendingPatchARAgingMemo
        }
      />
      <CommonMessageModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        header={<Header modalTitle={modalTitle} date={date} />}
        content={
          <Content
            id={selectAgingID}
            data={selectedARAging}
            mutation={patchARAgingMemoMutation}
          />
        }
      />
      <CommonErrorModal
        open={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        message={alertMessage}
        isSuccess={isSuccessAlert}
      />
      <div className="flex justify-between p-[30px]">
        <div>AR Aging Report ({date})</div>
        <CommonButton
          type="button"
          onClick={() => exportToExcel({ gridRef })}
          category="excel"
        >
          EXCEL
        </CommonButton>
      </div>
      <div className="w-[full] h-[833px]">
        <CommonDataGrid
          gridRef={gridRef}
          columnDefs={createARAgingColDefs()}
          data={arAgingList}
          onRowClicked={row => {
            setSelectAgingID(row?.gridParam?.data?.company);
            setModalTitle(row?.gridParam?.data?.name);
            setIsModalOpen(true);
          }}
          pinnedBottomRowData={[
            {
              company: 'TOTAL',
              current: arAgingList?.reduce((acc, cur) => acc + cur.current, 0),
              over30: arAgingList?.reduce((acc, cur) => acc + cur.over30, 0),
              over60: arAgingList?.reduce((acc, cur) => acc + cur.over60, 0),
              over90: arAgingList?.reduce((acc, cur) => acc + cur.over90, 0),
              over120: arAgingList?.reduce((acc, cur) => acc + cur.over120, 0),
              total_amt: arAgingList?.reduce(
                (acc, cur) => acc + cur.total_amt,
                0,
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default ARAging;
