import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import commonDefaultApi from '../api';

/**
 * Function that get id and name of company
 * @returns response
 */
const getCommonCompanyIdName = async ({ companyCategory }) => {
  try {
    const response = await commonDefaultApi.get(
      `company-list?category=${companyCategory}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

const getCommonCompanyPureIdName = async () => {
  try {
    const response = await commonDefaultApi.get(`company-list`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

const getCommonCustomerCompanyList = async () => {
  try {
    const response = await commonDefaultApi.get(`company-list?category=1`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

const getCommonCneeNglCompanyList = async () => {
  try {
    const response = await commonDefaultApi.get(`company-list?category=12`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

const getCommonCneeCompanyList = async () => {
  try {
    const response = await commonDefaultApi.get(`company-list?category=4`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

const getCommonVendorCompanyList = async () => {
  try {
    const response = await commonDefaultApi.get(`company-list?category=2`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export const commonCompanyGetAPI = {
  getCommonCompanyIdName,
  getCommonCompanyPureIdName,
  getCommonCustomerCompanyList,
  getCommonCneeNglCompanyList,
  getCommonCneeCompanyList,
  getCommonVendorCompanyList,
};
