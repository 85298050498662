import React from 'react';
import { Navigate } from 'react-router-dom';
import PermissionDenied from './PermissionDenied';
import checkSession from '../../utils/Common/Router/Handler/checkSession';
import checkPermission from '../../utils/Common/Router/Handler/checkPermission';

const AuthRouter = ({ children, ...props }) => {
  const { permission } = props;

  /** Check logged in */
  const isLoggedIn = checkSession();
  if (!isLoggedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  /** Check permission */
  const isPermitted = checkPermission({ permission });
  if (!isPermitted) {
    return <PermissionDenied />;
  }

  return children;
};

export default AuthRouter;
