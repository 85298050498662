/** Groups */
const DISPATCH_EQUIPMENT_GROUP = (themeColor, children, isUnaState = false) => [
  {
    header: 'EQUIPMENT',
    value: 'equip',
    theme: themeColor,
    children: [
      'cont_no',
      'ssl',
      'size',
      'wgt',
      'ch_no',
      'pool',
      'ch_type',
      'bond_entry',
      ...(isUnaState ? ['vessel'] : []),
    ],
  },
];

const DISPATCH_EQUIPMENT_GROUP_VAN = themeColor => [
  {
    header: 'EQUIPMENT',
    value: 'equip',
    theme: themeColor,
    children: ['trailer_no', 'size', 'type', 'wgt'],
  },
];

const DISPATCH_EQUIPMENT_GROUP_BROKERAGE = themeColor => [
  {
    header: 'EQUIPMENT',
    value: 'equip',
    theme: themeColor,
    children: [
      'trailer_no',
      'size',
      'type',
      'wgt',
      'ch_no',
      'pool',
      'ch_type',
      'empty_load_status',
    ],
  },
];

const DISPATCH_INFORMATION_GROUP = themeColor => [
  {
    header: 'INFORMATION',
    value: 'info',
    theme: themeColor,
    children: ['ref_no', 'purchase_no', 'mbl_no'],
  },
];

const DISPATCH_AVAILABILITY_GROUP = [
  {
    header: 'AVAILABILITY',
    value: 'ava',
    theme: 'grey',
    children: ['ava_dsc', 'ava_obl', 'ava_cus'],
  },
];

const DISPATCH_GEOFENCE_GROUP = themeColor => [
  {
    header: 'GEOFENCE',
    value: 'geofence',
    theme: themeColor || 'grey',
    children: ['orig', 'curr', 'dest'],
  },
];

const DISPATCH_RFD_GROUP = themeColor => [
  {
    header: 'RFD',
    value: 'rfd',
    theme: themeColor || 'darkGrey',
    children: ['rfd_date', 'rfd_day'],
  },
];

const DISPATCH_LFD_GROUP = themeColor => [
  {
    header: 'LFD',
    value: 'lfd',
    theme: themeColor || 'darkGrey',
    children: ['lfd_date', 'lfd_day'],
  },
];

const DISPATCH_PO_GROUP = ({
  color,
  header,
  schedule,
  actualIn,
  actualOut,
  suppressDriver,
}) => [
  {
    header: header || 'PULL OUT',
    value: 'po',
    theme: color,
    children: [
      'po_location',
      ...(schedule ? ['po_schedule'] : []),
      ...(actualIn ? ['po_actual_in'] : []),
      ...(actualOut ? ['po_actual_out'] : []),
      ...(!suppressDriver ? ['po_driver'] : []),
    ],
  },
];

const DISPATCH_DILV_GROUP = ({
  color,
  schedule,
  actualIn,
  actualOut,
  emptyCFM,
  loadCFM,
  elCFM,
  suppressDriver,
}) => [
  {
    header: 'DELIVERY',
    value: 'dilv',
    theme: color,
    children: [
      'dilv_location',
      'dilv_city',
      'dilv_state',
      ...(schedule ? ['dilv_schedule'] : []),
      ...(actualIn ? ['dilv_actual_in'] : []),
      ...(actualOut ? ['dilv_actual_out'] : []),
      ...(emptyCFM ? ['dilv_empty_cfm'] : []),
      ...(loadCFM ? ['dilv_load_cfm'] : []),
      ...(elCFM ? ['dilv_empty_load_cfm'] : []),
      ...(!suppressDriver ? ['dilv_driver'] : []),
    ],
  },
];

const DISPATCH_PU_GROUP = ({ color, header }) => [
  {
    header: header || 'PICK UP',
    value: 'pu',
    theme: color,
    children: ['pu_location', 'pu_schedule', 'pu_driver'],
  },
];

const DISPATCH_RTN_GROUP = themeColor => [
  {
    header: 'RETURN',
    value: 'rtn',
    theme: themeColor,
    children: ['rtn_location', 'rtn_schedule', 'rtn_driver'],
  },
];

const DISPATCH_YARD_GROUP = [
  {
    header: 'YARD',
    value: 'yard',
    theme: 'yard',
    children: [
      'yard_out_location',
      'yard_actual_out',
      'yard_in_location',
      'yard_actual_in',
      'yard_driver',
    ],
  },
];

const DISPATCH_BOOKING_GROUP = themeColor => [
  {
    header: 'BOOKING',
    value: 'booking',
    theme: themeColor,
    children: ['bk_no', 'st', 'erd', 'cutoff'],
  },
];

/** Common Columns */
const DISPATCH_COMMON_COLUMNS = [
  'wo_no',
  'billto',
  'over_height',
  'over_weight',
  'hazmat',
  'hot',
  'remarks',
];

const DISPATCH_IMPORT_COMMON_COLUMNS = [
  ...DISPATCH_COMMON_COLUMNS,
  ...DISPATCH_EQUIPMENT_GROUP('importColor1'),
  ...DISPATCH_INFORMATION_GROUP('importColor2'),
];

/** Import Columns */
const DISPATCH_IMPORT_UNA_COLUMNS = [
  ...DISPATCH_COMMON_COLUMNS,
  ...DISPATCH_EQUIPMENT_GROUP('importColor1', undefined, true),
  ...DISPATCH_INFORMATION_GROUP('importColor2'),
  ...DISPATCH_AVAILABILITY_GROUP,
  ...DISPATCH_PO_GROUP({ color: 'importColor3', schedule: true }),
  ...DISPATCH_DILV_GROUP({
    color: 'importColor4',
    schedule: true,
  }),
  'eta',
  'lfd',
];

const DISPATCH_IMPORT_AVA_COLUMNS = [
  ...DISPATCH_IMPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({ color: 'importColor3', schedule: true }),
  ...DISPATCH_DILV_GROUP({
    color: 'importColor4',
    schedule: true,
  }),
  ...DISPATCH_LFD_GROUP(),
  'category',
  'sche_type',
];

const DISPATCH_IMPORT_AVA_YARD_COLUMNS = [
  ...DISPATCH_IMPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'importColor4',
    actualOut: true,
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'importColor5',
    schedule: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'importColor6' }),
  ...DISPATCH_LFD_GROUP(),
  ...DISPATCH_YARD_GROUP,
  'category',
  'sche_type',
];

const DISPATCH_IMPORT_DILV_COLUMNS = [
  ...DISPATCH_IMPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({ color: 'importColor3', actualOut: true }),
  ...DISPATCH_DILV_GROUP({
    color: 'importColor4',
    schedule: true,
    emptyCFM: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'importColor5' }),
  ...DISPATCH_RFD_GROUP(),
  ...DISPATCH_GEOFENCE_GROUP(),
  'category',
  'sche_type',
];

const DISPATCH_IMPORT_DILV_YARD_COLUMNS = [
  ...DISPATCH_IMPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'importColor3',
    actualOut: true,
    suppressDriver: true,
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'importColor4',
    actualOut: true,
    emptyCFM: true,
    suppressDriver: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'importColor5' }),
  ...DISPATCH_RTN_GROUP('importColor6'),
  ...DISPATCH_RFD_GROUP(),
  ...DISPATCH_GEOFENCE_GROUP(),
  'category',
  'sche_type',
];

const DISPATCH_IMPORT_RTN_COLUMNS = [
  ...DISPATCH_IMPORT_DILV_YARD_COLUMNS,
  'complete',
];

const DISPATCH_IMPORT_RTN_YARD_COLUMNS = [
  ...DISPATCH_IMPORT_RTN_COLUMNS,
  ...DISPATCH_YARD_GROUP,
];

/** Export Columns */
const DISPATCH_EXPORT_BOOKING_COLUMNS = [
  'bk_no',
  'ssl',
  'billto',
  'erd',
  'cutoff',
  'dilv_location',
  'dilv_city',
  'dilv_state',
  'rtn_location',
  'doc',
  'status',
  {
    header: '1ST CONT',
    value: 'cont_1',
    theme: 'exportColor1',
    children: ['cont_size_1', 'cont_type_1', 'total_1', 'balance_1'],
  },
  {
    header: '2ND CONT',
    value: 'cont_2',
    theme: 'exportColor2',
    children: ['cont_size_2', 'cont_type_2', 'total_2', 'balance_2'],
  },
  {
    header: '3RD CONT',
    value: 'cont_3',
    theme: 'exportColor3',
    children: ['cont_size_3', 'cont_type_3', 'total_3', 'balance_3'],
  },
];

const DISPATCH_EXPORT_COMMON_COLUMNS = [
  ...DISPATCH_COMMON_COLUMNS,
  ...DISPATCH_EQUIPMENT_GROUP('exportColor1'),
  ...DISPATCH_INFORMATION_GROUP('exportColor2'),
  ...DISPATCH_BOOKING_GROUP('exportColor3'),
  'category',
  'sche_type',
];

const DISPATCH_EXPORT_UNA_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'exportColor4',
    actualOut: true,
    suppressDriver: true,
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'exportColor5',
    actualOut: true,
    emptyCFM: true,
    suppressDriver: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'exportColor6' }),
  ...DISPATCH_RTN_GROUP('exportColor7'),
  ...DISPATCH_GEOFENCE_GROUP(),
  ...DISPATCH_RFD_GROUP(),
  'export_btn',
];

const DISPATCH_EXPORT_UNA_YARD_COLUMNS = [
  ...DISPATCH_EXPORT_UNA_COLUMNS,
  ...DISPATCH_YARD_GROUP,
];

const DISPATCH_EXPORT_AVA_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({ color: 'exportColor5', schedule: true }),
  ...DISPATCH_DILV_GROUP({ color: 'exportColor6', schedule: true }),
  'rfd',
];

const DISPATCH_EXPORT_AVA_YARD_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({ color: 'exportColor5', actualOut: true }),
  ...DISPATCH_DILV_GROUP({ color: 'exportColor6', schedule: true }),
  ...DISPATCH_PU_GROUP({ color: 'exportColor7' }),
  ...DISPATCH_GEOFENCE_GROUP(),
  ...DISPATCH_YARD_GROUP,
  'rfd',
];

const DISPATCH_EXPORT_DILV_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({ color: 'exportColor5', actualOut: true }),
  ...DISPATCH_DILV_GROUP({
    color: 'exportColor6',
    schedule: true,
    loadCFM: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'exportColor7' }),
  ...DISPATCH_GEOFENCE_GROUP(),
  ...DISPATCH_RFD_GROUP(),
];

const DISPATCH_EXPORT_DILV_YARD_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'exportColor4',
    actualIn: true,
    suppressDriver: true,
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'exportColor5',
    actualIn: true,
    loadCFM: true,
    suppressDriver: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'exportColor6' }),
  ...DISPATCH_RTN_GROUP('exportColor7'),
  ...DISPATCH_GEOFENCE_GROUP(),
  ...DISPATCH_RFD_GROUP(),
];

const DISPATCH_EXPORT_RTN_COLUMNS = [
  ...DISPATCH_EXPORT_DILV_YARD_COLUMNS,
  'complete',
];

const DISPATCH_EXPORT_RTN_YARD_COLUMNS = [
  ...DISPATCH_EXPORT_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'exportColor4',
    actualIn: true,
    suppressDriver: true,
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'exportColor5',
    actualIn: true,
    loadCFM: true,
    suppressDriver: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'exportColor6' }),
  ...DISPATCH_RTN_GROUP('exportColor7'),
  ...DISPATCH_GEOFENCE_GROUP(),
  ...DISPATCH_RFD_GROUP(),
  ...DISPATCH_YARD_GROUP,
  'complete',
];

/** Van Columns */
const DISPATCH_VAN_COMMON_COLUMNS = [
  ...DISPATCH_COMMON_COLUMNS,
  ...DISPATCH_EQUIPMENT_GROUP_VAN('vanColor1'),
  ...DISPATCH_INFORMATION_GROUP('vanColor2'),
];

const DISPATCH_VAN_AVA_COLUMNS = [
  ...DISPATCH_VAN_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'vanColor3',
    schedule: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({ color: 'vanColor4', schedule: true }),
];

const DISPATCH_VAN_DILV_COLUMNS = [
  ...DISPATCH_VAN_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'vanColor3',
    actualOut: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({ color: 'vanColor4', schedule: true }),
  ...DISPATCH_GEOFENCE_GROUP(),
];

const DISPATCH_VAN_RTN_COLUMNS = [
  ...DISPATCH_VAN_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'vanColor3',
    actualOut: true,
    suppressDriver: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'vanColor4',
    actualOut: true,
    suppressDriver: true,
  }),
  ...DISPATCH_RTN_GROUP('vanColor5'),
  ...DISPATCH_GEOFENCE_GROUP(),
  'complete',
];

/** Brokerage Columns */
const DISPATCH_BRKG_COMMON_COLUMNS = [
  ...DISPATCH_COMMON_COLUMNS,
  ...DISPATCH_EQUIPMENT_GROUP_BROKERAGE('brokerageColor1'),
  ...DISPATCH_INFORMATION_GROUP('brokerageColor2'),
  'sche_type',
];

const DISPATCH_BRKG_AVA_COLUMNS = [
  ...DISPATCH_BRKG_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'brokerageColor4',
    schedule: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({ color: 'brokerageColor5', schedule: true }),
];

const DISPATCH_BRKG_DILV_COLUMNS = [
  ...DISPATCH_BRKG_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'brokerageColor3',
    actualOut: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'brokerageColor4',
    schedule: true,
    elCFM: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'brokerageColor5', header: 'L/E PICK UP' }),
  ...DISPATCH_GEOFENCE_GROUP(),
];

const DISPATCH_BRKG_RTN_COLUMNS = [
  ...DISPATCH_BRKG_COMMON_COLUMNS,
  ...DISPATCH_PO_GROUP({
    color: 'brokerageColor3',
    actualOut: true,
    suppressDriver: true,
    header: 'PICK UP',
  }),
  ...DISPATCH_DILV_GROUP({
    color: 'brokerageColor4',
    actualOut: true,
    elCFM: true,
    suppressDriver: true,
  }),
  ...DISPATCH_PU_GROUP({ color: 'brokerageColor5', header: 'L/E PICK UP' }),
  ...DISPATCH_RTN_GROUP('brokerageColor6'),
  ...DISPATCH_GEOFENCE_GROUP(),
  'complete',
];

/** Export Category Columns */
export const DISPATCH_IMP_COLUMNS = {
  imp_unava: DISPATCH_IMPORT_UNA_COLUMNS,
  imp_ava: DISPATCH_IMPORT_AVA_COLUMNS,
  imp_ava_yard: DISPATCH_IMPORT_AVA_YARD_COLUMNS,
  imp_dilv: DISPATCH_IMPORT_DILV_COLUMNS,
  imp_dilv_yard: DISPATCH_IMPORT_DILV_YARD_COLUMNS,
  imp_rtn: DISPATCH_IMPORT_RTN_COLUMNS,
  imp_rtn_yard: DISPATCH_IMPORT_RTN_YARD_COLUMNS,
};

export const DISPATCH_EXP_COLUMNS = {
  exp_booking: DISPATCH_EXPORT_BOOKING_COLUMNS,
  exp_unava: DISPATCH_EXPORT_UNA_COLUMNS,
  exp_unava_yard: DISPATCH_EXPORT_UNA_YARD_COLUMNS,
  exp_ava: DISPATCH_EXPORT_AVA_COLUMNS,
  exp_ava_yard: DISPATCH_EXPORT_AVA_YARD_COLUMNS,
  exp_dilv: DISPATCH_EXPORT_DILV_COLUMNS,
  exp_dilv_yard: DISPATCH_EXPORT_DILV_YARD_COLUMNS,
  exp_rtn: DISPATCH_EXPORT_RTN_COLUMNS,
  exp_rtn_yard: DISPATCH_EXPORT_RTN_YARD_COLUMNS,
};

export const DISPATCH_VAN_COLUMNS = {
  van_ava: DISPATCH_VAN_AVA_COLUMNS,
  van_dilv: DISPATCH_VAN_DILV_COLUMNS,
  van_rtn: DISPATCH_VAN_RTN_COLUMNS,
};

export const DISPATCH_BKRG_COLUMNS = {
  bkg_ava: DISPATCH_BRKG_AVA_COLUMNS,
  bkg_dilv: DISPATCH_BRKG_DILV_COLUMNS,
  bkg_rtn: DISPATCH_BRKG_RTN_COLUMNS,
};

export const DISPATCH_CATEGORY_COLUMNS = {
  import: DISPATCH_IMP_COLUMNS,
  export: DISPATCH_EXP_COLUMNS,
  van: DISPATCH_VAN_COLUMNS,
  brokerage: DISPATCH_BKRG_COLUMNS,
};
