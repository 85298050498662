import styled from 'styled-components';

const StyledWeeklyTable = styled.div`
  display: flex;
  flex-direction: row;
  width: 1780px;
  height: 800px;
`;

export default StyledWeeklyTable;
