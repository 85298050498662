import React from 'react';

const CommonMessageModalHeader = ({ content, ...props }) => {
  return (
    <div className="w-full h-[16px] min-h-[16px] text-[14px] font-bold flex items-center">
      {content}
    </div>
  );
};

export default CommonMessageModalHeader;
