import COLUMN_ITEM_PAIR from '../../../constants/Common/columnPair';

/**
 * @description
 * Function that handle system error.
 * @param {object} error: Error object from query
 * @returns {string}
 * Error message
 */
const handleSystemQueryError = ({ error }) => {
  const getSystemQueryError = error?.response?.data;
  if (getSystemQueryError) {
    const getSystemQueryErrorKey = Object.keys(getSystemQueryError);
    const getFirstSystemErrorQueryKey = getSystemQueryErrorKey?.[0];

    const createErrorMessage = `${COLUMN_ITEM_PAIR?.[getFirstSystemErrorQueryKey]} ${getSystemQueryError?.[getFirstSystemErrorQueryKey]}`;
    return createErrorMessage;
  }
  return `Please try again. If this issue happens continuously, please contact Admin.`;
};

export default handleSystemQueryError;
