import styled from 'styled-components';

const StyledCommonToastIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-right: 31px;
`;

export default StyledCommonToastIconWrapper;
