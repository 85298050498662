import { useEffect, useState } from 'react';
import handleCheckboxChange from './handleCheckboxChange';

const getCheckboxOptions = ({
  scheduleTypeController,
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  allQueries,
  divList,
}) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const [scheduleTypeOptionList, setScheduleTypeOptionList] = useState([
    {
      label: 'LIVE',
      value: 'LIVE',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setScheduleTypeQuery }),
    },
    {
      label: 'DROP',
      value: 'DROP',
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
        }),
    },
    {
      label: 'VAN',
      value: 'LVAN',
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
        }),
    },
  ]);

  useEffect(() => {
    scheduleTypeController?.setValue('LVAN', allQueries?.cat?.V);
    setScheduleTypeOptionList(prev => [
      ...prev?.map(item => {
        if (item?.value === 'LVAN') {
          return {
            ...item,
            disabled: !allQueries?.cat?.V,
          };
        }
        return item;
      }),
    ]);
  }, [allQueries?.cat?.V]);

  const divisionOptionList = [
    ...divList?.map(div => {
      return {
        label: div,
        value: div,
        defaultValue: div === defaultDiv,
        onChange: ({ ...props }) =>
          handleCheckboxChange({
            ...props,
            setQuery: setDivisionQuery,
          }),
      };
    }),
  ];

  const categoryOptionList = [
    {
      label: 'IMPORT',
      value: 'M',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
    {
      label: 'EXPORT',
      value: 'X',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
    {
      label: 'VAN',
      value: 'V',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
    {
      label: 'BROKERAGE',
      value: 'B',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
    {
      label: 'BARE CHASSIS',
      value: 'C',
      onChange: ({ ...props }) =>
        handleCheckboxChange({ ...props, setQuery: setCategoryQuery }),
    },
  ];

  return { scheduleTypeOptionList, divisionOptionList, categoryOptionList };
};

export default getCheckboxOptions;
