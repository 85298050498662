import React, { useRef, useState, useEffect } from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import onRowAddClickHandler from '../../../../utils/Common/Datagrid/Handler/onRowAddClickHandler';
import onRowClearClickHandler from '../../../../utils/Common/Datagrid/Handler/onRowClearClickHandler';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

/**
 * @description
 * Component that appears when addable is true.
 * Row used when adding data
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @param {function} onAddClicked
 * Function that will be called when add button clicked
 * @returns
 * Row used when adding data
 */
const CurrentRowAddCell = ({
  gridParam,
  onAddClicked,
  addDisabled,
  setAddDisabled,
  ...props
}) => {
  return (
    <div className="flex justify-center items-center gap-[16px] w-full h-full">
      <CommonButton
        category="add"
        customStyle={{ width: '85px' }}
        disabled={addDisabled}
        onClick={() => {
          onRowAddClickHandler({ gridParam, onAddClicked, setAddDisabled });
        }}
      >
        Add
      </CommonButton>
      <CommonButton
        category="clear"
        customStyle={{ width: '85px' }}
        onClick={() => onRowClearClickHandler({ gridParam })}
      >
        Clear
      </CommonButton>
    </div>
  );
};

export default CurrentRowAddCell;
