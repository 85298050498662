import dayjs from 'dayjs';

const TEXT_COLOR_RED = '#ff0000';
const TEXT_COLOR_PURPLE = '#b000FF';

const FREEDAYS = ['lfd', 'rfd'];
const CHECKLIST = ['hot', 'hazmat', 'over_weight', 'over_height'];

const getCellStyle = params => {
  if (FREEDAYS.includes(params?.colDef?.field) && !!params?.value) {
    const dateObject = dayjs(params?.value);
    const currentDate = dayjs();
    const dayDifference = dateObject.diff(currentDate, 'day', true);

    if (dayDifference < 0) {
      return { color: TEXT_COLOR_RED, fontWeight: 'bold' };
    }
    if (dayDifference <= 1) {
      return { color: TEXT_COLOR_PURPLE, fontWeight: 'bold' };
    }
    return null;
  }

  if (
    CHECKLIST.includes(params?.colDef?.field) &&
    params?.divColorSet?.[`${params?.colDef?.field}_active`] &&
    !!params?.divColorSet?.[`${params?.colDef?.field}_color`]
  ) {
    if (params?.value === true) {
      return {
        backgroundColor:
          params?.divColorSet?.[`${params?.colDef?.field}_color`],
      };
    }
    return { backgroundColor: 'transparent' };
  }

  return null;
};

export default getCellStyle;
