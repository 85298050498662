import setBCDetailTab from '../../../Common/Handler/setBCDetailTitle';

const openBCDetail = ({ woNo, category }) => {
  const bcDetailTab = window.open(
    `/bc-detail/detail-info/${category}/${woNo}`,
    'bc-detail-tab',
  );

  setBCDetailTab({
    bcDetailTab,
    woNo,
  });
};

export default openBCDetail;
