import styled from 'styled-components';

const StyledCustomerUtilityBar = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
`;

export default StyledCustomerUtilityBar;
