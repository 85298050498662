import React from 'react';

const Header = ({ modalTitle, date }) => {
  return (
    <div className="flex-col">
      <div>CUSTOMER NAME: {modalTitle}</div>
      <div>AS OF: {date}</div>
    </div>
  );
};

export default Header;
