import {
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOTFOUND,
  ERROR_UNAUTHORIZED,
} from '../../../constants/Common/errorStatus';
import {
  errorForbiddenHandler,
  errorInternalServerErrorHandler,
  errorUnauthorizedHandler,
  handleNotFoundError,
} from './errorStatusSpecificHandler';

/**
 * @description
 * Function that handle specific error status code
 * @param {object} error
 * API error reponse
 * @returns {string}
 * Error message
 */
const errorStatusHandler = ({ error }) => {
  /** Get error message from error response */
  const getErrorStatusCode = error?.response?.status;

  /** Handle Unauthorized status (401) */
  if (getErrorStatusCode === ERROR_UNAUTHORIZED)
    return errorUnauthorizedHandler();
  /** Handle Forbidden status (403) */
  if (getErrorStatusCode === ERROR_FORBIDDEN) return errorForbiddenHandler();
  /** Handle Internal Server Error status (500) */
  if (getErrorStatusCode === ERROR_INTERNAL_SERVER_ERROR)
    return errorInternalServerErrorHandler();
  /** Handle Not Found Error status (404) */
  if (getErrorStatusCode === ERROR_NOTFOUND) return handleNotFoundError();
  /** Not handling status code */
  return null;
};

export default errorStatusHandler;
