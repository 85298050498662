const MAIN_FIELD_HEADER = {
  category: 'CAT',
  created_date: 'DATE',
  wo_no: 'WO #',
  billto: 'BILL TO',
  cont_no: 'CONT #',
  trailer_no: 'TRAILER #',
  bk_no: 'BOOKING #',
  ssl: 'SSL',
  vessel: 'VSL',
  size: 'SZ',
  type: 'TYPE',
  ch_no: 'CH #',
  ch_type: 'CH TYPE',
  pool: 'POOL',
  bond_entry: 'BOND #',
  ref_no: 'REF #',
  purchase_no: 'PO #',
  mbl_no: 'MBL #',
  wgt: 'WGT',
  qty: 'QTY',

  over_height: 'O/H',
  over_weight: 'O/W',
  hazmat: 'HAZ',
  hot: 'HOT',

  st: 'S/T',
  erd: 'ERD',
  cutoff: 'C/O',
  rfd: 'RFD',
  lfd: 'LFD',
  eta: 'ETA',
  ava_date: 'AVA DATE',
  empty_load_cfm: 'MTY CFM',
  empty_load_status: 'L/E',
  profit: 'PRFT',
  inv: 'INV',

  po_g: 'PULL OUT',
  po_location: 'LOC',
  po_sche_date: 'SCHED',
  po_actual_in_date: 'A IN',
  po_actual_out_date: 'A OUT',
  po_driver: 'DRIVER',

  dilv_g: 'DELIVERY',
  dilv_location: 'LOC',
  dilv_sche_date: 'SCHED',
  dilv_actual_in_date: 'A IN',
  dilv_actual_out_date: 'A OUT',
  dilv_driver: 'DRIVER',

  pu_g: 'PICK UP',
  pu_location: 'LOC',
  pu_sche_date: 'SCHED',
  pu_actual_in_date: 'A IN',
  pu_actual_out_date: 'A OUT',
  pu_driver: 'DRIVER',

  rtn_g: 'RETURN',
  rtn_location: 'LOC',
  rtn_sche_date: 'SCHED',
  rtn_actual_in_date: 'A IN',
  rtn_actual_out_date: 'A OUT',
  rtn_driver: 'DRIVER',

  ar_g: 'AR',
  ar: 'AR',
  trucking: 'TRK/R',
  ar_fsc: 'FSC/R',
  ar_ch: 'CH/R',
  ar_dr: 'DR/R',
  ar_pe: 'PE/R',
  ar_de: 'DE/R',

  ap_g: 'AP',
  ap: 'AP',
  ap_drv: 'DRV/P',
  ap_ch: 'CH/P',
  ap_pe: 'PE/P',
  ap_de: 'DE/P',
  ap_dr: 'DR/P',

  doc_g: 'DOCUMENT',
  doc_g_exp: 'DOCUMENT',
  doc_pod: 'POD',
  doc_bl: 'B/L',
  doc_do: 'DO',
  doc_it: 'IT',

  remarks: 'REMARKS',
};

export default MAIN_FIELD_HEADER;
