import { Tab } from '@mui/material';
import styled from 'styled-components';

const StyledNavItemTab = styled(Tab)`
  height: 55px;
  padding: 0 !important;
  min-width: unset !important;
  color: #222;
  font-size: 16px !important;
  &:hover {
    font-weight: bold;
  }
  &.active {
    color: #001e5f !important;
    opacity: 1;
    font-weight: bold;
  }
`;

export default StyledNavItemTab;
