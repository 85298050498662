import React, { useEffect, useState } from 'react';
import StyledCommonMessageModalWrapper from '../../styles/Common/CommonMessageModal/StyledCommonMessageModalWrapper';
import CommonMessageModalHeader from './CommonMessageModalHeader';
import CommonMessageModalContent from './CommonMessageModalContent';
import { PATH_MODAL_CLOSE_ICON } from '../../constants/Common/path';
import CommonBlur from '../CommonBlur/CommonBlur';

/**
 *
 * @header {*} param0
 * Header content
 * @content
 * Modal Content
 * @buttonContent
 * Button Content
 * @returns
 * Component of common message modal
 */
const CommonMessageModal = ({
  header,
  content,
  buttonContent,
  onCloseClick,
  ...props
}) => {
  const [getOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props?.open);
  }, [props?.open]);

  return (
    getOpen && (
      <CommonBlur open={getOpen}>
        <div className="flex justify-center items-center w-full h-full">
          <StyledCommonMessageModalWrapper {...props}>
            <div className="relative -top-[9px] w-[full] h-[full] flex justify-end items-center">
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => {
                  onCloseClick?.({ ...props });
                  props?.setOpen?.(false);
                }}
              >
                <img
                  src={PATH_MODAL_CLOSE_ICON}
                  width={12}
                  height={12}
                  alt="Modal Close Icon"
                />
              </div>
            </div>
            <div className="flex gap-y-[30px] flex-col">
              <div className="flex gap-y-[12px] flex-col">
                <CommonMessageModalHeader content={header} />
                <CommonMessageModalContent content={content} />
              </div>
              <div className="w-full">{buttonContent}</div>
            </div>
          </StyledCommonMessageModalWrapper>
        </div>
      </CommonBlur>
    )
  );
};

export default CommonMessageModal;
