import styled from 'styled-components';

const StyledBottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 71px;

  .content {
    color: #666;
    font-size: 12px;
    font-weight: 500;
  }

  .container {
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .button-email {
    &:not(:hover) {
      border-color: #666666;
      color: #666666;
    }
    &:disabled {
      border-color: #a6a6a6;
      color: #a6a6a6;
      background-color: #ffffff;
    }
    column-gap: 0px;
  }
`;

export default StyledBottomBar;
