import { REGEX_MOBILE_DEVICE } from '../../../constants/Common/regex';

const checkIsMobile = () => {
  /** Check mobile device w/ userAgentData */
  const userAgentData = window?.navigator?.userAgentData;
  if (userAgentData?.mobile) return true;

  /** Check mobile device w/ userAgent (Different with userAgentData) */
  const userAgent =
    window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera;
  if (REGEX_MOBILE_DEVICE.test(userAgent)) {
    return true;
  }

  /** HAVE TO CHANGE WITH false */
  return false;
};

export default checkIsMobile;
