export const ERROR_UNAUTHORIZED = 401;
export const ERROR_FORBIDDEN = 403;
export const ERROR_NOTFOUND = 404;
export const ERROR_INTERNAL_SERVER_ERROR = 500;

export const ERROR_SPECIFIC_STATUS_CODE = [
  ERROR_UNAUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_NOTFOUND,
  ERROR_INTERNAL_SERVER_ERROR,
];
