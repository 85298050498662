import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import dispatchKeys from '../keys';
import getAPInput from '../../../services/Dispatch/Common/get/getAPInput';

const useGetAPInput = ({
  woNo,
  scheduleSeq,
  driver,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsLoadingOpen,
}) => {
  const [apInputData, setAPInputData] = useState(null);

  const query = useQuery({
    queryKey: dispatchKeys.driverAP({ woNo, scheduleSeq, driver }),
    queryFn: () => getAPInput({ woNo, scheduleSeq, driver }),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    const getAPInputData = query?.data?.data;
    if (query?.data?.data) {
      setAPInputData(getAPInputData);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError && query?.isFetchedAfterMount) {
      setMessageModalMessage(query?.error?.message);
      setIsMessageModalOpen(true);
    }
  }, [query?.isError]);

  useEffect(() => {
    if (query?.isFetching) {
      setIsLoadingOpen(true);
    } else {
      setIsLoadingOpen(false);
    }
  }, [query?.isFetching]);

  return { apInputData, ...query };
};

export default useGetAPInput;
