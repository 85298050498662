import { useMutation, useQueryClient } from '@tanstack/react-query';
import editWOMemoList from '../../../services/BCDetail/Memo/Patch/editWOMemoList';
import bcDetailKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePatchWOMemoContent = ({
  setMessageModalOpen,
  setMessageContent,
  woNo,
  setValue,
  setAddContent,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: ({ content, action, id }) => {
      return editWOMemoList({ woNo, content, action, id });
    },
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: bcDetailKeys.woMemoList({ woNo }),
      });
      setValue?.('memo', '');
      setAddContent?.(false);
      addToast({ message: SUCCESS_SAVE });
      setMessageModalOpen(true);
    },
    onError: error => {
      setMessageContent(error?.message);
      setMessageModalOpen(true);
    },
  });

  return mutator;
};

export default usePatchWOMemoContent;
