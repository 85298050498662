import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const PeriodNavigator = ({ cycle, prevDate, nextDate, setDateQuery }) => {
  const prevArrow = '../../../../assets/images/common_icon/prevArrow.svg';
  const nextArrow = '../../../../assets/images/common_icon/nextArrow.svg';

  const [prevNavigator, setPrevNavigator] = useState('Prev Week');
  const [nextNavigator, setNextNavigator] = useState('Next Week');

  const changeNavContent = () => {
    if (cycle === 'weekly') {
      setPrevNavigator('Prev Week');
      setNextNavigator('Next Week');
    }
    if (cycle === 'monthly' || cycle === 'yearly') {
      setPrevNavigator(
        dayjs(prevDate).format(cycle[0].toUpperCase().repeat(4)),
      );
      setNextNavigator(
        dayjs(nextDate).format(cycle[0].toUpperCase().repeat(4)),
      );
    }
  };

  useEffect(() => {
    changeNavContent();
  }, [cycle, prevDate, nextDate]);

  const handleNavClick = navDate => {
    setDateQuery(prev => ({
      ...prev,
      date: dayjs(navDate)?.format('YYYY-MM-DD'),
    }));
  };

  return (
    <div className="flex flex-row justify-between pb-[5px]">
      {prevDate && (
        <button
          type="button"
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => handleNavClick(prevDate)}
          disabled={!prevDate}
        >
          <img src={prevArrow} alt="prevArrow" />
          <div className="pt-[2px]">{prevNavigator}</div>
        </button>
      )}
      {nextDate && (
        <button
          type="button"
          className="flex items-center cursor-pointer text-[13px] font-[500] text-[#222222]"
          onClick={() => handleNavClick(nextDate)}
          disabled={!nextDate}
        >
          <div className="pt-[2px]">{nextNavigator}</div>
          <img src={nextArrow} alt="nextArrow" />
        </button>
      )}
    </div>
  );
};

export default PeriodNavigator;
