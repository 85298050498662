import styled, { css } from 'styled-components';

const StyledCommonInputWrapper = styled.div`
  display: flex;
  width: 100%;
  ${props =>
    props?.labelStyle === 'row'
      ? css`
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `
      : css`
          flex-direction: column;
          justify-content: center;
        `}
`;

export default StyledCommonInputWrapper;
