import styled from 'styled-components';

const StyledCommonToastMessageWrapper = styled.div`
  width: 248px;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
`;

export default StyledCommonToastMessageWrapper;
