import MAIN_FIELD_HEADER from '../../../constants/Main/Common/fieldHeader';
import { CUSTOM_COLUMN_COLORS } from '../../../styles/Main/colorTheme';
import getHeader from './Generator/getFieldsHeader';

const getAvailableItems = ({ items, category }) => {
  const availableItems = Object?.keys?.(items)?.map((item, id) => {
    const groupData =
      'children' in items?.[item]
        ? {
            theme: CUSTOM_COLUMN_COLORS?.[category]?.[item],
            children: items?.[item]?.children?.map((child, childrenID) => ({
              id: childrenID,
              label: getHeader({ item: child?.field, category }),
              value: child?.field,
            })),
          }
        : {};

    return {
      id,
      label: items[item]?.headerName || MAIN_FIELD_HEADER?.[item],
      value: item,
      ...groupData,
    };
  });
  return availableItems;
};

export default getAvailableItems;
