import {
  DISPATCH_PAGE_EXPORT_AVAILABLE,
  DISPATCH_PAGE_EXPORT_DELIVERY,
  DISPATCH_PAGE_EXPORT_RETURN,
  DISPATCH_PAGE_EXPORT_UNAVAILABLE,
  DISPATCH_PAGE_IMPORT_AVAILABLE,
  DISPATCH_PAGE_IMPORT_DELIVERY,
  DISPATCH_PAGE_IMPORT_RETURN,
} from '../../../../constants/Dispatch/Common/category';
import { LOCATION_TAB_FILTERS } from '../../../../constants/Dispatch/Common/filterButtonSettings';

const getYardQueries = ({ yardQueryObject, subFilter, status, isCounter }) => {
  if (isCounter) {
    subFilter = [
      DISPATCH_PAGE_IMPORT_DELIVERY,
      DISPATCH_PAGE_EXPORT_DELIVERY,
    ]?.includes(status)
      ? 'cnee'
      : 'yard';
  }

  if (!LOCATION_TAB_FILTERS?.includes(subFilter)) return {};
  if (
    status !== undefined &&
    ![
      DISPATCH_PAGE_IMPORT_AVAILABLE,
      DISPATCH_PAGE_IMPORT_DELIVERY,
      DISPATCH_PAGE_IMPORT_RETURN,
      DISPATCH_PAGE_EXPORT_UNAVAILABLE,
      DISPATCH_PAGE_EXPORT_AVAILABLE,
      DISPATCH_PAGE_EXPORT_DELIVERY,
      DISPATCH_PAGE_EXPORT_RETURN,
    ].includes(status)
  )
    return {};

  const yardQueries = { yard: true };
  if (subFilter === 'cnee') {
    return yardQueries;
  }
  if (!Object.values(yardQueryObject).includes(false)) {
    yardQueries['y-com-all'] = true;
  } else {
    yardQueries['y-com'] = [
      ...Object.keys(yardQueryObject).filter(yard => yardQueryObject[yard]),
    ];
  }
  return yardQueries;
};

export default getYardQueries;
