const DILV_NAME_SET = {
  title: 'DELIVERY',
  sche_time: 'dilv_sche_time',
  loc: 'dilv_loc',
  driver_id: 'dilv_driver_id',
  driver_name: 'dilv_driver_name',
  city: 'dilv_loc_city',
  state: 'dilv_loc_state',
  zip: 'dilv_loc_zip',
};

export default DILV_NAME_SET;
