import React, { useEffect, useState } from 'react';
import StyledCommonBlur from '../../styles/Common/CommonBlur/StyledCommonBlur';

const CommonBlur = ({ children, ...props }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props?.open) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
    setOpen(props?.open);
  }, [props?.open]);

  return (
    open && (
      // Delete 'backdrop-blur'
      <StyledCommonBlur className={open && 'bg-black/40'}>
        {children}
      </StyledCommonBlur>
    )
  );
};

export default CommonBlur;
