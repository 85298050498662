import styled, { css } from 'styled-components';

const StyledCommonMessageModalWrapper = styled.div`
  z-index: 9999;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 50px 30px 50px;
  position: absolute;

  ${props =>
    props?.width
      ? css`
          width: ${props?.width};
          min-width: ${props?.width};
        `
      : css`
          width: fit-content;
          min-width: 400px;
        `};

  ${props =>
    props?.height
      ? css`
          height: ${props?.height};
          min-height: ${props?.height};
        `
      : css`
          height: fit-content;
          min-height: 203px;
        `};
`;

export default StyledCommonMessageModalWrapper;
