import React, { useState } from 'react';
import { STATISTICS_ROW_TITLE_VALUE } from '../../../../../constants/TmsReport/Statistics/statisticsValues';
import StyledStatContentsContainer from '../../../../../styles/TmsReport/Statistics/StyledStatContentsContainer';

const StatRowCostContents = ({
  division,
  cycle,
  tableTitle,
  rowTitle,
  rowData,
  cycleUnits,
  ...props
}) => {
  const [isCellHovered, setIsCellHovered] = useState(false);
  const isAmountCell = !!(rowTitle === 'amount');

  const generateCellValue = unit => {
    const numberValue =
      cycle === 'weekly' ? rowData?.[unit]?.count : rowData?.[unit];
    const cellValue = isAmountCell ? numberValue?.toFixed(2) : numberValue;

    return cellValue;
  };

  return (
    <StyledStatContentsContainer isCellHovered={isCellHovered} {...props}>
      {rowTitle && (
        <div className="row-title left-category-sticky">
          {tableTitle.toUpperCase() + STATISTICS_ROW_TITLE_VALUE[rowTitle]}
        </div>
      )}
      {Object.keys(cycleUnits)?.map((unit, index) => (
        <div
          key={String(cycle + division + index)}
          onMouseOver={() => setIsCellHovered(true)}
          onMouseLeave={() => setIsCellHovered(false)}
          className={`cell ${isAmountCell && 'justify-between px-[20px]'}`}
        >
          {isAmountCell && <div>$</div>}
          {generateCellValue(unit)}
        </div>
      ))}
    </StyledStatContentsContainer>
  );
};

export default StatRowCostContents;
