import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
} from '../../../constants/Order/Common/basic';
import useGetBookingNumber from '../../../hooks/Common/useGetBookingNumber';
import useGetBookingInfo from '../../../hooks/Order/useGetBookingInfo';
import useGetCompanyOption from '../../../hooks/Order/useGetCompanyOption';
import useGetSendto from '../../../hooks/Order/useGetSendto';
import usePostOrderForm from '../../../hooks/Order/usePostOrderForm';
import usePostScanDO from '../../../hooks/Order/usePostScanDO';
import resetForm from '../../../utils/Order/Handler/resetForm';
import CommonErrorModal from '../../CommonErrorModal/CommonErrorModal';
import CommonContainerInfo from '../CommonContainerInfo';
import CommonContainerScanner from '../CommonContainerInfo/CommonContainerScanner';
import CommonOrderInfo from '../CommonOrderInfo';

/**
 * @description
 * Return Order Wrapper component
 * @subCategory {string} Sub-page category of Order page
 */
const OrderWrapper = ({ subCategory, ...props }) => {
  // RHF object property
  const { setValue, reset, getValues } = useFormContext();

  // Get common select option
  const commonOption = useSelector(state => state?.common);
  const currDiv = useSelector(state => state?.nowDiv);

  // Get billto's email
  const sendTo = useGetSendto();
  // Get booking number
  const bookingNumber = useGetBookingNumber();
  // Get 'Bill To', 'Pull Out', 'Delivery', 'Return'
  const companyOption = useGetCompanyOption({ pageCategory: 'order' });

  // Selected Booking Sequence
  const [bookingSequence, setBookingSequence] = useState(null);
  useEffect(() => {
    if (
      ![SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(subCategory)
    ) {
      setBookingSequence(null);
    }
  }, [subCategory]);

  // Container info count
  const [containerCount, setContainerCount] = useState(1);
  const [containerKey, setContainerKey] = useState(1);

  /**
   * Container key: Unique key of container ( Keep increase )
   * Container idx: Index of container ( Reset every time )
   */
  const [containerList, setContainerList] = useState([
    {
      containerKey: 1,
      containerIdx: 1,
    },
  ]);

  // Booking info according to booking #
  const bookingInfo = useGetBookingInfo({
    bookingSequence,
    containerList,
    setContainerList,
  });

  // Message modal info
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Post order form mutator
  const { mutate: orderPostMutator } = usePostOrderForm({
    modalMessageSetter: { setMessageModalOpen, setModalMessage },
    setContainerCount,
    setContainerList,
  });

  // Scan DO mutator
  const [doDisabled, setDODisabled] = useState(false);
  const {
    mutate: scanDOMuator,
    isError,
    isPending,
  } = usePostScanDO({
    modalMessageSetter: { setMessageModalOpen, setModalMessage },
    setValue,
    getValues,
    reset,
    containerCount,
    containerKey,
    setContainerCount,
    setContainerKey,
    setContainerList,
    companyOption,
    setDODisabled,
  });

  useEffect(() => {
    resetForm({ reset, setContainerCount, setContainerList, subCategory });
    setBookingSequence(null);
  }, [subCategory, currDiv]);

  return (
    <>
      <div className="min-h-[844px] h-[844px] mt-[30px] flex">
        {/* Order information component */}
        <CommonOrderInfo
          subCategory={subCategory}
          setContainerList={setContainerList}
          setContainerCount={setContainerCount}
          commonOption={commonOption}
          companyOption={companyOption}
          bookingNumber={bookingNumber}
          containerList={containerList}
          modalMessageSetter={{ setMessageModalOpen, setModalMessage }}
          orderPostMutator={orderPostMutator}
          currDiv={currDiv}
          setBookingSequence={setBookingSequence}
          doDisabled={doDisabled}
          isLoadingBookingNumber={bookingNumber?.query?.isLoading}
          isLoadingCompanyOption={
            companyOption?.query[0]?.isLoading ||
            companyOption?.query[1]?.isLoading
          }
        />
        {/* Vertical Lines */}
        <div className="w-[1px] min-w-[1px] h-[844px] min-h-[844px] bg-[#878d9bcc]" />
        {/* Container / Trailer / Chassis information component */}
        <CommonContainerInfo
          subCategory={subCategory}
          containerInfo={{
            containerKey,
            containerCount,
            containerList,
          }}
          containerInfoSetter={{
            setContainerKey,
            setContainerCount,
            setContainerList,
          }}
          commonOption={commonOption}
          bookingInfo={bookingInfo}
          bookingSequence={bookingSequence}
        />
        <CommonContainerScanner
          scanDOMuator={scanDOMuator}
          scanDOStatus={{ isError, isPending }}
        />
      </div>
      {/* Message Modal */}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          setIsOpen={setMessageModalOpen}
          message={modalMessage}
          isSuccess={false}
        />
      )}
    </>
  );
};

export default OrderWrapper;
