const handleCellFocused = ({ gridParam }) => {
  if (gridParam?.rowPinned) {
    gridParam?.api
      ?.getCellRendererInstances({
        columns: [gridParam?.column],
        rowNodes: [gridParam?.api?.getPinnedTopRow(0)],
      })?.[0]
      ?.compInputRef?.current?.focus();
  } else {
    const focusedCellEditorInstance = gridParam?.api?.getCellEditorInstances({
      rowNodex: [gridParam?.api?.getDisplayedRowAtIndex(gridParam?.rowIndex)],
      columns: [gridParam?.column],
    })?.[0];

    focusedCellEditorInstance?.componentInstance?.compInputRef?.current?.focus();
  }
};

export default handleCellFocused;
