import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import StyledContainerInfoWrapper from '../../../../styles/Order/StyledContainerInfo/StyledContainerInfoWrapper';
import StyledContainerInfoHeader from '../../../../styles/Order/StyledContainerInfo/StyledContainerInfoHeader';
import { CONTAINER_CLOSE_ICON } from '../../../../constants/Order/Common/path';
import StyledCloseContainerInfoButton from '../../../../styles/Order/StyledContainerInfo/StyledCloseContainerInfoButton';
import StyledContainerInfoContents from '../../../../styles/Order/StyledContainerInfo/StyledContainerInfoContents';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import {
  SUB_ORDER_TYPE_BARECHASSIS,
  SUB_ORDER_TYPE_BROKERAGE,
  SUB_ORDER_TYPE_EXPREG,
  SUB_ORDER_TYPE_EXPSHUTTLE,
  SUB_ORDER_TYPE_IMPREG,
  SUB_ORDER_TYPE_IMPSHUTTLE,
  SUB_ORDER_TYPE_VAN,
} from '../../../../constants/Order/Common/basic';
import handleTextInputChange from '../../../../utils/Order/Handler/InputHandler/handleTextInputChange';
import handleSelectChange from '../../../../utils/Order/Handler/InputHandler/handleSelectChange';
import handleRegexChange from '../../../../utils/Order/Handler/InputHandler/handleRegexChange';
import {
  REGEX_DECIMAL_10_2,
  REGEX_DECIMAL_5_2,
  REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
  REGEX_ONLY_NUMBER_ONLY_ALPHABET,
} from '../../../../constants/Common/regex';
import generateOption, {
  generateBookingFilteredOption,
  generateBookingOption,
} from '../../../../utils/Order/Generator/optionGenerator';
import {
  COMMON_CONTAINER_LOAD_EMPTY_SELECT_OPTION,
  COMMON_CONTAINER_UNIT_SELECT_OPTION,
} from '../../../../constants/Common/selectOption';
import createEmptySelect from '../../../../utils/Common/Option/createEmptySelect';
import handleBookingSelectChange from '../../../../utils/Order/Handler/InputHandler/handleBookingSelectChange';
import CommonCheckbox from '../../../Inputs/CommonCheckbox/CommonCheckbox';
import handleCheckboxChange from '../../../../utils/Order/Handler/InputHandler/handleCheckboxChange';
import createMandatorySelect from '../../../../utils/Common/Option/createMandatorySelect';

const ContainerInfoWrapper = ({
  equipLockRef,
  idx,
  dataKey,
  subCategory,
  containerList,
  setContainerList,
  commonOption,
  bookingOption,
  bookingSequence,
  bookingSelectOption,
  ...props
}) => {
  const { setValue } = useFormContext();
  const sizeWatcher = useWatch({ name: `size_${dataKey}` });
  const typeWatcher = useWatch({ name: `type_${dataKey}` });
  const chNoWatcher = !!useWatch({ name: `ch_no_${dataKey}` });
  const poolWatcher = useWatch({ name: `pool_${dataKey}` });

  return (
    <StyledContainerInfoWrapper subCategory={subCategory}>
      <StyledContainerInfoHeader>
        <div className="text-[#264B9F] text-[12px] font-bold">#{idx}</div>
        <div>
          {/* Show icons except for the first container information  */}
          {containerList.length !== 1 && (
            <StyledCloseContainerInfoButton
              src={CONTAINER_CLOSE_ICON}
              onClick={() => {
                if (equipLockRef.current) return;
                setContainerList(prev => {
                  return prev.filter(
                    container => container?.containerKey !== dataKey,
                  );
                });
                equipLockRef.current = true;
                setTimeout(() => {
                  equipLockRef.current = false;
                }, 300);
              }}
            />
          )}
        </div>
      </StyledContainerInfoHeader>
      <StyledContainerInfoContents>
        <div className="flex flex-row w-full gap-[10.5px]">
          <div
            className={`${
              [SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory)
                ? `w-[111px]`
                : `w-[124px]`
            } pb-[6px]`}
          >
            {[
              SUB_ORDER_TYPE_IMPREG,
              SUB_ORDER_TYPE_IMPSHUTTLE,
              SUB_ORDER_TYPE_EXPREG,
              SUB_ORDER_TYPE_EXPSHUTTLE,
            ].includes(subCategory) && (
              <CommonTextInput
                inputName={`cont_no_${dataKey}`}
                label="CONT #"
                maxLength={11}
                onChangeHandler={handleRegexChange}
                onBlurHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.value?.toUpperCase());
                }}
                regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
              />
            )}
            {[SUB_ORDER_TYPE_VAN, SUB_ORDER_TYPE_BROKERAGE].includes(
              subCategory,
            ) && (
              <CommonTextInput
                inputName={`trailer_no_${dataKey}`}
                label={
                  [SUB_ORDER_TYPE_BROKERAGE].includes(subCategory)
                    ? 'EQUIPMENT'
                    : 'TRAILER #'
                }
                maxLength={12}
                onChangeHandler={handleRegexChange}
                onBlurHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.value?.toUpperCase());
                }}
                regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
              />
            )}
            {[SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
              <CommonTextInput
                inputName={`ch_no_${dataKey}`}
                label="CHASSIS #"
                maxLength={12}
                onChangeHandler={handleRegexChange}
                onBlurHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.value?.toUpperCase());
                  if (
                    e?.target?.value?.toUpperCase()?.startsWith('NGLT') &&
                    (!poolWatcher || poolWatcher === '')
                  ) {
                    setValue?.(`pool_${dataKey}`, 'NGL');
                  }
                }}
                regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
              />
            )}
          </div>
          <div
            className={`${
              [SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory)
                ? `w-[112px]`
                : `w-[100px]`
            } pb-[6px]`}
          >
            {[SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
              <CommonSelect
                inputName={`pool_${dataKey}`}
                label="POOL"
                required={chNoWatcher}
                onChangeHandler={handleSelectChange}
                option={createEmptySelect({
                  selectOption: generateOption({
                    items: commonOption?.pool,
                    property: 'pool',
                  }),
                  emptyValue: '',
                })}
              />
            )}
            {![SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
              <CommonSelect
                dataKey={dataKey}
                targetProperty="size"
                inputName={`size_${dataKey}`}
                label="SIZE"
                defaultValue={
                  [SUB_ORDER_TYPE_VAN, SUB_ORDER_TYPE_BROKERAGE].includes(
                    subCategory,
                  )
                    ? '53'
                    : '40 HC'
                }
                required={[
                  SUB_ORDER_TYPE_EXPREG,
                  SUB_ORDER_TYPE_EXPSHUTTLE,
                ].includes(subCategory)}
                onChangeHandler={({
                  e,
                  selectProps,
                  onChange,
                  ...handlerProps
                }) =>
                  handleBookingSelectChange({
                    e,
                    subCategory,
                    onChange,
                    selectProps,
                    bookingSelectOptionAccordingToType:
                      bookingOption?.sizeOption,
                    setContainerList,
                    ...handlerProps,
                  })
                }
                option={
                  [SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
                    subCategory,
                  ) &&
                  bookingOption &&
                  bookingOption?.sizeOption &&
                  bookingSequence
                    ? createMandatorySelect({
                        selectOption: generateBookingFilteredOption({
                          watcher: typeWatcher,
                          bookingOption,
                          bookingSelectOption,
                          optionProperty: 'sizeOption',
                          selectOptionProperty: 'size',
                          oppositeOptionProperty: 'type',
                        }),
                        emptyValue: '',
                      })
                    : createMandatorySelect({
                        selectOption: generateOption({
                          items: commonOption?.size,
                          property: 'size',
                        }),
                        emptyValue: '',
                      })
                }
              />
            )}
          </div>
          {[SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
            <div className="w-[100px] pb-[6px]">
              <CommonSelect
                inputName={`ch_type_${dataKey}`}
                label="TYPE"
                onChangeHandler={handleSelectChange}
                defaultValue="REGULAR"
                option={generateOption({
                  items: commonOption?.chassisType,
                  property: 'type',
                })}
              />
            </div>
          )}
          {![SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
            <div className="w-[100px] pb-[6px]">
              <CommonSelect
                dataKey={dataKey}
                targetProperty="type"
                inputName={`type_${dataKey}`}
                label="TYPE"
                defaultValue="DRY"
                required={[
                  SUB_ORDER_TYPE_EXPREG,
                  SUB_ORDER_TYPE_EXPSHUTTLE,
                ].includes(subCategory)}
                onChangeHandler={({
                  e,
                  selectProps,
                  onChange,
                  ...handlerProps
                }) =>
                  handleBookingSelectChange({
                    e,
                    subCategory,
                    onChange,
                    selectProps,
                    bookingSelectOptionAccordingToType:
                      bookingOption?.sizeOption,
                    setContainerList,
                    ...handlerProps,
                  })
                }
                option={
                  [SUB_ORDER_TYPE_EXPREG, SUB_ORDER_TYPE_EXPSHUTTLE].includes(
                    subCategory,
                  ) &&
                  bookingOption &&
                  bookingOption?.typeOption &&
                  bookingSequence
                    ? createMandatorySelect({
                        selectOption: generateBookingFilteredOption({
                          watcher: sizeWatcher,
                          bookingOption,
                          bookingSelectOption,
                          optionProperty: 'typeOption',
                          selectOptionProperty: 'type',
                          oppositeOptionProperty: 'size',
                        }),
                        emptyValue: '',
                      })
                    : createMandatorySelect({
                        selectOption: generateOption({
                          items: commonOption?.type,
                          property: 'type',
                        }),
                        emptyValue: '',
                      })
                }
              />
            </div>
          )}
        </div>
        {[SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
          <div className="flex flex-row w-full gap-[11px]">
            <div className="w-[42px] pb-[6px]">
              <CommonCheckbox
                label="WMS"
                borderVisible
                options={[
                  {
                    inputName: `wms_${dataKey}`,
                    defaultValue: false,
                  },
                ]}
                onChangeHandler={handleCheckboxChange}
                customStyle={{
                  bgColor: 'bg-white',
                }}
              />
            </div>
          </div>
        )}
        {![SUB_ORDER_TYPE_BARECHASSIS].includes(subCategory) && (
          <>
            <div className="flex flex-row w-full gap-[10.5px]">
              <div className="w-[104px] pb-[6px]">
                <CommonTextInput
                  inputName={`wgt_${dataKey}`}
                  label="WGT"
                  maxLength={13}
                  regex={REGEX_DECIMAL_10_2}
                  onChangeHandler={handleRegexChange}
                />
              </div>
              <div className="w-[80px] pb-[6px]">
                <CommonTextInput
                  inputName={`qty_${dataKey}`}
                  label="QTY"
                  maxLength={8}
                  onChangeHandler={handleRegexChange}
                  regex={REGEX_DECIMAL_5_2}
                />
              </div>
              <div className="w-[84px] pb-[6px]">
                <CommonSelect
                  inputName={`unit_${dataKey}`}
                  label="UNIT"
                  onChangeHandler={handleSelectChange}
                  option={createEmptySelect({
                    selectOption: COMMON_CONTAINER_UNIT_SELECT_OPTION,
                    emptyValue: '',
                  })}
                />
              </div>
              <div className="w-[42px] pb-[6px]">
                <CommonCheckbox
                  label="WMS"
                  borderVisible
                  options={[
                    {
                      inputName: `wms_${dataKey}`,
                      defaultValue: false,
                    },
                  ]}
                  onChangeHandler={handleCheckboxChange}
                  customStyle={{
                    bgColor: 'bg-white',
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row w-full gap-[11px]">
              <div className="w-[167px] pb-[6px]">
                <CommonTextInput
                  inputName={`seal_${dataKey}`}
                  label="SEAL"
                  maxLength={20}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
              <div className="w-[167px] pb-[6px]">
                <CommonTextInput
                  inputName={`commodity_${dataKey}`}
                  label="COMMODITY"
                  maxLength={50}
                  onChangeHandler={handleTextInputChange}
                />
              </div>
            </div>
            {[SUB_ORDER_TYPE_BROKERAGE].includes(subCategory) && (
              <>
                <div className="flex flex-row w-full gap-[11px]">
                  <div className="w-[111px] pb-[6px]">
                    <CommonTextInput
                      inputName={`ch_no_${dataKey}`}
                      label="CHASSIS #"
                      maxLength={12}
                      onChangeHandler={handleRegexChange}
                      onBlurHandler={({ e, onChange, ...handlerProps }) => {
                        onChange(e?.target?.value?.toUpperCase());
                        if (
                          e?.target?.value?.toUpperCase()?.startsWith('NGLT') &&
                          (!poolWatcher || poolWatcher === '')
                        ) {
                          setValue?.(`pool_${dataKey}`, 'NGL');
                        }
                      }}
                      regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
                    />
                  </div>
                  <div className="w-[107px] pb-[6px]">
                    <CommonSelect
                      inputName={`pool_${dataKey}`}
                      label="POOL"
                      onChangeHandler={handleSelectChange}
                      required={chNoWatcher}
                      option={createEmptySelect({
                        selectOption: generateOption({
                          items: commonOption?.pool,
                          property: 'pool',
                        }),
                        emptyValue: '',
                      })}
                    />
                  </div>
                  <div className="w-[100px] pb-[6px]">
                    <CommonSelect
                      inputName={`ch_type_${dataKey}`}
                      label="TYPE"
                      onChangeHandler={handleSelectChange}
                      defaultValue="REGULAR"
                      option={generateOption({
                        items: commonOption?.chassisType,
                        property: 'type',
                      })}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full gap-[11px]">
                  <div className="w-[140px] pb-[6px]">
                    <CommonSelect
                      inputName={`empty_load_status_${dataKey}`}
                      label="L/E"
                      onChangeHandler={handleSelectChange}
                      option={createEmptySelect({
                        selectOption: COMMON_CONTAINER_LOAD_EMPTY_SELECT_OPTION,
                        emptyValue: '',
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {![
              SUB_ORDER_TYPE_VAN,
              SUB_ORDER_TYPE_BARECHASSIS,
              SUB_ORDER_TYPE_BROKERAGE,
            ].includes(subCategory) && (
              <div className="flex flex-row w-full gap-[11px]">
                <div className="w-[111px] pb-[6px]">
                  <CommonTextInput
                    inputName={`ch_no_${dataKey}`}
                    label="CHASSIS #"
                    maxLength={12}
                    onChangeHandler={handleRegexChange}
                    onBlurHandler={({ e, onChange, ...handlerProps }) => {
                      onChange(e?.target?.value?.toUpperCase());
                      if (
                        e?.target?.value?.toUpperCase()?.startsWith('NGLT') &&
                        (!poolWatcher || poolWatcher === '')
                      ) {
                        setValue?.(`pool_${dataKey}`, 'NGL');
                      }
                    }}
                    regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
                  />
                </div>
                <div className="w-[107px] pb-[6px]">
                  <CommonSelect
                    inputName={`pool_${dataKey}`}
                    label="POOL"
                    onChangeHandler={handleSelectChange}
                    required={chNoWatcher}
                    option={createEmptySelect({
                      selectOption: generateOption({
                        items: commonOption?.pool,
                        property: 'pool',
                      }),
                      emptyValue: '',
                    })}
                  />
                </div>
                <div className="w-[100px] pb-[6px]">
                  <CommonSelect
                    inputName={`ch_type_${dataKey}`}
                    label="TYPE"
                    onChangeHandler={handleSelectChange}
                    defaultValue="REGULAR"
                    option={generateOption({
                      items: commonOption?.chassisType,
                      property: 'type',
                    })}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </StyledContainerInfoContents>
    </StyledContainerInfoWrapper>
  );
};

export default ContainerInfoWrapper;
