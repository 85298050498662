/**
 * @description
 * Function that return disable condition for date after field
 * @param {string} dateCategory
 * Selected date category
 * @returns {boolean}
 * Disable condition for date after field
 */
const getDateAfterDisableCondition = ({ dateCategory }) => {
  if (
    dateCategory &&
    [
      'created_date',
      'po_actual_out_date',
      'dilv_actual_out_date',
      'pu_actual_out_date',
      'rtn_actual_out_date',
      'empty_cfm',
      'load_cfm',
    ].includes(dateCategory)
  )
    return true;
  return false;
};

export default getDateAfterDisableCondition;
