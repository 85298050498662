import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import StyledNavTabBox from '../../styles/SubTitle/StyledNavTabBox';
import StyledNavTabs from '../../styles/SubTitle/StyledNavTabs';
import StyledSpacingTabs from '../../styles/SubTitle/StyledSpacingTabs';
import StyledNavItemTabWidth from '../../styles/SubTitle/StyledNavItemTabWidth';
import StyledNavItemTab from '../../styles/SubTitle/StyledNavItemTab';

const SubTitle = ({ tabsData, MainTitle }) => {
  const [selected, setSelected] = useState(0);

  const location = useLocation();
  useEffect(() => {
    const setSelectedIndexByPath = path => {
      const matchedTab = tabsData.find(tab => path === tab.to);
      return matchedTab ? matchedTab.pathValue : false;
    };
    setSelected(setSelectedIndexByPath(location.pathname));
  }, [location.pathname, tabsData]);

  return (
    <>
      <div className="mt-20 text-[25px] font-[800]">
        <h1>{MainTitle}</h1>
      </div>
      <StyledNavTabBox>
        <StyledNavTabs value={selected} aria-label="nav tabs">
          {tabsData.map(data => {
            return (
              <StyledSpacingTabs>
                <StyledNavItemTabWidth>
                  {data.label.toUpperCase()}
                </StyledNavItemTabWidth>
                <StyledNavItemTab
                  disableelevation="true"
                  disableRipple
                  key={data.to}
                  component={NavLink}
                  label={data.label}
                  to={data.to}
                />
              </StyledSpacingTabs>
            );
          })}
        </StyledNavTabs>
      </StyledNavTabBox>
    </>
  );
};

export default SubTitle;
