import React, { useState } from 'react';
import {
  PATH_BUTTON_GRID_FOLD,
  PATH_BUTTON_GRID_UNFOLD,
} from '../../../../constants/Common/path';

const FoldHeaderRenderer = ({ ...props }) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div
      className="h-full w-full flex justify-center items-start mt-[30px] cursor-pointer"
      onClick={() => {
        props?.api?.forEachNode(node => {
          node?.setExpanded(!isExpand);
        });
        setIsExpand(prev => !prev);
      }}
    >
      {isExpand ? (
        <img src={PATH_BUTTON_GRID_FOLD} alt="gridFoldIcon" />
      ) : (
        <img src={PATH_BUTTON_GRID_UNFOLD} alt="gridUnfoldIcon" />
      )}
    </div>
  );
};

export default FoldHeaderRenderer;
