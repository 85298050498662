import styled, { css } from 'styled-components';
import BUTTON_ICON_PAIR from '../../../constants/Common/buttonIconPair';

const StyledCommonButtonIcon = styled.img.attrs(props => {
  const { category, state } = props;

  const DEFAULT_ICON_PATH = props?.disabled
    ? BUTTON_ICON_PAIR.find(element => element?.category === category)?.iconPath
        ?.disabled
    : BUTTON_ICON_PAIR.find(element => element?.category === category)?.iconPath
        ?.unselected;

  return {
    src: DEFAULT_ICON_PATH,
    width: '15px !important',
    minWidth: '15px !important',
    height: '15px !important',
    minHeight: '15px !important',
  };
})`
  ${props =>
    props?.size === 'small' &&
    css`
      width: 14px !important;
      min-width: 14px !important;
      height: 14px !important;
      min-height: 14px !important;
    `}
`;

export default StyledCommonButtonIcon;
