import { CELL_TYPE_TEXT } from '../../Common/DataGrid/cellType';
import cellEditorGenerator from '../../../utils/Common/Datagrid/Generator/cellEditorGenerator';

const EDITABLE_TEXT_DEF = {
  cellEditorSelector: gridParam =>
    cellEditorGenerator({
      gridParam,
      cellType: CELL_TYPE_TEXT,
      maxLength: 64,
    }),
};
export default EDITABLE_TEXT_DEF;
