import { useEffect, useState } from 'react';

const useControlTotalInfo = ({ gridRef, queryData, amountField }) => {
  const [totalRows, setTotalRows] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [changeFlag, setChangeFlag] = useState(false);

  useEffect(() => {
    setChangeFlag(prev => !prev);
  }, [queryData]);

  useEffect(() => {
    const checkedRows = gridRef?.current?.api
      ?.getSelectedNodes()
      ?.filter(row => row?.displayed)
      ?.map(row => row?.data);
    const displayedRows = gridRef?.current?.api
      ?.getModel()
      ?.rowsToDisplay?.map(row => row?.data);

    if (checkedRows?.length > 0) {
      const checkedRowsLength = checkedRows?.length;
      const checkedRowsAmount = checkedRows?.reduce(
        (acc, cur) => acc + Number(cur?.[amountField]),
        0,
      );

      setTotalRows(checkedRowsLength);
      setTotalAmount(checkedRowsAmount);
    } else {
      const displayedRowsLength = displayedRows?.length || 0;
      const displayedRowsAmount = displayedRows?.reduce(
        (acc, cur) => acc + Number(cur?.[amountField]),
        0,
      );

      setTotalRows(displayedRowsLength);
      setTotalAmount(displayedRowsAmount);
    }
  }, [changeFlag]);

  return {
    totalRows,
    setTotalRows,
    totalAmount,
    setTotalAmount,
    changeFlag,
    setChangeFlag,
  };
};

export default useControlTotalInfo;
