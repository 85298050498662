export const DISPATCH_GROUP_HEADER = header => ({
  equip: 'EQUIPMENT',
  info: 'INFORMATION',
  sche: 'SCHEDULE',
  geofence: 'GEOFENCE',
  ava: 'AVAILABILITY',
  cont_1: '1ST CONT',
  cont_2: '2ND CONT',
  cont_3: '3RD CONT',
  booking: 'BOOKING',
  yard: 'YARD',
  po: header?.po || 'PULL OUT',
  dilv: 'DELIVERY',
  pu: header?.pu || 'PICK UP',
  rtn: 'RETURN',
  lfd: 'LFD',
  rfd: 'RFD',
});

export const DISPATCH_COLUMN_GRID_FIELD = {
  lfd_date: 'lfd',
  rfd_date: 'rfd',
  orig: 'orig_loc',
  curr: 'curr_loc',
  dest: 'dest_loc',
  dilv_empty_cfm: 'empty_load_cfm',
  dilv_load_cfm: 'empty_load_cfm',
  dilv_empty_load_cfm: 'empty_load_cfm',
  load_empty: 'empty_load_status',
};
