/**
 * @description
 * Function that start the editing status of the row.
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 */
const onRowEditClickHandler = ({ gridParam, ...props }) => {
  /** Start editing cell when edit button clicked */
  gridParam?.api?.startEditingCell({
    rowIndex: gridParam?.node?.rowIndex,
    colKey:
      gridParam?.api?.getDisplayedCenterColumns()?.[
        gridParam?.api?.getDisplayedCenterColumns()?.length - 1
      ]?.colId,
  });
};

export default onRowEditClickHandler;
