const RTN_NAME_SET = {
  title: 'RETURN',
  sche_time: 'rtn_sche_time',
  loc: 'rtn_loc',
  driver_id: 'rtn_driver_id',
  driver_name: 'rtn_driver_name',
  city: 'rtn_loc_city',
  state: 'rtn_loc_state',
  zip: 'rtn_loc_zip',
};

export default RTN_NAME_SET;
