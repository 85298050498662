import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import QBAPARDetailTable from '../../../components/Acct/Common/QBAPARDetailTable';
import usePostMultipleQB from '../../../hooks/Acct/Common/Post/usePostMultipleQB';
import useGetAPARReconcile from '../../../hooks/Acct/APARReconcile/Get/useGetAPARReconcile';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import AccountQBHeaderBar from '../../../components/Acct/Common/AccountQBHeaderBar';
import createQBReconcileColumnDefs from '../../../utils/Acct/QBReconcile/Generator/createQBReconcileColumnDefs';
import useControlLoading from '../../../hooks/Acct/Common/Control/useControlLoading';
import AdvancedSearchModal from '../../../components/Acct/Common/AdvancedSearchModal';
import { ACCT_PAGE } from '../../../constants/Acct/Common/staticNames';
import moveBCDetailPage from '../../../utils/Acct/Common/Handler/moveBCDetailPage';
import useGetUserDivisionList from '../../../hooks/Acct/Common/Get/useGetUserDivisonList';
import useControlTotalInfo from '../../../hooks/Acct/Common/Control/useControlTotalInfo';
import TotalBottomBar from '../../../components/Acct/Common/TotalBottomBar';

const APARReconcile = () => {
  dayjs.extend(isoWeek);

  const divList = useGetUserDivisionList();

  const gridRef = useRef(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [queryObject, setQueryObject] = useState({
    type: 'ALL',
    dateType: 'qb',
    period: 'lastWeek',
    from_date: dayjs()
      ?.subtract(1, 'week')
      ?.startOf('isoWeek')
      ?.format('YYYY-MM-DD'),
    to_date: dayjs()
      ?.subtract(1, 'week')
      ?.endOf('isoWeek')
      ?.format('YYYY-MM-DD'),
  });

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const errorModalOptions = {
    setIsSuccess,
    setErrorMessage: setMessageContent,
    setIsErrorModalOpen: setMessageModalOpen,
  };

  const [columnDefs, setColumnsDefs] = useState([]);
  const apARList = useGetAPARReconcile({ queryObject });

  const handlerPostQuickBooks = usePostMultipleQB({ errorModalOptions });

  useEffect(() => {
    if (divList?.length > 0) {
      setColumnsDefs(createQBReconcileColumnDefs({ divList }));
    }
  }, [divList]);

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [apARList?.isLoadingAPARReconcile],
    isFetchingList: [apARList?.isFetchingAPARReconcile],
  });

  const { totalRows, totalAmount, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: apARList?.apARReconcile,
    amountField: 'total_amt',
  });

  return (
    <>
      <div className="pr-[40px] flex flex-col h-full">
        <AccountQBHeaderBar
          page={ACCT_PAGE?.qbReconcile}
          gridRef={gridRef}
          setOpenModal={setIsOpenModal}
          handlerPostQuickBooks={handlerPostQuickBooks}
          errorModalOptions={errorModalOptions}
        />
        <div className="w-full flex-1 flex flex-col">
          <CommonDataGrid
            data={apARList?.apARReconcile}
            columnDefs={columnDefs}
            gridRef={gridRef}
            foldable={Boolean(true)}
            foldableContent={QBAPARDetailTable}
            embedFullWidthRows
            onFilterChanged={gridParams => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={gridParams => {
              setChangeFlag(prev => !prev);
            }}
            onCellClicked={props =>
              moveBCDetailPage({
                gridParam: props?.gridParam,
                columnID: 'wo_no',
              })
            }
          />
          <TotalBottomBar totalRows={totalRows} totalAmount={totalAmount} />
        </div>
      </div>
      {isOpenModal && (
        <AdvancedSearchModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          queryObject={queryObject}
          setQueryObject={setQueryObject}
          page={ACCT_PAGE?.qbReconcile}
          errorModalOptions={errorModalOptions}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      {messageModalOpen && !isSuccess && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default APARReconcile;
