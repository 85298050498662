import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdvancedSearchModal from '../../../components/TmsReport/Statistics/Modal/AdvancedSearchModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import HeaderButtons from '../../../components/TmsReport/Statistics/HeaderButtons';
import PeriodNavigator from '../../../components/TmsReport/Statistics/PeriodNavigator';
import StatTable from '../../../components/TmsReport/Statistics/StatTable';
import StyledStatAreaWrapper from '../../../styles/TmsReport/Statistics/StyledStatAreaWrapper';
import useGetStatisticsList from '../../../hooks/TmsReport/Statistics/useGetStatisticsList';
import generateInitOptions from '../../../utils/TmsReport/Statistics/Generator/generateInitOptions';

const Statistics = () => {
  const divisionList = useSelector(state => state?.common?.allDiv);

  const [cycle, setCycle] = useState('weekly');

  const [dateQuery, setDateQuery] = useState({});

  const [divQuery, setDivQuery] = useState(
    Object.fromEntries(divisionList?.map(div => [div, true])),
  );

  const [isOpenAdvancedSearch, setIsOpenAdvancedSearch] = useState(false);

  const [customDivOptions, setCustomDivOptions] = useState([]);
  const [isOpenCustomDiv, setIsOpenCustomDiv] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCustomDivOptions(
      generateInitOptions({ divisionList, setQuery: setDivQuery }),
    );
  }, [divisionList]);

  const { statisticsList, currentDate, ...navDateProps } = useGetStatisticsList(
    {
      cycle,
      dateQuery,
      divQuery,
      setIsLoading,
      setIsError,
      setErrorMessage,
      setIsOpenErrorModal,
    },
  );

  const excelProps = {
    cycle,
    statisticsList,
    currentDate,
  };

  return (
    <>
      <div className="flex flex-col pr-[40px] h-full">
        <HeaderButtons
          setIsOpenAdvancedSearch={setIsOpenAdvancedSearch}
          customDivOptions={customDivOptions}
          setDivQuery={setDivQuery}
          isOpenCustomDiv={isOpenCustomDiv}
          setIsOpenCustomDiv={setIsOpenCustomDiv}
          {...excelProps}
        />
        <PeriodNavigator
          cycle={cycle}
          setDateQuery={setDateQuery}
          {...navDateProps}
        />
        <StyledStatAreaWrapper>
          {Object.entries(statisticsList)?.map(
            ([title, tableData]) =>
              tableData && (
                <StatTable
                  key={title}
                  tableTitle={title}
                  tableData={tableData}
                  cycle={cycle}
                  currentDate={currentDate}
                />
              ),
          )}
        </StyledStatAreaWrapper>
      </div>
      <CommonLoading open={isLoading || isError} />
      <AdvancedSearchModal
        open={isOpenAdvancedSearch}
        setIsOpen={setIsOpenAdvancedSearch}
        cycle={cycle}
        setCycle={setCycle}
        currentDate={currentDate}
        setDateQuery={setDateQuery}
      />
      <CommonErrorModal
        open={isOpenErrorModal}
        setIsOpen={setIsOpenErrorModal}
        message={errorMessage}
      />
    </>
  );
};

export default Statistics;
