import onRowAddClickHandler from './onRowAddClickHandler';
import onRowCancelEditClickHandler from './onRowCancelEditClickHandler';

const handleCellKeyDown = ({ gridParam, onAddClicked }) => {
  const keyEvent = gridParam?.event;

  if (keyEvent?.code === 'Escape') onRowCancelEditClickHandler({ gridParam });
  if (keyEvent?.code === 'Enter' && keyEvent?.ctrlKey) {
    if (gridParam?.rowPinned === 'top') {
      onRowAddClickHandler({ gridParam, onAddClicked });
    } else {
      gridParam?.api?.stopEditing(false);
    }
    /** Focus out */
    gridParam?.event?.target?.blur();
  }
};

export default handleCellKeyDown;
