import { createSlice } from '@reduxjs/toolkit';
import { ACCT_SLICE_KEY } from '../../Constants/Acct';

export const acctInitialState = {
  listFilter: {},
};

const acctSlice = createSlice({
  name: ACCT_SLICE_KEY,
  initialState: acctInitialState,
  reducers: {
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.filterName]: {
            ...state.listFilter?.[action.payload.filterName],
            [action.payload.location]: action.payload.dateValue,
          },
        },
      }),
    },
  },
});

export const { updateListDateFilter } = acctSlice.actions;

export default acctSlice.reducer;
