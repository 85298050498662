import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getBookingDetail from '../../../../services/Dispatch/Export/Booking/get/getBookingDetail';
import dispatchKeys from '../../keys';

const useGetBookingDetail = ({ bookingSeq }) => {
  const [data, setData] = useState({});

  const response = useQuery({
    queryFn: () => getBookingDetail({ bookingSeq }),
    queryKey: dispatchKeys?.bookingDetail({ bookingSeq }),
    refetchOnWindowFocus: false,
    enabled: !!bookingSeq,
  });

  useEffect(() => {
    if (response?.data?.data) {
      setData(prev => response?.data?.data);
    }
  }, [response?.data]);

  const { history, ...bookingDetail } = data;
  return {
    ...response,
    bookingDetail,
    bookingHistory: history,
  };
};

export default useGetBookingDetail;
