import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import DocumentTable from '../../../components/BCDetail/Document/DocumentTable';
import DocDeleteConfirmModal from '../../../components/BCDetail/Document/DocDeleteConfirmModal';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useGetDocumentList from '../../../hooks/BCDetail/Document/useGetDocumentList';
import usePatchDocumentList from '../../../hooks/BCDetail/Document/usePatchDocumentList';

const Document = ({ isEditable }) => {
  const woNo = document.URL?.split('/')?.pop();

  const [delType, setDelType] = useState(false);
  const [onOkClickHandler, setOnOkClickHandler] = useState(null);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const documentController = useForm();

  const modalProps = {
    setDelType,
    setOnOkClickHandler,
    setMessageModalOpen,
    setMessageContent,
  };

  const { documentList, docListRefech, isLoading, isError } =
    useGetDocumentList({
      woNo,
      ...modalProps,
    });

  const { mutate: documentMutator } = usePatchDocumentList({
    documentController,
    delType,
    docListRefech,
    ...modalProps,
  });

  const submitProps = {
    woNo,
    documentList,
    documentMutator,
  };

  return (
    <>
      {isLoading && <CommonLoading open={isLoading} />}
      {!isLoading && !isError && (
        <div className="bg-[#fff] px-[70px] py-[55px] w-[1920px]">
          <DocumentTable
            documentController={documentController}
            isEditable={isEditable}
            {...modalProps}
            {...submitProps}
          />
        </div>
      )}
      {delType && (
        <DocDeleteConfirmModal
          delType={delType}
          setDelType={setDelType}
          {...submitProps}
        />
      )}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
          onOkClickHandler={onOkClickHandler}
        />
      )}
    </>
  );
};

export default Document;
