import { createSlice } from '@reduxjs/toolkit';
import { DISPATCH_COMMON_SLICE_KEY } from '../../Constants/Dispatch/Common';
import convertDispatchType from '../../../utils/Dispatch/Common/Generator/convertDispatchType';

export const dispatchInitialState = {
  loading: false,
  error: null,
  dispatchType: null,
  dispatchStatus: null,
  buttonCount: {
    imp: null,
    exp: null,
    van: null,
  },
  buttonFilter: {
    filterType: null,
    filterValue: null,
  },
  listFilter: {
    impuna: {
      eta: {
        from: null,
        to: null,
      },
      lfd: {
        from: null,
        to: null,
      },
      pullout_sche_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
    },
    impava: {
      lfd: {
        from: null,
        to: null,
      },
      pullout_sche_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
    },
    impdilv: {
      rfd: {
        from: null,
        to: null,
      },
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
    },
    imprtn: {
      rfd: {
        from: null,
        to: null,
      },
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_actual_in_date: {
        from: null,
        to: null,
      },
      empty_cfm: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
      return_sche_date: {
        from: null,
        to: null,
      },
    },
    expuna: {
      st: {
        from: null,
        to: null,
      },
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_actual_in_date: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
    },
    expava: {
      st: {
        from: null,
        to: null,
      },
      rfd: {
        from: null,
        to: null,
      },
      pullout_sche_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
    },
    expdilv: {
      st: {
        from: null,
        to: null,
      },
      rfd: {
        from: null,
        to: null,
      },
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
      load_cfm: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
    },
    exprtn: {
      st: {
        from: null,
        to: null,
      },
      rfd: {
        from: null,
        to: null,
      },
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_actual_in_date: {
        from: null,
        to: null,
      },
      load_cfm: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
      return_sche_date: {
        from: null,
        to: null,
      },
    },
    vanava: {
      eta: {
        from: null,
        to: null,
      },
      pullout_sche_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
    },
    vandilv: {
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_sche_date: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
    },
    vanrtn: {
      pullout_actual_in_date: {
        from: null,
        to: null,
      },
      delivery_actual_in_date: {
        from: null,
        to: null,
      },
      pickup_sche_date: {
        from: null,
        to: null,
      },
      return_sche_date: {
        from: null,
        to: null,
      },
    },
  },
  dispatchOriginList: [],
  dispatchManipulatedList: [],
  dispatchEditedList: [],
  bookingList: [],
  gridAPI: null,
};

const dispatchSlice = createSlice({
  name: DISPATCH_COMMON_SLICE_KEY,
  initialState: dispatchInitialState,
  reducers: {
    // Update or Set dispatch type, status
    // i.e) dispatchType : IMP / EXP / VAN
    //      dispatchStatus : BKG / UNA / AVA / DELI / RTN
    setDispatchTypeStatus: {
      reducer: (state, action) => ({
        ...state,
        dispatchType: action.payload.dispatchType,
        dispatchStatus: action.payload.dispatchStatus,
      }),
    },
    setButtonCount: {
      reducer: (state, action) => ({
        ...state,
        buttonCount: {
          ...state.buttonCount,
          [convertDispatchType(action.payload.dispatchType)]:
            action.payload.buttonCountValue,
        },
      }),
    },
    setDispatchOriginList: {
      // Set origin list.
      reducer: (state, action) => ({
        ...state,
        dispatchOriginList: action.payload.originList,
      }),
    },
    setDispatchManipulatedList: {
      // Set manipulated list.
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: action.payload.manipulatedList,
        dispatchEditedList: action.payload.manipulatedList,
      }),
    },
    setButtonFilter: {
      reducer: (state, action) => ({
        ...state,
        buttonFilter: {
          filterType: action.payload.buttonFilterType,
          filterValue: action.payload.buttonFilterValue,
        },
      }),
    },
    updateDispatchManipulatedList: {
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: state.dispatchManipulatedList.map(element => {
          if (element?.wo_no === action.payload.wo_no) {
            return {
              ...element,
              [action.payload.field]: action.payload.value,
            };
          }
          return element;
        }),
      }),
    },
    updateDispatchBookingEditedList: {
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: state.dispatchManipulatedList.map(element => {
          if (element?.bk_seq === action.payload.bk_seq) {
            return {
              ...element,
              [action.payload.field]: action.payload.value,
            };
          }
          return element;
        }),
      }),
    },
    updateDispatchBookingManipulatedList: {
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: state.dispatchManipulatedList.map(element => {
          if (element?.bk_seq === action.payload.bk_seq) {
            return {
              ...element,
              [action.payload.field]: action.payload.value,
            };
          }
          return element;
        }),
      }),
    },
    deleteDispatchManipulatedList: {
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: state.dispatchManipulatedList.filter(
          element => element && element?.bk_seq !== action.payload.bkSeq,
        ),
      }),
    },
    updateDispatchEditedList: {
      reducer: (state, action) => ({
        ...state,
        dispatchEditedList: state.dispatchEditedList.map(element => {
          if (element?.wo_no === action.payload.wo_no) {
            return {
              ...element,
              [action.payload.field]: action.payload.value,
            };
          }
          return element;
        }),
      }),
    },
    updateListDateFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.pageCategory]: {
            ...state.listFilter?.[action.payload.pageCategory],
            [action.payload.filterItem]: {
              ...state.listFilter?.[action.payload.pageCategory]?.[
                action.payload.filterItem
              ],
              [action.payload.location]: action.payload.dateValue,
            },
          },
        },
      }),
    },
    updateListTimeFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...state.listFilter,
          [action.payload.pageCategory]: {
            ...state.listFilter?.[action.payload.pageCategory],
            [action.payload.filterItem]: action.payload.timeValue,
          },
        },
      }),
    },
    resetListDateTimeFilter: {
      reducer: (state, action) => ({
        ...state,
        listFilter: {
          ...dispatchInitialState.listFilter,
        },
      }),
    },
    updateDispatchCompleteList: {
      reducer: (state, action) => ({
        ...state,
        dispatchManipulatedList: state.dispatchManipulatedList.map(
          element => element?.wo_no !== action.payload.woNo && element,
        ),
      }),
    },
    setGridAPI: {
      reducer: (state, action) => ({
        ...state,
        gridAPI: action.payload.gridAPI,
      }),
    },
    setBookingList: {
      reducer: (state, action) => ({
        ...state,
        bookingList: action.payload.bookingList,
      }),
    },
  },
});

export const {
  setDispatchTypeStatus,
  setButtonCount,
  setDispatchOriginList,
  setDispatchManipulatedList,
  setButtonFilter,
  updateDispatchManipulatedList,
  updateDispatchBookingEditedList,
  deleteDispatchManipulatedList,
  updateDispatchEditedList,
  updateListDateFilter,
  updateListTimeFilter,
  resetListDateTimeFilter,
  updateDispatchCompleteList,
  setGridAPI,
  setBookingList,
  updateDispatchBookingManipulatedList,
} = dispatchSlice.actions;
export default dispatchSlice.reducer;
