import { useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';

import getCompanyList from '../../../services/Dispatch/Common/get/getCompanyList';
import createCompanyDropdown from '../../../utils/Dispatch/Common/Generator/createCompanyDropdown';
import dispatchKeys from '../keys';

/**
 * @description
 * Get company select option
 * @returns {object}
 * Bill to option, other company select option, query result
 */
const useGetCompanyOption = () => {
  // Url Params
  const BILL_TO_URL_PARAMS = { category: 1, credit: false };
  const OTHER_URL_PARAMS = { category: 12 };

  // Set state for company option
  const [billTo, setBillTo] = useState([]);
  const [loc, setLoc] = useState([]);

  const query = useQueries({
    queries: [
      // For Bill To
      {
        queryKey: dispatchKeys.companyBillto(),
        queryFn: () => getCompanyList({ urlParams: BILL_TO_URL_PARAMS }),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
      // For Pull Out, Delivery, Return Location
      {
        queryKey: dispatchKeys.companyLocation(),
        queryFn: () => getCompanyList({ urlParams: OTHER_URL_PARAMS }),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
    ],
  });

  // Set Bill to data
  useEffect(() => {
    if (query?.[0]?.data?.data) {
      const billToData = query?.[0]?.data?.data;
      const procBillToData = createCompanyDropdown({ companyData: billToData });
      setBillTo(procBillToData);
    }
  }, [query?.[0]?.data]);

  // Set Other data
  useEffect(() => {
    if (query?.[1]?.data?.data) {
      const otherData = query?.[1]?.data?.data;
      const procOtherData = createCompanyDropdown({ companyData: otherData });
      setLoc(procOtherData);
    }
  }, [query?.[1]?.data]);

  return { billTo, loc, query };
};

export default useGetCompanyOption;
