const checkSession = () => {
  const userID = localStorage.getItem('userId');

  if (userID) {
    return true;
  }
  return false;
};

export default checkSession;
