import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import useGetUninvoicedList from '../../../hooks/Acct/APARUninvoiced/Get/useGetUninvoicedList';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import moveBCDetailPage from '../../../utils/Acct/Common/Handler/moveBCDetailPage';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import createUnInvoicedColumnDefs from '../../../utils/Acct/APARUninvoiced/Generator/createUnInvoicedColumnDefs';
import useControlLoading from '../../../hooks/Acct/Common/Control/useControlLoading';
import AdvancedSearchModal from '../../../components/Acct/Common/AdvancedSearchModal';
import {
  ACCT_PAGE,
  ACCT_EXCEL_FILE_NAMES,
} from '../../../constants/Acct/Common/staticNames';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import useGetUserDivisionList from '../../../hooks/Acct/Common/Get/useGetUserDivisonList';
import useControlTotalInfo from '../../../hooks/Acct/Common/Control/useControlTotalInfo';
import TotalBottomBar from '../../../components/Acct/Common/TotalBottomBar';

const APARUninvoiced = () => {
  dayjs.extend(isoWeek);

  const gridRef = useRef(null);
  const divList = useGetUserDivisionList();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [queryObject, setQueryObject] = useState({
    type: 'ALL',
    dateType: 'create',
    period: 'lastWeek',
    from_date: dayjs()
      ?.subtract(1, 'week')
      ?.startOf('isoWeek')
      ?.format('YYYY-MM-DD'),
    to_date: dayjs()
      ?.subtract(1, 'week')
      ?.endOf('isoWeek')
      ?.format('YYYY-MM-DD'),
  });

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const errorModalOptions = {
    setIsSuccess,
    setErrorMessage,
    setIsErrorModalOpen,
  };

  const [columnDefs, setColumnsDefs] = useState([]);

  const { unInvoicedData, isLoading, isFetching } = useGetUninvoicedList({
    queryObject,
  });

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [isLoading],
    isFetchingList: [isFetching],
  });

  useEffect(() => {
    if (divList?.length > 0) {
      setColumnsDefs(createUnInvoicedColumnDefs({ divList }));
    }
  }, [divList]);

  const { totalRows, totalAmount, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: unInvoicedData,
    amountField: 'amt',
  });

  return (
    <>
      <div className="pr-[40px] flex flex-col h-full">
        <div className="w-full text-right mt-[25px] mb-[21px] gap-x-[12px] flex justify-end">
          <div>
            <CommonButton
              category="greySearch"
              border="0"
              backgroundColor="transparent"
              color="#666666"
              fontWeight="700"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              Search
            </CommonButton>
          </div>
          <div>
            <CommonButton
              type="button"
              onClick={() =>
                exportToExcel({
                  gridRef,
                  fileName: ACCT_EXCEL_FILE_NAMES?.[ACCT_PAGE?.unInvoiced],
                })
              }
              category="excel"
              fontWeight="500"
            >
              EXCEL
            </CommonButton>
          </div>
        </div>
        <div className="w-full flex-1 flex flex-col">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={unInvoicedData}
            onFilterChanged={gridParams => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={props => {
              setChangeFlag(prev => !prev);
            }}
            onCellClicked={props =>
              moveBCDetailPage({
                gridParam: props?.gridParam,
                columnID: 'wo_no',
              })
            }
          />
          <TotalBottomBar totalRows={totalRows} totalAmount={totalAmount} />
        </div>
      </div>
      {isOpenModal && (
        <AdvancedSearchModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          queryObject={queryObject}
          setQueryObject={setQueryObject}
          page={ACCT_PAGE?.unInvoiced}
          errorModalOptions={errorModalOptions}
        />
      )}
      <CommonLoading open={isLoadingOpen} />
      <CommonErrorModal
        open={isErrorModalOpen}
        message={errorMessage}
        setIsOpen={setIsErrorModalOpen}
        isSuccess={isSuccess}
      />
    </>
  );
};

export default APARUninvoiced;
