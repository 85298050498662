import { useQuery } from '@tanstack/react-query';
import getButtonCount from '../../../services/Dispatch/Common/get/getButtonCount';
import dispatchKeys from '../keys';
import getQueries from '../../../utils/Dispatch/Common/Generator/getQueries';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_EXPORT,
} from '../../../constants/Dispatch/Common/category';
import getYardQueries from '../../../utils/Dispatch/Common/Generator/getYardQueries';
import { DISPATCH_STATUS_VALUE } from '../../../constants/Dispatch/Common/statusValue';
import generateInitAdvancedForm from '../../../utils/Dispatch/Common/Generator/generateInitAdvancedForm';

const useGetButtonCount = ({
  division,
  category,
  queryObject,
  yardQueryObject,
  status,
  filterStatus,
}) => {
  const yardParamQueries = getYardQueries({
    yardQueryObject,
    status: filterStatus,
    isCounter: true,
  });

  const paramQueries = getQueries({
    queryObject:
      status === DISPATCH_PAGE_EXPORT_BOOKING
        ? generateInitAdvancedForm({
            category,
            woStatus: filterStatus,
          })
        : queryObject,
  });
  const paramType =
    category === DISPATCH_TYPE_BOOKING ? DISPATCH_TYPE_EXPORT : category;
  const result = useQuery({
    queryKey: dispatchKeys.countDivCategoryStatusQuery({
      division,
      category: paramType,
      status: DISPATCH_STATUS_VALUE?.[filterStatus],
      paramQueries,
      yardParamQueries,
    }),
    queryFn: () =>
      getButtonCount({
        paramDiv: division,
        paramType,
        status: DISPATCH_STATUS_VALUE?.[filterStatus],
        paramQueries,
        yardParamQueries,
      }),
    refetchOnWindowFocus: false,
    enabled:
      !!status &&
      !!category &&
      !!division &&
      !!filterStatus &&
      !!DISPATCH_STATUS_VALUE?.[filterStatus] &&
      filterStatus !== DISPATCH_PAGE_EXPORT_BOOKING &&
      !paramQueries?.status,
  });
  return result;
};

export default useGetButtonCount;
