import styled from 'styled-components';
import { STATISTICS_TABLE_TOTAL_TYPE_LIST } from '../../../constants/TmsReport/Statistics/statisticsValues';

const StyledDivisionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props =>
    STATISTICS_TABLE_TOTAL_TYPE_LIST.includes(props?.tableTitle)
      ? '153px'
      : '41px'};
  width: ${props =>
    STATISTICS_TABLE_TOTAL_TYPE_LIST.includes(props?.tableTitle)
      ? '153px'
      : '41px'};
  font-size: 11px;
  font-weight: 500;
  line-height: 12.89px;
  color: ${props => props?.divisionColor?.text};
  background-color: ${props => props?.divisionColor?.background};
`;

export default StyledDivisionTitle;
