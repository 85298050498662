import { GRID_MOD_COL_ID } from '../../../../constants/Common/DataGrid/setting';

/**
 * @description
 * Function of judge disable cell click handler.
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @returns
 * True if the cell is clickable, false otherwise.
 */
const judgeDisableCellClickHandler = ({
  gridParam,
  enableCellClickColumn,
  ...props
}) => {
  /** Get grid api from grid parameter */
  const getGridAPI = gridParam?.api;

  /** Are there any items in the grid that are being modified? */
  const isEditing = getGridAPI?.getEditingCells().length > 0;
  /** Does any of the items you are modifying match the current Column? */
  const isEditingCell = getGridAPI?.getEditingCells().some(cell => {
    return cell.rowIndex === gridParam?.rowIndex;
  });

  /** If clicked column id is in target, cell clickable. */
  if (
    enableCellClickColumn &&
    enableCellClickColumn?.includes(gridParam?.column?.colId)
  ) {
    return true;
  }
  /** If the column is a modification column, it is not clickable. */
  if (gridParam?.colDef?.colId === GRID_MOD_COL_ID) return false;
  /** If the target row pinned, it is not clickable */
  if (gridParam?.node?.rowPinned) return false;
  /** If the column is being modified, it is not clickable. */
  if (isEditing || isEditingCell) return false;
  /** Otherwise, clickable */
  return true;
};

export default judgeDisableCellClickHandler;
