import React, { useEffect, useState } from 'react';
import CommonErrorModal from '../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../components/CommonLoading/CommonLoading';
import SignInButton from '../../components/SignIn/SignInButton';
import {
  PATH_SIGN_IN_BACKGROUND,
  PATH_SIGN_IN_LOGO,
} from '../../constants/Common/path';
import { REDIRECT_URI } from '../../constants/SignIn/elements';
import useLogoutMutation from '../../hooks/SignIn/useLogoutMutation';
import useSignInMutation from '../../hooks/SignIn/useSignInMutation';
import StyledSignInBackgroundImageWrapper from '../../styles/SignIn/StyledSignInBackgroundImageWrapper/index';
import StyledSignInLoginButtonPartialWrapper from '../../styles/SignIn/StyledSignInLoginButtonParticialWrapper/index';
import StyledSignInWrapper from '../../styles/SignIn/StyledSignInWrapper/index';

const SignIn = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');

  const { mutate: loginMutation, isPending } = useSignInMutation({
    setIsMessageOpen,
    setMessage,
  });

  const { mutate: logoutMutation } = useLogoutMutation({
    setIsMessageOpen,
    setMessage,
  });

  useEffect(() => {
    const image = new Image();
    image.src = PATH_SIGN_IN_BACKGROUND;
    image.onload = () => {
      setIsImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    if (code) {
      const logoutAndLogin = async () => {
        await logoutMutation();
        loginMutation({
          authorization_code: code,
          redirect_uri: REDIRECT_URI,
        });
      };

      logoutAndLogin();
    }
  }, [code, loginMutation, logoutMutation]);

  return (
    <>
      <CommonLoading isOpen={isPending} />
      <CommonErrorModal
        open={isMessageOpen}
        setIsOpen={setIsMessageOpen}
        message={message}
      />
      <StyledSignInWrapper>
        {isImageLoaded && (
          <StyledSignInBackgroundImageWrapper>
            <StyledSignInLoginButtonPartialWrapper>
              <img
                width="112px"
                height="33px"
                src={PATH_SIGN_IN_LOGO}
                alt="logo"
              />
              <div data-testid="signInButton">
                <SignInButton setCode={setCode} />
              </div>
            </StyledSignInLoginButtonPartialWrapper>
          </StyledSignInBackgroundImageWrapper>
        )}
      </StyledSignInWrapper>
    </>
  );
};

export default SignIn;
