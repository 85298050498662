import styled from 'styled-components';

const StyledWOView = styled.div`
  display: flex;
  width: 225px;
  height: fit-content;
  background-color: ${props => props?.backgroundColor || 'white'};
`;

export default StyledWOView;
