/** Grid row height */
export const GRID_ROW_HEIGHT = 25;
export const GRID_PINNED_ROW_HEIGHT = 35;
export const GRID_FLOATING_FILTER_HEIGHT = 35;

/** Grid cell width */
export const GRID_ROW_CHECKBOX_WIDTH = 35;
export const GRID_ROW_FOLD_WIDTH = 35;
export const GRID_MOD_CELL_WIDTH = 210;

/** Grid mod type */
export const GRID_MOD_EDIT = 'editable';
export const GRID_MOD_DELETE = 'deletable';
export const GRID_MOD_BOTH = 'bothModifiable';

/** Modification column ID */
export const GRID_MOD_COL_ID = 'modificationCell';
