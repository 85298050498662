const checkPermission = ({ permission }) => {
  const userPermission = localStorage?.getItem('permission')?.split(',');

  /** No permission */
  if (!userPermission || userPermission?.length < 1) return false;

  /** List type permission */
  if (
    permission &&
    typeof permission === 'object' &&
    Array.isArray(permission)
  ) {
    return userPermission?.some(perm => permission.includes(perm));
  }

  /** String type permission */
  if (permission && typeof permission === 'string') {
    return userPermission?.includes(permission);
  }

  return true;
};

export default checkPermission;
