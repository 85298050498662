import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getUser from '../../services/SignIn/Get/userInfo';
import signInKeys from './keys';
import { setAllDiv } from '../../redux/features/Common';

const useGetUserInfo = ({ setEssentialLoading }) => {
  const dispatch = useDispatch();
  /** userID */
  const userID = localStorage?.getItem('userId');

  const query = useQuery({
    queryKey: signInKeys.userInfo({ userId: userID }),
    queryFn: () => getUser(),
    enabled: !!userID,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setEssentialLoading(prev => true);
      const result = query.data.data;
      localStorage.setItem('permission', result.permissions);
      // localStorage.setItem('firstPage', result.first_page);
      localStorage.setItem('firstPage', 'order/impreg');
      localStorage.setItem('allDiv', result.all_div);
      localStorage.setItem('defaultDiv', result.default_div);
      dispatch(setAllDiv(result.all_div));
      setEssentialLoading(prev => false);
    }
  }, [query?.data]);

  return { ...query };
};

export default useGetUserInfo;
