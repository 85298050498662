const convertFilterValue = ({ value, inputType }) => {
  const inputValue = value;

  /** Date type filter */
  if (inputType === 'date') {
    const dateValue = inputValue?.replace(/\D/g, '');
    if (dateValue?.length > 4)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(
        2,
        4,
      )}/${dateValue.slice(4)}`;
    if (dateValue?.length > 2)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(2)}`;
    return dateValue;
  }

  /** Time type filter */
  if (inputType === 'time') {
    const dateValue = inputValue?.replace(/\D/g, '');
    if (dateValue?.length > 4)
      return `${dateValue.slice(0, 2)}:${dateValue.slice(
        2,
        4,
      )}:${dateValue.slice(4)}`;
    if (dateValue?.length > 2)
      return `${dateValue.slice(0, 2)}:${dateValue.slice(2)}`;
    return dateValue;
  }

  /** Datetime type filter */
  if (inputType === 'datetime') {
    const dateValue = inputValue?.replace(/\D/g, '');
    if (dateValue?.length >= 11)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(
        2,
        4,
      )}/${dateValue.slice(4, 6)} ${dateValue.slice(6, 8)}:${dateValue.slice(
        8,
        10,
      )}:${dateValue.slice(10)}`;
    if (dateValue?.length >= 9)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(
        2,
        4,
      )}/${dateValue.slice(4, 6)} ${dateValue.slice(6, 8)}:${dateValue.slice(
        8,
      )}`;
    if (dateValue?.length >= 7)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(
        2,
        4,
      )}/${dateValue.slice(4, 6)} ${dateValue.slice(6)}`;
    if (dateValue?.length > 4)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(
        2,
        4,
      )}/${dateValue.slice(4)}`;
    if (dateValue?.length > 2)
      return `${dateValue.slice(0, 2)}/${dateValue.slice(2)}`;
    return dateValue;
  }

  /** Normal text filter */
  return inputValue;
};

export default convertFilterValue;
