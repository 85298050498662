import React from 'react';
import StyledCommonErrorPageWrapper from '../../styles/Common/CommonErrorPage/StyledCommonErrorPageWrapper';
import StyledCommonErrorPageContentsWrapper from '../../styles/Common/CommonErrorPage/StyledCommonErrorPageContentsWrapper';

const PermissionDenied = () => {
  return (
    <StyledCommonErrorPageWrapper>
      <StyledCommonErrorPageContentsWrapper>
        <div className="w-[200px] h-[200px] flex justify-center items-center">
          <img src="/assets/images/common_icon/lock.svg" alt="Lock" />
        </div>
        <div className="h-fit text-[35px] text-[#00235D] font-semibold pt-[36px]">
          YOU NEED ACCESS
        </div>
        <div className="text-[20px] text-[#00235D] flex justify-center items-center gap-[5px] flex-col pt-[30px]">
          <p>You don’t have permission to view this page.</p>
          <p>Ask for access to admin ASAP, or switch an account with access.</p>
        </div>
      </StyledCommonErrorPageContentsWrapper>
    </StyledCommonErrorPageWrapper>
  );
};

export default PermissionDenied;
