import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  NULL_PARAMETER_VALUE,
  SAVE,
} from '../../../../constants/Acct/Common/staticNames';
import { GRID_MOD_EDIT } from '../../../../constants/Common/DataGrid/setting';
import useGetWOChassisFromOptions from '../../../../hooks/Acct/ChassisReconcile/Get/useGetWOChassisFromOptions';
import useGetWOChassisToOptions from '../../../../hooks/Acct/ChassisReconcile/Get/useGetWOChassisToOptions';
import usePatchStatus from '../../../../hooks/Acct/ChassisReconcile/Patch/usePatchStatus';
import usePostInvoiceAP from '../../../../hooks/Acct/ChassisReconcile/Post/usePostInvoiceAP';
import useGetUserDivisionList from '../../../../hooks/Acct/Common/Get/useGetUserDivisonList';
import createChassisReconcileColumnDefs from '../../../../utils/Acct/ChassisReconcile/Generator/createChassisReconcileColumnDefs';
import handleCellClick from '../../../../utils/Acct/ChassisReconcile/Handler/handleCellClick';
import handleSaveClick from '../../../../utils/Acct/ChassisReconcile/Handler/handleSaveClick';
import generateInvoiceDateRange from '../../../../utils/Acct/Common/Generator/generateInvoiceDateRange';
import changeDataGridEditSelectOption from '../../../../utils/Acct/Common/Handler/changeDataGridEditSelectOption';
import { handleDataGridWOOptionChange } from '../../../../utils/Acct/Common/Handler/handleWOOptionChange';
import CommonDataGrid from '../../../CommonDataGrid';
import useControlTotalInfo from '../../../../hooks/Acct/Common/Control/useControlTotalInfo';
import TotalBottomBar from '../../Common/TotalBottomBar';
import usePatchChassisInvoice from '../../../../hooks/Acct/ChassisReconcile/Patch/usePatchChassisInvoice';

const DataGridArea = ({
  chassisReconcileList,
  productStatus,
  queryObject,
  gridRef,
  setErrorModalOptions,
}) => {
  const { handleSubmit } = useFormContext();

  const divList = useGetUserDivisionList();
  const originPoolList = useSelector(state => state?.common?.pool);

  const [poolList, setPoolOptionList] = useState([]);

  useEffect(() => {
    const tempPoolList = originPoolList?.map(pool => ({
      label: pool?.pool,
      value: pool?.pool,
    }));

    setPoolOptionList(tempPoolList);
  }, [originPoolList]);

  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const [woFromQueryObject, setWOFromQueryObject] = useState({});

  const [woToQueryObject, setWOToQueryObject] = useState({});

  const [isStartEdit, setIsStartEdit] = useState(false);

  const { woFromOptionList, woFromDateObjectList } = useGetWOChassisFromOptions(
    { queryObject: woFromQueryObject },
  );

  const { woToOptionList, woToDateObjectList } = useGetWOChassisToOptions({
    queryObject: woToQueryObject,
  });

  const { mutate: invoiceAPMutator } = usePostInvoiceAP({
    gridRef,
    setErrorModalOptions,
  });

  const { mutate: statusPatchMutator } = usePatchStatus({
    gridRef,
    queryObject,
    setErrorModalOptions,
  });

  const { mutate: chassisInvoicePatchMutator } = usePatchChassisInvoice({
    gridRef,
    setErrorModalOptions,
    queryObject,
  });

  const handleClickInvoice = ({ rowData }) => {
    if (productStatus === SAVE) {
      handleSubmit(data => {
        const postData = {
          seq: rowData?.seq,
          product_code: data?.product_code?.value,
        };
        invoiceAPMutator({ postData });
      })();
    } else {
      setIsSuccess(false);
      setErrorModalOpen(true);
      setErrorMessage('Please check the service code.');
    }
  };

  const [dataGridColumnDef, setDataGridColumnDef] = useState([]);

  useEffect(() => {
    if (divList?.length > 0 && poolList?.length > 0) {
      setDataGridColumnDef(
        createChassisReconcileColumnDefs({
          gridRef,
          setWOToQueryObject,
          setWOFromQueryObject,
          woToDateObjectList,
          woFromDateObjectList,
          handleClickInvoice,
          poolList,
          divList,
        }),
      );
    }
  }, [divList, poolList]);

  useEffect(() => {
    setDataGridColumnDef(
      createChassisReconcileColumnDefs({
        gridRef,
        setWOToQueryObject,
        setWOFromQueryObject,
        woToDateObjectList,
        woFromDateObjectList,
        handleClickInvoice,
        poolList,
        divList,
      }),
    );
  }, [woFromDateObjectList, woToDateObjectList, productStatus]);

  useEffect(() => {
    if (isStartEdit) {
      changeDataGridEditSelectOption({
        gridRef,
        editColName: 'wo_to',
        changeOptionList: woToOptionList,
      });

      changeDataGridEditSelectOption({
        gridRef,
        editColName: 'wo_from',
        changeOptionList: woFromOptionList,
      });

      setIsStartEdit(prev => false);
    }
  }, [isStartEdit]);

  useEffect(() => {
    changeDataGridEditSelectOption({
      gridRef,
      editColName: 'wo_to',
      changeOptionList: woToOptionList,
    });
    handleDataGridWOOptionChange({
      gridRef,
      woFieldName: 'wo_to',
      woOptionList: woToOptionList,
      pairFieldList: ['wo_to_date'],
    });
  }, [woToOptionList]);

  useEffect(() => {
    changeDataGridEditSelectOption({
      gridRef,
      editColName: 'wo_from',
      changeOptionList: woFromOptionList,
    });
    handleDataGridWOOptionChange({
      gridRef,
      woFieldName: 'wo_from',
      woOptionList: woFromOptionList,
      pairFieldList: ['div', 'wo_from_date'],
    });
  }, [woFromOptionList]);

  const { totalRows, totalAmount, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: chassisReconcileList,
    amountField: 'inv_amt',
  });

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        columnDefs={dataGridColumnDef}
        data={chassisReconcileList}
        modType={GRID_MOD_EDIT}
        onSaveClicked={props =>
          handleSaveClick({
            props,
            chassisInvoicePatchMutator,
            statusPatchMutator,
            setErrorModalOptions,
          })
        }
        onCellClicked={handleCellClick}
        onFilterChanged={gridParams => {
          setChangeFlag(prev => !prev);
        }}
        onSelectionChanged={gridParams => {
          setChangeFlag(prev => !prev);
        }}
        onRowEditingStart={({ gridParam }) => {
          const invFromDate =
            gridParam?.data?.inv_from &&
            dayjs(gridParam?.data?.inv_from)?.isValid()
              ? dayjs(gridParam?.data?.inv_from)?.format('YYYY-MM-DD')
              : '';
          const invToDate =
            gridParam?.data?.inv_to && dayjs(gridParam?.data?.inv_to)?.isValid()
              ? dayjs(gridParam?.data?.inv_to)?.format('YYYY-MM-DD')
              : '';
          setWOFromQueryObject(prev => ({
            ch_no: gridParam?.data?.ch_no || NULL_PARAMETER_VALUE,
            cont_no: gridParam?.data?.cont_from || NULL_PARAMETER_VALUE,
            ...generateInvoiceDateRange(invToDate),
          }));
          setWOToQueryObject(prev => ({
            ch_no: gridParam?.data?.ch_no || NULL_PARAMETER_VALUE,
            cont_no: gridParam?.data?.cont_to || NULL_PARAMETER_VALUE,
            ...generateInvoiceDateRange(invFromDate),
          }));
          setIsStartEdit(prev => true);
        }}
      />
      <TotalBottomBar totalRows={totalRows} totalAmount={totalAmount} />
    </>
  );
};

export default DataGridArea;
