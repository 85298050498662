import { useMutation, useQueryClient } from '@tanstack/react-query';
import postBookingData from '../../../services/Dispatch/Common/post/postBookingData';
import dispatchKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostBookingForm = ({
  setMessageModalMessage,
  setIsMessageModalOpen,
  addBookingController,
  setIsTabModalOpen,
  setTabIndexOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: postBookingData,
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({
        queryKey: dispatchKeys?.bookingCount(),
      });
      addToast({ message: SUCCESS_SAVE });
      addBookingController.reset(undefined, {
        keepDefaultValues: true,
      });
      setIsTabModalOpen(false);
      setTabIndexOpen(0);
    },
    onError: error => {
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });

  return mutator;
};

export default usePostBookingForm;
