import { createSlice } from '@reduxjs/toolkit';
import { BCDETAIL_BILLINGINFO_SLICE_KEY } from '../../Constants/BCDetail/key';

/**
 * Initial state of slice
 */
export const bcDetailBillingInfoInitialState = {
  loading: false,
  error: null,
};

/**
 * Redux slice
 */
const bcDetailBillingInfoSlice = createSlice({
  name: BCDETAIL_BILLINGINFO_SLICE_KEY,
  initialState: bcDetailBillingInfoInitialState,
  reducers: {},
});

/**
 * Export
 */
// export const {} = bcDetailBillingInfoSlice.actions;
export default bcDetailBillingInfoSlice.reducer;
