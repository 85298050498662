import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import ScheduleTableColumnGroup from '../ScheduleTableColumnGroup';
import ScheduleTypeOption from '../ScheduleTypeOption';
import ScheduleTypeSelect from '../ScheduleTypeSelect';
import { SCHE_TYPE } from '../../../../../../constants/Common/standard';
import usePatchConvertScheduleType from '../../../../../../hooks/BCDetail/DetailInfo/Schedule/usePatchConvertScheduleType';
import CommonLoading from '../../../../../CommonLoading/CommonLoading';
import CommonMessageModal from '../../../../../CommonMessageModal/CommonMessageModal';
import handleScheduleDropLiveButtonVisibility from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleScheduleDropLiveButtonVisibility';
import generatePickUpBaseScheduleKey from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Generator/generatePickUpBaseScheduleKey';
import handlePickUpBaseSchedule from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handlePickUpBaseSchedule';
import CommonErrorModal from '../../../../../CommonErrorModal/CommonErrorModal';

/**
 * @description
 * BC-Detail schedule header that display schedule type selector and schedule table column group
 * @param {object} detailInfoData
 * BC Detail - Detail info data
 * @returns {JSX} Component
 */
const ScheduleHeader = ({ detailInfoData, isEditable, ...props }) => {
  /** Detail Info Data */
  const {
    sche_type: scheType,
    job_set: jobSet,
    location_set: locationSet,
  } = detailInfoData;

  /** Watch P/U actual-in, actual-out */
  const [getPickUpBaseSchedule, setGetPickUpBaseSchedule] = useState(
    handlePickUpBaseSchedule({ jobSet, locationSet }),
  );
  const [pickUpBaseScheduleKey, setPickUpBaseScheduleKey] = useState(null);
  useEffect(() => {
    if (getPickUpBaseSchedule) {
      const getPickUpBaseScheduleKey = generatePickUpBaseScheduleKey({
        schedule: getPickUpBaseSchedule,
      });
      setPickUpBaseScheduleKey(getPickUpBaseScheduleKey);
    }
  }, [getPickUpBaseSchedule]);

  /** Get pick-up actual-in, actual-out date and time */
  const pickUpActualInDate = useWatch({
    name: `actual_in_date_${pickUpBaseScheduleKey}`,
  });
  const pickUpActualInTime = useWatch({
    name: `actual_in_time_${pickUpBaseScheduleKey}`,
  });
  const pickUpActualOutDate = useWatch({
    name: `actual_out_date_${pickUpBaseScheduleKey}`,
  });
  const pickUpActualOutTime = useWatch({
    name: `actual_out_time_${pickUpBaseScheduleKey}`,
  });

  /** Schedule Type option opener and type */
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(scheType || SCHE_TYPE?.LIVE);
  useEffect(() => {
    setType(scheType);
  }, [scheType]);

  /** Message modal opener */
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  /** Convert schedule type mutator */
  const {
    mutate: convertScheduleTypeMutator,
    isPending: convertScheduleTypePending,
  } = usePatchConvertScheduleType({
    setMessageModalOpen,
    setMessage,
  });

  /** Return component */
  return (
    <div className="relative flex items-center mt-[27px]">
      {/* Schedule Type Selector */}
      <ScheduleTypeSelect
        openSetter={{ open, setOpen }}
        typeSetter={{ type, setType }}
      />
      {handleScheduleDropLiveButtonVisibility({
        locationSet: detailInfoData?.location_set,
        jobSet: detailInfoData?.job_set,
        scheType,
        pickUpActualInDate,
        pickUpActualInTime,
        pickUpActualOutDate,
        pickUpActualOutTime,
        isEditable,
        completed: detailInfoData?.completed,
      }) ? (
        <ScheduleTypeOption
          openSetter={{ open, setOpen }}
          typeSetter={{ type, setType }}
          convertScheduleTypeMutator={convertScheduleTypeMutator}
        />
      ) : (
        <div className="mt-[26px] w-[65px] h-[16px]" />
      )}

      {/* Table Column Group */}
      <ScheduleTableColumnGroup />

      {/* Loading */}
      <CommonLoading open={convertScheduleTypePending} />

      {/* Message Modal */}
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          setIsOpen={setMessageModalOpen}
          message={message}
        />
      )}
    </div>
  );
};

export default ScheduleHeader;
