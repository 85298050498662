import styled from 'styled-components';

const StyledCommonErrorModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 400;
`;

export default StyledCommonErrorModalWrapper;
