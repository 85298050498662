import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'company',
  initialState: null,
  reducers: {
    saveCompany: (state, action) => action.payload,
  },
});

export const { saveCompany } = companySlice.actions;
export default companySlice.reducer;
