import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PERM_BC_DETAIL_PO_CHASSIS_VIEW_EDIT } from '../../../../../constants/Common/permission';
import {
  REGEX_DECIMAL_10_2,
  REGEX_DECIMAL_5_2,
  REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
} from '../../../../../constants/Common/regex';
import {
  COMMON_CONTAINER_LOAD_EMPTY_SELECT_OPTION,
  COMMON_CONTAINER_UNIT_SELECT_OPTION,
} from '../../../../../constants/Common/selectOption';
import useGetBasicInfo from '../../../../../hooks/BCDetail/DetailInfo/BasicInfo/useGetBasicInfo';
import useGetCompanyOption from '../../../../../hooks/Order/useGetCompanyOption';
import { createMandatorySelectOption } from '../../../../../utils/Common/createFloatingFilterSelectOption';
import createEmptySelect from '../../../../../utils/Common/Option/createEmptySelect';
import checkPermission from '../../../../../utils/Common/Router/Handler/checkPermission';
import generateOption from '../../../../../utils/Order/Generator/optionGenerator';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import Tooltip from '../Tooltip';
import changeBookingInfo from '../../../../../utils/BCDetail/DetailInfo/BasicInfo/Handler/changeBookingInfo';
import useControlToast from '../../../../../hooks/Common/Toast/useControlToast';
import validateEmail from '../../../../../utils/Common/Input/Validator/validateEmail';

const BasicInfoDataField = ({ ...props }) => {
  /** Get props data */
  const {
    bookingNumber,
    sizeWatcher,
    typeWatcher,
    sslWatcher,
    isEditable,
    isLoadingBookingNumber,
    scheduleFormController,
  } = props;
  /** Get WO type */
  const woType =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];
  const [bkList, setBkList] = useState([]);
  useEffect(() => {
    setBkList(prev => {
      const newBkList =
        bookingNumber?.map(item => {
          return {
            label: item?.bk_no,
            value: String(item?.seq),
          };
        }) || [];
      return [...newBkList];
    });
  }, [bookingNumber]);

  const { addToast } = useControlToast();

  const basicInfo = useGetBasicInfo();
  const companyOption = useGetCompanyOption({ pageCategory: 'bc-detail' });
  const { watch, setValue, getValues } = useFormContext();
  const companyList = useSelector(state => state?.company);
  const contSize = useSelector(state => state?.common?.size);
  const contType = useSelector(state => state?.common?.type);
  const ssl = useSelector(state => state?.common?.ssl);
  const pool = useSelector(state => state?.common?.pool);
  const chassisType = useSelector(state => state?.common?.chassisType);
  const pageTitle = location?.pathname?.split('/')[3];
  const [avaDate, setAvaDate] = useState('');

  const poolWatcher = useWatch({ name: `pool` });
  const chNoWatcher = useWatch({
    name: `ch_no`,
    defaultValue: basicInfo?.basicInfoData?.ch_no,
  });

  useEffect(() => {
    if (!basicInfo?.basicInfoLoading) {
      const dateValues = [
        watch('ava_dsc'),
        watch('ava_obl'),
        watch('ava_cus'),
      ].filter(Boolean);
      if (dateValues.length > 0) {
        let maxDate = dayjs(dateValues[0]);
        dateValues.forEach(dateStr => {
          const currentDate = dayjs(dateStr);
          if (currentDate.isAfter(maxDate)) {
            maxDate = currentDate;
          }
        });
        setAvaDate(maxDate.format('YYYY-MM-DD'));
      } else {
        setAvaDate('');
      }
    }
  }, [
    basicInfo?.basicInfoLoading,
    watch('ava_dsc'),
    watch('ava_obl'),
    watch('ava_cus'),
  ]);

  return (
    <div>
      <div className="w-[990px] flex justify-between items-center">
        <div className="w-[316px]">
          <CommonAutoComplete
            width="w-full"
            fieldSubject="BILL TO #"
            defaultValue={basicInfo?.basicInfoData?.billto}
            option={companyOption?.billTo}
            inputName="billto"
            required={Boolean(true)}
            label="BILL TO #"
            onChangeHandler={({
              e,
              onChange,
              selectProps,
              ...onChangeProps
            }) => {
              onChange(selectProps?.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            isLoading={
              companyOption?.query[0]?.isLoading ||
              companyOption?.query[1]?.isLoading
            }
          />
        </div>
        <div className="w-[316px]">
          {pageTitle === 'export' && (
            <div className="w-[316px]">
              <CommonAutoComplete
                required={Boolean(true)}
                label="BOOKING #"
                defaultValue={
                  basicInfo?.basicInfoData?.bk_no
                    ? {
                        label: basicInfo?.basicInfoData?.bk_no?.bk_no,
                        value: String(basicInfo?.basicInfoData?.bk_no?.seq),
                      }
                    : null
                }
                inputName="bk_no"
                option={bkList}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(onChangeProps?.selectProps);
                  if (onChangeProps?.selectProps) {
                    changeBookingInfo?.({
                      basicInfoData: basicInfo?.basicInfoData,
                      bookingNumber,
                      selectedValue: onChangeProps?.selectProps,
                      setValue,
                      scheduleFormController,
                    });
                  }
                  addToast({
                    message:
                      'BOOKING# will be changed; please check AR/AP for necessary updates.',
                    type: 'warning',
                  });
                }}
                disabled={
                  !sizeWatcher ||
                  !typeWatcher ||
                  !sslWatcher ||
                  sizeWatcher === '' ||
                  typeWatcher === '' ||
                  sslWatcher === '' ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
                isLoading={isLoadingBookingNumber}
              />
            </div>
          )}
          {(pageTitle === 'import' ||
            pageTitle === 'van' ||
            pageTitle === 'brokerage') && (
            <CommonTextInput
              required={Boolean(true)}
              label={pageTitle === 'brokerage' ? 'LOAD #' : 'MBL #'}
              width="w-full"
              defaultValue={basicInfo?.basicInfoData?.mbl_no}
              inputName="mbl_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          )}

          {pageTitle === 'bare-chassis' && (
            <div className="w-[316px]">
              <CommonTextInput
                required={Boolean(true)}
                label="REF #"
                defaultValue={basicInfo?.basicInfoData?.ref_no}
                inputName="ref_no"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e?.target?.value);
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          )}
        </div>
        {pageTitle === 'bare-chassis' ? (
          <div className="w-[316px]">
            <CommonTextInput
              required={Boolean(false)}
              label="DELIVERY #"
              defaultValue={basicInfo?.basicInfoData?.dilv_no}
              inputName="dilv_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
        ) : (
          <div className="w-[316px]">
            <CommonTextInput
              required={Boolean(false)}
              label={pageTitle === 'brokerage' ? 'BL #' : 'HBL #'}
              width="w-full"
              defaultValue={basicInfo?.basicInfoData?.hbl_no}
              inputName="hbl_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
        )}
      </div>
      {pageTitle !== 'bare-chassis' && (
        <div className="w-[990px] flex justify-between items-center">
          <div className="w-[316px]">
            <CommonTextInput
              required={Boolean(true)}
              label="REF #"
              width="w-full"
              defaultValue={basicInfo?.basicInfoData?.ref_no}
              inputName="ref_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
          <div className="w-[316px]">
            <CommonTextInput
              required={Boolean(false)}
              label="PO #"
              maxLength={50}
              width="w-full"
              defaultValue={basicInfo?.basicInfoData?.purchase_no}
              inputName="purchase_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
          <div className="w-[316px]">
            <CommonTextInput
              required={Boolean(false)}
              label="DELIVERY #"
              width="w-full"
              defaultValue={basicInfo?.basicInfoData?.dilv_no}
              inputName="dilv_no"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
        </div>
      )}
      {pageTitle !== 'bare-chassis' && (
        <div
          className={`flex w-[990px]  items-center ${
            pageTitle === 'van' || pageTitle === 'brokerage'
              ? 'justify-start gap-x-[20px]'
              : 'justify-between'
          }`}
        >
          {pageTitle === 'van' || pageTitle === 'brokerage' ? (
            <div
              className={`${
                pageTitle === 'brokerage' ? `w-[124px]` : `w-[316px]`
              }`}
            >
              <CommonTextInput
                label={pageTitle === 'brokerage' ? 'EQUIPMENT' : 'TRAILER'}
                required={Boolean(false)}
                maxLength={12}
                defaultValue={basicInfo?.basicInfoData?.trailer_no}
                inputName="trailer_no"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e?.target?.value);
                }}
                width="w-full"
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          ) : (
            <div className="w-[232px]">
              <CommonTextInput
                required={Boolean(false)}
                label="CONT #"
                defaultValue={basicInfo?.basicInfoData?.cont_no}
                inputName="cont_no"
                width="w-full"
                maxLength={11}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e?.target?.value);
                }}
                onBlurHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.value?.toUpperCase());
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          )}
          {pageTitle === 'brokerage' && (
            <div className="w-[124px]">
              <CommonSelect
                label="L/E"
                required={Boolean(false)}
                defaultValue={basicInfo?.basicInfoData?.empty_load_status}
                option={createEmptySelect({
                  selectOption: COMMON_CONTAINER_LOAD_EMPTY_SELECT_OPTION,
                  emptyValue: '',
                })}
                inputName="empty_load_status"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e.target.value);
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          )}
          <div
            className={`${
              pageTitle === 'brokerage' ? `w-[124px]` : `w-[148px]`
            }`}
          >
            <CommonSelect
              label="SIZE"
              required={Boolean(false)}
              defaultValue={basicInfo?.basicInfoData?.size}
              option={createMandatorySelectOption(
                contSize?.map(res => res?.size),
              )}
              inputName="size"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e.target.value);
                setValue('bk_no', null);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
          <div
            className={`${
              pageTitle === 'brokerage' ? `w-[124px]` : `w-[148px]`
            }`}
          >
            <CommonSelect
              label="TYPE"
              required={Boolean(false)}
              defaultValue={basicInfo?.basicInfoData?.type}
              option={createMandatorySelectOption(
                contType?.map(res => res?.type),
              )}
              inputName="type"
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e.target.value);
                setValue('bk_no', null);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
          {pageTitle === 'brokerage' && (
            <>
              <div className="relative w-[124px] group/chassis">
                <CommonTextInput
                  required={Boolean(false)}
                  label="CHASSIS #"
                  defaultValue={basicInfo?.basicInfoData?.ch_no}
                  inputName="ch_no"
                  maxLength={12}
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(e?.target?.value);
                  }}
                  onBlurHandler={({ e, onChange, ...handlerProps }) => {
                    onChange(e?.target?.value?.toUpperCase());
                    if (
                      e?.target?.value?.toUpperCase()?.startsWith('NGLT') &&
                      (!poolWatcher || poolWatcher === '')
                    )
                      setValue?.(`pool`, 'NGL');
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
                <div className="absolute z-10 top-11 group-hover/chassis:block hidden">
                  <Tooltip message={basicInfo?.basicInfoData?.ch_updated_by} />
                </div>
              </div>
              <div className="w-[124px]">
                <CommonSelect
                  label="POOL"
                  required={!!chNoWatcher}
                  defaultValue={basicInfo?.basicInfoData?.pool}
                  option={createEmptySelect({
                    selectOption: generateOption({
                      items: pool,
                      property: 'pool',
                    }),
                    emptyValue: '',
                  })}
                  inputName="pool"
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(e.target.value);
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
              <div className="w-[158px]">
                <CommonSelect
                  label="TYPE"
                  defaultValue={basicInfo?.basicInfoData?.ch_type}
                  option={generateOption({
                    items: chassisType,
                    property: 'type',
                  })}
                  inputName="ch_type"
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(e.target.value);
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
            </>
          )}
          {pageTitle !== 'van' && pageTitle !== 'brokerage' && (
            <div className="w-[148px]">
              <CommonSelect
                label="SSL"
                required={Boolean(false)}
                defaultValue={basicInfo?.basicInfoData?.ssl}
                option={createMandatorySelectOption(ssl?.map(res => res?.ssl))}
                inputName="ssl"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e.target.value);
                  setValue('bk_no', null);
                }}
                disabled={
                  woType === 'export' ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
              />
            </div>
          )}
          {!['van', 'brokerage'].includes(pageTitle) && (
            <div className="w-[232px]">
              <CommonTextInput
                required={Boolean(false)}
                label="VESSEL"
                maxLength={30}
                defaultValue={basicInfo?.basicInfoData?.vessel}
                inputName="vessel"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e?.target?.value);
                }}
                disabled={
                  woType === 'export' ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
              />
            </div>
          )}
        </div>
      )}
      {pageTitle !== 'van' && pageTitle !== 'brokerage' && (
        <div className="w-[990px] flex justify-between items-center">
          <div className="relative w-[124px] group/poChassis">
            <CommonTextInput
              required={Boolean(false)}
              label="P/OUT CHASSIS #"
              defaultValue={basicInfo?.basicInfoData?.po_ch_no}
              inputName="po_ch_no"
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
              onBlurHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value?.toUpperCase());
              }}
              disabled={
                !isEditable ||
                !!basicInfo?.basicInfoData?.completed ||
                (basicInfo?.basicInfoData?.po_ch_no
                  ? !checkPermission({
                      permission: [PERM_BC_DETAIL_PO_CHASSIS_VIEW_EDIT],
                    })
                  : true)
              }
            />
            <div className="absolute z-10 top-11 group-hover/poChassis:block hidden">
              <Tooltip message={basicInfo?.basicInfoData?.po_ch_updated_by} />
            </div>
          </div>
          <div className="w-[124px]">
            <CommonSelect
              label="P/OUT POOL"
              required={Boolean(false)}
              disabled={Boolean(true)}
              defaultValue={basicInfo?.basicInfoData?.po_pool}
              option={createEmptySelect({
                selectOption: generateOption({
                  items: pool,
                  property: 'pool',
                }),
                emptyValue: '',
              })}
              inputName="po_pool"
              onChangeHandler={({ e, onChange }) => {
                onChange(e.target.value);
              }}
            />
          </div>
          <div className="relative w-[124px] group/chassis">
            <CommonTextInput
              required={Boolean(false)}
              label="CHASSIS #"
              defaultValue={basicInfo?.basicInfoData?.ch_no}
              inputName="ch_no"
              maxLength={12}
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
              onBlurHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value?.toUpperCase());
                if (
                  e?.target?.value?.toUpperCase()?.startsWith('NGLT') &&
                  (!poolWatcher || poolWatcher === '')
                )
                  setValue?.(`pool`, 'NGL');
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
            <div className="absolute z-10 top-11 group-hover/chassis:block hidden">
              <Tooltip message={basicInfo?.basicInfoData?.ch_updated_by} />
            </div>
          </div>
          {pageTitle !== 'van' && pageTitle !== 'brokerage' && (
            <>
              <div className="w-[124px]">
                <CommonSelect
                  label="POOL"
                  required={!!chNoWatcher}
                  defaultValue={basicInfo?.basicInfoData?.pool}
                  option={createEmptySelect({
                    selectOption: generateOption({
                      items: pool,
                      property: 'pool',
                    }),
                    emptyValue: '',
                  })}
                  inputName="pool"
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(e.target.value);
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
              <div className="w-[158px]">
                <CommonSelect
                  label="TYPE"
                  defaultValue={basicInfo?.basicInfoData?.ch_type}
                  option={generateOption({
                    items: chassisType,
                    property: 'type',
                  })}
                  inputName="ch_type"
                  onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                    onChange(e.target.value);
                  }}
                  disabled={
                    !isEditable || !!basicInfo?.basicInfoData?.completed
                  }
                />
              </div>
            </>
          )}
          <div className="w-[232px]">
            <CommonTextInput
              required={Boolean(false)}
              label="TARE(lb)"
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
              defaultValue={basicInfo?.basicInfoData?.tare}
              inputName="tare"
              maxLength={8}
              onChangeHandler={({ e, onChange, regex, ...onChangeProps }) => {
                if (regex) {
                  if (e?.target?.value?.match(regex)) {
                    onChange(e?.target?.value);
                    return;
                  }
                  return;
                }
                onChange(e.target.value);
              }}
              disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            />
          </div>
        </div>
      )}

      <div className="w-[990px] flex justify-between items-center">
        <div className="w-[232px]">
          <CommonTextInput
            required={Boolean(false)}
            label="SEAL"
            defaultValue={basicInfo?.basicInfoData?.seal}
            inputName="seal"
            onChangeHandler={({ e, onChange, ...onChangeProps }) => {
              onChange(e?.target?.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
          />
        </div>
        <div className="w-[232px]">
          <CommonTextInput
            inputName="wgt"
            required={Boolean(false)}
            label="WGT"
            regex={REGEX_DECIMAL_10_2}
            defaultValue={basicInfo?.basicInfoData?.wgt}
            maxLength={13}
            onChangeHandler={({ e, onChange, regex, ...onChangeProps }) => {
              if (regex) {
                if (regex.test(e?.target?.value)) {
                  onChange(e?.target?.value);
                  return;
                }
                return;
              }
              onChange(e.target.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
          />
        </div>
        <div className="w-[232px]">
          <CommonTextInput
            inputName="qty"
            required={Boolean(false)}
            label="QTY"
            regex={REGEX_DECIMAL_5_2}
            defaultValue={basicInfo?.basicInfoData?.qty}
            maxLength={8}
            onChangeHandler={({ e, onChange, regex, ...onChangeProps }) => {
              if (regex) {
                if (regex.test(e?.target?.value)) {
                  onChange(e?.target?.value);
                  return;
                }
                return;
              }
              onChange(e.target.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
          />
        </div>
        <div className="w-[232px]">
          <CommonSelect
            label="UNIT"
            required={Boolean(false)}
            defaultValue={basicInfo?.basicInfoData?.unit}
            option={COMMON_CONTAINER_UNIT_SELECT_OPTION}
            inputName="unit"
            onChangeHandler={({ e, onChange, ...onChangeProps }) => {
              onChange(e?.target?.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
          />
        </div>
      </div>

      <div className="w-[990px] flex justify-between items-center">
        <div className="w-[232px]">
          <CommonTextInput
            required={Boolean(false)}
            label="COMMODITY"
            defaultValue={basicInfo?.basicInfoData?.commodity}
            inputName="commodity"
            onChangeHandler={({ e, onChange, ...onChangeProps }) => {
              onChange(e?.target?.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
          />
        </div>
        <div className="w-[737px]">
          <CommonTextInput
            required={Boolean(false)}
            label="SEND TO"
            placeholder="Email Address"
            defaultValue={basicInfo?.basicInfoData?.sendto?.join(', ')}
            inputName="sendto"
            maxLength={1000}
            onChangeHandler={({ e, onChange, ...onChangeProps }) => {
              onChange(e?.target?.value);
            }}
            disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
            validate={{
              custom: {
                ...validateEmail(),
              },
            }}
          />
        </div>
      </div>

      {pageTitle === 'import' && (
        <div className="flex items-center justify-between w-[990px]">
          <div className="w-[232px]">
            <CommonDate
              label="AVAILABILITY"
              inputName="ava_date"
              defaultValue={
                watch('cusCheckbox') &&
                watch('oblCheckbox') &&
                watch('dscCheckbox')
                  ? avaDate
                  : null
              }
              disabled={Boolean(true)}
              onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>

          <div className="w-[232px] flex items-center justify-between">
            <div className="w-[44px]">
              <CommonCheckbox
                inputName="dscCheckbox"
                label="DSC"
                width="w-full"
                borderVisible
                options={[
                  {
                    label: 'dscCheck',
                    defaultValue: !!basicInfo?.basicInfoData?.ava_dsc,
                    inputName: 'dscCheckbox',
                  },
                ]}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.checked);
                  if (e?.target?.checked) {
                    setValue(
                      'ava_dsc',
                      basicInfo?.basicInfoData?.ava_dsc || dayjs(),
                    );
                  } else {
                    setValue('ava_dsc', '');
                    setValue('ava_date', '');
                  }
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
            <div>
              <CommonDate
                inputName="ava_dsc"
                disabled={
                  !watch('dscCheckbox') ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
                defaultValue={basicInfo?.basicInfoData?.ava_dsc}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e);
                }}
              />
            </div>
          </div>
          <div className="w-[232px] flex items-center justify-between">
            <div className="w-[44px]">
              <CommonCheckbox
                inputName="oblCheckbox"
                label="OBL"
                width="w-full"
                borderVisible
                options={[
                  {
                    label: 'oblCheckbox',
                    defaultValue: !!basicInfo?.basicInfoData?.ava_obl,
                    inputName: 'oblCheckbox',
                  },
                ]}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.checked);
                  if (e?.target?.checked) {
                    setValue(
                      'ava_obl',
                      basicInfo?.basicInfoData?.ava_obl || dayjs(),
                    );
                  } else {
                    setValue('ava_obl', '');
                    setValue('ava_date', '');
                  }
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
            <div>
              <CommonDate
                inputName="ava_obl"
                disabled={
                  !watch('oblCheckbox') ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
                defaultValue={basicInfo?.basicInfoData?.ava_obl}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e);
                }}
              />
            </div>
          </div>
          <div className="w-[232px] flex items-center justify-between">
            <div className="w-[44px]">
              <CommonCheckbox
                inputName="cusCheckbox"
                label="CUS"
                width="w-full"
                borderVisible
                options={[
                  {
                    label: 'cusCheckbox',
                    defaultValue: !!basicInfo?.basicInfoData?.ava_cus,
                    inputName: 'cusCheckbox',
                  },
                ]}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.checked);
                  if (e?.target?.checked) {
                    setValue(
                      'ava_cus',
                      basicInfo?.basicInfoData?.ava_cus || dayjs(),
                    );
                  } else {
                    setValue('ava_cus', '');
                    setValue('ava_date', '');
                  }
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
            <div>
              <CommonDate
                inputName="ava_cus"
                disabled={
                  !watch('cusCheckbox') ||
                  !isEditable ||
                  !!basicInfo?.basicInfoData?.completed
                }
                defaultValue={basicInfo?.basicInfoData?.ava_cus}
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between w-[485px] ">
        <div className="w-[232px]">
          {pageTitle !== 'bare-chassis' ? (
            <div className="w-[232px]">
              <CommonCheckbox
                label="CHECK"
                width="w-full"
                borderVisible
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
                options={[
                  {
                    optionLabel: 'O/H',
                    defaultValue: basicInfo?.basicInfoData?.over_height,
                    inputName: 'over_height',
                  },
                  {
                    optionLabel: 'O/W',
                    defaultValue: basicInfo?.basicInfoData?.over_weight,
                    inputName: 'over_weight',
                  },
                  {
                    optionLabel: 'HAZ',
                    defaultValue: basicInfo?.basicInfoData?.hazmat,
                    inputName: 'hazmat',
                  },
                  {
                    optionLabel: 'HOT',
                    defaultValue: basicInfo?.basicInfoData?.hot,
                    inputName: 'hot',
                  },
                ]}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.checked);
                }}
              />
            </div>
          ) : (
            <div className="w-[232px]">
              <CommonCheckbox
                label="CHECK"
                width="w-full"
                borderVisible
                options={[
                  {
                    optionLabel: 'HOT',
                    defaultValue: true,
                    inputName: 'hot',
                  },
                ]}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  onChange(e?.target?.checked);
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          )}
        </div>
        <div>
          {!['brokerage', 'bare-chassis'].includes(pageTitle) && (
            <div className="w-[232px]">
              <CommonTextInput
                label="BOND-ENTRY #"
                defaultValue={basicInfo?.basicInfoData?.bond_entry}
                inputName="bond_entry"
                onChangeHandler={({ e, onChange, ...onChangeProps }) => {
                  onChange(e?.target?.value);
                }}
                disabled={!isEditable || !!basicInfo?.basicInfoData?.completed}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInfoDataField;
