import { Tabs } from '@mui/material';
import styled from 'styled-components';

const StyledNavTabs = styled(Tabs)`
  height: 50px;
  border-bottom: 1px solid #e5e7eb;
  max-width: 1820px;
  .MuiTabs-indicator {
    height: 3px;
    background: #001e5f !important;
  }
`;

export default StyledNavTabs;
