const handleUserColsApply = ({
  mutate,
  userID,
  appliedColumnList,
  status,
  buttonRef,
}) => {
  const patchData = {
    status,
    dsp_column: appliedColumnList,
  };

  mutate({ userID, patchData, buttonRef });
};

export default handleUserColsApply;
