import tmsReportAPI from '../../api';
import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';

const getStatisticsList = async ({ uriQueryString, ...props }) => {
  try {
    const response = await tmsReportAPI.get(`/statistics?${uriQueryString}`, {
      params: { ...props },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getStatisticsList;
