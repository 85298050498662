import React from 'react';
import StyledCustomerUtilityBar from '../../../styles/Customer/StyledCustomerUtilityBar';
import CommonButton from '../../CommonButton/CommonButton';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';

const CustomerUtilityBar = ({ ...props }) => {
  /** Get props data */
  const { gridRef, setAdvancedOpener } = props;
  /** Get list name from URL */
  const listName = window.location.pathname.split('/')?.pop();

  return (
    <StyledCustomerUtilityBar>
      <CommonButton
        category="greySearch"
        customStyle={{
          border: 'none',
          backgroundColor: 'unset',
          color: '#666',
        }}
        onClick={() => setAdvancedOpener(true)}
      >
        Filter Date
      </CommonButton>
      <CommonButton
        category="excel"
        onClick={() =>
          exportToExcel({
            gridRef,
            fileName: listName.replace(/%20/g, ' '),
          })
        }
      >
        EXCEL
      </CommonButton>
    </StyledCustomerUtilityBar>
  );
};

export default CustomerUtilityBar;
