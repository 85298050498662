export const COMMON_GET_SSL = 'COMMON/GET/SSL';
export const COMMON_GET_POOL = 'COMMON/GET/POOL';
export const COMMON_GET_SIZE = 'COMMON/GET/SIZE';
export const COMMON_GET_TYPE = 'COMMON/GET/TYPE';
export const COMMON_GET_DRIVER = 'COMMON/GET/DRIVER';
export const COMMON_GET_DIVISION = 'COMMON/GET/DIVISION';
export const COMMON_GET_CHASSIS_TYPE = 'COMMON/GET/CHASSIS_TYPE';

export const POOL = [
  'NGL',
  'AIM',
  'APL',
  'CS',
  'CSNA',
  'DCLI',
  'EG',
  'FLEXI',
  'GACP',
  'GCCP',
  'HD',
  'KLINE',
  'LABP',
  'MOL',
  'MTSN',
  'POP',
  'SEA',
  'TAXZ',
  'TNWA',
  'TPSP',
  'WCCP',
  'YM',
];

export const LIVE_DROP = ['LIVE', 'DROP'];
