import React, { useEffect } from 'react';
import {
  PATH_BUTTON_GRID_FOLD,
  PATH_BUTTON_GRID_UNFOLD,
} from '../../../../constants/Common/path';

const FoldCellRenderer = ({ ...props }) => {
  return (
    <div
      onClick={() => {
        props?.node?.setExpanded(!props?.node?.expanded);
      }}
      className="w-full h-full flex justify-center items-center cursor-pointer"
    >
      {props?.node?.expanded ? (
        <img src={PATH_BUTTON_GRID_FOLD} alt="gridFoldIcon" />
      ) : (
        <img src={PATH_BUTTON_GRID_UNFOLD} alt="gridUnfoldIcon" />
      )}
    </div>
  );
};

export default FoldCellRenderer;
