import FloatingFilterInput from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import cellRendererGenerator from '../../Common/Datagrid/Generator/cellRendererGenerator';
import generateFormattedDate from '../../Common/Generator/generateFormattedDate';
import handleGridCategory from '../Handler/handleGridCategory';
import {
  PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
  PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
} from '../../../constants/Common/path';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';
import FloatingFilterSelect from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CUSTOMER_REPORT_Y_N_FILTER } from '../../../constants/CustomerReport/option';

const createCustomerReportColDefs = [
  {
    headerName: 'CAT',
    field: 'category',
    width: 71,
    valueFormatter: params => {
      const getCategoryValue = params?.data?.category;
      return handleGridCategory({ data: getCategoryValue });
    },
    valueGetter: params => {
      const getCategoryValue = params?.data?.category;
      return handleGridCategory({ data: getCategoryValue });
    },
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'DATE',
    field: 'created_date',
    width: 62,
    comparator: dateComparator,
    valueGetter: params =>
      generateFormattedDate({ date: params.data.created_date }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'WO #',
    field: 'wo_no',
    editable: false,
    width: 100,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'BILL TO',
    field: 'billto',
    editable: false,
    width: 76,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'EQUIPMENT',
    field: 'cont_no',
    editable: false,
    width: 100,
    valueGetter: params => {
      return params.data?.cont_no || params.data?.trailer_no;
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'SSL',
    field: 'ssl',
    editable: false,
    width: 50,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'SZ',
    field: 'size',
    editable: false,
    width: 42,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'TYPE',
    field: 'type',
    editable: false,
    width: 57,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'CH #',
    field: 'ch_no',
    editable: false,
    width: 86,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'POOL',
    field: 'pool',
    editable: false,
    width: 52,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'BOND #',
    field: 'bond',
    editable: false,
    width: 62,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'REF #',
    field: 'ref_no',
    editable: false,
    width: 85,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'PO #',
    field: 'purchase_no',
    editable: false,
    width: 62,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'MBL #',
    field: 'mbl_no',
    editable: false,
    width: 118,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'WGT',
    field: 'wgt',
    editable: false,
    width: 60,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'right',
        },
      }),
  },
  {
    headerName: 'QTY',
    field: 'qty',
    editable: false,
    width: 46,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'right',
        },
      }),
  },
  {
    headerName: 'O/H',
    field: 'over_height',
    editable: false,
    width: 40,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'O/W',
    field: 'over_weight',
    editable: false,
    width: 40,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'HAZ',
    field: 'hazmat',
    editable: false,
    width: 40,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'HOT',
    field: 'hot',
    editable: false,
    width: 40,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'ETA',
    field: 'eta',
    width: 62,
    comparator: dateComparator,
    valueGetter: params =>
      generateFormattedDate({ date: params.data.created_date }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'BOOKING #',
    field: 'bk_no',
    editable: false,
    width: 105,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'S/T',
    field: 'st',
    width: 62,
    comparator: dateComparator,
    valueGetter: params => generateFormattedDate({ date: params.data.st }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'ERD',
    field: 'erd',
    width: 62,
    comparator: dateComparator,
    valueGetter: params => generateFormattedDate({ date: params.data.erd }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'C/O',
    field: 'cutoff',
    width: 62,
    comparator: dateComparator,
    valueGetter: params => generateFormattedDate({ date: params.data.cutoff }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'RFD',
    field: 'rfd',
    width: 62,
    comparator: dateComparator,
    valueGetter: params => generateFormattedDate({ date: params.data.rfd }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'LFD',
    field: 'lfd',
    width: 62,
    comparator: dateComparator,
    valueGetter: params => generateFormattedDate({ date: params.data.lfd }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'AVA DATE',
    field: 'ava_date',
    width: 76,
    comparator: dateComparator,
    valueGetter: params =>
      generateFormattedDate({ date: params.data.ava_date }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'PULL OUT',
    isColumnGroup: true,
    backgroundColor: '#CCD3E3',
    color: 'black',
    icon: null,
    field: 'po',
    children: [
      {
        headerName: 'LOC',
        field: 'po_location',
        editable: false,
        width: 76,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
      },
      {
        headerName: 'SCHED',
        field: 'po_sche_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.po_sche_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A IN',
        field: 'po_actual_in_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.po_actual_in_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A OUT',
        field: 'po_actual_out_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.po_actual_out_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'DRIVER',
        field: 'po_driver',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'DELIVERY',
    isColumnGroup: true,
    backgroundColor: '#B3BCD9',
    color: 'black',
    icon: null,
    field: 'delv',
    children: [
      {
        headerName: 'LOC',
        field: 'dilv_location',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
      },
      {
        headerName: 'SCHED',
        field: 'dilv_sche_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.dilv_sche_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A IN',
        field: 'dilv_actual_in_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.dilv_actual_in_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A OUT',
        field: 'dilv_actual_out_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.dilv_actual_out_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'DRIVER',
        field: 'dilv_driver',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'PICK UP',
    isColumnGroup: true,
    backgroundColor: '#919FC1',
    color: 'black',
    icon: null,
    field: 'pu',
    children: [
      {
        headerName: 'LOC',
        field: 'pu_location',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
      },
      {
        headerName: 'SCHED',
        field: 'pu_sche_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.pu_sche_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A IN',
        field: 'pu_actual_in_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.pu_actual_in_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A OUT',
        field: 'pu_actual_out_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.pu_actual_out_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'DRIVER',
        field: 'pu_driver',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'RETURN',
    isColumnGroup: true,
    backgroundColor: '#415688',
    color: 'white',
    icon: {
      fold: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      unfold: PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON,
    },
    field: 'rtn',
    children: [
      {
        headerName: 'LOC',
        field: 'rtn_location',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
      },
      {
        headerName: 'SCHED',
        field: 'rtn_sche_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.rtn_sche_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A IN',
        field: 'rtn_actual_in_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.rtn_actual_in_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'A OUT',
        field: 'rtn_actual_out_date',
        width: 62,
        comparator: dateComparator,
        valueGetter: params =>
          generateFormattedDate({ date: params.data.rtn_actual_out_date }),
        editable: false,
        floatingFilterComponent: FloatingFilterInput,
        floatingFilterComponentParams: {
          inputType: 'date',
          maxLength: 8,
          placeholder: 'MM/DD/YY',
        },
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
      {
        headerName: 'DRIVER',
        field: 'rtn_driver',
        width: 72,
        editable: false,
        cellRendererSelector: gridParam =>
          cellRendererGenerator({
            gridParam,
            customStyle: {
              textAlign: 'left',
            },
          }),
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'L/E CFM',
    field: 'empty_cfm',
    width: 70,
    comparator: dateComparator,
    valueGetter: params =>
      generateFormattedDate({
        date: params.data.empty_cfm || params.data.load_cfm,
      }),
    editable: false,
    floatingFilterComponent: FloatingFilterInput,
    floatingFilterComponentParams: {
      inputType: 'date',
      maxLength: 8,
      placeholder: 'MM/DD/YY',
    },
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
  {
    headerName: 'POD',
    field: 'pod',
    width: 42,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'B/L',
    field: 'b/l',
    width: 42,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'DO',
    field: 'do',
    width: 42,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'IT',
    field: 'it/p',
    width: 42,
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: CUSTOMER_REPORT_Y_N_FILTER,
    },
  },
  {
    headerName: 'REMARKS',
    field: 'remarks',
    editable: false,
    width: 150,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        customStyle: {
          textAlign: 'left',
        },
      }),
  },
];

export default createCustomerReportColDefs;
