import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { REGEX_NUMBER_REGEX } from '../../../../../constants/Common/regex';
import {
  COMMON_COUNTRY_SELECT_OPTION,
  COMMON_STATE_SELECT_OPTION,
} from '../../../../../constants/Common/selectOption';
import {
  DRIVER_GENDER_OPTION,
  DRIVER_LEGAL_STATUS_OPTION,
  DRIVER_MARITAL_STATUS_OPTION,
  DRIVER_ONR_CRR_TYPE_OPTION,
  DRIVER_POSITION_OPTION,
  DRIVER_RACE_OPTION,
} from '../../../../../constants/Master/Driver/masterDriverOption';
import { DRIVER_LEGAL_STATUS_VALUE } from '../../../../../constants/Master/Driver/masterDriverValue';
import defaultInputRules from '../../../../../utils/Common/Input/defaultInputRules';
import generateByDate from '../../../../../utils/Master/Common/Generator/generateByDate';
import sliceNumber from '../../../../../utils/Master/Common/Generator/sliceNumber';
import {
  handleCheckChange,
  handleDayChange,
  handleInputChange,
  handleUpperCaseInputChange,
} from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import handlePlaceSelect from '../../../../../utils/Master/Common/Handler/handlePlaceSelect';
import resetPlace from '../../../../../utils/Master/Common/resetPlace';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonFilePreviewInput from '../../../../Inputs/CommonFilePreviewInput/CommonFilePreviewInput';
import CommonMultipleInput from '../../../../Inputs/CommonMultipleInput/CommonMultipleInput';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonToggle from '../../../../Inputs/CommonToggle/CommonToggle';
import AutoCompleteAddress from '../../../Common/AutoCompleteAddress';
import validateEmail from '../../../../../utils/Common/Input/Validator/validateEmail';

const DriverInfo = ({
  isEditable,
  data,
  inputController,
  options,
  isCompany,
  editMode,
  divListOption,
  ...props
}) => {
  const { setIsCompany } = props;

  const { getValues, setValue } = useFormContext();

  const [legalIsCitizenship, setLegalIsCitizenship] = useState(
    data?.legal_status === DRIVER_LEGAL_STATUS_VALUE?.Citizenship,
  );

  const [place, setPlace] = useState(data?.address_01 || '');

  const nowDiv = useSelector(state => state.nowDiv);

  const slicePhone = sliceNumber(data?.cell_no, [3, 3, 4]);
  const sliceHome = sliceNumber(data?.home_no, [3, 3, 4]);
  const sliceEmergency = sliceNumber(data?.emergency_phone_no, [3, 3, 4]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="w-[155px]">
          <CommonSelect
            inputName="div"
            defaultValue={data?.div || nowDiv}
            label="DIVISION"
            option={divListOption}
            required
            disabled={!isEditable}
            onChangeHandler={handleInputChange}
          />
        </div>
        <div data-testid="category" className="w-[155px]">
          <CommonSelect
            inputName="category"
            defaultValue={data?.category || 'DR0001'}
            label="CATEGORY"
            option={DRIVER_POSITION_OPTION}
            required
            disabled={!isEditable || editMode}
            onChangeHandler={({ e, onChange }) => {
              if (e?.target?.value === 'DR0001') {
                setIsCompany(true);
              } else {
                setIsCompany(false);
              }
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            inputName="created_by"
            defaultValue={generateByDate(data, ['created_by', 'created_date'])}
            label="CREATED BY"
            disabled
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            inputName="updated_by"
            defaultValue={generateByDate(data, ['updated_by', 'updated_date'])}
            label="UPDATED BY"
            disabled
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className={`${!isCompany ? 'w-[180px]' : 'w-[120px]'}`}>
          <CommonTextInput
            inputName="driver"
            defaultValue={data?.driver}
            label="DRIVER ID"
            disabled
          />
        </div>
        {isCompany && (
          <div className="w-[120px]">
            <CommonTextInput
              inputName="cloud_id"
              defaultValue={data?.cloud_id?.trim()}
              label="CLOUD ID"
              maxLength={9}
              minLength={9}
              onChangeHandler={handleInputChange}
              regex={REGEX_NUMBER_REGEX}
              disabled={!isEditable}
            />
          </div>
        )}
        <div className={`${!isCompany ? 'w-[180px]' : 'w-[120px]'}`}>
          <CommonTextInput
            inputName="tablet_id"
            defaultValue={data?.tablet_id}
            label="TABLET ID"
            disabled
          />
        </div>
        <div className={`${!isCompany ? 'w-[180px]' : 'w-[120px]'}`}>
          <CommonTextInput
            inputName="qb_id"
            defaultValue={data?.qb_id}
            label="QB ID"
            disabled
          />
        </div>
        <div>
          {!isCompany ? (
            <div className="w-[80px]">
              <CommonCheckbox
                label="1099 DRIVER"
                borderVisible
                onChangeHandler={handleCheckChange}
                options={[
                  {
                    inputName: 'driver_1099',
                    defaultValue: data?.driver_1099,
                  },
                ]}
                disabled={!isEditable}
              />
            </div>
          ) : (
            <div className="w-[120px]">
              <CommonTextInput
                inputName="adp_id"
                defaultValue={data?.adp_id?.trim()}
                label="ADP ID"
                lengthCheck={false}
                maxLength={4}
                minLength={4}
                onChangeHandler={handleInputChange}
                regex={REGEX_NUMBER_REGEX}
                disabled={!isEditable}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-x-[20px]">
        <div className="w-[140px] flex items-start">
          <CommonFilePreviewInput
            inputName="photo_file"
            defaultValue={data?.photo_file}
            disabled={!isEditable}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            {isCompany ? (
              <>
                <div className="w-[140px]">
                  <CommonDate
                    label="HIRE DATE"
                    defaultValue={data?.hire_date}
                    inputName="hire_date"
                    onChangeHandler={handleDayChange}
                    required
                    disabled={!isEditable}
                  />
                </div>
                <div className="w-[140px]">
                  <CommonDate
                    label="REHIRE DATE"
                    defaultValue={data?.rehire_date}
                    inputName="rehire_date"
                    onChangeHandler={handleDayChange}
                    validate={{
                      custom: {
                        dayCheck: v => {
                          const hire = dayjs(getValues('hire_date'));
                          const rehire = dayjs(v);
                          if (
                            hire.isAfter(rehire) ||
                            rehire.format('MM/DD/YY') ===
                              hire.format('MM/DD/YY')
                          ) {
                            return 'Later than hire.';
                          }
                          return true;
                        },
                      },
                    }}
                    disabled={!isEditable}
                  />
                </div>
              </>
            ) : (
              <div className={`w-[220px] ${!isCompany ? 'block' : 'hidden'}`}>
                <CommonDate
                  label="CONTRACT DATE"
                  defaultValue={data?.contract_date}
                  required
                  inputName="contract_date"
                  onChangeHandler={handleDayChange}
                  disabled={!isEditable}
                />
              </div>
            )}
            <div className={`${!isCompany ? 'w-[220px]' : 'w-[140px]'}`}>
              <CommonDate
                label="TERMINATE DATE"
                defaultValue={data?.terminate_date}
                inputName="terminate_date"
                onChangeHandler={handleDayChange}
                validate={{
                  custom: {
                    dayCheck: v => {
                      const terminate = dayjs(v);
                      if (!isCompany) {
                        const contract = dayjs(getValues('contract_date'));
                        if (
                          terminate.isBefore(contract) &&
                          terminate.format('DD/MM/YY') !==
                            contract.format('DD/MM/YY')
                        ) {
                          return `Same or later than contract date.`;
                        }
                      } else {
                        const hire = dayjs(getValues('hire_date'));
                        if (
                          terminate.isBefore(hire) &&
                          terminate.format('DD/MM/YY') !==
                            hire.format('DD/MM/YY')
                        ) {
                          return `Same or later than hire date.`;
                        }
                      }
                      return true;
                    },
                  },
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[40px] mr-[10px]">
              <CommonToggle
                inputName="active"
                label="ACTIVE"
                options={[
                  {
                    inputName: 'active',
                    defaultValue: editMode ? data?.active : true,
                  },
                ]}
                onChangeHandler={handleCheckChange}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex justify-between">
            {isCompany ? (
              <>
                <div className="w-[258px]">
                  <CommonMultipleInput
                    label="NAME"
                    defaultValue={[
                      data?.first_name || '',
                      data?.mid_name || '',
                      data?.last_name || '',
                    ]}
                    inputName={['first_name', 'mid_name', 'last_name']}
                    placeholder={['* first', 'mid', '* last']}
                    required={[true, false, true]}
                    validate={[
                      {
                        default: {
                          required: {
                            value: true,
                            message: 'First field is mandatory.',
                          },
                        },
                      },
                      {
                        default: {
                          required: {
                            value: false,
                          },
                        },
                      },
                      {
                        default: {
                          required: {
                            value: true,
                            message: 'Third field is mandatory.',
                          },
                        },
                      },
                    ]}
                    onChangeHandler={[
                      handleInputChange,
                      handleInputChange,
                      handleInputChange,
                    ]}
                    onBlurHandler={[
                      handleUpperCaseInputChange,
                      handleUpperCaseInputChange,
                      handleUpperCaseInputChange,
                    ]}
                    disabled={[!isEditable, !isEditable, !isEditable]}
                  />
                </div>
                <div className="w-[142px]">
                  <CommonDate
                    label="DOB"
                    defaultValue={data?.dob}
                    inputName="dob"
                    onChangeHandler={handleDayChange}
                    disabled={!isEditable}
                  />
                </div>
                <div className="w-[80px]">
                  <CommonSelect
                    inputName="gender"
                    defaultValue={data?.gender}
                    label="GENDER"
                    option={DRIVER_GENDER_OPTION}
                    onChangeHandler={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="w-[160px]">
                  <CommonTextInput
                    maxLength={100}
                    label="BUSINESS NAME (DBA)"
                    defaultValue={data?.business_name}
                    required
                    inputName="business_name"
                    onChangeHandler={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
                <div className="w-[160px]">
                  <CommonTextInput
                    maxLength={100}
                    label="LEGAL NAME"
                    defaultValue={data?.legal_name}
                    inputName="legal_name"
                    onChangeHandler={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
                <div className="w-[160px]">
                  <CommonSelect
                    inputName="type"
                    defaultValue={data?.type}
                    label="TYPE"
                    option={DRIVER_ONR_CRR_TYPE_OPTION}
                    onChangeHandler={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between">
            <div className="w-[160px]">
              <CommonMultipleInput
                label="CELL #"
                maxLength={[3, 3, 4]}
                required={[true, true, true]}
                defaultValue={slicePhone}
                inputName={['cell_no_first', 'cell_no_second', 'cell_no_third']}
                validate={[
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'First field is mandatory.',
                      },
                      minLength: {
                        value: 3,
                        message: 'Please insert 3 numbers at first field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'Second field is mandatory.',
                      },
                      minLength: {
                        value: 3,
                        message: 'Please insert 3 numbers at second field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'Third field is mandatory.',
                      },
                      minLength: {
                        value: 4,
                        message: 'Please insert 4 numbers at last third field.',
                      },
                    },
                  },
                ]}
                regex={REGEX_NUMBER_REGEX}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
            <div className="w-[160px]">
              <CommonMultipleInput
                label="HOME #"
                maxLength={[3, 3, 4]}
                defaultValue={sliceHome}
                inputName={['home_no_first', 'home_no_second', 'home_no_third']}
                regex={REGEX_NUMBER_REGEX}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
            <div className="w-[160px]">
              <CommonMultipleInput
                label="EMERGENCY #"
                maxLength={[3, 3, 4]}
                defaultValue={sliceEmergency}
                inputName={[
                  'emergency_phone_no_first',
                  'emergency_phone_no_second',
                  'emergency_phone_no_third',
                ]}
                regex={REGEX_NUMBER_REGEX}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
          </div>
        </div>
      </div>
      {!isCompany && (
        <div className="flex justify-between">
          <div className="w-[405px]">
            <CommonMultipleInput
              label="NAME"
              defaultValue={[
                data?.first_name || '',
                data?.mid_name || '',
                data?.last_name || '',
              ]}
              inputName={['first_name', 'mid_name', 'last_name']}
              placeholder={['first', 'mid', 'last']}
              onChangeHandler={[
                handleInputChange,
                handleInputChange,
                handleInputChange,
              ]}
              disabled={[!isEditable, !isEditable, !isEditable]}
            />
          </div>
          <div className="w-[80px]">
            <CommonSelect
              inputName="gender"
              defaultValue={data?.gender}
              label="GENDER"
              option={DRIVER_GENDER_OPTION}
              onChangeHandler={handleInputChange}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[155px]">
            <CommonDate
              label="DOB"
              defaultValue={data?.dob}
              inputName="dob"
              onChangeHandler={handleDayChange}
              disabled={!isEditable}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="w-[330px]">
          <AutoCompleteAddress
            inputName="address_01"
            label="ADDRESS 01"
            place={place}
            setPlace={setPlace}
            defaultValue={data?.address_01 || ''}
            onPlaceSelect={({ ...placeData }) => {
              handlePlaceSelect({
                setValue,
                ...placeData,
              });
            }}
            onChangeHandler={({ e, onChange }) => {
              resetPlace({ e, setPlace, setValue });
              onChange?.(e?.target?.value);
            }}
            required
            disabled={!isEditable}
          />
        </div>
        <div className="w-[330px]">
          <CommonTextInput
            maxLength={255}
            label="ADDRESS 02"
            defaultValue={data?.address_02}
            inputName="address_02"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[155px]">
          <CommonTextInput
            label="CITY"
            defaultValue={data?.city}
            inputName="city"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonSelect
            inputName="state"
            label="STATE"
            onChangeHandler={handleInputChange}
            defaultValue={data?.state}
            option={COMMON_STATE_SELECT_OPTION}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            maxLength={20}
            label="ZIP"
            defaultValue={data?.zip}
            inputName="zip"
            onChangeHandler={handleInputChange}
            regex={REGEX_NUMBER_REGEX}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[155px]">
          <CommonTextInput
            label="COUNTRY"
            defaultValue={data?.country}
            required
            disabled
            inputName="country"
            onChangeHandler={handleInputChange}
          />
        </div>
      </div>
      {isCompany && (
        <>
          <div className="flex justify-between">
            <div className="w-[280px]">
              <CommonSelect
                label="MARITAL STATUS"
                defaultValue={data?.marital_status}
                required
                inputName="marital_status"
                option={DRIVER_MARITAL_STATUS_OPTION}
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[280px]">
              <CommonSelect
                label="RACE"
                defaultValue={data?.race}
                required
                inputName="race"
                option={DRIVER_RACE_OPTION}
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[80px]">
              <CommonCheckbox
                label="TOBACCO"
                borderVisible
                onChangeHandler={handleCheckChange}
                options={[
                  {
                    inputName: 'tobacco',
                    defaultValue: data?.tobacco,
                  },
                ]}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[213px]">
              <CommonSelect
                label="LEGAL STATUS"
                defaultValue={data?.legal_status}
                required
                inputName="legal_status"
                option={DRIVER_LEGAL_STATUS_OPTION}
                onChangeHandler={({ e, onChange }) => {
                  if (
                    e?.target?.value === DRIVER_LEGAL_STATUS_VALUE?.Citizenship
                  ) {
                    setLegalIsCitizenship(true);
                  } else {
                    setLegalIsCitizenship(false);
                  }
                  onChange(e?.target?.value);
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[213px]">
              <CommonSelect
                label="NATIONALITY"
                defaultValue={data?.nationality}
                required={!legalIsCitizenship}
                inputName="nationality"
                option={COMMON_COUNTRY_SELECT_OPTION}
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[214px]">
              <CommonDate
                label="LEGAL EXP"
                required={!legalIsCitizenship}
                defaultValue={data?.legal_exp}
                inputName="legal_exp"
                onChangeHandler={handleDayChange}
                disabled={!isEditable}
              />
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between">
        <div className="w-[330px]">
          <CommonTextInput
            maxLength={128}
            label="EMAIL"
            defaultValue={data?.email}
            required
            inputName="email"
            validate={{
              custom: {
                ...validateEmail(),
              },
            }}
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[330px]">
          <CommonTextInput
            maxLength={1000}
            label="REMARKS"
            defaultValue={data?.remarks}
            inputName="remarks"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
      </div>
      {props.renderDivideLine()}
    </div>
  );
};

export default DriverInfo;
