import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import StyledCommonToastWrapper from '../../styles/Common/CommonToast/StyledCommonToastWrapper';
import StyledCommonToastColorBox from '../../styles/Common/CommonToast/StyledCommonToastColorBox';
import StyledCommonToastContentWrapper from '../../styles/Common/CommonToast/StyledCommonToastContentWrapper';
import StyledCommonToastIconWrapper from '../../styles/Common/CommonToast/StyledCommonToastIconWrapper';
import StyledCommonToastMessageWrapper from '../../styles/Common/CommonToast/StyledCommonToastMessageWrapper';
import StyledCommonToastTitle from '../../styles/Common/CommonToast/StyledCommonToastTitle';
import StyledCommonToastMainText from '../../styles/Common/CommonToast/StyledCommonToastMainText';
import { PATH_TOAST_ERROR_ICON } from '../../constants/Common/path';
import StyledCommonToastBackGround from '../../styles/Common/CommonToast/StyledCommonToastBackGround';
import { TOAST_TYPE } from '../../constants/Common/Toast/toastTypes';
import {
  generateToastHeader,
  generateToastMessage,
} from '../../utils/Common/Toast/Generator/toastContentGenerator';
import { TOAST_ICON } from '../../constants/Common/Toast/toastIcon';

const CommonToast = ({ open, setOpen, onCancelHandler, ...props }) => {
  const { type, time, header, message, suppressAutoFadeOut } = props;

  const timerRef = useRef({ toastTimer: null });

  const [isOpenToast, setIsOpenToast] = useState(false);
  const [toastingTime, setToastingTime] = useState(2000);
  const [toastType, setToastType] = useState(TOAST_TYPE?.SUCCESS);

  useEffect(() => {
    return () => {
      if (timerRef?.current?.toastTimer) {
        clearTimeout(timerRef?.current?.toastTimer);
        timerRef.current.toastTimer = null;
      }
    };
  }, []);

  useEffect(() => {
    setIsOpenToast(open);
  }, [open]);

  useEffect(() => {
    if (time !== undefined && time !== null && !isNaN(time) && time > 0) {
      setToastingTime(time);
    }
  }, [time]);

  useEffect(() => {
    if (type && Object?.values?.(TOAST_TYPE)?.includes?.(type)) {
      setToastType(type);
    }
  }, [type]);

  const debounceToast = useCallback(
    debounce(() => {
      if (isOpenToast && !suppressAutoFadeOut) {
        if (timerRef?.current?.toastTimer) {
          clearTimeout(timerRef?.current?.toastTimer);
          timerRef.current.toastTimer = null;
        }

        timerRef.current.toastTimer = setTimeout(() => {
          onCancelHandler?.();
          setOpen(false);
          setIsOpenToast(false);
        }, toastingTime);
      }
    }, 200),
    [isOpenToast, suppressAutoFadeOut, toastingTime],
  );

  useEffect(() => {
    debounceToast();
  }, [props]);

  return (
    isOpenToast && (
      <StyledCommonToastBackGround {...props}>
        <StyledCommonToastWrapper
          toastingTime={toastingTime}
          toastType={toastType}
          {...props}
        >
          {toastType !== TOAST_TYPE?.CUSTOM && (
            <StyledCommonToastColorBox toastType={toastType} {...props} />
          )}
          <StyledCommonToastContentWrapper>
            {toastType !== TOAST_TYPE?.CUSTOM && (
              <StyledCommonToastIconWrapper>
                <img
                  src={TOAST_ICON?.[toastType]?.iconPath}
                  width={TOAST_ICON?.[toastType]?.width}
                  height={TOAST_ICON?.[toastType]?.height}
                  alt="Toast Icon"
                />
              </StyledCommonToastIconWrapper>
            )}
            <StyledCommonToastMessageWrapper>
              <StyledCommonToastTitle>
                {generateToastHeader({ type: toastType, header })}
              </StyledCommonToastTitle>
              <StyledCommonToastMainText>
                {generateToastMessage({ type: toastType, message })}
              </StyledCommonToastMainText>
            </StyledCommonToastMessageWrapper>
            <div
              className="flex justify-center items-center cursor-pointer mb-[36px]"
              onClick={() => {
                onCancelHandler?.();
                setOpen(false);
              }}
            >
              <img
                src={PATH_TOAST_ERROR_ICON}
                width={12}
                height={12}
                alt="Toast Close Icon"
              />
            </div>
          </StyledCommonToastContentWrapper>
        </StyledCommonToastWrapper>
      </StyledCommonToastBackGround>
    )
  );
};

export default CommonToast;
