import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import tmsReportAPI from '../../api';

export const patchEarlyWarning = async ({ gridParam }) => {
  const seq = gridParam?.data?.seq;
  try {
    const response = await tmsReportAPI.patch(`early-warning/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
