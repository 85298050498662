import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchCompleteWO from '../../../services/Dispatch/Common/patch/patchCompleteWO';
import dispatchKeys from '../keys';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../constants/Common/successMessage';

const usePatchCompleteWO = ({
  setMessageModalMessage,
  setIsMessageModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();
  const mutator = useMutation({
    mutationFn: patchCompleteWO,
    refetchOnWindowFocus: false,
    onSuccess: (data, variables) => {
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.count() });
      queryClient?.invalidateQueries({ queryKey: dispatchKeys?.list() });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setMessageModalMessage(error.message);
      setIsMessageModalOpen(true);
    },
  });
  return mutator;
};

export default usePatchCompleteWO;
