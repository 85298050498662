import { useQuery } from '@tanstack/react-query';
import getDispatchList from '../../../services/Dispatch/Common/get/getDispatchList';
import getBookingList from '../../../services/Dispatch/Common/get/getBookingList';
import dispatchKeys from '../keys';
import getQueries from '../../../utils/Dispatch/Common/Generator/getQueries';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
} from '../../../constants/Dispatch/Common/category';
import { DISPATCH_STATUS_VALUE } from '../../../constants/Dispatch/Common/statusValue';
import convertStatus from '../../../utils/Dispatch/Common/Generator/convertStatus';
import getYardQueries from '../../../utils/Dispatch/Common/Generator/getYardQueries';

const useGetDispatchList = ({
  division,
  category,
  queryObject,
  yardQueryObject,
  status,
  isBooking,
  subFilter,
  ...props
}) => {
  const yardParamQueries = getYardQueries({
    yardQueryObject,
    subFilter,
    status,
  });

  const paramQueries = getQueries({ queryObject });
  const response =
    status !== DISPATCH_PAGE_EXPORT_BOOKING
      ? useQuery({
          queryKey: dispatchKeys.listDivStatusQuery({
            division,
            status,
            category,
            paramQueries,
            yardParamQueries,
          }),
          queryFn: () =>
            getDispatchList({
              category: convertStatus({ status }),
              div: division,
              status: DISPATCH_STATUS_VALUE?.[status],
              paramQueries,
              yardParamQueries,
            }),
          refetchOnWindowFocus: false,
          enabled:
            !isBooking &&
            category !== DISPATCH_TYPE_BOOKING &&
            status !== DISPATCH_PAGE_EXPORT_BOOKING &&
            !paramQueries?.status?.includes('active'),
        })
      : useQuery({
          queryKey: dispatchKeys.listDivStatusQuery({
            division,
            status: DISPATCH_STATUS_VALUE?.[status],
            category,
            paramQueries,
          }),
          queryFn: () => getBookingList({ div: division, paramQueries }),
          refetchOnWindowFocus: false,
          enabled:
            !!isBooking &&
            category === DISPATCH_TYPE_BOOKING &&
            status === DISPATCH_PAGE_EXPORT_BOOKING &&
            !!paramQueries?.status,
        });

  return {
    dispatchList: response.data?.data,
    getListLoading: response.isLoading,
    getListError: response.isError,
    getListFetching: response.isFetching,
    getListRefetching: response.isRefetching,
  };
};

export default useGetDispatchList;
