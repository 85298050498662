import React, { useEffect, useRef, useState } from 'react';
import ModificationCell from '../../../../components/CommonDataGrid/Component/ModificationCell';
import {
  GRID_MOD_CELL_WIDTH,
  GRID_MOD_BOTH,
  GRID_MOD_COL_ID,
  GRID_MOD_DELETE,
  GRID_MOD_EDIT,
} from '../../../../constants/Common/DataGrid/setting';
import selectRowCheckboxGenerator from './selectRowCheckboxGenerator';
import CurrentRowAddCell from '../../../../components/CommonDataGrid/Component/ModificationCell/CurrentRowAddCell';
import rowFolderGenerator from './rowFolderGenerator';

/**
 * @description
 * Create modification column for the grid automatically according to user-defined modification type.
 * @param {array} columns
 * Array of column object
 * @param {string} modType
 * Patameter that indicate the type of modification of the grid
 * GRID_MOD_BOTH: Enable edit and delete
 * GRID_MOD_EDIT: Enable edit only
 * GRID_MOD_DELETE: Enable delete only
 * @param {function} onDeleteClicked
 * Function that will be called when delete button clicked
 * @param {boolean} addable
 * Parameter that indicate whether add button will be shown or not
 * @returns
 * Array of column object that will be used to create the grid
 */
const modificationColumnGenerator = ({
  columns,
  modType,
  onDeleteClicked,
  onAddClicked,
  addable,
  foldable,
  modColumnPinned,
  setEditDisabled,
  setDeleteDisabled,
  pinnedCheckbox,
  modDisabled,
  ...props
}) => {
  /** Copy given column */
  const getOriginalColumns = () => {
    const tempOriginalColumns = [
      selectRowCheckboxGenerator(pinnedCheckbox),
      ...columns,
    ];
    if (foldable)
      tempOriginalColumns?.splice(1, 0, rowFolderGenerator({ ...props }));
    return tempOriginalColumns;
  };

  /** Set modification column setting */
  const defaultModificationColumnSetting = {
    // Set width
    width: GRID_MOD_CELL_WIDTH,
    // Set column id
    colId: GRID_MOD_COL_ID,
    // Resizable false
    resizable: false,
    // Editable false
    editable: false,
    // Did not use filter
    filter: false,
    // Did not use sorting feature
    sortable: false,
    // Did not use floating filter
    floatingFilter: false,
    // Did not use row click feature
    suppressRowClickSelection: true,
    // Did not use row drag moving feature
    suppressMovable: true,
    // Prevent double click event
    onCellDoubleClicked: null,
    // Set cell pinned to right
    // pinned: modColumnPinned ? 'right' : null,
    // Render modification cell using custom component
    cellRenderer: gridParam => {
      if (gridParam?.node?.rowPinned) {
        if (addable)
          return (
            <CurrentRowAddCell
              gridParam={gridParam}
              onAddClicked={onAddClicked}
              {...modDisabled}
            />
          );
      }
      return (
        <ModificationCell
          gridParam={gridParam}
          modType={modType}
          onDeleteClicked={onDeleteClicked}
          setEditDisabled={setEditDisabled}
          setDeleteDisabled={setDeleteDisabled}
        />
      );
    },
  };

  /** Set modification column according to user-defined modification type */
  if ([GRID_MOD_EDIT, GRID_MOD_DELETE, GRID_MOD_BOTH].includes(modType)) {
    // Create new column object
    const tempColumn = [
      // Copy original column
      ...getOriginalColumns(),
      // Add(push) modification column
      {
        ...defaultModificationColumnSetting,
      },
    ];
    return tempColumn;
  }
  return getOriginalColumns();
};

export default modificationColumnGenerator;
