import {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP,
  GROUP_DEFS,
  PICK_UP_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
} from '../Common/columnGroups';

const BARE_CHASSIS_DEFAULT_FIELDS = {
  created_date: {
    headerName: 'DATE',
    type: 'nglDateType',
  },
  wo_no: {
    headerName: 'WO #',
  },
  billto: {
    headerName: 'BILL TO',
  },
  ch_no: {
    headerName: 'CHASSIS #',
  },
  pool: {
    headerName: 'POOL',
  },
  ch_type: {
    headerName: 'CH TYPE',
  },
  ref_no: {
    headerName: 'REF #',
  },
  hot: {
    headerName: 'HOT',
  },
  po_g: {
    headerName: 'PULL OUT',
    children: PULL_OUT_GROUP,
  },
  dilv_g: {
    headerName: 'DELIVERY',
    children: DELIVERY_GROUP,
  },
  pu_g: {
    headerName: 'PICK UP',
    children: PICK_UP_GROUP,
  },
  rtn_g: {
    headerName: 'RETURN',
    children: RETURN_GROUP,
  },
  ar_g: {
    headerName: 'AR',
    children: AR_GROUP,
  },
  ap_g: {
    headerName: 'AP',
    children: AP_GROUP,
  },
  profit: {
    headerName: 'PRFT',
  },
  inv: {
    headerName: 'INV',
  },
  doc_g: {
    headerName: 'DOCUMENT',
    children: DOCUMENT_GROUP,
  },
  remarks: {
    headerName: 'REMARKS',
  },
};

export { BARE_CHASSIS_DEFAULT_FIELDS };
