import dayjs from 'dayjs';

const createSelectedARAgingColDefs = () => {
  return [
    {
      headerName: 'NGL INV #',
      field: 'inv_no',
      editable: false,
    },
    {
      headerName: 'CONTAINER #',
      field: 'cont_no',
      editable: false,
    },
    {
      headerName: 'BILL DATE',
      field: 'inv_date',
      editable: false,
      valueGetter: params => {
        const dateValue = params.data?.inv_date;
        return dateValue && dayjs(dateValue)?.isValid()
          ? dayjs(dateValue).format('MM/DD/YY')
          : null;
      },
    },
    {
      headerName: 'AGING',
      field: 'aging',
      editable: false,
    },
    {
      headerName: 'YOUR REF #',
      field: 'ref_no',
      editable: false,
    },
    {
      headerName: 'M B/L #',
      field: 'mbl_no',
      editable: false,
    },
    {
      headerName: 'AMOUNT',
      field: 'inv_amt',
      editable: false,
    },
    {
      headerName: 'PAID	',
      field: 'rcvd_amt',
      editable: false,
    },
    {
      headerName: 'BALANCE',
      field: 'balance',
      editable: false,
    },
    {
      headerName: 'MEMO',
      field: 'memo',
    },
  ];
};

export default createSelectedARAgingColDefs;
