import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_EARLY_WARNING_SLICE_KEY } from '../../../Constants/Admin/EarlyWarning';

const earlyWarningInitialState = {
  earlyWarningList: [],
};

const adminEarlyWarningSlice = createSlice({
  name: ADMIN_EARLY_WARNING_SLICE_KEY,
  initialState: earlyWarningInitialState,
  reducers: {
    originAdminEarlyWarningList: {
      reducer: (state, action) => ({
        ...state,
        earlyWarningList: action.payload.earlyWarningList,
      }),
    },
  },
});

export const { originAdminEarlyWarningList } = adminEarlyWarningSlice.actions;
export default adminEarlyWarningSlice.reducer;
