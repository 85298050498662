import { createSlice } from '@reduxjs/toolkit';

export const colvalueInitialState = {
  colId: '',
  value: '',
  color: '',
  pageTitle: '',
};

const colValueSlice = createSlice({
  name: 'COLVALUE',
  initialState: colvalueInitialState,
  reducers: {
    setColId(state, action) {
      const colId = action.payload;
      state.colId = colId;
    },
    setValue(state, action) {
      const value = action.payload;
      state.value = value;
    },
    setColor(state, action) {
      const color = action.payload;
      state.color = color;
    },
    setPageTitle(state, action) {
      const pageTitle = action.payload;
      state.pageTitle = pageTitle;
    },
  },
});

export const { setColId, setValue, setColor, setPageTitle } =
  colValueSlice.actions;
export default colValueSlice.reducer;
