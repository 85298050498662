import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import dispatchDefaultAPI from '../../api';

const patchColumnOrder = async ({ userID, patchData }) => {
  try {
    const response = await dispatchDefaultAPI.patch(
      `user/${userID}`,
      patchData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchColumnOrder;
