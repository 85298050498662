import React from 'react';
import StyledWOLabel from '../../../../styles/TmsReport/Schedules/StyledWOLabel';
import StyledWOView from '../../../../styles/TmsReport/Schedules/StyledWOView';
import handleWOClick from '../../../../utils/TmsReport/Common/handleWOClick';
import WEEKLY_THEME from '../../../../constants/TmsReport/Schedules/weeklyTheme';

const WeeklyTableItem = ({ wo, nameSet }) => {
  const scheduleType = wo?.sche_type === 'LVAN' ? 'VAN' : wo?.sche_type;
  return (
    <div
      className="flex flex-row h-fit shadow-[0px_0.5px_1px_0px_#00000040] cursor-pointer"
      onClick={() => {
        handleWOClick(wo);
      }}
    >
      <StyledWOLabel backgroundColor={WEEKLY_THEME?.[wo.color_type][0]} />
      <StyledWOView backgroundColor={WEEKLY_THEME?.[wo.color_type][1]}>
        <p className="text-[12px] font-[500] leading-relaxed pl-[12px] py-[5px]">
          <span className="font-[700]">
            {wo.category}
            {wo?.[nameSet?.sche_time]
              ? `, ${wo?.[nameSet?.sche_time]?.substring(0, 5)}`
              : ''}
            {scheduleType ? ` ${scheduleType}` : ''}
          </span>
          <br />
          {wo?.categoty === 'VAN' || wo?.categoty === 'BROKEAGE'
            ? wo.trailer_no
            : wo.cont_no}
          <br />
          {wo.ch_no}
          <br />
          {wo?.[nameSet.loc]}
          <br />
          {wo?.[nameSet.city] ? `${wo?.[nameSet.city]}` : ''}
          {wo?.[nameSet.state] ? `, ${wo?.[nameSet.state]} ` : ''}
          {wo?.[nameSet.zip] ? `, ${wo?.[nameSet.zip]} ` : ''}
          <br />
          {wo?.[nameSet.driver_id]}
          {wo?.[nameSet.driver_name] ? (
            `, ${wo?.[nameSet.driver_name]}`
          ) : (
            <br />
          )}
        </p>
      </StyledWOView>
    </div>
  );
};

export default WeeklyTableItem;
