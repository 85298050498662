import handleCheckboxChange from '../../Common/handleCheckboxChange';

const generateInitOptions = ({ divisionList, setQuery }) => {
  const initOptions = [];
  divisionList?.forEach(division => {
    initOptions.push({
      label: division,
      value: division,
      onChange: ({ ...props }) => handleCheckboxChange({ ...props, setQuery }),
    });
  });
  return initOptions;
};

export default generateInitOptions;
