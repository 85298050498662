import React, { useEffect, useState } from 'react';
import StyledCommonLoadingWrapper from '../../styles/Common/CommonLoading/StyledCommonLoadingWrapper';
import { PATH_LOADING_ICON } from '../../constants/Common/path';
import CommonBlur from '../CommonBlur/CommonBlur';

const CommonLoading = ({ ...props }) => {
  const [getOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props?.open);
  }, [props?.open]);

  return (
    getOpen && (
      <CommonBlur open={getOpen}>
        <StyledCommonLoadingWrapper>
          <div className="flex justify-center items-center min-w-[150px] min-h-[150px] w-[150px] h-[150px]">
            <img
              src={PATH_LOADING_ICON}
              alt="Loading"
              width="100%"
              height="100%"
              data-testid="progressbar"
            />
          </div>
        </StyledCommonLoadingWrapper>
      </CommonBlur>
    )
  );
};

export default CommonLoading;
