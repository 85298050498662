import { createAsyncThunk } from '@reduxjs/toolkit';
import { commonDefaultGetAPI } from '../Default/get';
import {
  COMMON_GET_CHASSIS_TYPE,
  COMMON_GET_DIVISION,
  COMMON_GET_DRIVER,
  COMMON_GET_POOL,
  COMMON_GET_SIZE,
  COMMON_GET_SSL,
  COMMON_GET_TYPE,
} from '../../../constants/Common';

export const getSsl = createAsyncThunk(COMMON_GET_SSL, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonSsl();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getPool = createAsyncThunk(COMMON_GET_POOL, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonPool();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getSize = createAsyncThunk(COMMON_GET_SIZE, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonSize();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getType = createAsyncThunk(COMMON_GET_TYPE, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonType();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getDriver = createAsyncThunk(COMMON_GET_DRIVER, async () => {
  try {
    const response = await commonDefaultGetAPI.getCommonDriver();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getDiv = createAsyncThunk(COMMON_GET_DIVISION, async () => {
  try {
    const response = await commonDefaultGetAPI.getDivisionList();
    return response?.data;
  } catch (err) {
    return null;
  }
});

export const getChassisType = createAsyncThunk(
  COMMON_GET_CHASSIS_TYPE,
  async () => {
    try {
      const response = await commonDefaultGetAPI.getChassisTypeList();
      return response?.data;
    } catch (err) {
      return null;
    }
  },
);
