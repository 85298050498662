import dayjs from 'dayjs';
import FloatingFilterInput from '../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  CELL_TYPE_DATE,
  CELL_TYPE_DATETIME,
  CELL_TYPE_TEXT,
} from '../../../constants/Common/DataGrid/cellType';
import cellRendererGenerator from '../../Common/Datagrid/Generator/cellRendererGenerator';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';

const create322ColumnDefs = () => {
  return [
    {
      headerName: 'CREATE DATE',
      field: 'created_date',
      width: 98,
      editable: false,
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.created_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'CREATED BY',
      field: 'user',
      width: 92,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'IM/EX',
      field: 'imp_exp',
      width: 60,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      valueGetter: params => {
        const value = params.data?.imp_exp;
        if (value === 'I') return 'IMPORT';
        if (value === 'O') return 'EXPORT';
        return null;
      },
    },
    {
      headerName: 'IN/OUT',
      field: 'in_out',
      width: 70,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      valueGetter: params => {
        const value = params.data?.in_out;
        if (value === 'I') return 'IN GATE';
        if (value === 'OA') return 'OUT GATE';
        return null;
      },
    },
    {
      headerName: 'L/E',
      field: 'load_empty',
      width: 55,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
      valueGetter: params => {
        const value = params.data?.load_empty;
        if (value === 'L') return 'LOAD';
        if (value === 'E') return 'EMPTY';
        return null;
      },
    },
    {
      headerName: 'CONT #',
      field: 'cont_no',
      width: 92,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'TRANSACTION',
      field: 'transaction',
      width: 106,
      editable: false,
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATETIME,
        }),
      valueGetter: params => {
        const dateValue = params.data?.transaction;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'datetime',
        maxLength: 14,
        placeholder: 'MM/DD/YY HH:mm',
      },
    },
    {
      headerName: 'MBL #',
      field: 'mbl_no',
      width: 125,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'BOOKING #',
      field: 'bk_no',
      width: 200,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
  ];
};

export default create322ColumnDefs;
