import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import { acctDefaultAPI } from '../../api';

export const patchARAgingMemo = async ({ invNo, memo }) => {
  try {
    const response = await acctDefaultAPI.patch(`ar-aging/${invNo}`, {
      memo,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
