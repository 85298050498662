import {
  ERROR_SPECIFIC_CODES,
  ERROR_SPECIFIC_FIELDS,
} from '../../../constants/Common/specificErrorItem';

/**
 * @description
 * Function that check if error code exist in specific codes.
 * @param {list} code
 * List of error code
 * @returns {boolean}
 * True if error code exist in specific codes, false otherwise
 */
export const specificCodeExistHandler = ({ code }) => {
  /** Check code */
  if (ERROR_SPECIFIC_CODES.includes(code)) return true;
  return false;
};

/**
 * @description
 * Function that check if error fields exist in specific fields.
 * @param {list} fields
 * List of error fields
 * @returns {boolean}
 * True if error fields exist in specific fields, false otherwise
 */
export const specificFieldsExistHandler = ({ fields }) => {
  /** Check fields */
  for (let fieldIdx = 0; fieldIdx < fields?.length; fieldIdx += 1) {
    if (ERROR_SPECIFIC_FIELDS.includes(fields?.[fieldIdx])) return true;
  }
  return false;
};
