import { createSlice } from '@reduxjs/toolkit';
import { BCDETAIL_BASICINFO_SLICE_KEY } from '../../Constants/BCDetail/key';

/**
 * Initial state of slice
 */
export const bcDetailBasicInfoInitialState = {
  loading: false,
  error: null,
};

/**
 * Redux slice
 */
const bcDetailBasicInfoSlice = createSlice({
  name: BCDETAIL_BASICINFO_SLICE_KEY,
  initialState: bcDetailBasicInfoInitialState,
  reducers: {},
});

/**
 * Export
 */
// export const {} = bcDetailBasicInfoSlice.actions;
export default bcDetailBasicInfoSlice.reducer;
