export const PATH_MODAL_CLOSE_ICON =
  '/assets/images/common_icon/modalCloseIcon.svg';
export const PATH_LOADING_ICON = '/assets/images/common_icon/loadingIcon.gif';
export const PATH_SCANNING_ICON = '/assets/images/common_icon/scanning.svg';

export const PATH_DATEPICKER_OPENER_ICON =
  '/assets/images/common_icon/datePickerIcon.svg';

export const PATH_INPUT_CLEAR_ICON =
  '/assets/images/common_icon/inputClearIcon.svg';

export const PATH_HYPHEN_ICON = '/assets/images/common_icon/hyphenIcon.svg';

export const PATH_SEPARATE_LINE = '/assets/images/ediIcon/separateLine.svg';

export const PATH_EDITABLE_HEADER_MARK =
  '/assets/images/common_icon/editableHeaderMark.svg';

/** Toast Icon */
export const PATH_TOAST_SUCCESS_ICON =
  '/assets/images/common_icon/toastSuccessIcon.svg';
export const PATH_TOAST_ERROR_ICON =
  '/assets/images/common_icon/toastErrorIcon.svg';
export const PATH_TOAST_WARNING_ICON =
  '/assets/images/common_icon/toastWarningIcon.svg';

/** Speech bubble icon */
export const PATH_SPEECH_BUBBLE_DATETIME_BLUE_ICON =
  '/assets/images/common_icon/speechBubbleDatetimeBlue.svg';

// Button Icon Path
const BUTTON_ICON_PATH = '/assets/images/buttonIcon/';
export const PATH_BUTTON_EXCEL_UNSELECTED_ICON = `${BUTTON_ICON_PATH}excelUnselected.svg`;
export const PATH_BUTTON_EXCEL_SELECTED_ICON = `${BUTTON_ICON_PATH}excelSelected.svg`;

export const PATH_BUTTON_PDF_UNSELECTED_ICON = `${BUTTON_ICON_PATH}pdfUnselected.svg`;
export const PATH_BUTTON_PDF_SELECTED_ICON = `${BUTTON_ICON_PATH}pdfSelected.svg`;

export const PATH_BUTTON_EXPORT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}uploadUnselected.svg`;
export const PATH_BUTTON_EXPORT_SELECTED_ICON = `${BUTTON_ICON_PATH}uploadSelected.svg`;

export const PATH_BUTTON_IMPORT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}downloadUnselected.svg`;
export const PATH_BUTTON_IMPORT_SELECTED_ICON = `${BUTTON_ICON_PATH}downloadSelected.svg`;

export const PATH_BUTTON_UPLOAD_UNSELECTED_ICON = `${BUTTON_ICON_PATH}uploadThinUnselected.svg`;
export const PATH_BUTTON_UPLOAD_SELECTED_ICON = `${BUTTON_ICON_PATH}uploadThinSelected.svg`;

export const PATH_BUTTON_SYNC_UNSELECTED_ICON = `${BUTTON_ICON_PATH}syncUnselected.svg`;
export const PATH_BUTTON_SYNC_SELECTED_ICON = `${BUTTON_ICON_PATH}syncSelected.svg`;
export const PATH_BUTTON_SYNC_DISABLED_ICON = `${BUTTON_ICON_PATH}syncDisabled.svg`;

export const PATH_BUTTON_DELETE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}trashUnselected.svg`;
export const PATH_BUTTON_DELETE_SELECTED_ICON = `${BUTTON_ICON_PATH}trashSelected.svg`;

export const PATH_BUTTON_APPROVAL_UNSELECTED_ICON = `${BUTTON_ICON_PATH}checkOutlineUnselected.svg`;
export const PATH_BUTTON_APPROVAL_SELECTED_ICON = `${BUTTON_ICON_PATH}checkOutlineSelected.svg`;

export const PATH_BUTTON_COMPLETE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}checkUnselected.svg`;
export const PATH_BUTTON_COMPLETE_SELECTED_ICON = `${BUTTON_ICON_PATH}checkSelected.svg`;

export const PATH_BUTTON_INCOMPLETE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}redForbiddenUnselected.svg`;
export const PATH_BUTTON_INCOMPLETE_SELECTED_ICON = `${BUTTON_ICON_PATH}redForbiddenSelected.svg`;

export const PATH_BUTTON_EDIT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}editUnselected.svg`;
export const PATH_BUTTON_EDIT_SELECTED_ICON = `${BUTTON_ICON_PATH}editSelected.svg`;

export const PATH_BUTTON_CLEAR_UNSELECTED_ICON = `${BUTTON_ICON_PATH}redRefreshUnselected.svg`;
export const PATH_BUTTON_CLEAR_SELECTED_ICON = `${BUTTON_ICON_PATH}redRefreshSelected.svg`;

export const PATH_BUTTON_CLEAR_BLUE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}blueRefreshUnselected.svg`;
export const PATH_BUTTON_CLEAR_BLUE_SELECTED_ICON = `${BUTTON_ICON_PATH}blueRefreshSelected.svg`;

export const PATH_BUTTON_LEAVE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}postUnselected.svg`;
export const PATH_BUTTON_LEAVE_SELECTED_ICON = `${BUTTON_ICON_PATH}postSelected.svg`;

export const PATH_BUTTON_CANCEL_UNSELECTED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineUnselected.svg`;
export const PATH_BUTTON_CANCEL_SELECTED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineSelected.svg`;
export const PATH_BUTTON_CANCEL_DISABLED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineDisabled.svg`;

export const PATH_BUTTON_YES_UNSELECTED_ICON = `${BUTTON_ICON_PATH}checkOutlineUnselected.svg`;
export const PATH_BUTTON_YES_SELECTED_ICON = `${BUTTON_ICON_PATH}checkOutlineSelected.svg`;

export const PATH_BUTTON_NO_UNSELECTED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineUnselected.svg`;
export const PATH_BUTTON_NO_SELECTED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineSelected.svg`;
export const PATH_BUTTON_NO_DISABLED_ICON = `${BUTTON_ICON_PATH}redCrossOutlineDisabled.svg`;

export const PATH_BUTTON_GOTIT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}checkUnselected.svg`;
export const PATH_BUTTON_GOTIT_SELECTED_ICON = `${BUTTON_ICON_PATH}checkSelected.svg`;

export const PATH_BUTTON_SWITCH_TO_DROP_UNSELECTED_ICON = `${BUTTON_ICON_PATH}orangeRefreshUnselected.svg`;
export const PATH_BUTTON_SWITCH_TO_DROP_SELECTED_ICON = `${BUTTON_ICON_PATH}orangeRefreshSelected.svg`;

export const PATH_BUTTON_SWITCH_TO_LIVE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}greenRefreshUnselected.svg`;
export const PATH_BUTTON_SWITCH_TO_LIVE_SELECTED_ICON = `${BUTTON_ICON_PATH}orangeRefreshSelected.svg`;

export const PATH_BUTTON_TRACKING_UNSELECTED_ICON = `${BUTTON_ICON_PATH}trackingUnselected.svg`;
export const PATH_BUTTON_BLUE_TRACKING_UNSELECTED_ICON = `${BUTTON_ICON_PATH}blueTrackingUnselected.svg`;
export const PATH_BUTTON_TRACKING_SELECTED_ICON = `${BUTTON_ICON_PATH}trackingSelected.svg`;

export const PATH_BUTTON_ADD_UNSELECTED_ICON = `${BUTTON_ICON_PATH}plusUnselected.svg`;
export const PATH_BUTTON_ADD_SELECTED_ICON = `${BUTTON_ICON_PATH}plusSelected.svg`;

export const PATH_BUTTON_SAVE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}floppyUnselected.svg`;
export const PATH_BUTTON_SAVE_SELECTED_ICON = `${BUTTON_ICON_PATH}floppySelected.svg`;

export const PATH_BUTTON_CREATE_BILL_NO_UNSELECTED_ICON = `${BUTTON_ICON_PATH}createBillNoUnselected.svg`;
export const PATH_BUTTON_CREATE_BILL_NO_SELECTED_ICON = `${BUTTON_ICON_PATH}createBillNoSelected.svg`;
export const PATH_BUTTON_CREATE_BILL_NO_DISABLED_ICON = `${BUTTON_ICON_PATH}createBillNoDisabled.svg`;

export const PATH_BUTTON_CREATE_INV_NO_UNSELECTED_ICON = `${BUTTON_ICON_PATH}createInvNoUnselected.svg`;
export const PATH_BUTTON_CREATE_INV_NO_SELECTED_ICON = `${BUTTON_ICON_PATH}createInvNoSelected.svg`;
export const PATH_BUTTON_CREATE_INV_NO_DISABLED_ICON = `${BUTTON_ICON_PATH}createInvNoDisabled.svg`;

export const PATH_BUTTON_QUICKBOOKS_UNSELECTED_ICON = `${BUTTON_ICON_PATH}quickBooksUnselected.svg`;
export const PATH_BUTTON_QUICKBOOKS_SELECTED_ICON = `${BUTTON_ICON_PATH}quickBooksSelected.svg`;
export const PATH_BUTTON_QUICKBOOKS_DISABLED_ICON = `${BUTTON_ICON_PATH}quickBooksDisabled.svg`;

export const PATH_BUTTON_RED_DELETE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}redTrashUnselected.svg`;
export const PATH_BUTTON_RED_DELETE_SELECTED_ICON = `${BUTTON_ICON_PATH}redTrashSelected.svg`;
export const PATH_BUTTON_RED_DELETE_DISABLED_ICON = `${BUTTON_ICON_PATH}redTrashUnselected.svg`;

export const PATH_BUTTON_SEARCH_UNSELECTED_ICON = `${BUTTON_ICON_PATH}searchUnselected.svg`;
export const PATH_BUTTON_SEARCH_SELECTED_ICON = `${BUTTON_ICON_PATH}searchSelected.svg`;
export const PATH_BUTTON_SEARCH_DISABLED_ICON = `${BUTTON_ICON_PATH}searchUnselected.svg`;

export const PATH_BUTTON_GREY_SEARCH_UNSELECTED_ICON = `${BUTTON_ICON_PATH}greySearchUnselected.svg`;
export const PATH_BUTTON_GREY_SEARCH_SELECTED_ICON = `${BUTTON_ICON_PATH}greySearchSelected.svg`;

export const PATH_BUTTON_ACTIVE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}activeUnselected.svg`;
export const PATH_BUTTON_ACTIVE_SELECTED_ICON = `${BUTTON_ICON_PATH}activeSelected.svg`;
export const PATH_BUTTON_ACTIVE_DISABLED_ICON = `${BUTTON_ICON_PATH}activeSelected.svg`;

export const PATH_BUTTON_INACTIVE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}inactiveUnselected.svg`;
export const PATH_BUTTON_INACTIVE_SELECTED_ICON = `${BUTTON_ICON_PATH}inactiveSelected.svg`;
export const PATH_BUTTON_INACTIVE_DISABLED_ICON = `${BUTTON_ICON_PATH}inactiveSelected.svg`;

export const PATH_BUTTON_GREY_DELETED_UNSELECTED_ICON = `${BUTTON_ICON_PATH}greyDeletedUnselected.svg`;
export const PATH_BUTTON_GREY_DELETED_SELECTED_ICON = `${BUTTON_ICON_PATH}greyDeletedSelected.svg`;
export const PATH_BUTTON_GREY_DELETED_DISABLED_ICON = `${BUTTON_ICON_PATH}greyDeletedSelected.svg`;

export const PATH_BUTTON_CUSTOMIZE_COLUMN_UNSELECTED_ICON = `${BUTTON_ICON_PATH}customizeColumnUnselected.svg`;
export const PATH_BUTTON_CUSTOMIZE_COLUMN_SELECTED_ICON = `${BUTTON_ICON_PATH}customizeColumnSelected.svg`;
export const PATH_BUTTON_CUSTOMIZE_COLUMN_DISABLED_ICON = `${BUTTON_ICON_PATH}customizeColumnSelected.svg`;

export const PATH_BUTTON_GRID_EXPORT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}exportUnselected.svg`;
export const PATH_BUTTON_GRID_EXPORT_DISABLED_ICON = `${BUTTON_ICON_PATH}exportDisabled.svg`;

export const PATH_BUTTON_GRID_FOLD = `${BUTTON_ICON_PATH}gridFold.svg`;
export const PATH_BUTTON_GRID_UNFOLD = `${BUTTON_ICON_PATH}gridUnfold.svg`;

export const PATH_BUTTON_ATTACHED_UNSELECTED_ICON = `${BUTTON_ICON_PATH}attachedUnselected.svg`;
export const PATH_BUTTON_ATTACHED_SELECTED_ICON = `${BUTTON_ICON_PATH}attachedSelected.svg`;
export const PATH_BUTTON_ATTACHED_DISABLED_ICON = `${BUTTON_ICON_PATH}attachedDisabled.svg`;

export const PATH_BUTTON_SEND_UNSELECTED_ICON = `${BUTTON_ICON_PATH}sendUnselected.svg`;
export const PATH_BUTTON_SEND_SELECTED_ICON = `${BUTTON_ICON_PATH}sendSelected.svg`;
export const PATH_BUTTON_SEND_DISABLED_ICON = `${BUTTON_ICON_PATH}sendDisabled.svg`;

export const PATH_BUTTON_ASSIGN_UNSELECTED_ICON = `${BUTTON_ICON_PATH}assignUnselected.svg`;
export const PATH_BUTTON_ASSIGN_SELECTED_ICON = `${BUTTON_ICON_PATH}assignSelected.svg`;
export const PATH_BUTTON_ASSIGN_DISABLED_ICON = `${BUTTON_ICON_PATH}assignDisabled.svg`;

export const PATH_BUTTON_SKIP_UNSELECTED_ICON = `${BUTTON_ICON_PATH}skipUnselected.svg`;

export const PATH_BUTTON_GREY_SEARCH = `${BUTTON_ICON_PATH}greySearch.svg`;
export const PATH_BUTTON_GREY_SAVED_LIST = `${BUTTON_ICON_PATH}greySavedList.svg`;

export const PATH_BUTTON_EMAIL_UNSELECTED_ICON = `${BUTTON_ICON_PATH}emailUnselected.svg`;
export const PATH_BUTTON_EMAIL_SELECTED_ICON = `${BUTTON_ICON_PATH}emailSelected.svg`;

export const PATH_BUTTON_INVOICE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}invoice.svg`;
export const PATH_BUTTON_INVOICE_SELECTED_ICON = `${BUTTON_ICON_PATH}invoice.svg`;
export const PATH_BUTTON_INVOICE_DISABLED_ICON = `${BUTTON_ICON_PATH}invoiceDisabled.svg`;

export const PATH_DATAGRID_GROUP_FOLD_WHITE_ICON = `${BUTTON_ICON_PATH}groupFoldWhite.svg`;
export const PATH_DATAGRID_GROUP_UNFOLD_WHITE_ICON = `${BUTTON_ICON_PATH}groupUnfoldWhite.svg`;

export const PATH_BUTTON_OVERWRITE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}overWrite.svg`;

export const PATH_BUTTON_AREA_SCAN_ICON = `${BUTTON_ICON_PATH}areaScan.svg`;

export const PATH_BUTTON_ACCEPT_UNSELECTED_ICON = `${BUTTON_ICON_PATH}acceptUnselected.svg`;
export const PATH_BUTTON_ACCEPT_SELECTED_ICON = `${BUTTON_ICON_PATH}acceptSelected.svg`;

export const PATH_BUTTON_FILE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}fileUnselected.svg`;
export const PAHT_BUTTON_DELETE_RED_CROSS_ICON = `${BUTTON_ICON_PATH}redCross.svg`;

export const PATH_BUTTON_EXPAND_ICON = `${BUTTON_ICON_PATH}expand.svg`;

export const PATH_SIDEBAR_MENU_ARROW_ICON = {
  greyScale: `${BUTTON_ICON_PATH}greyNextArrow.svg`,
};

export const PATH_HEADER_NAVIGATOR_ICON = `${BUTTON_ICON_PATH}headerNavigator.svg`;
export const PATH_HEADER_NAVIGATOR_ARROW_ICON = `${BUTTON_ICON_PATH}headerNavigatorArrow.svg`;

export const PATH_BUTTON_LOCK_PAY_UNSELECTED_ICON = `${BUTTON_ICON_PATH}lockPay.svg`;

export const PATH_BUTTON_YARD_SELECTED_ICON = `${BUTTON_ICON_PATH}yardSelected.svg`;
export const PATH_BUTTON_YARD_UNSELECTED_ICON = `${BUTTON_ICON_PATH}yardUnselected.svg`;
export const PATH_BUTTON_YARD_BLUE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}blueYardUnselected.svg`;

export const PATH_BUTTON_CATEGORY_SELECTED_ICON = `${BUTTON_ICON_PATH}categorySelected.svg`;
export const PATH_BUTTON_CATEGORY_UNSELECTED_ICON = `${BUTTON_ICON_PATH}categoryUnselected.svg`;

export const PATH_BUTTON_SCHEDULE_TYPE_SELECTED_ICON = `${BUTTON_ICON_PATH}scheduleTypeSelected.svg`;
export const PATH_BUTTON_SCHEDULE_TYPE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}scheduleTypeUnselected.svg`;

export const PATH_BUTTON_COLOR_SELECTED_ICON = `${BUTTON_ICON_PATH}colorSelected.svg`;
export const PATH_BUTTON_COLOR_UNSELECTED_ICON = `${BUTTON_ICON_PATH}colorUnselected.svg`;

export const PATH_BUTTON_LIVE_SHARE_SELECTED_ICON = `${BUTTON_ICON_PATH}liveShareSelected.svg`;
export const PATH_BUTTON_LIVE_SHARE_UNSELECTED_ICON = `${BUTTON_ICON_PATH}liveShareUnselected.svg`;

// SignIn Image path
export const SIGN_IN_IMAGE_PATH = '/assets/images/signInIcon/';
export const PATH_SIGN_IN_BUTTON = `${SIGN_IN_IMAGE_PATH}button.svg`;
export const PATH_SIGN_IN_LOGO = `${SIGN_IN_IMAGE_PATH}logo.png`;
export const PATH_SIGN_IN_BACKGROUND = `${SIGN_IN_IMAGE_PATH}background.svg`;
