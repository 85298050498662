import React from 'react';
import StyledDisabledField from '../../../../../../styles/BCDetail/Document/StyledDisabledField';
import CommonOpenModal from '../../../../../SecondInputs/CommonOpenModal/CommonOpenModal';

const MobileMemoButtonField = ({
  setSelectedRowData,
  scheduleSeq,
  setMobileModalOpener,
}) => {
  return (
    <>
      <StyledDisabledField className="w-[99px] bg-[#F2F4F8] absolute right-[269.5px] top-0" />
      <div className="absolute right-[275.5px] top-1/2 transform -translate-y-1/2 ">
        <CommonOpenModal
          width="w-[87px]"
          onClick={() => {
            setSelectedRowData({
              scheduleSeq,
            });
            setMobileModalOpener(true);
          }}
        />
      </div>
    </>
  );
};

export default MobileMemoButtonField;
