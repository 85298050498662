import styled from 'styled-components';

const StyledCommonToastContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 20px 0px 26px;
  justify-content: space-between;
  align-items: center;
`;

export default StyledCommonToastContentWrapper;
