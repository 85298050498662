const isDisabled = ({ gridParam, scheduleType }) => {
  if (scheduleType === 'pu') {
    return (
      gridParam.data.sche_type !== 'DROP' ||
      !gridParam?.data?.pu_loc_exist ||
      gridParam.data?.pu_actual_in
    );
  }
  if (
    scheduleType === 'po' ||
    scheduleType === 'dilv' ||
    scheduleType === 'rtn'
  ) {
    return (
      !gridParam.data?.[`${scheduleType}_loc_exist`] ||
      gridParam.data?.[`${scheduleType}_actual_in`]
    );
  }
  return false;
};

export default isDisabled;
