import React, { useContext } from 'react';
import { ToastContext } from '../../contexts/Toast/ToastContext';
import StyledCommonToastBackGround from '../../styles/Common/CommonToast/StyledCommonToastBackGround';
import CommonToastItem from './CommonToastItem';

const CommonContextToast = () => {
  const { toastList } = useContext(ToastContext);

  return (
    toastList?.length > 0 && (
      <StyledCommonToastBackGround>
        {toastList?.map(toast => (
          <CommonToastItem key={toast.key} toastKey={toast.key} {...toast} />
        ))}
      </StyledCommonToastBackGround>
    )
  );
};

export default CommonContextToast;
