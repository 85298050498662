import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import tmsReportKeys from '../keys';
import getStatisticsList from '../../../services/TmsReport/Statistics/Get/getStatisticsList';
import getURIQuery from '../../../utils/TmsReport/Common/getURIQuery';
import { STATISTICS_TABLE_TITLE_VALUE } from '../../../constants/TmsReport/Statistics/statisticsValues';
import generateStatisticsData from '../../../utils/TmsReport/Statistics/Generator/generateStatisticsData';

const useGetStatisticsList = ({
  cycle,
  dateQuery,
  divQuery,
  setIsLoading,
  setIsError,
  setErrorMessage,
  setIsOpenErrorModal,
}) => {
  const uriQueryString = getURIQuery(divQuery);

  const [statisticsList, setStatisticsList] = useState({});
  const [currentDate, setCurrentDate] = useState(null);
  const [prevDate, setPrevDate] = useState(null);
  const [nextDate, setNextDate] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
  }, [cycle, dateQuery]);

  Object.keys(STATISTICS_TABLE_TITLE_VALUE).forEach(tableTitle => {
    const result = useQuery({
      queryKey: tmsReportKeys?.statisticsData({
        period: cycle,
        stat: tableTitle,
        divQuery,
        dateQuery,
      }),
      queryFn: () =>
        getStatisticsList({
          uriQueryString,
          period: cycle,
          stat: tableTitle,
          ...dateQuery,
        }),
      refetchOnWindowFocus: false,
    });

    useEffect(() => {
      setIsLoading(prev =>
        !prev ? prev : result?.isLoading && result?.isFetching,
      );
      setIsError(prev => prev || result?.isError);

      if (result?.isError) {
        setErrorMessage(result?.error?.message);
        setIsOpenErrorModal(true);
      }

      if (!result?.isError && !result?.isLoading && !result?.isFetching) {
        const { tableData } = generateStatisticsData(result?.data?.data, {
          setCurrentDate,
          setPrevDate,
          setNextDate,
        });
        setStatisticsList(list => ({ ...list, [tableTitle]: tableData }));
      }
    }, [result?.isError, result?.isLoading, result?.isFetching]);
  });

  return {
    statisticsList,
    currentDate,
    prevDate,
    nextDate,
  };
};

export default useGetStatisticsList;
