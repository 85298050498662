import FoldCellRenderer from '../../../../components/CommonDataGrid/Component/FoldCellRenderer';
import { GRID_ROW_FOLD_WIDTH } from '../../../../constants/Common/DataGrid/setting';
import FoldHeaderRenderer from '../../../../components/CommonDataGrid/Component/FoldHeaderRenderer';

/**
 * @description
 * Function that generate fold column
 * @param {object} props
 * Props that contains the grid object(API).
 * @returns
 * Column definition.
 */
const rowFolderGenerator = ({ ...props }) => {
  return {
    headerName: 'FOLD',
    headerComponent: FoldHeaderRenderer,
    headerComponentParams: {
      ...props,
    },
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      // Turn off the row count
      suppressCount: true,
      // Turn off double click for expand
      suppressDoubleClickExpand: true,
      // Disable checkbox selection
      checkbox: false,
      // Provide an inner renderer
      innerRenderer: FoldCellRenderer,
      innerRendererParams: { ...props },
    },
    minWidth: GRID_ROW_FOLD_WIDTH,
    maxWidth: GRID_ROW_FOLD_WIDTH,
    width: GRID_ROW_FOLD_WIDTH,
    resizable: false,
    sortable: false,
    filter: false,
    floatingFilter: false,
    editable: false,
    suppressRowClickSelection: true,
    suppressMovable: true,
    onCellDoubleClicked: null,
  };
};

export default rowFolderGenerator;
