import React from 'react';

const TotalBottomBar = ({ totalRows, totalAmount }) => {
  return (
    <div className="w-full h-[30px] bg-[#C6D7FF] flex items-center pl-[198px] pr-[198px] justify-between shadow-[0_0_13.6px_rgba(57,110,235,0.53)]">
      <div className="flex gap-x-[63px]">
        <p className="font-[500] text-[12px] text-[#222222]">TOTAL ROWS</p>
        <p className="font-[700] text-[12px] text-[#222222]">{totalRows}</p>
      </div>
      <div className="flex gap-x-[30px]">
        <p className="font-[500] text-[12px] text-[#222222]">TOTAL AMOUNT</p>
        <p className="font-[700] text-[12px] text-[#222222]">
          $ {totalAmount?.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default TotalBottomBar;
