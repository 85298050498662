import React, { useEffect, useState } from 'react';

import MobileMemoButtonField from '../MobileMemoButtonField';
import ScheduleRow from '..';

const ScheduleRowJob = ({ pageCategory, jobSet, ...props }) => {
  /** Get schedule job set */
  const [scheduleJobSet, setScheduleJobSet] = useState(jobSet || null);
  useEffect(() => {
    setScheduleJobSet(jobSet);
  }, [jobSet]);

  /** Render schedule row according to job-set */
  return scheduleJobSet?.map((job, index) => {
    /** Get each location and sequence from job */
    const {
      driver,
      from_location: frLoc,
      live_dilv_location: ldLoc,
      to_location: toLoc,
      seq,
    } = job;

    const jobProps = {
      jobSet,
      jobData: job,
      prevData: index !== 0 ? scheduleJobSet[index - 1] : null,
      nextData:
        index !== scheduleJobSet.length - 1 ? scheduleJobSet[index + 1] : null,
      jobSetIdx: index,
      scheduleSeq: seq,
      scheduleType: 'job',
      driver,
    };

    /** Row key */

    return (
      (frLoc || ldLoc || toLoc) && (
        <div className="relative" key={seq}>
          <div key={seq} className="flex flex-col gap-y-[2.5px]">
            {/* Render schedule row if from_location is exist */}
            {frLoc && (
              <ScheduleRow
                pageCategory={pageCategory}
                data={frLoc}
                rowIdx={0}
                rowSeq={frLoc?.seq}
                dataKey={`${seq}_${frLoc?.seq}`}
                locationInfo="fr"
                frDataKey={`${seq}_${frLoc?.seq}_fr`}
                ldDataKey={`${seq}_${ldLoc?.seq}_ld`}
                toDataKey={`${seq}_${toLoc?.seq}_to`}
                {...jobProps}
                {...props}
              />
            )}
            {/* Render schedule row if live_dilv_location is exist */}
            {ldLoc && (
              <ScheduleRow
                pageCategory={pageCategory}
                data={ldLoc}
                rowIdx={1}
                rowSeq={ldLoc?.seq}
                dataKey={`${seq}_${ldLoc?.seq}`}
                locationInfo="ld"
                frDataKey={`${seq}_${frLoc?.seq}_fr`}
                ldDataKey={`${seq}_${ldLoc?.seq}_ld`}
                toDataKey={`${seq}_${toLoc?.seq}_to`}
                {...jobProps}
                {...props}
              />
            )}
            {/* Render schedule row if to_location is exist */}
            {toLoc && (
              <ScheduleRow
                pageCategory={pageCategory}
                data={toLoc}
                rowIdx={2}
                rowSeq={toLoc?.seq}
                dataKey={`${seq}_${toLoc?.seq}`}
                locationInfo="to"
                frDataKey={`${seq}_${frLoc?.seq}_fr`}
                ldDataKey={`${seq}_${ldLoc?.seq}_ld`}
                toDataKey={`${seq}_${toLoc?.seq}_to`}
                {...jobProps}
                {...props}
              />
            )}
          </div>
          {/* Render a mobile button per job */}
          <MobileMemoButtonField
            setSelectedRowData={props.setSelectedRowData}
            scheduleSeq={seq}
            setMobileModalOpener={props.setMobileModalOpener}
          />
        </div>
      )
    );
  });
};

export default ScheduleRowJob;
