import styled from 'styled-components';

const StyledCommonToastTitle = styled.div`
  width: 100%;
  height: 18px
  line-height: 17.5px;
  font-weight: 700;
  font-size: 15px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default StyledCommonToastTitle;
