import React from 'react';

const ScheduleTableColumnGroup = ({ ...props }) => {
  return (
    <div className="flex items-center gap-x-[2px] ml-[468.5px]">
      <div className="w-[581.5px] h-[26px] pt-[6px] pb-[7px] bg-[#DDE2EC] text-[#222] font-[500] text-center text-[11px]">
        IN
      </div>
      <div className="bg-[#CCD3E3] w-[289.5px] h-[26px] pt-[6px] pb-[7px] text-[#222] font-[500] text-center text-[11px]">
        OUT
      </div>
    </div>
  );
};

export default ScheduleTableColumnGroup;
