const convertDispatchType = (type, search) => {
  if (type === 'M') {
    if (search) return 'import';
    return 'imp';
  }
  if (type === 'X') {
    if (search) return 'export';
    return 'exp';
  }
  if (type === 'V') {
    if (search) return 'van';
    return 'van';
  }
  if (type === 'B') {
    if (search) return 'brokerage';
    return 'brokerage';
  }
  return null;
};

export default convertDispatchType;
