import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import DetailModal from '../../../components/TmsReport/Perdiem/DetailModal';
import SearchModal from '../../../components/TmsReport/Perdiem/SearchModal';
import useGetPerdiemList from '../../../hooks/TmsReport/Perdiem/useGetPerdiemList';
import usePatchPerdiemMutation from '../../../hooks/TmsReport/Perdiem/usePatchPerdiemMutation';
import usePostSendEmailMutation from '../../../hooks/TmsReport/Perdiem/usePostSendEmailMutation';
import StyledTMSReportCommonButtonContainer from '../../../styles/TmsReport/StyledTMSReportCommonButtonContainer';
import StyledTMSReportCommonDataGridContainer from '../../../styles/TmsReport/StyledTMSReportCommonDataGridContainer';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import createTmsReportPerdiemColumnDefs from '../../../utils/TmsReport/Perdiem/createTmsReportPerdiemColumnDefs';
import setBCDetailTab from '../../../utils/Common/Handler/setBCDetailTitle';

const Perdiem = () => {
  const gridRef = useRef(null);
  const searchController = useForm();
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  );
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const division = useSelector(state => state.nowDiv);
  const { isLoading, data, isError } = useGetPerdiemList({
    division,
    fromDate,
    toDate,
  });

  const { mutate: patchPerdiemMutation } = usePatchPerdiemMutation({
    setIsMessageOpen,
    setMessage,
    gridRef,
  });

  const { mutate: postSendEmailMutation } = usePostSendEmailMutation({
    setIsMessageOpen,
    setMessage,
    division,
    fromDate,
    toDate,
  });

  const [columnDefs, setColumnDefs] = useState(
    createTmsReportPerdiemColumnDefs({
      postSendEmailMutation,
      setMessage,
      setIsSuccess,
      setIsMessageOpen,
    }),
  );

  useEffect(() => {
    if (isError) {
      setMessage('Error occurred while fetching data.');
      setIsSuccess(false);
      setIsMessageOpen(true);
    }
  }, [isError]);

  const handleEmailSend = ({ buttonRef }) => {
    const selectedRows = gridRef?.current?.api
      ?.getSelectedNodes()
      ?.filter(node => node?.displayed)
      ?.map(node => node?.data);
    if (selectedRows.length === 0) {
      setMessage('Please select a row.');
      setIsSuccess(false);
      setIsMessageOpen(true);
      if (buttonRef) updateButtonDisabled({ ref: buttonRef, disabled: false });
      return;
    }
    const billto = selectedRows[0].billto;
    for (let i = 1; i < selectedRows.length; i++) {
      if (selectedRows[i].billto !== billto) {
        setMessage('Please select rows with same billto.');
        setIsSuccess(false);
        setIsMessageOpen(true);
        if (buttonRef)
          updateButtonDisabled({ ref: buttonRef, disabled: false });
        return;
      }
    }
    selectedRows.forEach(row => {
      postSendEmailMutation({
        woNo: row.wo_no,
        buttonRef,
      });
    });
  };

  return (
    <>
      {isLoading && <CommonLoading open={isLoading} />}
      {isDetailModalOpen && (
        <DetailModal
          open={isDetailModalOpen}
          setOpen={setDetailModalOpen}
          data={modalData}
          setAlertOpen={setIsMessageOpen}
          setAlertMessage={setMessage}
          patchPerdiemMutation={patchPerdiemMutation}
        />
      )}
      <FormProvider {...searchController}>
        <form>
          {isSearchModalOpen && (
            <SearchModal
              open={isSearchModalOpen}
              setOpen={setSearchModalOpen}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              setIsMessageOpen={setIsMessageOpen}
              setMessage={setMessage}
              setIsSuccess={setIsSuccess}
            />
          )}
        </form>
      </FormProvider>
      {isMessageOpen && !isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      <div className="w-full h-full flex flex-col">
        <StyledTMSReportCommonButtonContainer>
          <CommonButton
            category="greySearch"
            border="0"
            backgroundColor="transparent"
            color="#666666"
            fontWeight="700"
            onClick={() => {
              setSearchModalOpen(true);
            }}
          >
            Search
          </CommonButton>
          <CommonButton
            type="button"
            category="excel"
            onClick={() => exportToExcel({ gridRef, fileName: 'PERDIEM' })}
          >
            EXCEL
          </CommonButton>
          <CommonButton
            type="button"
            category="email"
            preventDupClick
            onClick={({ buttonRef }) => handleEmailSend({ buttonRef })}
          >
            Email
          </CommonButton>
        </StyledTMSReportCommonButtonContainer>
        <StyledTMSReportCommonDataGridContainer>
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={data}
            onCellClicked={e => {
              if (e?.gridParam?.colDef?.field === 'checkboxRowSelector') return;
              if (e?.gridParam?.colDef?.field === 'email') return;
              if (
                e?.gridParam?.colDef?.field === 'category' ||
                e?.gridParam?.colDef?.field === 'wo_no'
              ) {
                const bcDetailTab = window.open(
                  `/bc-detail/detail-info/${e?.gridParam?.data?.category?.toLowerCase()}/${
                    e?.gridParam?.data?.wo_no
                  }`,
                  'bc-detail-tab',
                );
                setBCDetailTab({
                  bcDetailTab,
                  woNo: e?.gridParam?.data?.wo_no,
                });
                return;
              }
              setModalData(e?.gridParam?.data);
              setDetailModalOpen(true);
            }}
          />
        </StyledTMSReportCommonDataGridContainer>
      </div>
    </>
  );
};

export default Perdiem;
