import getDateDef from '../../../utils/DailyReport/Common/Generator/getDateDef';
import getDatetimeDef from '../../../utils/DailyReport/Common/Generator/getDatetimeDef';
import getNumberDef from '../../../utils/DailyReport/Common/Generator/getNumberDef';
import CHECKBOX_DEF from '../../Dispatch/Common/checkBoxDef';

const DAILY_REPORT_BASIC_COLUMN_DEF = {
  no: {
    valueGetter: params => params.node.rowIndex + 1,
  },
  hazmat: CHECKBOX_DEF,
  wgt: getNumberDef('wgt'),

  lfd: getDateDef('lfd'),
  rfd: getDateDef('rfd'),
  erd: getDateDef('erd'),
  empty_load_cfm: getDateDef('empty_load_cfm'),

  cutoff: getDatetimeDef('cutoff'),
  po_actual_out: getDatetimeDef('po_actual_out'),

  po_schedule: getDatetimeDef('po_schedule'),
  dilv_schedule: getDatetimeDef('dilv_schedule'),
  rtn_schedule: getDatetimeDef('rtn_schedule'),
};

export default DAILY_REPORT_BASIC_COLUMN_DEF;
