import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import { acctDefaultAPI } from '../../api';

export const getSelectedARAging = async ({ id }) => {
  if (id === '') return null;
  try {
    const response = await acctDefaultAPI.get(`ar-aging/${id}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
