import dayjs from 'dayjs';

/**
 *
 * @param {string | object} date
 * @returns
 */
export const getFormattedDateGenerator = ({ date }) => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  return null;
};

/**
 * @param {string | object} date
 * @param {string | object} time
 * @returns
 */
export const getFormattedTimeGenerator = ({ date, time }) => {
  if (typeof date === 'object' && typeof date === 'object') {
    if (date && dayjs(date).isValid() && time && dayjs(time).isValid()) {
      return dayjs(
        `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm:ss')}`,
      ).format('HH:mm:ss');
    }
  }
  if (typeof date === 'string' && typeof date === 'string') {
    if (
      date &&
      dayjs(date).isValid() &&
      time &&
      dayjs(`${date} ${time}`).isValid()
    ) {
      return dayjs(
        `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(`${date} ${time}`).format(
          'HH:mm:ss',
        )}`,
      ).format('HH:mm:ss');
    }
  }
  return null;
};

/**
 * @param {string | object} date
 * @param {string | object} time
 * @returns
 */
export const getConvertDateTimeGenerator = ({ date, time }) => {
  if (typeof date === 'object' && typeof date === 'object') {
    if (date && dayjs(date).isValid() && time && dayjs(time).isValid()) {
      return dayjs(
        `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm:ss')}`,
      );
    }
  }

  if (typeof date === 'string' && typeof date === 'string') {
    if (
      date &&
      dayjs(date).isValid() &&
      time &&
      dayjs(`${date} ${time}`).isValid()
    ) {
      return dayjs(
        `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(`${date} ${time}`).format(
          'HH:mm:ss',
        )}`,
      );
    }
  }
  return null;
};
