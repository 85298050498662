import { createSlice } from '@reduxjs/toolkit';
import { BCDETAIL_COMMON_SLICE_KEY } from '../../Constants/BCDetail/key';

/**
 * Initial state of slice
 */
export const bcDetailCommonInitialState = {
  loading: false,
  error: null,
  pageTitle: '',
};

/**
 * Redux slice
 */
const bcDetailCommonSlice = createSlice({
  name: BCDETAIL_COMMON_SLICE_KEY,
  initialState: bcDetailCommonInitialState,
  reducers: {
    setDetailPageTitle: {
      reducer: (state, action) => ({
        ...state,
        pageTitle: action.payload,
      }),
    },
  },
});

/**
 * Export
 */
export const { setDetailPageTitle } = bcDetailCommonSlice.actions;
export default bcDetailCommonSlice.reducer;
