import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

const getCustomerData = async ({ listName }) => {
  try {
    const response = await cusReportDefaultUrl.get(`${listName}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getCustomerData;
