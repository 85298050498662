import dayjs from 'dayjs';
import React from 'react';
import openDailyReport from '../../../../utils/TmsReport/Common/openDailyReport';

const WeeklyTableHeader = ({ page, weeklyData, day, allQueries }) => {
  return (
    <div
      className="flex flex-row gap-[7px] w-[251px] h-[35px]
      justify-center items-center cursor-pointer text-[13px] font-[500]"
      onClick={() => {
        openDailyReport({
          page,
          date: dayjs(weeklyData?.[day]?.date).format('YYYY-MM-DD'),
          allQueries,
        });
      }}
    >
      <div className="flex text-[#001E5F]">{day}</div>
      <div className="flex text-[#222222]">
        {`${dayjs(weeklyData?.[day].date).format('MM/DD/YY')}`}
      </div>
    </div>
  );
};

export default WeeklyTableHeader;
