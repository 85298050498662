import React from 'react';
import CommonButton from '../../../CommonButton/CommonButton';
import onRowCancelEditClickHandler from '../../../../utils/Common/Datagrid/Handler/onRowCancelEditClickHandler';

/**
 * @description
 * Component that appears when the Edit button is pressed
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @param {string} modType
 * Patameter that indicate the type of modification of the grid
 * GRID_MOD_BOTH: Enable edit and delete
 * GRID_MOD_EDIT: Enable edit only
 * GRID_MOD_DELETE: Enable delete only
 * @returns
 * Component that appears when the Edit button is pressed
 */
const CurrentRowEditingCell = ({ gridParam, modType, ...props }) => {
  return (
    <>
      <CommonButton
        category="save"
        customStyle={{ width: '85px', size: 'small' }}
        onClick={() => {
          /** Stop editing when save button clicked */
          gridParam?.api?.stopEditing(false);
        }}
      >
        Save
      </CommonButton>
      <CommonButton
        category="cancel"
        customStyle={{ width: '85px', size: 'small' }}
        onClick={() => {
          /** Cancel and stop editing when cancel button clicked */
          onRowCancelEditClickHandler({ gridParam });
        }}
      >
        Cancel
      </CommonButton>
    </>
  );
};

export default CurrentRowEditingCell;
