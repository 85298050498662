import React, { useEffect, useState } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import themes from './styles/Themes';
import {
  getChassisType,
  getDiv,
  getDriver,
  getPool,
  getSize,
  getSsl,
  getType,
} from './services/Common/Common/get';
import GlobalStyle from './styles/Global/GlobalStyle';
import useGetUserInfo from './hooks/SignIn/useGetUserInfo';
import CommonLoading from './components/CommonLoading/CommonLoading';
import { ToastProvider } from './contexts/Toast/ToastContext';
import CommonContextToast from './components/CommonContextToast';

/**
 * Control console.log
 * If REACT_APP_ENV variable in environment file is 'production',
 * console will not be printed.
 */
if (process.env.REACT_APP_RELEASE_ENV === 'prod') {
  console.log = function noConsole() {};
  console.warn = function noConsole() {};
  console.error = function noConsole() {};
}

const App = () => {
  const dispatch = useDispatch();
  const [isEssentialLoading, setEssentialLoading] = useState(true);

  const {
    isLoading: userInfoLoading,
    isFetching: userInfoFetching,
    isRefetching: userInfoRefetching,
  } = useGetUserInfo({ setEssentialLoading });

  useEffect(() => {
    /** Get user ID to check is login or not */
    const getUserId = localStorage.getItem('userId');
    /** If userId is exist, load common API */
    if (getUserId && getUserId !== '') {
      dispatch(getSsl());
      dispatch(getSize());
      dispatch(getPool());
      dispatch(getType());
      dispatch(getDriver());
      dispatch(getDiv());
      dispatch(getChassisType());
    }
  }, []);

  if (
    userInfoLoading ||
    userInfoFetching ||
    userInfoRefetching ||
    isEssentialLoading
  ) {
    return (
      <CommonLoading
        open={
          userInfoLoading ||
          userInfoFetching ||
          userInfoRefetching ||
          isEssentialLoading
        }
      />
    );
  }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={themes}>
        <ToastProvider>
          <CommonContextToast />
          <Outlet />
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
