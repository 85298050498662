const dailyReportKeys = {
  all: ['DAILY_REPORT'],
  list: ({ type, date, cat, div, sche }) => [
    ...dailyReportKeys.all,
    type,
    date,
    cat,
    div,
    sche,
  ],

  yardAll: ['DAILY_REPORT_YARD_LIST'],

  returnYardAll: ['DAILY_REPORT_RETURN_YARD'],
  returnYardList: ({ yard, cat, div, sche, color }) => [
    ...dailyReportKeys.returnYardAll,
    yard,
    cat,
    div,
    sche,
    color,
  ],

  driver: () => [...dailyReportKeys.all, 'DRIVER'],
  driverList: ({ woNo, driver, page }) => [
    ...dailyReportKeys.driver(),
    woNo,
    driver,
    page,
  ],
  driverAP: ({ woNo, scheduleSeq, driver, page }) => [
    ...dailyReportKeys.driverList({ woNo, driver, page }),
    scheduleSeq,
  ],
};

export default dailyReportKeys;
