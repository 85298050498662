import styled from 'styled-components';

const StyledWOLabel = styled.div`
  display: flex;
  width: 8px;
  height: 100%;
  background-color: ${props => props?.backgroundColor || 'white'};
`;

export default StyledWOLabel;
