import dayjs from 'dayjs';
import FloatingFilterInput from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import {
  DATAGRID_RECONCILE_STATUS,
  RECONCILE_STATUS,
} from '../../../../constants/Acct/Common/acctElement';
import { NULL_PARAMETER_VALUE } from '../../../../constants/Acct/Common/staticNames';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { CELL_CUSTOM_STYLE } from '../../../../styles/Acct/columnStyles';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  FLOATING_FILTER_DEFAULT_OPTION,
  generateListToOption,
} from '../../Common/Generator/floatingFilterSelectOptionGenerator';
import generateInvoiceDateRange from '../../Common/Generator/generateInvoiceDateRange';
import {
  changePairList,
  getPairValue,
} from '../../Common/Handler/changedPairHandler';
import { generateReconcileStatusValue } from '../../Common/Generator/dataGridValueGetter';
import BillButtonCellRender from '../../../../components/Acct/Common/BillButtonCellRender';

const createChassisReconcileColumnDefs = ({
  setWOToQueryObject,
  setWOFromQueryObject,
  woToDateObjectList,
  woFromDateObjectList,
  poolList,
  divList,
  ...columnProps
}) => {
  return [
    {
      headerName: 'DIV',
      field: 'div',
      width: 51,
      editable: param => param?.data?.editable,
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(divList?.map(div => div?.div)),
      },
    },
    {
      headerName: 'VENDOR',
      field: 'vendor',
      width: 72,
      editable: false,
    },
    {
      headerName: 'POOL',
      field: 'pool',
      width: 64,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: poolList,
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: [...FLOATING_FILTER_DEFAULT_OPTION, ...poolList],
      },
    },
    {
      headerName: 'CH #',
      field: 'ch_no',
      width: 86,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 12,
          onChangeHandler: ({ ...props }) => {
            const { getChangedValue } = props;
            setWOToQueryObject(prev => ({
              ...prev,
              ch_no: getChangedValue || NULL_PARAMETER_VALUE,
              cont_no: prev?.cont_no || NULL_PARAMETER_VALUE,
            }));
            setWOFromQueryObject(prev => ({
              ...prev,
              ch_no: getChangedValue || NULL_PARAMETER_VALUE,
              cont_no: prev?.cont_no || NULL_PARAMETER_VALUE,
            }));
          },
        });
      },
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 78,
      valueFormatter: gridParam => {
        const value = generateReconcileStatusValue(gridParam?.data?.status);
        return value;
      },
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: RECONCILE_STATUS,
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: [
          ...FLOATING_FILTER_DEFAULT_OPTION,
          ...DATAGRID_RECONCILE_STATUS,
        ],
      },
    },
    {
      headerName: 'WO(FROM)',
      field: 'wo_from',
      width: 100,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      editable: param => param?.data?.editable,
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          pairList: [
            {
              name: 'div',
              objList: woFromDateObjectList,
              findField: 'woDiv',
              comparisonField: 'woNo',
            },
            {
              name: 'wo_from_date',
              objList: woFromDateObjectList,
              findField: 'woDate',
              comparisonField: 'woNo',
            },
            {
              name: 'pool',
              objList: woFromDateObjectList,
              findField: 'woPool',
              comparisonField: 'woNo',
            },
            {
              name: 'ch_no',
              objList: woFromDateObjectList,
              findField: 'woChassisNo',
              comparisonField: 'woNo',
            },
            {
              name: 'cont_from',
              objList: woFromDateObjectList,
              findField: 'woContNo',
              comparisonField: 'woNo',
            },
          ],
          onChangeHandler: ({ ...props }) => {
            changePairList({ ...props });
            const contNo = getPairValue({
              objList: woFromDateObjectList,
              comparisonField: 'woNo',
              getChangedValue: props?.getChangedValue,
              findField: 'woContNo',
            });

            const chassisNo = getPairValue({
              objList: woFromDateObjectList,
              comparisonField: 'woNo',
              getChangedValue: props?.getChangedValue,
              findField: 'woChassisNo',
            });

            setWOFromQueryObject(prev => ({
              ...prev,
              ch_no: chassisNo,
              cont_no: contNo,
            }));
            setWOToQueryObject(prev => ({
              ...prev,
              ch_no: chassisNo,
            }));
          },
        });
      },
    },
    {
      headerName: 'FROM',
      field: 'wo_from_date',
      width: 65,
      valueGetter: params => {
        const dateValue = params.data?.from_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      editable: param => param?.data?.editable,
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          disabled: true,
          customStyle: {
            textAlign: 'center',
          },
        });
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'WO(TO)',
      field: 'wo_to',
      width: 100,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      editable: param => param?.data?.editable,
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          pairList: [
            {
              name: 'wo_to_date',
              objList: woToDateObjectList,
              findField: 'woDate',
              comparisonField: 'woNo',
            },
            {
              name: 'ch_no',
              objList: woToDateObjectList,
              findField: 'woChassisNo',
              comparisonField: 'woNo',
            },
            {
              name: 'cont_to',
              objList: woToDateObjectList,
              findField: 'woContNo',
              comparisonField: 'woNo',
            },
          ],
          onChangeHandler: ({ ...props }) => {
            changePairList({ ...props });

            const contNo = getPairValue({
              objList: woToDateObjectList,
              comparisonField: 'woNo',
              getChangedValue: props?.getChangedValue,
              findField: 'woContNo',
            });

            const chassisNo = getPairValue({
              objList: woToDateObjectList,
              comparisonField: 'woNo',
              getChangedValue: props?.getChangedValue,
              findField: 'woChassisNo',
            });

            setWOFromQueryObject(prev => ({
              ...prev,
              ch_no: chassisNo,
            }));
            setWOToQueryObject(prev => ({
              ...prev,
              ch_no: chassisNo,
              cont_no: contNo,
            }));
          },
        });
      },
    },
    {
      headerName: 'TO',
      field: 'wo_to_date',
      width: 62,
      valueGetter: params => {
        const dateValue = params.data?.to_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      comparator: dateComparator,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      editable: param => param?.data?.editable,
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          disabled: true,
          customStyle: {
            textAlign: 'center',
          },
        });
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'NGL USED',
      field: 'ngl_used',
      width: 77,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'INV #',
      field: 'inv_no',
      width: 100,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
        }),
    },
    {
      headerName: 'INV DATE',
      field: 'inv_date',
      width: 86,
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.inv_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'P/O CONT #',
      field: 'cont_from',
      width: 100,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          onChangeHandler: ({ ...props }) => {
            setWOFromQueryObject(prev => ({
              ...prev,
              ch_no: prev?.ch_no || NULL_PARAMETER_VALUE,
              cont_no: props?.getChangedValue || NULL_PARAMETER_VALUE,
            }));
          },
        });
      },
    },
    {
      headerName: 'RTN CONT #',
      field: 'cont_to',
      width: 100,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          onChangeHandler: ({ ...props }) => {
            setWOToQueryObject(prev => ({
              ...prev,
              ch_no: prev?.ch_no || NULL_PARAMETER_VALUE,
              cont_no: props?.getChangedValue || NULL_PARAMETER_VALUE,
            }));
          },
        });
      },
    },
    {
      headerName: 'FROM',
      field: 'inv_from',
      width: 65,
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          customStyle: {
            textAlign: 'center',
          },
          onChangeHandler: ({ ...props }) => {
            const invDate =
              props?.getChangedValue && dayjs(props?.getChangedValue)?.isValid()
                ? dayjs(props?.getChangedValue)?.format('YYYY-MM-DD')
                : '';
            setWOFromQueryObject(prev => ({
              ...prev,
              ...generateInvoiceDateRange(invDate),
            }));
          },
        }),
      valueGetter: params => {
        const dateValue = params.data?.inv_from;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'TO',
      field: 'inv_to',
      width: 62,
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          customStyle: {
            textAlign: 'center',
          },
          onChangeHandler: ({ ...props }) => {
            const invDate =
              props?.getChangedValue && dayjs(props?.getChangedValue)?.isValid()
                ? dayjs(props?.getChangedValue)?.format('YYYY-MM-DD')
                : '';
            setWOToQueryObject(prev => ({
              ...prev,
              ...generateInvoiceDateRange(invDate),
            }));
          },
        }),
      valueGetter: params => {
        const dateValue = params.data?.inv_to;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },

    {
      headerName: 'DAYS',
      field: 'inv_days',
      width: 50,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'AMT',
      field: 'inv_amt',
      width: 85,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
    {
      headerName: 'DIFFER',
      field: 'difference',
      width: 60,
      cellStyle: params => {
        if (params.value !== 0) {
          return { backgroundColor: '#F0A0A0' };
        }
        return null;
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
      editable: false,
    },
    {
      headerName: 'FILE',
      width: 70,
      field: 'fileButton',
      floatingFilter: false,
      sortable: false,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
          },
          content: 'File',
          category: 'invoice',
          onClickHandler: ({ ...props }) => {
            window.open(props?.gridParam?.data?.inv_file);
          },
        }),
      cellEditorSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
          },
          content: 'File',
          category: 'invoice',
          onClickHandler: ({ ...props }) => {
            window.open(props?.gridParam?.data?.inv_file);
          },
        }),
    },
    {
      headerName: 'BILL',
      width: 70,
      field: 'editable',
      floatingFilter: false,
      cellRenderer: BillButtonCellRender,
      cellRendererParams: {
        ...columnProps,
      },
      cellEditorSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
            ...CELL_CUSTOM_STYLE.button,
          },
          content: 'Bill',
          category: 'invoice',
          disabled: true,
        }),
    },
  ];
};

export default createChassisReconcileColumnDefs;
