import createHeaderComponent from './createHeaderComponent';

/**
 * @description
 * Function that generate default column definitions.
 * Function to be used when user does not set default column definition.
 * @returns
 * Default column definitions for the data grid.
 */
const defaultColumnDefsGenerator = () => {
  return {
    // Use agTextColumnFilter as default filter.
    // Reference: https://www.ag-grid.com/react-data-grid/filtering/#column-filter-types
    filter: 'agTextColumnFilter',
    // Set to true if no menu should be shown for this column header.
    suppressHeaderMenuButton: true,
    // Use floating filter. If want to use floating filter, filter must be set.
    floatingFilter: true,
    // Enable resize column.
    resizable: true,
    // Set visible sort icon always.
    unSortIcon: true,
    // Set floating filter component params.
    suppressFloatingFilterButton: true,
    // Set sortable
    sortable: true,
    // Set to true if you don't want the column header for this column to span the whole height of the header container.
    suppressSpanHeaderHeight: true,
    // Set header component params.
    headerComponentParams: gridParam => createHeaderComponent({ ...gridParam }),
    // Set editable to false when target row(column) is pinned.
    editable: gridParam => {
      if (gridParam?.node?.rowPinned) return false;
      return true;
    },
    // Prevent to editing when press 'Enter' or 'Backspace' key.
    suppressKeyboardEvent: params => {
      return (
        params?.event?.key === 'Enter' ||
        params?.event?.key === 'Backspace' ||
        params?.event?.key === 'ArrowDown' ||
        params?.event?.key === 'ArrowUp' ||
        params?.event?.key === 'ArrowLeft' ||
        params?.event?.key === 'ArrowRight'
      );
    },
  };
};

export default defaultColumnDefsGenerator;
