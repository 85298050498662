import ExcelJS from 'exceljs';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import {
  STATISTICS_HEADER_CYCLE_VALUE,
  STATISTICS_TABLE_TITLE_VALUE,
  STATISTICS_ROW_TITLE_VALUE,
  STATISTICS_TABLE_NORMAL_TYPE_LIST,
  STATISTICS_TABLE_TOTAL_TYPE_LIST,
  STATISTICS_TABLE_COST_TYPE_LIST,
} from '../../../../constants/TmsReport/Statistics/statisticsValues';

const getFormattedDate = () => {
  const date = moment.tz('America/Los_Angeles');
  const day = date.format('DD');
  const month = date.format('MM');
  const year = date.format('YYYY');

  return `${month}${day}${year}`;
};

const handleExcelClick = async ({ statisticsList, cycle, currentDate }) => {
  const blankSpace = 3;
  const isWeekly = cycle === 'weekly';
  const headers = STATISTICS_HEADER_CYCLE_VALUE[cycle];

  const workBook = new ExcelJS.Workbook();
  const workSheet = workBook.addWorksheet(cycle);

  workSheet.addRow([`Search Date: ${currentDate}`]);

  const generateDivisionRow = (tableTitle, division, divData) => {
    const divisionRow = [];

    if (STATISTICS_TABLE_NORMAL_TYPE_LIST.includes(tableTitle)) {
      Object.entries(divData)?.forEach(([rowTitle, rowData]) => {
        const row = [division, STATISTICS_ROW_TITLE_VALUE[rowTitle]];
        Object.keys(headers)?.map(unit =>
          row.push(isWeekly ? rowData?.[unit]?.count : rowData?.[unit]),
        );

        divisionRow.push(row);
      });
    }

    if (STATISTICS_TABLE_TOTAL_TYPE_LIST.includes(tableTitle)) {
      const row = [division];
      Object.keys(headers)?.map(unit =>
        row.push(isWeekly ? divData?.[unit]?.count : divData?.[unit]),
      );
      divisionRow.push(row);
    }

    if (STATISTICS_TABLE_COST_TYPE_LIST.includes(tableTitle)) {
      const row = [division];
      Object.keys(headers)?.map(unit =>
        row.push(
          isWeekly ? divData?.cases?.[unit]?.count : divData?.cases?.[unit],
          isWeekly ? divData?.amount?.[unit]?.count : divData?.amount?.[unit],
        ),
      );

      divisionRow.push(row);
    }

    return divisionRow;
  };

  const generateTableHeader = tableTitle => {
    const tableHeader = [{ name: STATISTICS_TABLE_TITLE_VALUE[tableTitle] }];

    if (STATISTICS_TABLE_NORMAL_TYPE_LIST.includes(tableTitle)) {
      tableHeader.push({ name: 'TITLE' });
      Object.values(headers)?.map(unit => tableHeader.push({ name: unit }));
    }
    if (STATISTICS_TABLE_TOTAL_TYPE_LIST.includes(tableTitle)) {
      Object.values(headers)?.map(unit => tableHeader.push({ name: unit }));
    }
    if (STATISTICS_TABLE_COST_TYPE_LIST.includes(tableTitle)) {
      Object.values(headers)?.forEach(unit => {
        tableHeader.push({ name: `${unit} CASES` });
        tableHeader.push({ name: `${unit} AMOUNT` });
      });
    }
    return { tableHeader };
  };

  const generateTableRows = (tableTitle, tableData) => {
    const tableRows = [];
    Object.entries(tableData)?.forEach(([division, divData]) => {
      if (division !== 'current_date')
        tableRows.push(...generateDivisionRow(tableTitle, division, divData));
    });

    return { tableRows };
  };

  const generateTableData = (tableTitle, tableData) => {
    const { tableHeader } = generateTableHeader(tableTitle);
    const { tableRows } = generateTableRows(tableTitle, tableData);

    return { tableHeader, tableRows };
  };

  let rowIndex = blankSpace;
  const costTableIndex = [];

  const setStyle = () => {
    const dateRow = workSheet.findRow(1);

    dateRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '002060' },
    };
    dateRow.font = {
      color: { argb: 'FFFFFF' },
    };

    costTableIndex.forEach(index => {
      const headerRow = workSheet.findRow(index);
      headerRow.alignment = {
        wrapText: true,
      };
    });
  };

  Object.entries(statisticsList)?.forEach(([tableTitle, tableData]) => {
    const { tableHeader, tableRows } = generateTableData(tableTitle, tableData);

    if (STATISTICS_TABLE_COST_TYPE_LIST.includes(tableTitle))
      costTableIndex.push(rowIndex);

    workSheet.addTable({
      name: tableTitle,
      ref: `A${rowIndex}`,
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      columns: tableHeader,
      rows: tableRows,
    });
    rowIndex += tableRows.length + blankSpace;
  });

  setStyle();

  const columnWidthStyle = Array(workSheet.columnCount).fill({ width: 11 });
  workSheet.columns = columnWidthStyle;

  const buffer = await workBook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer]),
    `STATISTICS ${cycle.toUpperCase()}_${getFormattedDate()}.xlsx`,
  );
};

export default handleExcelClick;
