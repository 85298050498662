import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import { acctDefaultAPI } from '../../api';

export const getARAgingList = async () => {
  try {
    const response = await acctDefaultAPI.get(`ar-aging`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};
