import React, { useEffect, useRef, useState } from 'react';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonSideModal from '../../../components/CommonSideModal';
import HeaderButtons from '../../../components/Master/Common/HeaderButtons';
import AdvancedSearchModal from '../../../components/Master/Company/AdvancedSearchModal';
import SideModalContent from '../../../components/Master/Company/SideModalContent';
import useGetTermsList from '../../../hooks/Master/Common/useGetTermsList';
import useGetCompanyData from '../../../hooks/Master/Company/useGetCompanyData';
import useGetSelectedCompany from '../../../hooks/Master/Company/useGetSelectedCompany';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import {
  createEditOption,
  createViewOption,
} from '../../../utils/Master/Company/Generator/categoryOptionGenerator';
import createCompanyColumnDefs from '../../../utils/Master/Company/Generator/createCompanyColumnDefs';

const Company = ({ viewPerm, editPerm }) => {
  const gridRef = useRef(null);

  const [rowData, setRowData] = useState();
  const [editMode, setEditMode] = useState(false);

  const [queryObject, setQueryObject] = useState('');

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [sideModalOpen, setSideModalOpen] = useState(false);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageContents, setMessageContent] = useState('');

  const modalProps = {
    setMessageModalOpen,
    setMessageContent,
  };

  const [companyIDOption, setCompanyIDOption] = useState([]);
  const [termsOption, setTermsOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState({
    viewOption: createViewOption({ viewPerm, editPerm }),
    editOption: createEditOption({ editPerm }),
  });

  const [columnDefs, setColumnDefs] = useState(
    createCompanyColumnDefs({
      categoryOption: categoryOption?.viewOption,
    }),
  );

  useEffect(() => {
    setCategoryOption({
      viewOption: createViewOption({ viewPerm, editPerm }),
      editOption: createEditOption({ editPerm }),
    });
  }, [viewPerm, editPerm]);

  useEffect(() => {
    setColumnDefs(
      createCompanyColumnDefs({
        categoryOption: categoryOption?.viewOption,
      }),
    );
  }, [categoryOption]);

  const { isLoading: loadingTermsList, isError: errorTermsList } =
    useGetTermsList({ setTermsOption });

  const {
    data: companyList,
    isLoading,
    isError,
  } = useGetCompanyData({
    setCompanyIDOption,
    queryObject,
  });

  const { mutate: getSelectedCompanyMutator } = useGetSelectedCompany({
    setRowData,
    setEditMode,
    setSideModalOpen,
    ...modalProps,
  });

  const handleAddClick = () => {
    setRowData(null);
    setEditMode(false);
    setSideModalOpen(true);
  };

  return (
    <>
      {(isLoading || isError || loadingTermsList || errorTermsList) && (
        <CommonLoading
          open={isLoading || isError || loadingTermsList || errorTermsList}
        />
      )}
      {/* Company Header */}
      <div className="flex flex-col h-full">
        <HeaderButtons
          gridRef={gridRef}
          setAdvancedSearchOpen={setAdvancedSearchOpen}
          onAddClickHandler={handleAddClick}
          isEditable={editPerm?.compEdit || editPerm?.cneeEdit}
          fileName="COMPANY"
        />
        {/* Company Contents */}
        <StyledMasterCommonDataGridContainer>
          <CommonDataGrid
            columnDefs={columnDefs}
            data={companyList?.data}
            gridRef={gridRef}
            onRowClicked={params =>
              getSelectedCompanyMutator(params?.gridParam?.data?.company)
            }
          />
        </StyledMasterCommonDataGridContainer>
      </div>
      {/* ADD  / EDIT Modal */}
      <CommonSideModal
        open={sideModalOpen}
        setOpen={setSideModalOpen}
        title="Company Detail"
        styles={{ width: '624px' }}
      >
        <SideModalContent
          viewPerm={viewPerm}
          editPerm={editPerm}
          editMode={editMode}
          companyData={editMode ? rowData : undefined}
          termsOption={termsOption}
          companyIDOption={companyIDOption}
          categoryOption={categoryOption}
          gridRef={gridRef}
          {...modalProps}
        />
      </CommonSideModal>
      <AdvancedSearchModal
        categoryOption={categoryOption?.viewOption}
        viewPerm={viewPerm}
        openModal={advancedSearchOpen}
        setOpenModal={setAdvancedSearchOpen}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
      />
      {messageModalOpen && (
        <CommonErrorModal
          open={messageModalOpen}
          message={messageContents}
          setIsOpen={setMessageModalOpen}
        />
      )}
    </>
  );
};

export default Company;
