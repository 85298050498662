import { createSlice } from '@reduxjs/toolkit';
import { WORK_ORDER_CATEGORY_REGULAR } from '../../../constants/Order/Common/standard';
import { ORDER_SLICE_KEY } from '../../Constants/Order';
import {
  getBillTo,
  getBkNo,
  getPoDilvLoc,
  getPool,
  getSize,
  getSsl,
  getType,
} from '../../../services/Order/Redux/getInitialData';

export const orderInitialState = {
  loading: false,
  error: null,
  category: WORK_ORDER_CATEGORY_REGULAR,
  billto: [],
  bkNo: [],
  poDilvLoc: [],
  ssl: [],
  pool: [],
  size: [],
  type: [],
};

const orderSlice = createSlice({
  name: ORDER_SLICE_KEY,
  initialState: orderInitialState,
  reducers: {
    getState: {
      reducer: (state, action) => state,
    },
    updateCategory: {
      reducer: (state, action) => ({ ...state, category: action.payload }),
    },
  },
  extraReducers: builder => {
    /**
     * Bill to
     */
    builder.addCase(getBillTo.fulfilled, (state, action) => {
      state.loading = false;
      state.billto = action.payload;
    });
    builder.addCase(getBillTo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBillTo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * PO DILV RTN location
     */
    builder.addCase(getPoDilvLoc.fulfilled, (state, action) => {
      state.loading = false;
      state.poDilvLoc = action.payload;
    });
    builder.addCase(getPoDilvLoc.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPoDilvLoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Booking #
     */
    builder.addCase(getBkNo.fulfilled, (state, action) => {
      state.loading = false;
      state.bkNo = action.payload;
    });
    builder.addCase(getBkNo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBkNo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * SSL
     */
    builder.addCase(getSsl.fulfilled, (state, action) => {
      state.loading = false;
      state.ssl = action.payload;
    });
    builder.addCase(getSsl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSsl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * POOL
     */
    builder.addCase(getPool.fulfilled, (state, action) => {
      state.loading = false;
      state.pool = action.payload;
    });
    builder.addCase(getPool.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPool.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Size
     */
    builder.addCase(getSize.fulfilled, (state, action) => {
      state.loading = false;
      state.size = action.payload;
    });
    builder.addCase(getSize.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /**
     * Type
     */
    builder.addCase(getType.fulfilled, (state, action) => {
      state.loading = false;
      state.type = action.payload;
    });
    builder.addCase(getType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { getState, updateCategory } = orderSlice.actions;
export default orderSlice.reducer;
