import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useControlQueries = ({ divList }) => {
  const defaultDiv = localStorage.getItem('defaultDiv');

  const [dateQuery, setDateQuery] = useState(dayjs().format('YYYY-MM-DD'));

  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP: true,
    LIVE: true,
    LVAN: true,
  });

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(divList?.map(div => [div, div === defaultDiv])),
  });

  const [categoryQuery, setCategoryQuery] = useState({
    M: true,
    X: true,
    V: true,
    B: true,
    C: true,
  });

  const [allQueries, setAllQueries] = useState({
    sche: scheduleTypeQuery,
    div: divisionQuery,
    cat: categoryQuery,
    date: dateQuery,
  });

  useEffect(() => {
    setAllQueries(prev => ({ ...prev, date: dateQuery }));
  }, [dateQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: { ...scheduleTypeQuery },
      div: { ...divisionQuery },
    }));
  }, [scheduleTypeQuery, divisionQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: {
        ...scheduleTypeQuery,
        LVAN: categoryQuery?.V === false ? false : prev?.sche?.LVAN,
      },
      cat: { ...categoryQuery },
    }));
  }, [categoryQuery]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    allQueries,
  };
};

export default useControlQueries;
