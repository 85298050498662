import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/CommonButton/CommonButton';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { GRID_MOD_EDIT } from '../../../constants/Common/DataGrid/setting';
import useGetTierWeightList from '../../../hooks/Master/TierWeight/useGetTierWeightList';
import usePostTierWeightMutation from '../../../hooks/Master/TierWeight/usePostTierWeightMutation';
import useUpdateTierWeightMutation from '../../../hooks/Master/TierWeight/useUpdateTierWeightMutation';
import StyledMasterCommonButtonContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonButtonContainer';
import StyledMasterCommonDataGridContainer from '../../../styles/Master/Common/DataGrid/StyledMasterCommonDataGridContainer';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import mutationHandler from '../../../utils/Master/Common/mutationHandler';
import setResultData from '../../../utils/Master/Common/setResultData';
import createMasterTierWeightColDefs from '../../../utils/Master/TierWeight/Generator/createMasterTierWeightColDefs';

const TierWeight = () => {
  const div = useSelector(state => state.nowDiv);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const gridRef = useRef(null);

  const {
    loadingTierWeightList,
    tierWeightList,
    isRefetching,
    errorTierWeightList,
  } = useGetTierWeightList({ div });

  const updateTierWeightMutation = useUpdateTierWeightMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    div,
    setIsSuccess,
  });
  const postTierWeightMutation = usePostTierWeightMutation({
    setIsMessageOpen,
    setMessage,
    gridRef,
    div,
    setIsSuccess,
  });

  const [columnDefs, setColumnDefs] = useState(createMasterTierWeightColDefs());

  useEffect(() => {
    if (errorTierWeightList) {
      setIsSuccess(false);
      setMessage('Error occurred while fetching data.');
      setIsMessageOpen(true);
    }
    if (!loadingTierWeightList && tierWeightList && !isRefetching) {
      setResultData(setList, setIsLoading, tierWeightList);
    }
  }, [loadingTierWeightList, isRefetching]);

  return (
    <>
      <CommonLoading open={isLoading} />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {!isLoading && (
        <div className="flex flex-col h-full">
          <StyledMasterCommonButtonContainer>
            <CommonButton
              onClick={() =>
                exportToExcel({ gridRef, fileName: 'TIER WEIGHT' })
              }
              type="button"
              category="excel"
            >
              EXCEL
            </CommonButton>
          </StyledMasterCommonButtonContainer>
          <StyledMasterCommonDataGridContainer>
            <CommonDataGrid
              addable
              modType={GRID_MOD_EDIT}
              gridRef={gridRef}
              data={list}
              columnDefs={columnDefs}
              onSaveClicked={props =>
                mutationHandler(updateTierWeightMutation, props, false)
              }
              onAddClicked={props =>
                mutationHandler(postTierWeightMutation, props, false)
              }
            />
          </StyledMasterCommonDataGridContainer>
        </div>
      )}
    </>
  );
};

export default TierWeight;
