export const STATUS_CHECKBOX_LIST_STYLE = {
  W: {
    backgroundColor: 'unset',
  },
  Y: {
    backgroundColor: '#FFD70090',
  },
  G: {
    backgroundColor: '#A2E47F',
  },
  B: {
    backgroundColor: '#CDE5FF',
  },
};
