const customerReportKeys = {
  all: ['CUSTOMER_REPORT'],
  mainDefault: () => [...customerReportKeys?.all, 'MAIN'],
  main: ({ ...keyProps }) => [
    ...customerReportKeys?.mainDefault(),
    {
      ...keyProps,
    },
  ],
  savedListDefault: () => [...customerReportKeys?.all, 'SAVED_LIST'],
  savedList: ({ div }) => [
    ...customerReportKeys?.savedListDefault(),
    {
      div,
    },
  ],
  setting: ({ div }) => [...customerReportKeys?.all, 'SETTING_LIST', div],
  customerDefault: () => [...customerReportKeys?.all, 'CUSTOMER'],
  customer: ({ listName }) => [
    ...customerReportKeys?.customerDefault(),
    listName,
  ],
};

export default customerReportKeys;
