import { createSlice } from '@reduxjs/toolkit';

const customerCompanySlice = createSlice({
  name: 'customerCompany',
  initialState: null,
  reducers: {
    saveCustomerCompany: (state, action) => action.payload,
  },
});

export const { saveCustomerCompany } = customerCompanySlice.actions;
export default customerCompanySlice.reducer;
