import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchARAgingMemo } from '../../../../services/Acct/ARAging/Patch/patchARAgingMemo';
import acctKeys from '../../keys';

const usePatchARAgingMemoMutation = ({
  setIsAlertOpen,
  setIsSuccessAlert,
  setAlertMessage,
  id,
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchARAgingMemo,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: acctKeys?.selectedARAging({ id }),
      });
      setIsSuccessAlert(true);
      setAlertMessage('Save Success');
      setIsAlertOpen(true);
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: acctKeys?.selectedARAging({ id }),
      });
      setIsSuccessAlert(false);
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    },
  });
};

export default usePatchARAgingMemoMutation;
