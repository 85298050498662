import React, { useEffect, useState } from 'react';
import CommonButton from '../../CommonButton/CommonButton';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import { DISPATCH_PAGE_EXPORT_BOOKING } from '../../../constants/Dispatch/Common/category';
import EXCEL_FILE_NAMES from '../../../constants/Dispatch/Common/excelFileNames';
import { YARD_TAB } from '../../../constants/Dispatch/Common/filterButtonSettings';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';
import YardCheckboxListButton from './YardCheckboxListButton';

const HeaderButtons = ({
  status,
  setCustomizeCols,
  yardList,
  yardQueryObject,
  setYardQuery,
  subFilter,
  category,
  ...props
}) => {
  const [isYardOpen, setIsYardOpen] = useState(true);

  useEffect(() => {
    setIsYardOpen(false);
  }, [subFilter, status, props?.division]);

  return (
    <div className="flex mt-[20px] mb-[15px] pr-[40px] justify-end gap-x-[5px]">
      {subFilter === YARD_TAB && !_.isEmpty(yardQueryObject) && (
        <>
          <YardCheckboxListButton
            yardList={yardList}
            yardQueryObject={yardQueryObject}
            setYardQuery={setYardQuery}
            isYardOpen={isYardOpen}
            setIsYardOpen={setIsYardOpen}
            category={category}
          />
          <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
        </>
      )}
      <CommonButton
        category="greySearch"
        onClick={() => {
          props.setAdvancedSearchOpen(true);
        }}
        border="0"
        backgroundColor="transparent"
        color="#666666"
      >
        Search
      </CommonButton>
      {status !== DISPATCH_PAGE_EXPORT_BOOKING && (
        <>
          <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-center" />
          <CommonButton
            category="customizeColumn"
            onClick={() => {
              setCustomizeCols(true);
            }}
            border="0"
            backgroundColor="transparent"
            color="#666666"
          >
            Customize Columns
          </CommonButton>
        </>
      )}
      <div className="flex gap-x-[10px]">
        {status === DISPATCH_PAGE_EXPORT_BOOKING && (
          <div>
            <CommonButton
              category="add"
              onClick={() => {
                props.setTabModalOpen(true);
                props.setTabIndexOpen(0);
              }}
            >
              Add
            </CommonButton>
          </div>
        )}
        {status === DISPATCH_PAGE_EXPORT_BOOKING && (
          <div>
            {props?.queryObject?.status === 'inactive' ? (
              <CommonButton
                category="inactive"
                borderColor="#B74747"
                preventDupClick
                onClick={({ buttonRef }) => {
                  const selectedBookingList = props?.gridRef?.current?.api
                    ?.getSelectedRows()
                    .map(row => row.seq);
                  if (selectedBookingList.length > 0) {
                    props?.handleBookingListIncomplete({
                      bookingList: selectedBookingList,
                      buttonRef,
                    });
                  } else {
                    updateButtonDisabled({ ref: buttonRef, disabled: false });
                  }
                }}
              >
                Incomplete
              </CommonButton>
            ) : (
              <CommonButton
                category="complete"
                preventDupClick
                onClick={({ buttonRef }) => {
                  const selectedBookingList = props?.gridRef?.current?.api
                    ?.getSelectedRows()
                    .map(row => row.seq);
                  if (selectedBookingList.length > 0) {
                    props?.handleBookingListComplete({
                      bookingList: selectedBookingList,
                      buttonRef,
                    });
                  } else {
                    updateButtonDisabled({ ref: buttonRef, disabled: false });
                  }
                }}
              >
                Complete
              </CommonButton>
            )}
          </div>
        )}
        <div>
          {status !== DISPATCH_PAGE_EXPORT_BOOKING && (
            <CommonButton
              category="excel"
              onClick={() => {
                exportToExcel({
                  gridRef: props?.gridRef,
                  fileName: EXCEL_FILE_NAMES?.[status],
                });
              }}
            >
              EXCEL
            </CommonButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderButtons;
